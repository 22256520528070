import React, { useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker, Circle } from '@react-google-maps/api';


const containerStyle = {
  width: '100%',
  height: '600px'
};

const options = {
  minZoom: 4,
  maxZoom: 18,
}

// const center = {
//     lat: 37.983810,
//     lng: 23.727539
// };

function MarkerDragMap({ center }) {


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    region: "GR",
    language: "el",
  })

  const [map, setMap] = React.useState(null)
  const [zoom, setZoom] = React.useState(12)



  useEffect(() => {
    setTimeout(() => {
      setZoom(12)
    }, 300);
  }, [])

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])




  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={11}
      onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      <Marker
        //onLoad={onLoad}
        // icon={'/assets/userspin.png'}
        // draggable={true}
        position={center}
      // onDragEnd={(e)=>{
      // //console.log(e.latLng.lat())
      //   handler(e.latLng)
      // }}
      />

    </GoogleMap>

  ) : <></>
}

export default React.memo(MarkerDragMap)