import React from 'react'
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import OnboardSteps from '../components/OnboardSteps'
import useMunicipality from '../hooks/useMunicipality';
import { motion, useInView } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useModal } from '../contexts/ModalContext';
import { FaQuestion } from 'react-icons/fa';
import { addQrTransaction } from '../services/transactionService';
import { addCitizenTQrransaction } from '../api';

const QrScanNotFind = () => {
  const { municipality } = useMunicipality();

  const navigate = useNavigate();

  const { login, user, isAuthenticated, updateUserValues } = useAuth();

  const { setModal, unSetModal } = useModal()

  const location = useLocation();



  const getLocation = () =>
  {
     let locs = location.pathname.split("/");

     return locs[2]+"/"+locs[3];
  }


  return (
    <div className="bg-white min-h-screen ">


      <div className="flex flex-col  items-center max-w-2xl px-4 py-16 mx-auto sm:py-20 sm:px-6 lg:max-w-7xl lg:px-8">

        <div className='flex flex-col items-center'>


          <h2 className=" text-2xl font-bold text-gray-700 ">Ο δήμος δεν υποστηρίζει την υπηρεσία σκάναρε και κέρδισε ⚡</h2>

          {/* <h2 className="text-lg font-normal text-gray-700">Ο δήμος δεν υποστηρίζει την υπηρεσία σκάναρε και κέρδισε ⚡</h2> */}

        </div>

        

      </div>

    </div>
  )
}

export default QrScanNotFind