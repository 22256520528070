import { CircularProgress } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import {
    addSportClubsFollow,
    getAllSchools,
    getAllSportClubs,
    getFollow,
    getSportClubsFollow,
    removeSportClubFollow,
} from "../api";
import useMunicipality from "../hooks/useMunicipality";
import { motion } from "framer-motion";
import { getImage } from "../utils/utils";
import { BsRecordCircleFill } from "react-icons/bs";
import { ThumbUp } from "@mui/icons-material";
import { useModal } from "../contexts/ModalContext";
import { FaQuestion } from "react-icons/fa";
import useAuth from "../hooks/useAuth";
import { RiCloseLine } from "react-icons/ri";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel from "react-multi-carousel";

const SportClubs = ({ isHomePage = false }) => {
    const { municipality } = useMunicipality();

    const { user, isAuthenticated, updateUserValues } = useAuth();

    const [followChanged, setFollowChanged] = useState(false);

    const { isLoading, isError, error, data, isFetching } = useQuery(
        ["sportcloubs", municipality, followChanged],
        () => getAllSportClubs({ municipalityId: municipality.MunicipalityId }),
        {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            keepPreviousData: true,
        }
    );

    const {
        isLoading: isLoadingFollow,
        isError: isErrorFollow,
        error: errorFollow,
        data: follow,
    } = useQuery(
        ["sportcloubsfollow", municipality, followChanged],
        () =>
            getSportClubsFollow({ citizenId: user !== null ? user.citizenId : null }),
        {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            keepPreviousData: true,
        }
    );

    const { setModal, unSetModal } = useModal();

    const getProjectPointsBySportClub = (SportClubId) => {
        let points = 0;
        for (let index = 0; index < data.projects.length; index++) {
            const element = data.projects[index];
            if (element.SportClubId === SportClubId) points = element.BalancePoints;
        }

        return points;
    };

    // const getProjectPointsBySchool = (schoollId)=>
    // {
    //     let points = 0
    //     for (let index = 0; index < data.projects.length; index++) {
    //         const element = data.projects[index];
    //         if(element.SchoolId === schoollId)
    //           points = element.BalancePoints;

    //     }

    //     return points;
    // }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const cardVariants = {
        initial: {
            y: 300,
        },
        exit: {
            y: 0,
        },
        animate: {
            y: 0,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0.6,
                duration: 1,
            },
        },
    };

    //error handlers
    if (isLoading || isLoadingFollow)
        return (
            <div className="flex justify-center max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <CircularProgress></CircularProgress>
            </div>
        );

    if (isError || isErrorFollow) {
        return (
            <div className="flex justify-center max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className=" text-center">
                    <p className="texgt-md font-medium">
                        ο δήμος δέν εχει συλλόγους: {error.message}{" "}
                    </p>
                </div>
            </div>
        );
    }

    if (data.sportclubs.length < 1) return null;

    return (
        <div className="bg-white">
            {isHomePage && data.sportclubs.length > 1 ? (
                <div className="flex flex-col space-y-2 md:flex-row items-center justify-between px-4 mx-auto lg:flex lg:px-0 max-w-[1200px] -mb-10 mt-0 ">
                    <div
                        className={`text-white bg-gradient-to-r  ${municipality.IsGreenSchool === 1
                                ? "from-app_green  to-app_dark_green"
                                : "from-app_green  to-app_dark_green"
                            }   font-bold rounded-full text-2xl px-10 py-4 text-center`}
                    >
                        Οι πρωτ-Αθλητές μας
                    </div>
                    <Link
                        to={"contest"}
                        className="shadow-lg flex justify-center  items-center shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 "
                    >
                        <div className="flex items-center">
                            <BsRecordCircleFill></BsRecordCircleFill>
                            <p className="pl-1.5 pb-0.5">{"περισσοτερα".toUpperCase()}</p>
                        </div>
                    </Link>
                </div>
            ) : null}
            <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                {!isHomePage && data.sportclubs.length > 1 ? (
                    <motion.div
                        className="card-container"
                        variants={cardVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        viewport={{ once: true, amount: 0.8 }}
                    >
                        <h2 className="pb-8 text-3xl font-bold text-gray-700">
                            Μάθε για τους πρωταθλητές της ανακύκλωσης
                        </h2>
                        {/* <h2 className="pb-3 text-3xl font-semibold text-gray-700">{data.contest.TitleEl}</h2> */}
                        <h2 className="text-lg font-semibold text-gray-700">
                            Μάθε για τους συλλόγους που σκοράρουν στην ανακύκλωση και ενίσχυσε
                            τους αθλητές με κάθε εκπαιδευτική σου δραστηριότητα.
                        </h2>
                    </motion.div>
                ) : (
                    <></>
                )}

               {
               municipality.LocalityEn === "peristeri" ? (
                <Carousel
                    className="pb-0 pt-5"
                    swipeable={true}
                    draggable={true}
                    showDots={isHomePage ? false : true}
                    responsive={responsive}
                    infinite={true}
                    // focusOnSelect={false}
                    autoPlay={true}
                    dotListClass="custom-dot-list-style pt-10 !mt-10"
                // itemClass="carousel-item-padding-40-px"
                >
                    {data.sportclubs.map(
                        (sportclub, index) => {
                            // if (index > 2)
                            //     return null;
                            // else {

                            return (
                                <motion.div
                                    variants={cardVariants}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    viewport={{ once: true, amount: 0.8 }}
                                    key={sportclub.SportClubId}
                                    className="relative overflow-hidden duration-200 m-5 ease-out shadow-lg cursor-pointer rounded-2xl group hover:-translate-y-2"
                                >
                                    <div className="relative w-full rounded-md  max-h-80 ">
                                        <div className="flex h-56 justify-center items-center">
                                            <div
                                                draggable={false}
                                                className=" flex justify-center  absolute top-0 w-full min-h-14 content-between pt-2 pl-3 pb-2 opacity-90  "
                                                onClick={async () => {
                                                    if (!isAuthenticated) {
                                                        setModal(
                                                            <div className="flex flex-col items-center justify-center py-5">
                                                                <motion.div
                                                                    initial={{
                                                                        rotate: 500,
                                                                        scale: 0,
                                                                        opacity: 0,
                                                                    }}
                                                                    exit={{ rotate: 1, scale: 1, opacity: 0 }}
                                                                    animate={{
                                                                        rotate: 0,
                                                                        scale: 1,
                                                                        opacity: 1,
                                                                    }}
                                                                    transition={{
                                                                        damping: 20,
                                                                        duration: 0.35,
                                                                    }}
                                                                    className="border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center"
                                                                >
                                                                    <div>
                                                                        <RiCloseLine className="w-16 h-16 text-app_red"></RiCloseLine>
                                                                    </div>
                                                                </motion.div>
                                                                <div className="font-bold text-lg text-center pt-5">
                                                                    Κάνε εγγραφή ή συνδέσου για να
                                                                    υποστηρίξεις τον Σύλλογο
                                                                </div>
                                                                <div
                                                                    className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                    onClick={() => {
                                                                        unSetModal();
                                                                    }}
                                                                >
                                                                    OK
                                                                </div>
                                                            </div>
                                                        );
                                                    } else if (
                                                        follow.follow !== null &&
                                                        follow.follow.SportClubId ===
                                                        sportclub.SportClubId
                                                    ) {
                                                        setModal(
                                                            <div className="flex flex-col items-center justify-center py-5">
                                                                <motion.div
                                                                    initial={{
                                                                        rotate: 500,
                                                                        scale: 0,
                                                                        opacity: 0,
                                                                    }}
                                                                    exit={{ rotate: 1, scale: 1, opacity: 0 }}
                                                                    animate={{
                                                                        rotate: 0,
                                                                        scale: 1,
                                                                        opacity: 1,
                                                                    }}
                                                                    transition={{
                                                                        damping: 20,
                                                                        duration: 0.35,
                                                                    }}
                                                                    className="border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center"
                                                                >
                                                                    <div>
                                                                        <FaQuestion className="w-12 h-12 text-app_brown"></FaQuestion>
                                                                    </div>
                                                                </motion.div>
                                                                <div className="font-bold text-lg text-center pt-5">
                                                                    Θέλεις να σταματήσεις υποστηρίζεις τον
                                                                    αθλητικό σύλλογο:
                                                                </div>
                                                                <div className="font-bold text-xl text-center text-app_green ">
                                                                    {sportclub.Name};
                                                                </div>
                                                                {/* <div className='font-semibold text-md text-center pt-5'>Ενίσχυσε με περισσότερους πόντους τον σύλλογό σου, διαβάζοντας άρθρα, βλέποντας βίντεο και παίζοντας κουίζ!</div> */}
                                                                <div className="flex flex-col md:flex-row">
                                                                    <div
                                                                        className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                        onClick={async () => {
                                                                            ///add support for sport club
                                                                            await removeSportClubFollow({
                                                                                citizenId: user.citizenId,
                                                                            });
                                                                            setFollowChanged(!followChanged);
                                                                            unSetModal();
                                                                        }}
                                                                    >
                                                                        ΝΑΙ
                                                                    </div>
                                                                    <div
                                                                        className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                        onClick={() => {
                                                                            unSetModal();
                                                                        }}
                                                                    >
                                                                        ΟΧΙ
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        setModal(
                                                            <div className="flex flex-col items-center justify-center py-5">
                                                                <motion.div
                                                                    initial={{
                                                                        rotate: 500,
                                                                        scale: 0,
                                                                        opacity: 0,
                                                                    }}
                                                                    exit={{ rotate: 1, scale: 1, opacity: 0 }}
                                                                    animate={{
                                                                        rotate: 0,
                                                                        scale: 1,
                                                                        opacity: 1,
                                                                    }}
                                                                    transition={{
                                                                        damping: 20,
                                                                        duration: 0.35,
                                                                    }}
                                                                    className="border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center"
                                                                >
                                                                    <div>
                                                                        <FaQuestion className="w-12 h-12 text-app_yellow"></FaQuestion>
                                                                    </div>
                                                                </motion.div>
                                                                <div className="font-bold text-lg text-center pt-5">
                                                                    Θέλεις να υποστηρίξεις τον αθλητικό
                                                                    σύλλογο:
                                                                </div>
                                                                <div className="font-bold text-xl text-center text-app_green ">
                                                                    {sportclub.Name};
                                                                </div>
                                                                <div className="font-semibold text-md text-center pt-5">
                                                                    Ενίσχυσε με περισσότερους πόντους τον
                                                                    σύλλογό σου, διαβάζοντας άρθρα, βλέποντας
                                                                    βίντεο και παίζοντας κουίζ!
                                                                </div>
                                                                <div className="flex flex-col md:flex-row">
                                                                    <div
                                                                        className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                        onClick={async () => {
                                                                            ///add support for sport club
                                                                            await addSportClubsFollow({
                                                                                citizenId: user.citizenId,
                                                                                municipalityId:
                                                                                    municipality.MunicipalityId,
                                                                                sportClubId: sportclub.SportClubId,
                                                                            });
                                                                            setFollowChanged(!followChanged);
                                                                            unSetModal();
                                                                        }}
                                                                    >
                                                                        ΝΑΙ
                                                                    </div>
                                                                    <div
                                                                        className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                        onClick={() => {
                                                                            unSetModal();
                                                                        }}
                                                                    >
                                                                        ΟΧΙ
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                }}
                                            >
                                                {/* <div onClick={()=>{
                                console.log(follow.follow.SportClubId)
                                console.log(sportclub.SportClubId)
                            }}>click</div> */}
                                                {!follow.follow ? (
                                                    <>
                                                        <div className="leading-[17px] space-x-5 text-base text-left flex justify-center items-center font-semibold text-white">
                                                            <div className="text-gray-800">
                                                                Στήριξε τον σύλλογο{" "}
                                                            </div>
                                                            <ThumbUp className="text-gray-500"></ThumbUp>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {follow.follow.SportClubId ===
                                                            sportclub.SportClubId ? (
                                                            <div className="leading-[17px] space-x-5 text-base text-left flex justify-center items-center font-semibold text-white">
                                                                <div className="text-app_green">
                                                                    Στηρίζω
                                                                </div>
                                                                <ThumbUp className="text-app_green"></ThumbUp>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="leading-[17px] space-x-5 text-base text-left flex justify-center items-center font-semibold text-white">
                                                                    <div className="text-gray-800">
                                                                        Στήριξε τον σύλλογο{" "}
                                                                    </div>
                                                                    <ThumbUp className="text-gray-500"></ThumbUp>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                            <img
                                                src={
                                                    sportclub.Image.includes("ΦΩΤΟΓΡΑΦΙΑ ΣΧΟΛΕΙΟΥ")
                                                        ? getImage("/images/school/default.jpg")
                                                        : getImage(sportclub.Image)
                                                }
                                                alt=""
                                                // onDrag={(e)=>{e.preventDefault()}}
                                                draggable={false}
                                                className="flex object-cover h-32 w-32 rounded-full mb-5"
                                            />

                                            <div
                                                draggable={false}
                                                className=" flex flex-col justify-center  absolute bottom-0 w-full min-h-14 content-between pt-2 pl-3 pb-2 opacity-90 bg-app_dark_green "
                                            >
                                                <div className="leading-[17px] text-base text-left flex justify-center items-center font-semibold text-white">
                                                    {sportclub.Name}
                                                </div>
                                                <div className=" flex-col text-md text-center flex justify-center items-center font-semibold text-white">
                                                    <div className="text-sm ">Πόντοι ανά σύλλογο</div>

                                                    <div onClick={() => { }}>
                                                        {getProjectPointsBySportClub(
                                                            sportclub.SportClubId
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            );
                        }
                        // }
                    )}
                </Carousel>
            ) :
               
               
               <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                    { (
                        data.sportclubs.map(
                            (sportclub, index) => {
                                return (
                                    <motion.div
                                        variants={cardVariants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        viewport={{ once: true, amount: 0.8 }}
                                        key={sportclub.SportClubId}
                                        className="relative overflow-hidden duration-200 m-5 ease-out shadow-lg cursor-pointer rounded-2xl group hover:-translate-y-2"
                                    >
                                        <div className="relative w-full rounded-md  max-h-80 ">
                                            <div className="flex h-56 justify-center items-center">
                                                <div
                                                    draggable={false}
                                                    className=" flex justify-center  absolute top-0 w-full min-h-14 content-between pt-2 pl-3 pb-2 opacity-90  "
                                                    onClick={async () => {
                                                        if (!isAuthenticated) {
                                                            setModal(
                                                                <div className="flex flex-col items-center justify-center py-5">
                                                                    <motion.div
                                                                        initial={{
                                                                            rotate: 500,
                                                                            scale: 0,
                                                                            opacity: 0,
                                                                        }}
                                                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}
                                                                        animate={{
                                                                            rotate: 0,
                                                                            scale: 1,
                                                                            opacity: 1,
                                                                        }}
                                                                        transition={{
                                                                            damping: 20,
                                                                            duration: 0.35,
                                                                        }}
                                                                        className="border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center"
                                                                    >
                                                                        <div>
                                                                            <RiCloseLine className="w-16 h-16 text-app_red"></RiCloseLine>
                                                                        </div>
                                                                    </motion.div>
                                                                    <div className="font-bold text-lg text-center pt-5">
                                                                        Κάνε εγγραφή ή συνδέσου για να υποστηρίξεις
                                                                        τον Σύλλογο
                                                                    </div>
                                                                    <div
                                                                        className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                        onClick={() => {
                                                                            unSetModal();
                                                                        }}
                                                                    >
                                                                        OK
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else if (
                                                            follow.follow !== null &&
                                                            follow.follow.SportClubId ===
                                                            sportclub.SportClubId
                                                        ) {
                                                            setModal(
                                                                <div className="flex flex-col items-center justify-center py-5">
                                                                    <motion.div
                                                                        initial={{
                                                                            rotate: 500,
                                                                            scale: 0,
                                                                            opacity: 0,
                                                                        }}
                                                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}
                                                                        animate={{
                                                                            rotate: 0,
                                                                            scale: 1,
                                                                            opacity: 1,
                                                                        }}
                                                                        transition={{
                                                                            damping: 20,
                                                                            duration: 0.35,
                                                                        }}
                                                                        className="border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center"
                                                                    >
                                                                        <div>
                                                                            <FaQuestion className="w-12 h-12 text-app_brown"></FaQuestion>
                                                                        </div>
                                                                    </motion.div>
                                                                    <div className="font-bold text-lg text-center pt-5">
                                                                        Θέλεις να σταματήσεις υποστηρίζεις τον
                                                                        αθλητικό σύλλογο:
                                                                    </div>
                                                                    <div className="font-bold text-xl text-center text-app_green ">
                                                                        {sportclub.Name};
                                                                    </div>
                                                                    {/* <div className='font-semibold text-md text-center pt-5'>Ενίσχυσε με περισσότερους πόντους τον σύλλογό σου, διαβάζοντας άρθρα, βλέποντας βίντεο και παίζοντας κουίζ!</div> */}
                                                                    <div className="flex flex-col md:flex-row">
                                                                        <div
                                                                            className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                            onClick={async () => {
                                                                                ///add support for sport club
                                                                                await removeSportClubFollow({
                                                                                    citizenId: user.citizenId,
                                                                                });
                                                                                setFollowChanged(!followChanged);
                                                                                unSetModal();
                                                                            }}
                                                                        >
                                                                            ΝΑΙ
                                                                        </div>
                                                                        <div
                                                                            className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                            onClick={() => {
                                                                                unSetModal();
                                                                            }}
                                                                        >
                                                                            ΟΧΙ
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            setModal(
                                                                <div className="flex flex-col items-center justify-center py-5">
                                                                    <motion.div
                                                                        initial={{
                                                                            rotate: 500,
                                                                            scale: 0,
                                                                            opacity: 0,
                                                                        }}
                                                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}
                                                                        animate={{
                                                                            rotate: 0,
                                                                            scale: 1,
                                                                            opacity: 1,
                                                                        }}
                                                                        transition={{
                                                                            damping: 20,
                                                                            duration: 0.35,
                                                                        }}
                                                                        className="border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center"
                                                                    >
                                                                        <div>
                                                                            <FaQuestion className="w-12 h-12 text-app_yellow"></FaQuestion>
                                                                        </div>
                                                                    </motion.div>
                                                                    <div className="font-bold text-lg text-center pt-5">
                                                                        Θέλεις να υποστηρίξεις τον αθλητικό σύλλογο:
                                                                    </div>
                                                                    <div className="font-bold text-xl text-center text-app_green ">
                                                                        {sportclub.Name};
                                                                    </div>
                                                                    <div className="font-semibold text-md text-center pt-5">
                                                                        Ενίσχυσε με περισσότερους πόντους τον
                                                                        σύλλογό σου, διαβάζοντας άρθρα, βλέποντας
                                                                        βίντεο και παίζοντας κουίζ!
                                                                    </div>
                                                                    <div className="flex flex-col md:flex-row">
                                                                        <div
                                                                            className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                            onClick={async () => {
                                                                                ///add support for sport club
                                                                                await addSportClubsFollow({
                                                                                    citizenId: user.citizenId,
                                                                                    municipalityId:
                                                                                        municipality.MunicipalityId,
                                                                                    sportClubId: sportclub.SportClubId,
                                                                                });
                                                                                setFollowChanged(!followChanged);
                                                                                unSetModal();
                                                                            }}
                                                                        >
                                                                            ΝΑΙ
                                                                        </div>
                                                                        <div
                                                                            className="flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150"
                                                                            onClick={() => {
                                                                                unSetModal();
                                                                            }}
                                                                        >
                                                                            ΟΧΙ
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {/* <div onClick={()=>{
                                        console.log(follow.follow.SportClubId)
                                        console.log(sportclub.SportClubId)
                                    }}>click</div> */}
                                                    {!follow.follow ? (
                                                        <>
                                                            <div className="leading-[17px] space-x-5 text-base text-left flex justify-center items-center font-semibold text-white">
                                                                <div className="text-gray-800">
                                                                    Στήριξε τον σύλλογο{" "}
                                                                </div>
                                                                <ThumbUp className="text-gray-500"></ThumbUp>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {follow.follow.SportClubId ===
                                                                sportclub.SportClubId ? (
                                                                <div className="leading-[17px] space-x-5 text-base text-left flex justify-center items-center font-semibold text-white">
                                                                    <div className="text-app_green">Στηρίζω</div>
                                                                    <ThumbUp className="text-app_green"></ThumbUp>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="leading-[17px] space-x-5 text-base text-left flex justify-center items-center font-semibold text-white">
                                                                        <div className="text-gray-800">
                                                                            Στήριξε τον σύλλογο{" "}
                                                                        </div>
                                                                        <ThumbUp className="text-gray-500"></ThumbUp>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>

                                                <img
                                                    src={
                                                        sportclub.Image.includes("ΦΩΤΟΓΡΑΦΙΑ ΣΧΟΛΕΙΟΥ")
                                                            ? getImage("/images/school/default.jpg")
                                                            : getImage(sportclub.Image)
                                                    }
                                                    alt=""
                                                    // onDrag={(e)=>{e.preventDefault()}}
                                                    draggable={false}
                                                    className="flex object-cover h-32 w-32 rounded-full mb-5"
                                                />

                                                <div
                                                    draggable={false}
                                                    className=" flex flex-col justify-center  absolute bottom-0 w-full min-h-14 content-between pt-2 pl-3 pb-2 opacity-90 bg-app_dark_green "
                                                >
                                                    <div className="leading-[17px] text-base text-left flex justify-center items-center font-semibold text-white">
                                                        {sportclub.Name}
                                                    </div>
                                                    <div className=" flex-col text-md text-center flex justify-center items-center font-semibold text-white">
                                                        <div className="text-sm ">Πόντοι ανά σύλλογο</div>

                                                        <div onClick={() => { }}>
                                                            {getProjectPointsBySportClub(
                                                                sportclub.SportClubId
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </motion.div>
                                );
                            }
                            // }
                        )
                    )}
                </div>}

                {!isHomePage ? (
                    <div className="flex-1 pt-12 ">
                        {/* <Link
        to={'index/' + data.contest.SportClubContestId.toString()}
        // state={{ offer: offer, citizenFavorites: citizenFavorites, merchant: getMerchant(offer.MerchantId) }}
        className="flex w-44 mb-6 shadow-lg shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
        <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
            <p className='pl-1.5 pb-0.5'>{'περισσοτερα'.toUpperCase()}</p></div>
    </Link> */}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default SportClubs;
