import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { ArrowBackIos, ArrowRight, ArrowRightAlt, ArrowRightAltTwoTone, Visibility, VisibilityOff } from '@mui/icons-material';
// import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, CircularProgress, FormControl, Grid, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
// import { Paragraph } from 'app/components/Typography';
import useAuth from '../hooks/useAuth';
import { Formik } from 'formik';
import { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '../components/Loading';
import { LoadingButton } from '@mui/lab';
import { useQuery } from 'react-query';
import { getLiveMunicipalities, registerUser, sendContactEmail } from '../api';
import useMunicipality from '../hooks/useMunicipality';
import { motion } from 'framer-motion';
import { useModal } from '../contexts/ModalContext';
import { RiCheckLine } from 'react-icons/ri';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));



// inital login credentials
const initialValues = {
  email: '',
  subject: '',
  name: '',
  content:''


};



// form field validation schema
const validationSchema = Yup.object().shape({

  email: Yup.string().email('Η διεύθυνση email δεν είναι έγκυρη').required('Παρακαλώ εισάγετε διεύθυνση email'),
  name: Yup.string().required('Υποχρεωτικό πεδίο'),
  subject: Yup.string().required('Υποχρεωτικό πεδίο'),
  content: Yup.string().required('Υποχρεωτικό πεδίο'),


});

const Contact = ({ isLandingPage = false }) => {
  const breadcrumbs = useBreadcrumbs();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const [showpass, setShowpass] = useState(false);

  const { setModal, unSetModal } = useModal()


  const location = useLocation()

  const [category, setCtegory] = useState("info@followgreen.gr");

  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const emailData =
      {
        email: values.email,        
        followgreenEmail: category,        
        content: values.content,
        name: values.name,
        subject: values.subject,
        
      }
    //console.log(emailData)
      const emailResponse = await sendContactEmail(emailData);
      setModal(
        <div className='flex flex-col items-center justify-center py-5'>
          <motion.div
            initial={{ rotate: 500, scale: 0, opacity: 0 }}
            exit={{ rotate: 1, scale: 1, opacity: 0 }}

            animate={{ rotate: 0, scale: 1, opacity: 1 }}
            transition={{
              damping: 20,
              duration: 0.35
            }}
            className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

            <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
          </motion.div>
          <div className='font-bold text-lg text-center pt-5'>Το email σας έχει αποσταλεί</div>
          <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
            onClick={() => {
              resetForm();
              unSetModal();
            }}
          >OK</div>
        </div>
      )
      setLoading(false);

      // navigate('/');
    } catch (e) {
    //console.log("ERROR CREDENTIALS")
      setIsWrong(true)
      setLoading(false);
    }
  };

  return (



    <motion.div

      initial={
        { x: -300, }
      }
      animate={
        {
          x: 0,
          transition: {
            type: "spring",
            bounce: 0.6,
            duration: 1
          }
        }
      }
      exit={
        { x: -300, }
      }
      className={`${!isLandingPage ? 'my-[5%] px-5 lg:px-[28%]' : ''} relative w-full bg-white flex justify-center`} >
      {/* <svg className='absolute' viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path fill="#8FD6D2" d="M48.2,-38.6C55,-29.8,48,-10.2,40.5,3.7C32.9,17.7,24.7,26,15.6,29C6.6,32,-3.3,29.7,-19.5,27.1C-35.6,24.4,-58,21.4,-61.9,12C-65.8,2.6,-51.1,-13.3,-37.7,-23.5C-24.3,-33.7,-12.1,-38.4,4.3,-41.8C20.7,-45.2,41.3,-47.3,48.2,-38.6Z" transform="translate(100 100)" />
            </svg> */}

      {/* <div className="absolute top-0 -left-4 w-36 h-36 bg-app_red rounded-full  opacity-10  blur-md"></div>
            <div className="absolute top-0 -right-4 w-72 h-72 bg-app_green_light rounded-full  opacity-10 blur-md"></div>
            <div className="absolute -bottom-8 left-20 w-72 h-72 bg-app_yellow rounded-full  opacity-10 blur-md"></div> */}

      {/* <div className="flex">{breadcrumbs.map(({ breadcrumb }) => {
            return <div className="p-1 flex ">{breadcrumb}/</div>
         })}</div> */}
      <div className="z-10 relative !shadow-2xl   rounded-3xl  bg-white overflow-hidden flex flex-col justify-center items-center">

        <div className='pt-10 font-semibold text-xl text-gray-400'>Επικοινωνία</div>
        <div className="bg-gradient-to-r w-72 mt-1 h-2 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>



        {isWrong ? <div className='pt-5 text-red-400'>Σφάλμα</div> : null}

        <div >
          <div className='bg-white p-10 px-16 h-auto'>
            <Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            // enableReinitialize={false}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>

                  <FormControl
                    // helperText={touched.password && errors.password}
                    // error={Boolean(errors.password && touched.password)}
                    className='!outline-0 !border-0 w-full'>
                    <Select

                      className="flex w-[100%]  h-10 mb-5"
                      defaultValue={"info@followgreen.gr"}
                      onChange={
                        (e) => {

                          const val = e.target.value;
                          setCtegory(val)
                          // var result = data.rows.filter(obj => {
                          //   return obj.MunicipalityId === parseInt(val)
                          // })

                          setCtegory(val)
                        }
                      }

                    >

                      <MenuItem value={"info@followgreen.gr"}>Γενικές πληροφορίες</MenuItem>
                      <MenuItem value={"offers@followgreen.gr"}>Προσφορές</MenuItem>
                      <MenuItem value={"support@followgreen.gr"}>Τεχνική Υποστήριξη</MenuItem>
                      <MenuItem value={"schools@followgreen.gr"}>Σχολεία</MenuItem>




                    </Select>
                  </FormControl>

                  <TextField
                    className='group !group:border-red-300'
                    fullWidth
                    size="small"
                    type="name"
                    name="name"
                    label="Όνομα"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={handleChange}
                    helperText={touched.name && errors.name}
                    error={Boolean(errors.name && touched.name)}
                    sx={{
                      mb: 3,
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //     borderColor: "#F19853"
                      // }
                    }}
                  />
                  <TextField
                    className='group !group:border-red-300'
                    fullWidth
                    size="small"
                    type="subject"
                    name="subject"
                    label="Θέμα"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.subject}
                    onChange={handleChange}
                    helperText={touched.subject && errors.subject}
                    error={Boolean(errors.subject && touched.subject)}
                    sx={{
                      mb: 3,
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //     borderColor: "#F19853"
                      // }
                    }}
                  />
                  <TextField
                    className='group !group:border-red-300'
                    fullWidth
                    size="small"
                    type="email"
                    name="email"
                    label="Διεύθυνση email"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                    helperText={touched.email && errors.email}
                    error={Boolean(errors.email && touched.email)}
                    sx={{
                      mb: 3,
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //     borderColor: "#F19853"
                      // }
                    }}
                  />

                  <TextField
                    className='group !group:border-red-300'
                    fullWidth
                    size="medium"
                    multiline
                    minRows={5}
                    type="text"
                    name="content"
                    label="Σχόλια"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.content}
                    onChange={handleChange}
                    helperText={touched.content && errors.content}
                    error={Boolean(errors.content && touched.content)}
                    sx={{
                      mb: 3,
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //     borderColor: "#F19853"
                      // }
                    }}
                  />



                  <LoadingButton
                    className='!bg-gradient-to-r disabled:text-white disabled:cursor-not-allowed from-app_yellow to-app_brown !shadow-orange-300 !shadow-xl !drop-shadow-none'
                    type="submit"
                   
                    loading={loading}
                    variant="contained"
                    sx={{ my: 2 }}
                  >
                    <div className='capitalize pr-2'>Αποστολή   </div>
                    <ArrowRightAlt></ArrowRightAlt>
                  </LoadingButton>




                </form>

              )}
            </Formik>

            


          </div>
        </div>

      </div>
    </motion.div >



  )
}

export default Contact