import React from 'react'
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import OnboardSteps from '../components/OnboardSteps'
import useMunicipality from '../hooks/useMunicipality';
import { motion, useInView } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useModal } from '../contexts/ModalContext';
import { FaQuestion } from 'react-icons/fa';
import { addQrTransaction } from '../services/transactionService';
import { addCitizenTQrransaction } from '../api';
import { useEffect } from 'react';

const QrScan = () => {
  const { municipality } = useMunicipality();

  const navigate = useNavigate();

  const { login, user, isAuthenticated, updateUserValues } = useAuth();

  const { setModal, unSetModal } = useModal()

  const location = useLocation();

  useEffect(() => {
    if(isAuthenticated)
    {
       if(municipality.MunicipalityId !== user.municipalityId)
       {

        setModal(
          <div className='flex flex-col items-center justify-center py-5'>
            <motion.div
              initial={{ rotate: 500, scale: 0, opacity: 0 }}
              exit={{ rotate: 1, scale: 1, opacity: 0 }}

              animate={{ rotate: 0, scale: 1, opacity: 1 }}
              transition={{
                damping: 20,
                duration: 0.35
              }}
              className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

              <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
            </motion.div>
            <div className='font-bold text-lg text-center pt-5'>Ο δήμος σου δεν υποστηρίζει την υπηρεσία σκάναρε και κέρδισε</div>
            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
              onClick={() => { unSetModal() }}
            >OK</div>
          </div>
        )

        navigate("../../../" + user.localityEn);
       }
       
    }
  }, [municipality])
  






  const getLocation = () =>
  {
     let locs = location.pathname.split("/");

     return locs[2]+"/"+locs[3];
  }


  return (
    <div className="bg-white min-h-screen ">


      <div className="flex flex-col  items-center max-w-2xl px-4 py-16 mx-auto sm:py-20 sm:px-6 lg:max-w-7xl lg:px-8">

        <div className='flex flex-col items-center'>


          <h2 className=" text-3xl font-bold text-gray-700 ">Το Followgreen σε επιβραβεύει! 🎁</h2>

          <h2 className="text-lg font-normal text-gray-700">Υπηρεσία σκάναρε και κέρδισε 150 πόντους κάθε εβδομάδα.</h2>
          

        </div>

        {/* <div className='flex justify-center items-center flex-col pt-10'>
                <div className=" border-gray-200 text-prim font-semibold text-2xl pb-1">Πώς λειτουργεί</div>
                <div className="bg-gradient-to-r w-56 h-1.5 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>
        </div> */}

        <div>
          {!isAuthenticated
            ?

            <div className='justify-center flex whitespace-pre-line px-8 pb-10 cursor-pointer  pt-5' >
              <div className='shadow-md p-5 bg-gradient-to-r from-[#DEEFFF] to-[#F4F7EB] rounded-3xl'>
                
                <div className='text-gray-700 font-semibold text-center'>Απόκτησε αυτή την προσφορά, κάνοντας είσοδο. <br/><br/><p className='!text-[11px] font-semibold italic'>*Εάν δεν είσαι ήδη μέλος σκάναρε ξανά το Qr Code όταν ολοκληρωθεί η διαδικασία εγγραφής και επιβεβαίωσης.</p></div>
                <div className='flex justify-center space-x-3'>
                  <div className='justify-center flex whitespace-pre-line   cursor-pointer' onClick={() => {
                    // navigate('../../../account/login?qr='+window.location.href)
                    navigate('../'+municipality.LocalityEn + '/account/login?qr='+getLocation());
                  }}>
                    <div className='text-white  font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Είσοδος</div>
                  </div>
                  <div className='justify-center flex whitespace-pre-line   cursor-pointer' onClick={() => {
                    navigate('../../../account/register')
                  }}>
                    <div className='text-white font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Εγγραφη</div>
                  </div>
                </div>
              </div>

            </div>
            :
            <button className={`m-5 ${isAuthenticated ? "bg-app_green_light opacity-100" : "bg-gray-500 opacity-30"}  rounded-full `}
              onClick={async () => {
                if (!isAuthenticated) {
                  setModal(
                    <div className='flex flex-col items-center justify-center py-5'>
                      <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                          damping: 20,
                          duration: 0.35
                        }}
                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                      </motion.div>
                      <div className='font-bold text-lg text-center pt-5'>Απόκτησε αυτή την προσφορά, κάνοντας είσοδο. Εάν δεν είσαι ήδη μέλος κάνε τώρα εγγραφή και απόλαυσε την επιβράβευση.</div>
                      <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                        onClick={() => { unSetModal() }}
                      >OK</div>
                    </div>
                  )
                }
                else {

                  const qrTransactionReq = await addCitizenTQrransaction({ CitizenId: user.citizenId })
                  if (qrTransactionReq.status === 2) {
                    updateUserValues()
                    setModal(
                      <div className='flex flex-col items-center justify-center py-5'>
                        <motion.div
                          initial={{ rotate: 500, scale: 0, opacity: 0 }}
                          exit={{ rotate: 1, scale: 1, opacity: 0 }}

                          animate={{ rotate: 0, scale: 1, opacity: 1 }}
                          transition={{
                            damping: 20,
                            duration: 0.35
                          }}
                          className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                          <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                        </motion.div>
                        <div className='font-bold text-lg text-center pt-5'>Ευχαριστούμε για την συμμετοχή σου. Μόλις κέρδισες 150 πόντους.</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                          onClick={() => { unSetModal() }}
                        >OK</div>
                      </div>
                    )
                  }
                  else {

                    setModal(
                      <div className='flex flex-col items-center justify-center py-5'>
                        <motion.div
                          initial={{ rotate: 500, scale: 0, opacity: 0 }}
                          exit={{ rotate: 1, scale: 1, opacity: 0 }}

                          animate={{ rotate: 0, scale: 1, opacity: 1 }}
                          transition={{
                            damping: 20,
                            duration: 0.35
                          }}
                          className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                          <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                        </motion.div>
                        <div className='font-bold text-lg text-center pt-5'>Έχεις ήδη λάβει τους πόντους σου για αυτή την εβδομάδα. Μπορείς να προσπαθήσεις την ερχόμενη Δευτέρα..</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                          onClick={() => { unSetModal() }}
                        >OK</div>
                      </div>
                    )
                  }




                }

              }}
            >
              <p className="px-5 py-4 font-semibold text-white ">{'Πάρε τους πόντους σου'}</p>
            </button>
          }

        </div>

      </div>

    </div>
  )
}

export default QrScan