import React, { useState } from 'react'
import { generateRandomId } from '../utils/utils'
import { motion } from 'framer-motion';
import { Check, CheckCircle, Close, FactCheck, Remove, RemoveDone } from '@mui/icons-material';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../contexts/ModalContext';
import { date } from 'yup';

const VoteOption = ({ question, submitAnswer, canVoteMore, expireDate }) => {
    const { logout, user, isAuthenticated, updateUserValues } = useAuth();
    const navigate = useNavigate()

    const { setModal, unSetModal } = useModal()

    function extractVideoID(url) {
        console.log(url);
        const res = url.split("=");
        const embeddedUrl = "https://youtube.com/embed/" + res[1];
        return embeddedUrl

    }

    // console.log(question)
    return (
        <div className='_md:w-1/2 mt-5 max-w-[580px] w-full'>


            <div
                // initial={{ rotate: 0, x: 300, scale: 0, opacity: 0 }}
                // animate={{ rotate: 0, x: 0, scale: 1, opacity: 1 }}
                // transition={{
                //     // type: "spring",
                //     // stiffness: 6,
                //     // damping: 100,
                //     // duration: 0.5
                // }}
                // key={generateRandomId()}

                className='w-full h-[360px]'>


                {
                    question.VoteOptionImage.includes('youtube') ? <ReactPlayer url={question.VoteOptionImage}
                        width="100%"
                        height="100%"
                        playsinline={true}
                        pip={false}
                        controls={false}
                        config={{
                            youtube: {
                                playerVars: { showinfo: 0 }
                            },
                        }}
                        onProgress={(s) => {
                            // console.log(s)
                        }}
                        onEnded={() => {

                        }} />
                        :

                        <img
                            className='shadow-xl object-contain w-full !rounded-t-3xl  h-[360px]'
                            src={(question.VoteOptionImage.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + question.VoteOptionImage}
                            alt='' />



                }




            </div>
            <motion.div
                initial={{ rotate: 0, x: -300, scale: 0, opacity: 0 }}
                animate={{ rotate: 0, x: 0, scale: 1, opacity: 1 }}
                transition={{
                    // type: "spring",
                    // stiffness: 6,
                    // damping: 100,
                    // duration: 0.5
                }}
                key={question.VoteOptionId}
                className='flex flex-col mt-8'>


                <div className='font-bold text-sm sm:text-md md:text-lg  text-app_green text-center' dangerouslySetInnerHTML={{ __html: '<div>' + question.BodyEl.replaceAll(`/images/`, process.env.REACT_APP_BUCKET_URL + `/images/`) }}></div>

                {!isAuthenticated
                    ?
                    <>
                        <div className='mt-5 mb-0 justify-center flex whitespace-pre-line  cursor-pointer ' >
                            <div className='shadow-md p-5 bg-gradient-to-r from-[#DEEFFF] to-[#F4F7EB] rounded-3xl'>
                                <div className='text-gray-700 font-semibold text-center'>Προσοχή: Εάν είστε ήδη εγγεγραμένο μέλος παρακαλούμε συνδεθείτε ώστε να συμμετάσχετε στην ψηφοφορία</div>
                                <div className='flex justify-center space-x-3'>
                                    <div className='justify-center flex whitespace-pre-line   cursor-pointer' onClick={() => {
                                        navigate('../../../account/login')
                                    }}>
                                        <div className='text-white  font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Είσοδος</div>
                                    </div>

                                </div>
                            </div></div>
                    </>
                    :
                    <>
                        {(() => {
                            console.log(Date.now())
                            let current = Date.now();
                            let expire = new Date(expireDate).getTime()
                            
                            if (current < expire) {
                                return (
                                    <>
                                        <div className=' mt-5 font-semibold text-md text-center'>Σου αρέσει αυτή η δημιουργία; </div>
                                        <div className='flex justify-center space-x-5 mt-5'>
                                            <div className='hover:bg-app_green hover:shadow-md px-8 flex justify-center items-center space-x-3 bg-app_dark_green p-3 shadow-lg cursor-pointer rounded-3xl'
                                                onClick={async () => {
                                                    // if()
                                                    if (canVoteMore) {
                                                        submitAnswer({ isYes: true, voteOptionId: question.VoteOptionId });
                                                        setModal(
                                                            <div className='flex flex-col items-center justify-center py-5'>
                                                                <motion.div
                                                                    initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                                    exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                                    animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                                    transition={{
                                                                        damping: 20,
                                                                        duration: 0.35
                                                                    }}
                                                                    className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                                                                    <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                                                                </motion.div>
                                                                <div className='font-bold text-lg text-center pt-5'>Ευχαριστούμε για την ψηφοφορία!</div>
                                                                <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                    onClick={() => { unSetModal() }}
                                                                >OK</div>
                                                            </div>
                                                        )

                                                    }
                                                    else {
                                                        setModal(
                                                            <div className='flex flex-col items-center justify-center py-5'>
                                                                <motion.div
                                                                    initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                                    exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                                    animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                                    transition={{
                                                                        damping: 20,
                                                                        duration: 0.35
                                                                    }}
                                                                    className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                                    <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                                                </motion.div>
                                                                <div className='font-bold text-lg text-center pt-5'>Έχετε ήδη εξαντλήσει τις 3ς ψήφους!</div>
                                                                <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                    onClick={() => { unSetModal() }}
                                                                >OK</div>
                                                            </div>
                                                        )
                                                    }
                                                }}
                                            >
                                                <div className=' text-white font-semibold text-md text-center'> ΝΑΙ </div>

                                            </div>
                                            <div className='hover:bg-app_green hover:shadow-md px-8 flex justify-center items-center space-x-3 bg-app_dark_green p-3 shadow-lg cursor-pointer rounded-3xl'
                                                onClick={async () => {
                                                    submitAnswer({ isYes: false, voteOptionId: question.VoteOptionId });
                                                }}
                                            >
                                                <div className=' text-white font-semibold text-md text-center'> ΟΧΙ </div>

                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            else{
                                return <div className='px-8 mt-5 flex justify-center items-center  bg-app_dark_green p-3 shadow-lg rounded-3xl text-white font-semibold'>Η περίοδος ψηφοφοριών έχει λήξει.</div>
                            }

                            return null;
                        })()}

                    </>
                }





            </motion.div>



        </div>
    )
}

export default VoteOption