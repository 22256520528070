import React, { useState } from 'react'
import AppDownloadSection from '../components/AppDownloadSection'
import Awards from '../components/Awards'
import LeftOverlayContent from '../components/LeftOverlayContent'
import MunicipalitiesBanner from '../components/MunicipalitiesBanner'
import OnboardSteps from '../components/OnboardSteps'
import RightOverlayContent from '../components/RightOverlayContent'
import Login from './Login'
import Register from './Register'
import logo from '../assets/followgreen-app-logo.svg';

const Landing = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const overlayBg =
    "bg-gradient-to-r from-blue-800 via-purple-800 to-indigo-800";
  return (
    <div className="bg-white min-h-screen  px-4 mx-auto sm:py-20 sm:px-6 lg:px-8">

     <div className='flex justify-center pb-10 '>
     <div className='grid grid-cols-1  sm:grid-cols-1 lg:grid-cols-2 space-x-0 md:space-x-10 w-full max-w-[1300px]'>
        <div className='flex flex-col justify-center'>
          <img className="w-44  mt-5  md:pt-2" alt="Followgreen" src={logo} />
          <div className=" border-gray-200 text-prim font-semibold text-2xl pb-1 text-gray-700">ΑΝΑΚΥΚΛΩΣΕ ΚΑΙ ΚΕΡΔΙΣΕ</div>
          {/* <div className="bg-gradient-to-r w-[full] h-1.5 rounded-full from-app_green_light  to-app_green "></div> */}
          <div className=" border-gray-200 pt-3">Η πλατφόρμα που σε επιβραβεύει ανακυκλώνοντας στον Δήμο σου.</div>
          <img className="w-32 hidden md:flex" alt="app store badge" src={process.env.REACT_APP_BUCKET_URL + "/Content/css/assets/leafs.svg"} />
          <div className=" pt-5 md:pt-20">
            <img className="mr-2" alt="app store badge" src={process.env.REACT_APP_BUCKET_URL + "/Content/css/assets/followgreen-hero-image-3.svg"} />

          </div>
        </div>
        <div className=''>
        <div className="flex justify-center space-x-5 p-8">
                            <div
                                className={`${!isLogin  ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white  cursor-pointer  px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                                onClick={() => { setIsLogin(false) }}>Εγγραφή</div>
                            <div
                                className={`${isLogin ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer   px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                                onClick={() => { setIsLogin(true) }}>Είσοδος</div>
                           
                        </div>
          {
            !isLogin ? (
              <Register isLandingPage={true} />
              ) : (
                <Login isLandingPage={true} />
            )
          }
        </div>

      </div>
     </div>

      {/* <Login /> */}

      <div className='flex justify-center items-center flex-col pt-10 '>
        <div className=" border-gray-200 text-prim font-semibold text-2xl pb-1">Πώς λειτουργεί</div>
        <div className="bg-gradient-to-r w-56 h-1.5 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>
      </div>

      <div className='relative py-16 mx-auto sm:py-10 max-w-[1300px]'><OnboardSteps></OnboardSteps></div>

      <MunicipalitiesBanner></MunicipalitiesBanner>

      <Awards></Awards>

      {/* <AppDownloadSection></AppDownloadSection> */}
    </div>
  )
}

export default Landing