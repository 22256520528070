import React from "react";
import useMunicipality from "../hooks/useMunicipality";

const OnboardSteps = () => {
  const { municipality } = useMunicipality();
  const onboardTiles = [
    {
      title: "Δημιουργείς λογαριασμό",
      img: "/assets/images/onboard-1.svg",
      left: 1,
      description: "Δηλώνεις όνομα, email και Δήμο στον οποίο ανήκεις και ο λογαριασμός σου είναι έτοιμος. Με την εγγραφή, κερδίζεις τους πρώτους πόντους επιβράβευσης. ​​Εάν ο Δήμος σου είναι followgreen Δήμος, τότε θα οδηγηθείς στη σελίδα του, διαφορετικά θα μπορείς να ενημερώνεσαι ως επισκέπτης.",
    },
    {
      title: "Κερδίζεις πόντους",
      img: "/assets/images/onboard-2.svg",
      left: 150,
      description: municipality.IsGreenSchool === 1 ?
      "Κερδίζεις πόντους α) όταν ενημερώνεσαι για την ανακύκλωση μέσα άρθρων, κουίζ, βίντεο και Green Missions."
      : "Κερδίζεις πόντους α) όταν ενημερώνεσαι για την ανακύκλωση μέσω άρθρων, κουίζ, βίντεο και Green Missions και β) όταν επαναχρησιμοποιείς ή ανακυκλώνεις σε συγκεκριμένα σημεία του Δήμου σου, λαμβάνοντας κουπόνι ανακύκλωσης. Ακόμη, κερδίζεις πόντους όταν δηλώσεις την ανακύκλωσή σου εβδομαδιαία στη σελίδα ‘’Ανακύκλωσε’’, καθώς και όταν συστήνεις το Followgreen σε φίλο/η σου.",
    },
    {
      title: "Εξαργυρώνεις πόντους",
      img: "/assets/images/onboard-3.svg",
      left: 200,
      description: "Χρησιμοποιείς τους πόντους επιβράβευσης για να αποκτήσεις προσφορές τοπικών επιχειρήσεων στον Δήμο σου ή/και web προσφορές σε όλη την Ελλάδα.",
    },
    {
      title: "Δωρίζεις πόντους στα σχολεία",
      img: "/assets/images/onboard-4.svg",
      left: 220,
      description: "Δωρίζεις τους πόντους επιβράβευσης σε σχολεία της επιλογής σου, στο πλαίσιο δράσεων ανακύκλωσης που διοργανώνει ο Δήμος. Με αυτόν τον τρόπο στηρίζεις την προσπάθεια των παιδιών για ανακύκλωση και ενθαρρύνεις την ευγενή τους άμιλλα.",
    },
  ];

  if(municipality.LocalityEn === '4all' )
  {
    onboardTiles.length =3
  }

  return (
    <div className="grid grid-cols-1  gap-x-3 sm:grid-cols-2 lg:grid-cols-7 mx-auto m-16">
      {onboardTiles.map((tile, index) => {
        if(municipality.IsGreenSchool === 1  &&  index===2) return <></>
        else
        return (
          <>
            <div key={index} className="w-full flex flex-col  items-center">
              <div

                className={`group  hover:bg-[#008755] bg-145 bg-center rounded-full h-40 w-40 transition duration-300 ease-in-out cursor-pointer`}
                style={{ backgroundImage: `url(${tile.img})` }}
              >

                <span
                  class={`group-hover:opacity-100 transition-opacity
                       bg-white text-gray-500 rounded-full p-8  duration-300 ease-in-out
                        absolute w-2/4  bottom-0 drop-shadow font-semibold
                         opacity-0 invisible lg:visible`}
                         style={{
                          left: `${index * tile.left}px`,
                          
                        }}
                         >
                  {tile.description}
                </span>
              </div>
              <div className="w-32  flex mt-[1rem]">
                <div className="font-bold text-gray-600   text-center">{tile.title}</div>
              </div>

            </div>
            {
              municipality.LocalityEn === '4all' 
              ? <>{
                (index < 2 )  ?
                  <div className="w-full  hidden lg:flex h-40 p-5  justify-center items-center"><img src={'/assets/images/onboard-arrow.svg'} alt=""></img></div>
                  : <div className="invisible"></div>
              }</>
               : <>{
                (index < 3 )  ?
                  <div className="w-full  hidden lg:flex h-40 p-5  justify-center items-center"><img src={'/assets/images/onboard-arrow.svg'} alt=""></img></div>
                  : <div className="invisible"></div>
              }</>
            }
            
          </>
        );
      })}



    </div>
  );
};

export default OnboardSteps;
