
import { ConstructionSharp } from "@mui/icons-material"
import { addCitizenFavorite, addCitizenTransaction, getPoints } from "../api"


export const toggleFavoriteOffer = async ({citizenId, offerId, isFavorited} ) =>{

    const favorite = {
        CitizenId: citizenId,
        OfferId: offerId,
        IsFavorite: 1,
    }
  //console.log( favorite)
    try {
        await addCitizenFavorite(favorite);
        
    } catch (error) {
        throw new Error(error.message);
    }
   
   
}