import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { ArrowBackIos, ArrowRight, ArrowRightAlt, ArrowRightAltTwoTone, Visibility, VisibilityOff } from '@mui/icons-material';
// import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, Grid, InputAdornment, TextField } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
// import { Paragraph } from 'app/components/Typography';
import useAuth from '../hooks/useAuth';
import { Formik, replace } from 'formik';
import { useState } from 'react';
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '../components/Loading';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import useMunicipality from '../hooks/useMunicipality';
import { useQuery } from 'react-query';
import { getMunicipalityByLocality } from '../api';
import ResetPasswordRequest from './ResetPasswordRequest';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));





// inital login credentials
const initialValues = {
    email: '',
    password: '',
    remember: true,
};



// form field validation schema
const validationSchema = Yup.object().shape({
    password: Yup.string()
        // .min(6, 'Password must be 6 character length')
        .required('Παρακαλώ εισάγετε τον κωδικό πρόσβασης'),
    email: Yup.string().email('Η διεύθυνση email δεν είναι έγκυρη').required('Παρακαλώ εισάγετε διεύθυνση email'),
});

const Login = ({ isLandingPage = false }) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const breadcrumbs = useBreadcrumbs();
    const theme = useTheme();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isWrong, setIsWrong] = useState(false);
    const [showpass, setShowpass] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Λάθος email ή κωδικός πρόσβασης');

    const { municipality, init } = useMunicipality();

    const [isResetPassword, setIseResetPassword] = useState(false);



    const { login, user } = useAuth();

    const handleState = () => {
        setIseResetPassword(false)
    }

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {

            await login(values.email, values.password).then((e) => {
                // navigate("../../../" + e.localityEn);
                if (e.status === "schooluser") {
                    console.log("THis is a school User")
                    // console.log(e.municipality)
                    navigate(`/${e.municipality.LocalityEn}/account/schooluserview`, { state: { school: e.school, project: e.project } });

                    return;
                }
                console.log(e.status);
                if (e.status === "publicorganizationuser") {
                    console.log("THis is a publicorganization User")
                    // console.log(e.municipality)
                    navigate(`/${e.municipality.LocalityEn}/account/publicorganizationview`, { state: { publicorganization: e.publicorganization, tokens: e.tokens } });

                    return;
                }

                getMunicipalityByLocality(e.localityEn).then((m) => {

                    const newMunicipality = {
                        NameEl: m.NameEl,
                        MunicipalityId: m.MunicipalityId,
                        CenterLatitude: m.CenterLatitude,
                        CenterLongitude: m.CenterLongitude,
                        LocalityEn: m.LocalityEn,
                        RecycleBucketText: m.RecycleBucketText,
                        IsGreenSchool: m.IsGreenSchool,
                        MobileEnabled: m.MobileEnabled,
                    }
                    init(newMunicipality);

                    // alert(searchParams.get('qr'))
                    if (searchParams.get('qr') !== null) {
                        navigate("../../../" + e.localityEn + "/" + searchParams.get('qr'));                          
                        window.location.reload();
                    }
                    else {
                        navigate("../../../" + e.localityEn);
                        window.location.reload();
                    }

                })
            });

            setIsWrong(false)
            setLoading(false);

        } catch (e) {

            try {
                if (e.response.data.Error.status === 2) {
                    setErrorMessage('Για να ολοκληρωθεί η διαδικασία εγγραφής παρακαλούμε πατήστε επιβεβαίωση στο email που σας έχει σταλεί')
                }
                else {
                    setErrorMessage('Λάθος email ή κωδικός πρόσβασης')
                }
            } catch (error) {
                setErrorMessage('Λάθος email ή κωδικός πρόσβασης')
            }
            setIsWrong(true)
            setLoading(false);
        }
    };


    if (isResetPassword) { return <ResetPasswordRequest isLandingPage={isLandingPage} event={handleState}></ResetPasswordRequest> }
    else
        return (



            < motion.div

                initial={
                    { x: 300, }
                }
                animate={
                    {
                        x: 0,
                        transition: {
                            type: "spring",
                            bounce: 0.6,
                            duration: 1
                        }
                    }
                }
                exit={
                    { x: 300, }
                }
                // viewport={{ once: true, amount: 0.8 }}
                className={`${!isLandingPage ? 'my-[10%]' : ''} relative bg-white flex justify-center`} >
                {/* <svg className='absolute' viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path fill="#8FD6D2" d="M48.2,-38.6C55,-29.8,48,-10.2,40.5,3.7C32.9,17.7,24.7,26,15.6,29C6.6,32,-3.3,29.7,-19.5,27.1C-35.6,24.4,-58,21.4,-61.9,12C-65.8,2.6,-51.1,-13.3,-37.7,-23.5C-24.3,-33.7,-12.1,-38.4,4.3,-41.8C20.7,-45.2,41.3,-47.3,48.2,-38.6Z" transform="translate(100 100)" />
            </svg> */}

                {/* <div className="absolute top-0 -left-4 w-36 h-36 bg-app_red rounded-full  opacity-10  blur-md"></div>
            <div className="absolute top-0 -right-4 w-72 h-72 bg-app_green_light rounded-full  opacity-10 blur-md"></div>
            <div className="absolute -bottom-8 left-20 w-72 h-72 bg-app_yellow rounded-full  opacity-10 blur-md"></div> */}

                {/* <div className="flex">{breadcrumbs.map(({ breadcrumb }) => {
            return <div className="p-1 flex ">{breadcrumb}/</div>
         })}</div> */}
               
                <div className="z-10 relative !shadow-2xl h-full rounded-3xl  bg-white overflow-hidden flex flex-col justify-center items-center">
                    <div className='flex flex-col justify-center items-center'>
                        <div className='pt-10 font-semibold text-xl text-gray-400'>Είσοδος στον Λογαριασμό</div>
                        <div className="bg-gradient-to-r w-72 mt-1 h-2 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>
                    </div>

                    {isWrong ? <div className='pt-5 px-5 w-[80%] text-red-400'>{errorMessage}</div> : null}

                    <div >
                        <div className='bg-white p-10 px-16  h-auto w-auto'>
                            <Formik
                                enableReinitialize
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                            // enableReinitialize={false}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>

                                        <TextField
                                            className='group !group:border-red-300'
                                            fullWidth
                                            size="small"
                                            type="email"
                                            name="email"
                                            label="Διεύθυνση email"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.email}
                                            onChange={handleChange}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(errors.email && touched.email)}
                                            sx={{
                                                mb: 3,
                                                // "& .MuiOutlinedInput-notchedOutline": {
                                                //     borderColor: "#F19853"
                                                // }
                                            }}
                                        />

                                        <TextField
                                            fullWidth
                                            size="small"
                                            pas
                                            name="password"
                                            label="Κωδικός πρόσβασης"
                                            variant="outlined"
                                            type={showpass ? "text" : "password"}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            onChange={handleChange}
                                            helperText={touched.password && errors.password}
                                            error={Boolean(errors.password && touched.password)}
                                            sx={{ mb: 1.5 }}
                                            InputProps={
                                                {
                                                    endAdornment: (
                                                        <InputAdornment position="end"> {
                                                            showpass ? (
                                                                <Visibility className="cursor_pointer"
                                                                    onClick={
                                                                        () => {
                                                                            setShowpass(false)
                                                                        }
                                                                    }
                                                                />
                                                            ) : (
                                                                <VisibilityOff onClick={

                                                                    () => {
                                                                        setShowpass(true)
                                                                    }
                                                                }
                                                                />
                                                            )
                                                        }
                                                        </InputAdornment>
                                                    ),
                                                }}
                                        />

                                        <div className='mt-1 mb-1'>
                                            <div className='flex space-x-3'>
                                                <Checkbox
                                                    className=' !text-orange-300 '
                                                    size="small"
                                                    name="remember"
                                                    onChange={handleChange}
                                                    checked={values.remember}
                                                    sx={{ padding: 0, }}
                                                />

                                                <div>Να παραμένω σε σύνδεση</div>

                                            </div>

                                            {/* <NavLink
                        to="/session/forgot-password"
                        style={{ color: theme.palette.primary.main }}
                      >
                        Forgot password?
                      </NavLink> */}
                                        </div>

                                        <LoadingButton
                                            className='!bg-gradient-to-r from-app_yellow to-app_brown !shadow-orange-300 !shadow-xl !drop-shadow-none'
                                            type="submit"

                                            loading={loading}
                                            variant="contained"
                                            sx={{ my: 2 }}
                                        >
                                            <div className='capitalize pr-2'> Είσοδος</div>
                                            <ArrowRightAlt></ArrowRightAlt>
                                        </LoadingButton>

                                        <div className='mt-5 flex justify-center  cursor-pointer'>

                                            <div
                                                onClick={() => {
                                                    setIseResetPassword(true)
                                                }}

                                            >
                                                Ξέχασες τον κωδικό σου;
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>


                        </div>
                    </div>



                </div>
            </ motion.div>



        )
}

export default Login