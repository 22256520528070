import {
    Link,
    useLocation
} from "react-router-dom";



import { ArrowBackIos, ArrowBackIosNew, Check } from "@mui/icons-material";
import { CircularProgress, TextField } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import 'react-multi-carousel/lib/styles.css';
import { useQuery } from "react-query";
import { addSchoolTransaction, getAllContestSchools } from "../api";
import SchoolPieChart from "../components/SchoolsPieChart";
import { useModal } from "../contexts/ModalContext";
import useAuth from "../hooks/useAuth";
import useMunicipality from "../hooks/useMunicipality";
import { getImage } from "../utils/utils";
import { BsRecordCircleFill } from "react-icons/bs";



export default function SchoolsContestDesctiption() {


    const { municipality } = useMunicipality();

    let { setModal, unSetModal } = useModal()
    const { setModal: setInputModal, unSetModal: unsetInputModal } = useModal()


    const { login, user, isAuthenticated, updateUserValues } = useAuth();


    const cardVariants = {
        initial: {
            y: 300,
        },
        exit: {
            y: 0
        },
        animate: {
            y: 0,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0.6,
                duration: 1
            }
        }
    };




    const { isLoading, isError, error, data, isFetching, } =
        useQuery(['schools_contest', municipality,],
            () =>
                getAllContestSchools({ municipalityId: municipality.MunicipalityId, filter: 0 }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                // keepPreviousData: true
            })







    //error handlers
    if (isLoading)
        return <div className='flex justify-center items-center min-h-screen'><CircularProgress></CircularProgress></div>

    if (isError) {

        return <div className='flex p-8 justify-center min-h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA: {error.message} </p>
            </div>
        </div>
    }

    if (data.length < 1)
        return <div className='flex p-8 justify-center min-h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>No Data To Load</p>
            </div>
        </div>




    var schools = data.schools.filter(obj => {
        return obj.Image !== null;
    })



    return (
        <div className="bg-white ">
            <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <motion.div className="card-container"
                    variants={cardVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    viewport={{ once: true, amount: 0.8 }}>

                    <h2 className="pb-8 text-3xl font-bold text-gray-700">{data.contest.TitleEl}</h2>
                    {/* <h2 className="pb-3 text-3xl font-semibold text-gray-700">{data.contest.TitleEl}</h2>
                    <h2 className="text-lg font-semibold text-gray-700">Ανακάλυψε τα σχολεία που συμμετέχουν στη δράση, δώρισε πόντους και ενίσχυσε την προσπάθεια των μαθητών.</h2> */}
                           
<div  className='max-w-none' dangerouslySetInnerHTML={{ __html: '<div>' + data.contest.BodyEl.replaceAll(`https://followgreen.gr/docs/`,process.env.REACT_APP_BUCKET_URL + `/docs/`) }}></div>
<Link  
                        to={'./..'}
                        // state={{ offer: offer, citizenFavorites: citizenFavorites, merchant: getMerchant(offer.MerchantId) }}
                        className="flex mt-5 w-40  mb-6 shadow-lg shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                            <div className='flex items-center'>< ArrowBackIos></ArrowBackIos>
                                <p className='pl-1.5 pb-0.5'>{'Επιστροφη'.toUpperCase()}</p></div>
                        </Link>
                </motion.div>





            </div >


        </div >
    )
}