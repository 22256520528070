import earnImg1 from '../assets/earn/1.jpg';
import earnImg11 from '../assets/earn/11.jpg';
import earnImg13 from '../assets/earn/13.jpg';
import earnImg14 from '../assets/earn/14.jpeg';
import earnImg15 from '../assets/earn/15.jpeg';
import earnImg16 from '../assets/earn/16.jpg';
import earnImg17 from '../assets/earn/17.jpg';
import earnImg19 from '../assets/earn/19.jpg';


import { Alert, AlertTitle, CircularProgress, FormControl, NativeSelect, TextField } from '@mui/material';
import { motion, useInView } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BsRecordCircleFill } from 'react-icons/bs';
import { FaHeart, FaQuestion, FaShare } from "react-icons/fa";
import { useInfiniteQuery, useQuery } from 'react-query';
import { addCitizenOffer, getAllCitizenFavorites, getAllCitizenFavoritesOnly, getAllFeaturedOffers, getAllMerchants, getAllOffers, getAllOfferTypes, getLookupsByType, sendOtp, updateSmartRecycleDetails, verifyOtp } from '../api';
import useMunicipality from '../hooks/useMunicipality';
import { generateRandomId } from '../utils/utils';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { toggleFavoriteOffer } from '../services/favoritesService';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useModal } from '../contexts/ModalContext';
import { RiAlarmWarningFill, RiAlertFill, RiAlertLine, RiCellphoneFill, RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { Check } from '@mui/icons-material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MdElectricalServices } from 'react-icons/md';

const products = [
    {
        id: 1,
        name: 'Κλήρωση για ένα Bamboo Bluetooth speaker',
        href: '#',
        imageSrc: earnImg1,
        imageAlt: "Front of men's Basic Tee in black.",
        discountType: '10% έκπτωση',
        points: 100,
        isNew: true,
        shopName: 'Flanagan - Ένδυση',
        type: 'Shopping'
    },
    {
        id: 2,
        name: '10% έκπτωση σε όλα τα προϊόντα ',
        href: '#',
        imageSrc: earnImg13,
        imageAlt: "Front of men's Basic Tee in black.",
        discountType: '10% έκπτωση',
        points: 50,
        isNew: false,
        shopName: 'Flanagan - Ένδυση',
        type: 'Shopping'
    },
    {
        id: 3,
        name: '10% έκπτωση σε όλα τα ενδύματα και αξεσουάρ',
        href: '#',
        imageSrc: earnImg11,
        imageAlt: "Front of men's Basic Tee in black.",
        discountType: '10% έκπτωση',
        points: 80,
        isNew: false,
        shopName: 'Flanagan - Ένδυση',
        type: 'Shopping'
    },
    {
        id: 4,
        name: '30% έκπτωση στα jean παντελόνια flanagan',
        href: '#',
        imageSrc: earnImg15,
        imageAlt: "Front of men's Basic Tee in black.",
        discountType: '10% έκπτωση',
        points: 200,
        isNew: false,
        shopName: 'Flanagan - Ένδυση',
        type: 'Shopping'
    },
    {
        id: 5,
        name: '20% έκπτωση στα βαφτιστικά ρούχα',
        href: '#',
        imageSrc: earnImg14,
        imageAlt: "Front of men's Basic Tee in black.",
        discountType: '10% έκπτωση',
        points: 10,
        isNew: false,
        shopName: 'Flanagan - Ένδυση',
        type: 'Shopping'
    },
    {
        id: 6,
        name: '10% έκπτωση σε όλα τα προϊόντα ',
        href: '#',
        imageSrc: earnImg16,
        imageAlt: "Front of men's Basic Tee in black.",
        discountType: '10% έκπτωση',
        points: 10,
        isNew: false,
        shopName: 'Flanagan - Ένδυση',
        type: 'Shopping'
    },
    {
        id: 7,
        name: 'Δώρο αναψυκτικό 500ml της επιλογής σας με αγορά 2 γευμάτων',
        href: '#',
        imageSrc: earnImg17,
        imageAlt: "Front of men's Basic Tee in black.",
        discountType: '10% έκπτωση',
        points: 10,
        isNew: false,
        shopName: 'Flanagan - Ένδυση',
        type: 'Shopping'
    },
    {
        id: 8,
        name: '25% έως 40% έκπτωση σε επώνυμα αυθεντικά καλλυντικά',
        href: '#',
        imageSrc: earnImg19,
        imageAlt: "Front of men's Basic Tee in black.",
        discountType: '10% έκπτωση',
        points: 10,
        isNew: false,
        shopName: 'Flanagan - Ένδυση',
        type: 'Shopping'
    },

    // More products...
]

//Keep the count of loaded items to check agains the database
let loadedOffers = 0

var phone = null;

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


const initialValuesPhone = {
    phone: '',

};

const initialValuesCode = {
    code: '',

};

var phone = null;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// form field validation schema
const validationSchemaPhone = Yup.object().shape({

    phone: Yup.string().min(10, 'Ελέγξτε αν ο αριθμός τηλεφώνου είναι στη σωστή μορφή').matches(phoneRegExp, 'Ελέγξτε αν ο αριθμός τηλεφώνου είναι στη σωστή μορφή'),
});

const validationSchemaCode = Yup.object().shape({

    code: Yup.string().min(5, 'Ελέγξτε αν ο αριθμός είναι στη σωστή μορφή'),
});


export default function Offers({ isHomePage = false }) {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(isHomePage ? 3 : 9);

    const [filter, setFilter] = useState(0)

    const [favoritesClicked, setFavoritesClicked] = useState(false)

    const [priceRange, setPriceRange] = useState(0)
    const [offerType, setOfferType] = useState(0)

    const [filteredOffers, setFilteredOffers] = useState([])

    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const [searchingNearbyPlaces, setSearchingNearbyPlaces] = useState(false)
    const [searchingNearbyPlacesDone, setSearchingNearbyPlacesDone] = useState(false)

    const [isVerified, setIsVerified] = useState(false)

    let { setModal, unSetModal } = useModal()

    const handleFilterChanges = (filterId) => {
        setFilter(filterId)

        const _offers = []
        for (let i = 0; i < offers.pages.length; i++) {
            const _page = offers.pages[i];
            for (let j = 0; j < _page.rows.length; j++) {
                const element = _page.rows[j];
                _offers.push(element);
            }
        }



        if (filterId === 0)
            setFilteredOffers(_offers)
        if (filterId === 1) {
            if (searchingNearbyPlaces === false && searchingNearbyPlacesDone === false) {
                if (navigator.geolocation) {

                    setSearchingNearbyPlaces(true)
                    navigator.permissions
                        .query({ name: "geolocation" })
                        .then(function (result) {
                            if (result.state === "granted") {
                                // console.log("Granted");

                                //If granted then you can directly call your function here
                                navigator.geolocation.getCurrentPosition(function (position) {

                                    let findData = [];
                                    for (let index = 0; index < _offers.length; index++) {
                                        const offer = _offers[index];


                                        // filteredOffers.push(offer)
                                        const merchantLocation = getMerchantLocation(offer.MerchantId)
                                        if (merchantLocation != null) {
                                            const distance = checkIfNearby(merchantLocation.Latitude, merchantLocation.Longitude, position.coords.latitude, position.coords.longitude, position.coords.longitude)
                                            // console.log("Distance is :", distance)
                                            // filteredOffers.push(offer)
                                            if (distance < 3) {
                                                // console.log("Nearby Place Find :", distance)
                                                findData.push(offer)
                                                setSearchingNearbyPlaces(false)
                                                setSearchingNearbyPlacesDone(true)
                                            }
                                        }

                                    }

                                    setFilteredOffers(findData)
                                    setSearchingNearbyPlaces(false)
                                    setSearchingNearbyPlacesDone(true)


                                }, (error) => {
                                    setFilteredOffers([])
                                    setSearchingNearbyPlaces(false)
                                    setSearchingNearbyPlacesDone(false)
                                })


                            }
                            else if (result.state === "prompt") {
                                // console.log("prompt");
                                navigator.geolocation.getCurrentPosition(function (position) {

                                    let findData = [];
                                    for (let index = 0; index < _offers.length; index++) {
                                        const offer = _offers[index];


                                        // filteredOffers.push(offer)
                                        const merchantLocation = getMerchantLocation(offer.MerchantId)
                                        if (merchantLocation != null) {
                                            const distance = checkIfNearby(merchantLocation.Latitude, merchantLocation.Longitude, position.coords.latitude, position.coords.longitude, position.coords.longitude)
                                            // console.log("Distance is :", distance)
                                            // filteredOffers.push(offer)
                                            if (distance < 2) {
                                                // console.log("Nearby Place Find :", distance)
                                                findData.push(offer)
                                                setSearchingNearbyPlaces(false)
                                                setSearchingNearbyPlacesDone(true)
                                            }
                                        }

                                    }

                                    setFilteredOffers(findData)
                                    setSearchingNearbyPlaces(false)
                                    setSearchingNearbyPlacesDone(true)


                                }, (error) => {
                                    setFilteredOffers([])
                                    setSearchingNearbyPlaces(false)
                                    setSearchingNearbyPlacesDone(false)
                                });


                            }
                            else if (result.state === "denied") {
                                //console.log("denied");
                                setFilteredOffers([])
                                setSearchingNearbyPlaces(false)
                                setSearchingNearbyPlacesDone(false)

                                //If denied then you have to show instructions to enable location
                            }



                        });
                } else {
                    alert("Sorry Not available!");

                }

                //console.log("DONEEEEEEEEEEEEEEEEe")
            }
            // setSearchingNearbyPlaces(false)
            // setSearchingNearbyPlacesDone(true)
            // filteredOffers = offers.rows
        }
        if (filterId === 2) {
            let findData = [];
            for (let index = 0; index < _offers.length; index++) {
                const offer = _offers[index];



                if (checkIfNewOffer(offer.StartDate)) {

                    findData.push(offer)
                }
                //console.log("DATAAAAAAAAAAa")


            }

            setFilteredOffers(findData)

        }


    }



    const handleFormSubmitPhone = async (values) => {
        //console.log(values)

        //console.log(values)
        if (values.phone.length > 9) {
            await sendOtp({ phone: values.phone }).then((e) => {
                phone = values.phone
                if (e.data.status === 1) {
                    unSetModal();
                    setModal(
                        <div className='flex flex-col items-center justify-center py-5'>
                            <motion.div
                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                transition={{
                                    damping: 20,
                                    duration: 0.35
                                }}
                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                            </motion.div>
                            <div className='font-bold text-lg text-center pt-5'>Έχετε ήδη χρησιμοποιήσει αυτόν τον αριθμό!
                            </div>
                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                onClick={() => { unSetModal() }}
                            >OK</div>
                        </div>
                    )
                }
                else if (e.data.status === 3) {
                    alert('Σας έχει αποσταλεί ο κωδικός επιβεβαίωσης.');
                }
                else {
                    unSetModal();
                    setModal(
                        <div className='flex flex-col items-center justify-center py-5'>
                            <motion.div
                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                transition={{
                                    damping: 20,
                                    duration: 0.35
                                }}
                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                            </motion.div>
                            <div className='font-bold text-lg text-center pt-5'>Ωχ Κάτι πήγε στραβά. Ενημερώθηκε ο διαχειριστής συστήματος.
                            </div>
                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                onClick={() => { unSetModal() }}
                            >OK</div>
                        </div>
                    )
                }
            })
            // 

            // 

        }


        // console.log("OTPSENT IS:",otpSent)
    }

    const handleFormSubmitCode = async (values) => {
        //console.log(phone)
        if (!phone)
            return


        const otpVerificationResonse = await verifyOtp({ phone: phone, code: values.code, citizenId: user.citizenId })
        if (otpVerificationResonse.data.status === 2) {
            updateUserValues()
            setIsVerified(true);
            unSetModal()
        }
        // setOtpSent(true)
    }


    const handleρedemption = async (offer) => {
        if (municipality.MunicipalityId === 380 && user.Deh === null) {
            var deh = 0;
            // setWidth(330)
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}
                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><MdElectricalServices className='w-16 h-16 text-app_red'></MdElectricalServices></div>
                    </motion.div>

                    <div className='font-bold text-lg text-center pt-5'>Καταχώρησε τον Αριθμό Παροχής Ηλεκτρικού Ρεύματος για να συνεχίσεις</div>

                    <p className=' text-center pt-5'>Από την ένδειξη "Αριθμός Παροχής" που αναγράφεται στον λογαριασμό σας: π.χ. 1 23456789-02 5 </p>
                    <p className='text-center text-md italic pt-1'>*Εισάγετε ΜΟΝΟ τα 8 ψηφία, που βρίσκονται μετά τον πρώτο αριθμό έως και την παύλα, όπως παρακάτω: 23456789
                    </p>


                    <TextField
                        className='!mt-5 !border-2 !outline-none !border-dashed !shadow-[inset_0px_6px_12px_rgba(0,0,0,0.3)]'
                        label=""
                        onChange={(e) => {

                            deh = e.target.value;
                        }}
                        type="number"
                        sx={{ padding: 0, borderRadius: '15px !important', }}

                    />
                    <div className='flex flex-col md:flex-row'>
                        <button className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={async () => {
                                const digits_only = string => [...string].every(c => '0123456789'.includes(c));
                                if (deh.length === 8 && digits_only(deh)) {
                                    console.log("Dei is: " + deh);

                                    var recycleData = {
                                        Deh: deh,
                                        ValidDeh: user.ValidDeh,
                                        BrownBin: user.BrownBin,
                                        BinNumber: user.BinNumber,
                                        RecyclableMaterials: user.RecyclableMaterials,
                                        PlasticBagNumber: user.PlasticBagNumber,
                                    };
                                    await updateSmartRecycleDetails({ citizenId: user.citizenId, data: recycleData })

                                    updateUserValues();

                                    unSetModal();

                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Ο αριθμός παροχής καταχωρήθηκε επιτυχώς!</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    )


                                }
                                else {
                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Ο Αριθμός Παροχής δεν είναι σωστός
                                            </div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    )
                                }
                            }}
                        >Καταχώρηση</button>


                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { unSetModal() }}
                        >Ακύρωση</div>
                    </div>
                </div>
            )
        }
        else if (municipality.MunicipalityId !== 380 && user.AuthyVerified === 0) {
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><RiCellphoneFill className='w-12 h-12 text-app_yellow'></RiCellphoneFill></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Επιβεβαίωσε τον αριθμό του κινητού σου</div>
                    <div className='font-bold text-lg text-center pt-5'>Για να ολοκληρωθεί η διαδικασία επιβεβαίωσης του προφίλ σου, καταχώρησε τον αριθμό του κινητού τηλεφώνου σου και πάτησε το κουμπί αποστολή για να λάβεις τον κωδικό ενεργοποίησης.</div>
                    <div className="flex flex-col md:flex-row justify-evenly w-full">
                        <Formik
                            onSubmit={handleFormSubmitPhone}
                            initialValues={initialValuesPhone}
                            validationSchema={validationSchemaPhone} className='flex flex-col pt-5 items-center space-y-3'>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='flex justify-center items-center'>
                                        <div>+30</div>
                                        <TextField
                                            className='group  w-40 !mt-1.5'
                                            size="small"
                                            type="tel"
                                            name="phone"
                                            label=""
                                            variant="standard"
                                            onBlur={handleBlur}
                                            value={values.phone}
                                            onChange={handleChange}
                                            helperText={touched.phone && errors.phone}
                                            error={Boolean(errors.phone && touched.phone)}
                                        />
                                    </div>
                                    <button type="submit" className='mt-3 flex w-40 justify-center cursor-pointer  text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                    //    onClick={}
                                    >Αποστολή</button>
                                </form>
                            )}
                        </Formik>
                        <Formik
                            onSubmit={handleFormSubmitCode}
                            initialValues={initialValuesCode}
                            validationSchema={validationSchemaCode} className='flex flex-col pt-5 items-center space-y-3'>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='flex justify-center items-center'>

                                        <TextField
                                            className='group  w-40 !mt-0.5'
                                            placeholder='000000'
                                            size="small"
                                            type="text"
                                            name="code"
                                            label=""
                                            variant="standard"
                                            onBlur={handleBlur}
                                            value={values.code}
                                            onChange={handleChange}
                                            helperText={touched.code && errors.code}
                                            error={Boolean(errors.code && touched.code)}

                                        />
                                    </div>
                                    <button type="submit" className=' mt-3 disabled:cursor-not-allowed disabled:bg-gray-300 flex w-40 justify-center cursor-pointer  text-center text-white font-semibold hover:text-white enabled:bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'

                                    >Ενεργοποίηση</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            );
        }
        else if (user.points < offer.OfferPoints) {
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Δυστυχώς έχετε μόλις {user.points} πόντους!</div>
                    <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                        onClick={() => { unSetModal() }}
                    >OK</div>
                </div>
            );
        }
        else {


            // console.log(citizenOffer)
            // downloadPDF(citizenOffer)
            // updateUserValues();
            //ON SUCCESS WE HAVE TO MAKE 
            // let transaction;

            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><FaQuestion className='w-12 h-12 text-app_yellow'></FaQuestion></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Επιβεβαίωση απόκτησης προσφοράς</div>
                    <div className='font-bold text-lg text-center pt-5'>Με την ολοκλήρωση της ενέργειας θα αφαιρεθούν ({offer.OfferPoints}) πόντοι απο τον λογαριασμό σου.</div>
                    <div className="flex flex-col md:flex-row">
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={async () => {

                                unSetModal()
                                let citizenOffer = await addCitizenOffer({ citizenId: user.citizenId, offer: offer, });
                                if (citizenOffer.transaction_result === 2) {
                                    updateUserValues();
                                    setModal(

                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_green rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCheckLine className='w-16 h-16 text-app_green'></RiCheckLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Επιτυχής απόκτηση προσφοράς. Το κουπόνι έχει εκδοθεί και έχει αποσταλεί στο email σου.</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    );

                                }
                                else {
                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Δεν υπάρχει διαθέσιμη προσφορά.</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    );
                                }

                            }}
                        >ΝΑΙ</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { unSetModal() }}
                        >ΟΧΙ</div>
                    </div>
                </div>
            );








        }


    }


    //To handle response on municiupality changes
    const { municipality } = useMunicipality();

    const { isLoading: isLoadingFeatured, isError: isFeaturedError, data: featured, } =
        useQuery('featured_offers', () => getAllFeaturedOffers({ municipalityId: municipality.MunicipalityId }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            onSuccess: (ftchedData) => {
                //console.log("FETCHED DATA: ", ftchedData)
            }
        })


    const { isLoading: isCitizenFavoritesLoading, isError: isCitizenFavoritesError, data: citizenFavorites, } =
        useQuery(['citizenFavoritesOnly', favoritesClicked], () => getAllCitizenFavoritesOnly({ citizenId: user !== null ? user.citizenId : null }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            onSuccess: (ftchedData) => {
                //console.log("FETCHED DATA: ", ftchedData)
            }
        })

    //Get All offers by Municipality
    const { isLoading, isError, error, data: offers, isFetching, fetchNextPage,
        hasNextPage, } =
        useInfiniteQuery(['offers', municipality, isVerified],
            ({ pageParam = 0 }) =>
                getAllOffers({ limit: pageSize, page: pageParam, optionalKeyword: '', municipalityId: municipality.MunicipalityId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                keepPreviousData: true,
                getNextPageParam: (lastPage, allPages) => {
                    const count = lastPage.count;
                    // console.log("count is:", count)
                    let _i = 0;
                    for (let index = 0; index < allPages.length; index++) {
                        const element = allPages[index].rows.length
                        _i += element

                    }
                    // console.log("Length is:", _i)
                    // console.log('LoadedQuizes is :',loadedQuizes)                
                    const nextPage = allPages.length
                    // console.log('filter is: ',filter)
                    // loadedQuizes = (_i + 10)
                    loadedOffers = _i
                    if ((_i + 10 <= count || allPages.length)) {


                        return nextPage

                    }
                    // else {

                    // }
                },

                onSuccess: (d) => {

                    // let data = d.rows;

                    // data.sort(function (a, b) {
                    //     // return  Math.random() - 0.5
                    //     return new Date(b.StartDate) - new Date(a.StartDate);
                    //     // return b.Views -  a.Views;
                    // });
                    // console.log(d)
                    const _offers = []
                    for (let i = 0; i < d.pages.length; i++) {
                        const _page = d.pages[i];
                        for (let j = 0; j < _page.rows.length; j++) {
                            const element = _page.rows[j];
                            _offers.push(element);
                        }
                    }
                    if (isHomePage)
                        _offers.length = 3
                    setFilteredOffers(_offers)
                    // if (filteredOffers.length < 1) {
                    //   //console.log("SETTTTIIINGN")
                    //     setFilteredOffers(d.pages[0].rows)
                    // }
                }
            })

    //Get All Mechants by Municipality        
    const { isLoading: isLoadingMerchants, isError: isErrorMerchantd, error: errorMerchants, data: merchants, isFetching: isFetchingMerchants } =
        useQuery(['merchants', municipality,],
            () =>
                getAllMerchants({ limit: 0, page: 0, optionalKeyword: '', municipalityId: municipality.MunicipalityId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,

            })

    //Get All Offer Types          
    const { isLoading: isOfferTypeLoading, isError: isOfferTypeError, data: offerTypes, } =
        useQuery('offertypes', () => getAllOfferTypes(), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })

    const { isLoading: lookupIsLoading, isError: lookupIsError, error: lookupError, data: lookups, } =
        useQuery('merchant_lookup', () => getLookupsByType({ lookupType: 'merchant_category' }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })


    const observer = useRef()




    const lastElementRef = useCallback((node) => {




        // if (_quizes.length >= quizes.pages[0].count - 1 && filter === 1) return
        // if (_blogs.length >= blogs.pages[0].count - 1 && filter === 2) return
        // if (_videos.length >= videos.pages[0].count - 1 && filter === 3) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {



            if (entries[0].isIntersecting) {

                const { scrollHeight, scrollTop, clientHeight } =
                    node;

                //console.log("COUNT IS::::", offers.pages[0].count)
                //console.log("LENGTH IS::::", loadedOffers)
                if (isLoading || (loadedOffers >= offers.pages[0].count)) return



                if (scrollHeight - scrollTop <= clientHeight * 2) {
                    fetchNextPage()
                    let previousScrollYPosition = window.scrollY

                    setTimeout(function () {
                        window.scrollTo({
                            top: previousScrollYPosition,
                            behavior: "smooth"
                        });
                    }, 50);
                }
            }



        }
        )
        if (node) observer.current.observe(node)
        //console.log(node)
    }, [isLoading, hasNextPage])

    const checkIfFavorite = (offerId) => {
        //Prevent errors if user is not loged in
        if (!isAuthenticated) return 0;






        try {
            var citizenFavorite = citizenFavorites.filter(obj => {
                return obj.OfferId === offerId
            })

            if (!isAuthenticated) return 0;

            if (citizenFavorite === null) return 0;
            if (citizenFavorite[0].IsFavorite === 1)
                return 1

        } catch (error) {
            return 0
        }

    }

    const getOfferTitle = (offerTypeId) => {
        var corispondingOfferTitle = offerTypes.filter(obj => {
            return obj.OfferTypeId === offerTypeId
        })

        if (corispondingOfferTitle) return corispondingOfferTitle[0].NameEl
        else return ".."
    }

    const getMerchantTitle = (merchantId) => {
        var corispondingMerchantTitle = merchants.rows.filter(obj => {
            return obj.MerchantId === merchantId
        })

        if (corispondingMerchantTitle) return corispondingMerchantTitle[0].NameEl
        else return ".."
    }

    const getMerchantTitleFeatured = (merchantId) => {
        var corispondingMerchantTitle = featured.merchants.filter(obj => {
            return obj.MerchantId === merchantId
        })

        //console.log(corispondingMerchantTitle);

        if (corispondingMerchantTitle) return corispondingMerchantTitle[0].NameEl
        else return ".."
    }

    const getMerchant = (merchantId) => {
        var corispondingMerchantTitle = merchants.rows.filter(obj => {
            return obj.MerchantId === merchantId
        })

        if (corispondingMerchantTitle) return corispondingMerchantTitle[0]
        else return null
    }

    const getMerchantFeatured = (merchantId) => {
        var corispondingMerchantTitle = featured.merchants.filter(obj => {
            return obj.MerchantId === merchantId
        })

        if (corispondingMerchantTitle) return corispondingMerchantTitle[0]
        else return null
    }

    const getMerchantLocation = (merchantId) => {
        var corispondingMerchant = merchants.rows.filter(obj => {
            return obj.MerchantId === merchantId
        })

        if (corispondingMerchant) {
            return {
                Latitude: corispondingMerchant[0].AddressLatitude,
                Longitude: corispondingMerchant[0].AddressLongitude,
            }
        }
        else return null
    }

    const getMerchantCategory = (merchantId) => {

        var corispondingMerchant = merchants.rows.filter(obj => {
            return obj.MerchantId === merchantId
        })

        var corispondingMerchantCategory = lookups.filter(obj => {
            return obj.code === corispondingMerchant[0].MerchantCategoryId
        })
        // console.log(corispondingMerchantCategory[0].name)
        if (corispondingMerchantCategory) return corispondingMerchantCategory[0].name ?? ''
        else return ".."
    }

    const getMerchantCategoryFeatured = (merchantId) => {

        var corispondingMerchant = featured.merchants.filter(obj => {
            return obj.MerchantId === merchantId
        })

        var corispondingMerchantCategory = lookups.filter(obj => {
            return obj.code === corispondingMerchant[0].MerchantCategoryId
        })
        // console.log(corispondingMerchantCategory[0].name)
        if (corispondingMerchantCategory) return corispondingMerchantCategory[0].name ?? ''
        else return ".."
    }


    const getMerchantCategoryType = (merchantId) => {

        var corispondingMerchant = merchants.rows.filter(obj => {
            return obj.MerchantId === merchantId
        })

        var corispondingMerchantCategory = lookups.filter(obj => {
            return obj.code === corispondingMerchant[0].MerchantCategoryId
        })
        //console.log("One is:", corispondingMerchantCategory[0].id)
        //console.log("Two is:", offerType)
        if (corispondingMerchantCategory[0].id === offerType) return false
        else return true
    }




    const checkIfNewOffer = (startDate) => {
        const today = new Date();
        const offerStartDate = new Date(startDate);
        const diffTime = Math.abs(offerStartDate - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // console.log(diffDays + " days");
        if (diffDays > 30) return false;
        else return true
    }

    const deg2Rad = (deg) => {
        return deg * Math.PI / 180;
    }

    const checkIfNearby = (lat1, lon1, lat2, lon2) => {
        lat1 = deg2Rad(lat1);
        lat2 = deg2Rad(lat2);
        lon1 = deg2Rad(lon1);
        lon2 = deg2Rad(lon2);
        const R = 6371;
        const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
        const y = (lat2 - lat1);
        const d = Math.sqrt(x * x + y * y) * R;
        return d;
    }

    const allawPriceVlues = (offer) => {
        //console.log(priceRange);
        switch (priceRange) {
            case 0:
                return true

            case 1:
                if (offer.OfferPoints < 151)
                    return true
                else return false
            case 2:
                if (offer.OfferPoints > 150 && offer.OfferPoints < 300)
                    return true
                else return false
            case 3:
                if (offer.OfferPoints > 300)
                    return true
                else return false

            default:
                return false

        }

    }


    const allawOfferTypes = (offer) => {
        if (offerType === 0)
            return true;
        else {
            if (!getMerchantCategoryType(offer.MerchantId)) return true;
            else return false;
        }



    }

    // useEffect(() => {
    //   //console.log('USE EFFECT CALLED"')
    //   //console.log(filteredOffers)
    // }, [])


    //Error checking and loading        
    if (isLoading || isFetchingMerchants || lookupIsLoading || isCitizenFavoritesLoading || isLoadingFeatured || isOfferTypeLoading)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError || isErrorMerchantd || lookupIsError || isCitizenFavoritesError || isFeaturedError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA </p>
            </div>
        </div>

    if (offers.length < 1)
        return <div className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>
                <p className='texgt-md font-medium'>No Data To Load</p>
            </div>
        </div>


    if(municipality.IsGreenSchool === 1 && offers.pages[0].count < 1)
        return null;


    if(offers.pages[0].count < 1 && !isHomePage)
        return <div className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>
                <p className='!text-lg font-bold '>Δεν υπάρχουν διαθέσιμες προσφορές για εξαργύρωση</p>

              

                
            </div>
        </div>


if(offers.pages[0].count < 1 && isHomePage)
    return <div className='flex p-8 justify-center h-40 items-center'>
        <div className=' text-center'>
            <p className='!text-lg font-bold '>Δεν υπάρχουν διαθέσιμες προσφορές για εξαργύρωση</p>

          

            
        </div>
    </div>


    // console.log(citizenFavorites)


    //    return <div>
    //  {municipality.MunicipalityId} 
    // </div>


    return (
        <div className="bg-white ">
            <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                {isHomePage ? null :
                    <>
                        <h2 className="text-3xl font-bold text-gray-700 ">Έχεις συγκεντρώσει πόντους επιβράβευσης;</h2>
                        <h2 className="text-lg font-normal text-gray-700 pb-7">Η ανακύκλωση που έχεις κάνει σε ανταμείβει και μάλιστα... στη γειτονιά σου! Με μια απλή κίνηση εξαργύρωσε τους πόντους σου με βάση τις ανάγκες και τις επιθυμίες σου. Βρες εδώ πληροφορίες για τις προσφορές των τοπικών και ηλεκτρονικών επιχειρήσεων και επίλεξε αυτήν που σου ταιριάζει.</h2>









                        <div className="">
                            <Carousel
                                className="pb-10 pt-5"
                                swipeable={true}
                                draggable={true}
                                showDots={isHomePage ? false : true}
                                responsive={responsive}

                                // renderButtonGroupOutside={true}
                                // arrows={false}
                                // centerMode={true}
                                // ssr={false} // means to render carousel on server-side.
                                infinite={true}
                                // focusOnSelect={false}
                                autoPlay={true}
                                // autoPlaySpeed={1000}
                                // keyBoardControl={true}
                                // customTransition="all .5"
                                // transitionDuration={500}

                                // containerClass="carousel-container"
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                // deviceType={this.props.deviceType}
                                dotListClass="custom-dot-list-style pt-10 !mt-10"
                            // itemClass="carousel-item-padding-40-px"
                            >
                                {featured.offers.map((offer, index) => {

                                    return (
                                        <div className='flex justify-center w-full'>
                                            <motion.div
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 20
                                                }}
                                                key={index} className="relative w-full  bg-gradient-to-l from-green-400 via-blue-500 to-green-500  justify-center items-center flex flex-col duration-200 ease-out shadow-lg  rounded-3xl group  hover:-translate-y-2">

                                                <div

                                                    className="w-full  rounded-md min-h-64 aspect-w-1 aspect-h-1 lg:h-auto lg:aspect-none">
                                                    <div className='relative h-[20.0rem]'>
                                                        <img
                                                            loading="lazy"
                                                            src={offer.ImageFront ? ((offer.ImageFront.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + offer.ImageFront) : ''}
                                                            alt={offer.imageAlt}
                                                            className="object-cover  h-full object-center w-full p-1.5 lg:w-full rounded-3xl"
                                                        />


                                                        <div className="absolute top-3 flex items-center justify-center bg-opacity-100 rounded-full shadow-md opacity-100 w-14 h-14 left-3 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner w-11 h-11 shadow-gray-200 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">
                                                                {offer.OfferPoints}
                                                            </div>
                                                        </div>


                                                        <div className='absolute   top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col justify-center items-center w-full'>
                                                            <Link
                                                                to={isHomePage ? './offer/detail/' + offer.Token : './detail/' + offer.Token}
                                                                state={{ offer: offer, citizenFavorites: citizenFavorites, merchant: getMerchantFeatured(offer.MerchantId) }}

                                                                className="pt-5 pb-2 px-3 w-11/12 space-y-3">
                                                                <div
                                                                    style={{ 'text-shadow': '2px 2px 4px #353535' }}
                                                                    className="flex justify-center text-3xl font-semibold leading-6 text-center text-white drop-shadow-md  shadow-black">

                                                                    {offer.TitleEl}
                                                                </div>
                                                                <div
                                                                    style={{ 'text-shadow': '2px 2px 4px #353535' }}
                                                                    className="flex justify-center text-xl font-semibold leading-6 text-center text-white drop-shadow-lg shadow-black">

                                                                    {offer.DescriptionEl}
                                                                </div>
                                                            </Link>
                                                            <button className='m-5 bg-gray-500 rounded-full h-full'
                                                                onClick={async (e) => {
                                                                    // e.stopPropagation();

                                                                    if (!isAuthenticated) {
                                                                        setModal(
                                                                            <div className='flex flex-col items-center justify-center py-5'>
                                                                                <motion.div
                                                                                    initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                                                    exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                                                    animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                                                    transition={{
                                                                                        damping: 20,
                                                                                        duration: 0.35
                                                                                    }}
                                                                                    className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                                                    <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                                                                </motion.div>
                                                                                <div className='font-bold text-lg text-center pt-5'>Απόκτησε αυτή την προσφορά, κάνοντας είσοδο. Εάν δεν είσαι ήδη μέλος κάνε τώρα εγγραφή και απόλαυσε την επιβράβευση.</div>
                                                                                <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                                    onClick={() => { unSetModal() }}
                                                                                >OK</div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    else {
                                                                        await handleρedemption(offer)
                                                                    }
                                                                }}




                                                            >
                                                                <p className="px-5 py-4 font-semibold text-white ">{'Αποκτηση'.toUpperCase()}</p>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        </div>
                                    )
                                })}

                            </Carousel>
                        </div>
















                        {/* Filters Menu */}
                        <div className="flex justify-center space-x-2 p-5">
                            <div
                                className={`${filter === 0 ? 'shadow-inner bg-gray-600 !text-white hover:!text-gray-50 hover:bg-gray-500' : 'drop-shadow-none shadow-inner bg-white  text-gray-400 '}  hover:bg-gray-50 cursor-pointer  px-5 py-2 w-48 flex justify-center font-semibold text-gray-500 rounded-md`}
                                onClick={() => { handleFilterChanges(0) }}>Όλες οι προσφορές</div>
                            <div
                                className={`${filter === 1 ? 'shadow-inner bg-gray-600 !text-white hover:!text-gray-50 hover:bg-gray-500' : 'drop-shadow-none shadow-inner bg-white text-gray-400'}  hover:bg-gray-50 cursor-pointer  px-5 py-2 w-48 flex justify-center font-semibold text-gray-500 rounded-md`}
                                onClick={() => { handleFilterChanges(1) }}>Κοντά μου</div>
                            <div
                                className={`${filter === 2 ? 'shadow-inner bg-gray-600 !text-white hover:!text-gray-50 hover:bg-gray-500' : 'drop-shadow-none shadow-inner bg-white text-gray-400'}  hover:bg-gray-50 cursor-pointer  px-5 py-2 w-48 flex justify-center font-semibold text-gray-500 rounded-md`}
                                onClick={() => { handleFilterChanges(2) }}>Νέες προσφορές</div>

                        </div>


                        <div className='flex space-x-2 flex-col md:flex-row justify-center'>

                            <div className='flex justify-start '>
                                <FormControl className='w-[350px]'>
                                    <NativeSelect
                                        className=" w-full border p-1 rounded "
                                        defaultValue={0}
                                        onChange={
                                            (e) => {
                                                setOfferType(parseInt(e.target.value))
                                            }
                                        }>
                                        <option value={0}>Είδος Προσφοράς:</option>
                                        {lookups.map((category) => <option value={category.id}>{category.nameEl}</option>)}
                                    </NativeSelect>
                                </FormControl>
                            </div>


                            <div className='flex justify-start '>
                                <FormControl className='w-[350px]'>
                                    <NativeSelect
                                        className=" w-full border p-1 rounded "
                                        defaultValue={0}
                                        onChange={
                                            (e) => {
                                                setPriceRange(parseInt(e.target.value))
                                                // setData({
                                                //     ...data,
                                                //     OfferTypeId: e.target.value
                                                // })
                                            }
                                        }>

                                        <option value="0">Εύρος πόντων</option>
                                        <option value="1">1-150</option>
                                        <option value="2">151-300</option>
                                        <option value="3">&gt;300</option>

                                    </NativeSelect>
                                </FormControl>
                            </div>

                        </div>

                    </>
                }

                {(searchingNearbyPlaces) ?

                    <div className='flex justify-center items-center mt-24'><CircularProgress></CircularProgress></div>

                    :


                    <div>

                        {(municipality.IsGreenSchool === 1 && offers.pages[0].count > 0) ? <div className='flex  flex-col space-y-2 md:flex-row items-center justify-between  px-4 mx-auto lg:flex lg:px-0 max-w-[1200px] -mb-5 mt-16 '>
                            <div className="mb-10 text-white bg-gradient-to-r from-app_blue  to-app_blue_dark  font-bold rounded-full text-2xl px-10 py-4 text-center">Κληρώσεις</div>
                           
                        </div> : null


                        }

                        <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">



                            {filteredOffers.map((offer, index) => {
                                // if (filteredOffers.length === index + 1) return 
                                // else
                                if (allawPriceVlues(offer) === false) return null
                                if (allawOfferTypes(offer) === false) return null
                                else
                                    return (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{
                                                type: "spring",
                                                stiffness: 260,
                                                damping: 20
                                            }}
                                            key={index} className="relative  p-1 bg-gradient-to-l from-green-400 via-blue-500 to-green-500  justify-between flex flex-col duration-200 ease-out shadow-2xl cursor-pointer rounded-3xl group shadow-gray-500 hover:-translate-y-2">
                                            {/* ADDED  FOR GRADIENT */}
                                            <div className='bg-white w-full h-full rounded-3xl'>
                                                <Link
                                                    to={isHomePage ? './offer/detail/' + offer.Token : './detail/' + offer.Token}
                                                    state={{ offer: offer, citizenFavorites: citizenFavorites, merchant: getMerchant(offer.MerchantId) }}

                                                    className="w-full rounded-md min-h-80 aspect-w-1 aspect-h-1 lg:h-auto lg:aspect-none">
                                                    <div className='relative h-[17.3rem]'>
                                                        <img
                                                            loading="lazy"
                                                            
                                                            src={offer.Image ? ((offer.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + offer.Image) : ''}
                                                            alt={offer.imageAlt}
                                                            className="object-cover object-center w-full  lg:w-full rounded-3xl"
                                                        />


                                                        <div className="absolute bottom-0 flex items-center justify-center bg-opacity-100 rounded-full shadow-md opacity-100 w-14 h-14 right-3 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner w-11 h-11 shadow-gray-200 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">
                                                                {offer.OfferPoints}
                                                            </div>
                                                        </div>


                                                        <div className="absolute bottom-0 z-10 flex items-center justify-center h-10 px-3 -skew-x-12 -left-4 opacity-90 rounded-2xl bg-app_green">
                                                            <a href={offer.href} className="flex justify-center text-base font-semibold text-center text-white ">

                                                                {getOfferTitle(offer.OfferTypeId)}
                                                            </a>
                                                        </div>
                                                        {
                                                            checkIfNewOffer(offer.StartDate) ? <div className="absolute flex items-center justify-center h-10 -skew-x-12 -left-4 bottom-8 opacity-90 rounded-2xl bg-app_red">
                                                                <a href={offer.href} className="flex justify-center px-5 text-base font-semibold text-center text-white">
                                                                    Νέο
                                                                </a>
                                                            </div> : <></>
                                                        }



                                                    </div>



                                                    <div className="pt-5 pb-2 px-3 w-11/12">
                                                        <a href={'/'} className="flex justify-center text-xl font-semibold leading-6 text-center text-gray-600 ">

                                                            {offer.TitleEl}
                                                        </a>
                                                    </div>
                                                </Link>



                                                <div className="flex flex-col items-center text-center justify-evenly w-full">
                                                    <div className="flex justify-center py-1.5 mx-4 my-3 text-center bg-gray-100 rounded-xl w-11/12">

                                                        <p className="text-sm font-semibold text-gray-900 ">{getMerchantTitle(offer.MerchantId)}</p>
                                                    </div>

                                                    <div className="flex justify-center text-center w-11/12">

                                                        <p className="text-sm font-semibold text-gray-500 ">{getMerchantCategory(offer.MerchantId)}</p>
                                                    </div>

                                                    <div className='flex justify-between items-baseline w-11/12'>
                                                        <div
                                                            className="flex items-center justify-center bg-gray-200 rounded-full opacity-50 h-14 w-14"
                                                            onClick={async () => {
                                                                if (isAuthenticated) {

                                                                    await toggleFavoriteOffer({ citizenId: user.citizenId, offerId: offer.OfferId, isFavorited: 1 })
                                                                        .then(() => {
                                                                            //console.log('Added favorite');
                                                                            setFavoritesClicked(!favoritesClicked)
                                                                        });

                                                                }

                                                            }}
                                                        >
                                                            {
                                                                checkIfFavorite(offer.OfferId) === 1
                                                                    ? <FaHeart color='red' size={18}></FaHeart>
                                                                    :
                                                                    <FaHeart color='gray' size={18}></FaHeart>
                                                            }
                                                        </div>
                                                        <button className={`m-5 ${isAuthenticated ? "bg-app_green_light opacity-100" : "bg-gray-500 opacity-30"}  rounded-full `}
                                                            onClick={async () => {
                                                                if (!isAuthenticated) {
                                                                    setModal(
                                                                        <div className='flex flex-col items-center justify-center py-5'>
                                                                            <motion.div
                                                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                                                transition={{
                                                                                    damping: 20,
                                                                                    duration: 0.35
                                                                                }}
                                                                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                                                            </motion.div>
                                                                            <div className='font-bold text-lg text-center pt-5'>Απόκτησε αυτή την προσφορά, κάνοντας είσοδο. Εάν δεν είσαι ήδη μέλος κάνε τώρα εγγραφή και απόλαυσε την επιβράβευση.</div>
                                                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                                onClick={() => { unSetModal() }}
                                                                            >OK</div>
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    await handleρedemption(offer)
                                                                }

                                                            }}
                                                        >
                                                            <p className="px-5 py-4 font-semibold text-white ">{'Αποκτηση'.toUpperCase()}</p>
                                                        </button>
                                                        <a
                                                            data-pgaction-redirection="0"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            //  title={title}
                                                            href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                                                            //  imageurl=${this.state.imageUrl}}
                                                            className="flex items-center justify-center bg-gray-200 rounded-full opacity-50 h-14 w-14">
                                                            <FaShare color='gray' size={18}></FaShare>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </motion.div>


                                    )
                            })}

                            {
                                !isHomePage ? <div
                                    key={100000000}
                                    className="relative  overflow-hidden flex justify-center items-center"
                                    ref={lastElementRef}
                                >
                                    {(loadedOffers >= offers.pages[0].count) ? null : <></>}
                                </div> : null
                            }


                        </div>
                    </div>
                }
                {
                    isHomePage ? null :
                        <>{!searchingNearbyPlaces ? <div className='w-full flex justify-center mt-20'>
                            <div type="button" className="shadow-lg flex shadow-gray-300 bg-white drop-shadow-md   text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                                <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
                                    <p className='pl-1.5 pb-0.5'>{filteredOffers.length > 1 ? 'Δεν βρέθηκαν άλλες καταχωρίσεις.' : 'Δεν βρέθηκαν καταχωρίσεις.'}</p></div>
                            </div>

                        </div> : <div className='w-full flex justify-center mt-20'>
                            <div type="button" className="shadow-lg flex shadow-gray-300 bg-white drop-shadow-md   text-app_brown font-semibold rounded-full text-md px-5 text-center ">
                                <div className='flex flex-col items-center p-5'>
                                    <p className='pl-1.5 pb-0.5'>Γίνεται ναζήτηση κοντινών προσφορών</p>
                                    <p className='pl-1.5 pb-0.5'>Παρακαλώ, περιμένετε...</p>
                                </div>
                            </div></div>
                        }</>
                }

            </div>
        </div>
    )
}