import logo1 from '../assets/awards/euro_awards.png';
import logo2 from '../assets/awards/nbg_awards.png';
import logo3 from '../assets/awards/oikopolis_awards.jpg';
import logo4 from '../assets/awards/paseppe_awards.jpg';
import logo5 from '../assets/awards/quality-win.png';
import logo6 from '../assets/awards/waste-winner.png';

function Awards() {
    return (
        <div className='max-w-[1200px] my-auto mt-10  justify-between mx-auto bg-cover '>
            <div className='flex justify-center items-center flex-col'>
                <div className=" border-gray-200 text-prim font-semibold text-2xl pb-1">Οι διακρίσεις μας</div>
                <div className="bg-gradient-to-r w-56 h-1.5 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>
                {/* <div className=" border-gray-200 text-prim font-semibold s pt-3">Το Followgreen επιβραβεύει, αλλά και επιβραβεύεται</div> */}
            </div>
            <div className="justify-center justify-items-center mt-20 mb-20 space-x-1 grid lg:grid-cols-6  md:grid-cols-3 xl:grid-cols-6 grid-cols-1 gap-3">

                <img src={logo6} className="h-16 object-contain" alt="logo"  />
                <img src={logo2} className="h-16 object-contain " alt="logo"  />
                <img src={logo1} className="h-16 object-contain" alt="logo"  />
                <img src={logo3} className="h-16 object-contain" alt="logo"  />
                <img src={logo4} className="h-16 object-contain" alt="logo"  />
                <img src={logo5} className="h-16 object-contain" alt="logo"  />

            </div>
        </div>
    );
}

export default Awards;
