import { CircularProgress } from '@mui/material';
import { useQueries, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getLiveMunicipalities } from '../api';
import logo1 from '../assets/awards/euro_awards.png';
import logo2 from '../assets/awards/nbg_awards.png';
import logo3 from '../assets/awards/oikopolis_awards.jpg';
import logo4 from '../assets/awards/paseppe_awards.jpg';
import logo5 from '../assets/awards/quality-win.png';
import logo6 from '../assets/awards/waste-winner.png';

function MunicipalitiesBanner() {

   
    const { isLoading, isError, error, data } = useQuery('live_municipalities', () => getLiveMunicipalities(), {
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    })

    if (isLoading) <>LOADING</>
    if (isError) <>ERROR</>
    if (!data) return <CircularProgress></CircularProgress>

    return (
        <div className='max-w-[1300px] mb-28  justify-between mx-auto bg-cover '>
            <div className='flex justify-center items-center flex-col mb-20'>
                <div className=" border-gray-200 text-prim font-semibold text-2xl pb-1">Οι Δήμοι μας</div>
                <div className="bg-gradient-to-r w-56 h-1.5 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>
                <div className=" border-gray-200 text-prim font-semibold s pt-3">Ανακύκλωσε στον Δήμο σου και εξαργύρωσε μοναδικές προσφορές</div>
            </div>
            <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">


                {data.rows.map((municipality, index) => {

                    if (municipality.Registered === 1 && municipality.LocalityEn !== "4all")
                        return <Link to={'/'+municipality.LocalityEn} key={index} className='relative h-[280px] overflow-hidden duration-200 ease-out shadow-2xl cursor-pointer rounded-b-[10.5%] rounded-3xl group shadow-gray-500 hover:-translate-y-2 '>
                            <div className="absolute bottom-0 rounded-t-none  w-full z-20 bg-white p-2 px-3 n  border-gray-200 font-semibold text-gray-700 ">{municipality.NameEl}</div>
                            <img loading="lazy" src={process.env.REACT_APP_BUCKET_URL + `/Content/css/assets/muncipality-images/${municipality.LocalityEn}.jpg`} className="object-cover h-full w-full" alt="municipality" />
                        </Link>
                })}




            </div>
        </div>
    );
}

export default MunicipalitiesBanner;
