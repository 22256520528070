

import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { BsNewspaper, BsRecordCircleFill } from "react-icons/bs";
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { getMunicipalityByLocality, subscribeToNewsletter } from '../api';
import Awards from '../components/Awards';
import GreenBanner from '../components/GreenBanner';
import useMunicipality from '../hooks/useMunicipality';
import ComingSoon from './ComingSoon';
import Earn from './Earn';
import News from './News';
import NotFind from './NotFind';
import Offers from './Ofers';
import Schools from './Schools';
import SportClubs from './SportCllubs';
import useAuth from '../hooks/useAuth';
import { useModal } from '../contexts/ModalContext';
import { RiCheckLine, RiCloseLine, RiNewspaperFill, RiQuestionAnswerFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';

let popupShown = false;

function HomeMunicipality() {


    let params = useParams();

    const { municipality } = useMunicipality();
    // console.log("MUNICIPALITY:::", municipality)
    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const { setModal, unSetModal } = useModal()

    const [isPopupShown, setIsPopupShown] = useState(false)


    let currentLocality = (params.municipalityId !== municipality.LocalityEn) ? params.municipalityId : municipality.LocalityEn;


    useEffect(() => {   
        if (isAuthenticated && !isPopupShown
            && user.AgreedGDPR == 0 
            && !window.localStorage.getItem("u-subscription-state")
            ) {
           
                setIsPopupShown(true);
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}
    
                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-0 border-app_green rounded-full w-20 h-20 flex justify-center items-center'>
                        {/* <div className='font-bold text-lg text-center pt-5'>Κάνε εγγραφή στο Newsletter μας!</div> */}
                        <div><BsNewspaper className='w-16 h-16 text-app_green'></BsNewspaper></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Κάνε εγγραφή στο Newsletter μας!</div>
    
                    <div className='font-semibold text-lg text-center pt-5'>Κάνε εγγραφή στο newsletter του Followgreen για να ενημερώνεσαι για
                        όλες τις δράσεις ανακύκλωσης του Δήμου σου και κέρδισε <b>200</b> πόντους!</div>
                    <Link to={municipality.LocalityEn + '/home/policy'} target="_blank" rel="noreferrer" className="mt-3 text-semibold text-app_blue_dark">Πολιτική Απορρήτου</Link>
                    <div className="flex flex-col md:flex-row">
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={async () => {
                                unSetModal();
                                let res = await subscribeToNewsletter({ citizenId: user.citizenId,});
                                updateUserValues();
                                window.localStorage.setItem("u-subscription-state", true);
    
                                //check if already got the points
                                if (res.status === 2) {
                                    // console.log("Points already given");
                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}
                            
                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>
                                                {/* <div className='font-bold text-lg text-center pt-5'>Κάνε εγγραφή στο Newsletter μας!</div> */}
                                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Έχει ήδη εξαργυρώσει τους πόντους εγγραφής στο Newsletter παλιότερα!</div>
                            
                                                <div className="flex flex-col md:flex-row">
                                                <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                    onClick={async () => {
                                                       
                                                        unSetModal();                      
                                                       
                            
                                                    }}
                                                >OK</div>
                                               
                                            </div>
                            
                                           
                                        </div>
                                    )
                                }
                                else{
                                    // console.log("Points given to your account");
                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}
                            
                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_green rounded-full w-20 h-20 flex justify-center items-center'>
                                                {/* <div className='font-bold text-lg text-center pt-5'>Κάνε εγγραφή στο Newsletter μας!</div> */}
                                                <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Συγχαρητήρια! Μόλις κερδίσατε 200 πόντους για την εγγραφή σας στο Newsletter.</div>
                            
                                                <div className="flex flex-col md:flex-row">
                                                <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                    onClick={async () => {
                                                       
                                                        unSetModal();                      
                                                       
                            
                                                    }}
                                                >OK</div>
                                               
                                            </div>
                            
                                           
                                        </div>
                                    )

                                }
                                
    
                            }}
                        >ΝΑΙ</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { 
                                window.localStorage.setItem("u-subscription-state", true);    
                                unSetModal()
                             }}
                        >ΟΧΙ</div>
                    </div>
    
                    {/* <div className='italic text-sm text-center pt-5'>*Οποιαδήποτε στιγμή επιθυμείτε, μπορείτε να διαγραφείτε από τη λίστα αποστολής των
                        email, διαγράφοντας την επιλογή αυτή στη σελίδα «Αλλαγή Στοιχείων» στον λογαριασμό
                        σας.</div> */}
                </div>
            )
        }
    }, [])

    const { isLoading, isError, error, data, isFetching, } =
        useQuery(['selected_municipality', municipality, params.municipalityId],
            () =>
                getMunicipalityByLocality(currentLocality),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                // /keepPreviousData: true
            })



    if (isLoading || isFetching)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError) {

        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA: {error.message} </p>
            </div>
        </div>
    }

    if (data === 'NO_MUNICIPALITY')
        return <NotFind></NotFind>

    // console.log(data)    

    if (municipality.LocalityEn === 'new_comming_soon')
        return <ComingSoon isHomePage={true}></ComingSoon>


    //window.localStorage.getItem("u-auth-token");
    //preview popup

    // console.log("Token:",window.localStorage.getItem("u-subscription-state"))
    // console.log(user)
   

    return (

        
        <div
            className="">
            {/* max-w-[1200px] */}
            <div className={`container relative  justify-between mx-auto bg-cover rounded-2xl
             ${municipality.LocalityEn === '4all'
                    ? "h-[500px] bg-fill mb-5 mt-5 max-w-[1200px] !bg-[url('https://followgreen.fra1.digitaloceanspaces.com/Content/city-4all.jpg')]"
                    :



                    "bg-headerimg mb-5 h-96 md:h-[475px] mt-5 my-auto max-w-[1200px] "}`}

                style={{ backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL}/Content/municipality/${municipality.LocalityEn}.png)` }}
            // style={{backgroundImage: `url(/assets/header/green-mission-logo.png)`}}

            >
                {/* { municipality.LocalityEn !== 'leros' ? null :
                    <div className='absolute bottom-[35%] left-10 w-64 flex '>
                        <div className='text-3xl font-bold text-white drop-shadow-md'>Καλώς ήρθες στον Δήμο Λέρου</div>
                    </div>
                } */}
                <div className="relative">
                    {/* <img src={logo} className="logo" alt="logo" /> */}
                    {


                        municipality.LocalityEn === '4all' ?
                            <h1 className="pt-10 p-2 text-4xl font-semibold">
                                {/* Καλώς ήρθες στον Δήμο {params.municipalityId} */}
                                Καλώς ήρθες στο Followgreen!
                            </h1> :
                            <h1 className="pt-10 text-4xl font-semibold">
                                {/* Καλώς ήρθες στον Δήμο {params.municipalityId} */}
                                <br></br>
                            </h1>
                    }
                    <div className='ml-0 md:ml-6 flex h-24 w-full items-start justify-start'>
                        <motion.img
                            initial={{ height: '0%' }}
                            animate={{ height: '6rem' }}
                            exit={{ height: '0%' }}
                            transition={{ duration: 0.5, ease: "easeOut", }}
                            // src={headerimgtitle}
                            // src={municipality.LocalityEn === '4all' ? '' :
                            //     (`https://followgreen.fra1.digitaloceanspaces.com/Content/css/assets/home-assets/title-${data.LocalityEn ?? "pallini"}.svg`)
                            // }
                            src={municipality.LocalityEn === '4all' ? '' :
                                (`${process.env.REACT_APP_BUCKET_URL}/Content/banner/${municipality.LocalityEn}.svg`)
                            }
                            // width={230}
                            // height={50}
                            alt=""
                            className='h-24'
                        />
                    </div>

                </div>
                <div className="absolute bottom-0 w-full h-20 bg-gradient-to-t from-white to-transparent"></div>
                {/* <div className="absolute top-0 w-full h-10 bg-gradient-to-b from-white via-gray-100 to-transparent"></div> */}
            </div>

            {/* <div className="container flex mx-auto mt-3 justify-evenly " >
               <img src={headerimg} alt=""></img>
            </div> */}



            {/* Schools Section */}
            {municipality.LocalityEn === '4all' ? null :
                <>
                    <div className='flex flex-col space-y-2 md:flex-row items-center justify-between px-4 mx-auto lg:flex lg:px-0 max-w-[1200px] -mb-10 mt-10 '>
                        <div className={`text-white bg-gradient-to-r  ${municipality.IsGreenSchool === 1 ? 'from-app_green  to-app_dark_green' : 'from-app_blue  to-app_blue_dark'}   font-bold rounded-full text-2xl px-10 py-4 text-center`}>Τα σχολεία μας</div>
                        <Link to={'contest'} className="shadow-lg flex justify-center  items-center shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                            <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
                                <p className='pl-1.5 pb-0.5'>{'περισσοτερα'.toUpperCase()}</p></div>
                        </Link>

                    </div>
                    <Schools isHomePage={true}></Schools>

                </>
            }




            <SportClubs isHomePage={true}></SportClubs>

            <div className=''> <GreenBanner isGreenSchool={municipality.IsGreenSchool}></GreenBanner></div>



            {municipality.IsGreenSchool === 1 ? null :
                <div className='flex  flex-col space-y-2 md:flex-row items-center justify-between  px-4 mx-auto lg:flex lg:px-0 max-w-[1200px] -mb-5 mt-16 '>
                    <div className="text-white bg-gradient-to-r from-app_blue  to-app_blue_dark  font-bold rounded-full text-2xl px-10 py-4 text-center">Εξαργύρωσε</div>
                    <Link to={'earn'} className="shadow-lg flex justify-center  items-center shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                        <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
                            <p className='pl-1.5 pb-0.5'>{'περισσοτερα'.toUpperCase()}</p></div>
                    </Link>
                </div>
                
                }


            {/* {municipality.IsGreenSchool === 1 ? null : <Offers isHomePage={true}></Offers>} */}


            {/* added */}
            <Offers isHomePage={true}></Offers>


            <div className='flex flex-col space-y-2 md:flex-row items-center justify-between  px-4 mx-auto lg:flex lg:px-0 max-w-[1200px] -mb-5 mt-14 '>
                <div className={`text-white bg-gradient-to-r ${municipality.IsGreenSchool === 1 ? 'from-app_green  to-app_dark_green' : 'from-app_blue  to-app_blue_dark'}  font-bold rounded-full text-2xl px-10 py-4 text-center`}>Μάθε και κέρδισε</div>
                <Link to={'earn'} className="shadow-lg flex justify-center  items-center shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                    <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
                        <p className='pl-1.5 pb-0.5'>{'περισσοτερα'.toUpperCase()}</p></div>
                </Link>
            </div>

            <Earn isHomePage={true}></Earn>

            {municipality.LocalityEn === '4all' ? null :
                <>   <div className='flex flex-col space-y-2 md:flex-row items-center justify-between  px-4 mx-auto lg:flex lg:px-0 max-w-[1200px] -mb-5 mt-4'>
                    <div className={`text-white bg-gradient-to-r ${municipality.IsGreenSchool === 1 ? 'from-app_green  to-app_dark_green' : 'from-app_blue  to-app_blue_dark'}  font-bold rounded-full text-2xl px-10 py-4 text-center`}>Νέα του Δήμου</div>
                    <Link to={'news'} className="shadow-lg flex justify-center  items-center shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                        <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
                            <p className='pl-1.5 pb-0.5'>{'περισσοτερα'.toUpperCase()}</p></div>
                    </Link>
                </div>

                    <News isHomePage={true}></News></>
            }

            {/* <Earn></Earn>

            <div className="bg-gradient-to-r from-app_yellow  to-app_brown ">


            </div>
 */}



            <Awards ></Awards>

            {/* <h4 className="underline bg-green-500">Κατέβασε το Followgreen App δωρεάν</h4> */}

        </div>
    );
}

export default HomeMunicipality;
