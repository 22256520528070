import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/followgreen-app-logo.svg';
import useMunicipality from '../hooks/useMunicipality';

const NotFind = () => {

    const { municipality } = useMunicipality();

    return (
        <div className="h-screen  flex flex-col items-center mt-48 space-y-8">
            <div className="max-w-lg w-[200px] md:w-[500px]">
                <img
                    src={logo}
                    className="flex w-full"
                    alt="Logo"
                />
            </div>
            <h1 className="text-9xl font-bold text-orange-400">404</h1>
            <p
                className="text-2xl md:text-3xl font-light leading-normal"
            >Η σελίδα δεν βρέθηκε. </p>
            {/* <p className="mb-8">But dont worry, you can find plenty of other things on our homepage.</p> */}

            {/* <Link to={`/${municipality.LocalityEn}`} className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-orange-400  hover:bg-orange-500">Επιστροφή στην αρχική σελίδα</Link> */}
            <div 
            onClick={() => {
                window.location.href = `/`;
            }}
            className="cursor-pointer px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-orange-400  hover:bg-orange-500">Επιστροφή στην αρχική σελίδα</div>
        </div>
    )
}

export default NotFind