import { CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { addCitizenMissionAction, checkIfEarned, getVideoByUrl } from '../api';
import useAuth from '../hooks/useAuth';
import { addTransaction } from '../services/transactionService';
import { formatDate, getThumb } from '../utils/utils';
import ReactPlayer from 'react-player'
import useMunicipality from '../hooks/useMunicipality';
import { GolfCourse } from '@mui/icons-material';

const SingVideo = () => {

    // const video = useLocation().state.post;
    // const videosList = useLocation().state.postList;


    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const transactionType = 3;

    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const [isEarned, setIsEarned] = useState(false);


    const { municipality } = useMunicipality();

    const location = useLocation()

    const quizUrl = location.pathname.split('/')[4];

    //offer and merchant must be fetched
    const { isLoading:isVideoLoading, isError:isVideoError, data: video, isFetching:isVideoFetching} =
        useQuery(['singlevideo'], () => getVideoByUrl({ url: quizUrl,municipalityId: municipality.MunicipalityId   }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            suspense:true
        })
    


    function extractVideoID(url) {
        const res = url.split("=");
        const embeddedUrl = "https://youtube.com/embed/" + res[1];
        return embeddedUrl

    }

    const { isLoading, isError, error, data, isFetching, } =
        useQuery(['check_if_earned',],
            () =>
                checkIfEarned({ citizenId: user !== null ? user.citizenId : null, transactionType: transactionType, earnId: video.video.VideoId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                // /keepPreviousData: true
            })

    const handlePoints = async () => {
        try {

            if (isAuthenticated) {
                if (!data) {
                    await addTransaction({ citizenId: user.citizenId, points: video.video.Points, transactionType: transactionType, earnId: video.video.VideoId })
                    updateUserValues()
                }
                setIsEarned(true);
                await new Promise(resolve => setTimeout(resolve, 100));
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',

                });
            }
            else { setIsEarned(true); }

        } catch (error) {

        }

    }

    const handleMission = async () => {
        try {
            if (!data) {
                await addTransaction({ citizenId: user.citizenId, points: video.video.Points, transactionType: transactionType, earnId: video.video.VideoId })
                
            }
            await addCitizenMissionAction(
                { citizenId: user.citizenId, 
                    citizenMissionId: searchParams.get('CitizenMissionId'),
                    goalId:searchParams.get('MissionGoalId'),
                    targetId:video.video.VideoId,

                 }).then((e) => {

                // setUserMission(true);
              })
            updateUserValues()
            setIsEarned(true);
            await new Promise(resolve => setTimeout(resolve, 100));
            window.scrollTo({
                top: 0,
                behavior: 'smooth',

            });

        } catch (error) {

        }

    }


    if (isLoading || isFetching || isVideoFetching)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA: {error.message} </p>
            </div>
        </div>


  //console.log(data)

    return (
        <div className='grid mx-auto grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-6 justify-center w-full md:w-[90%]  lg:w-[90%] max-w-[1200px] py-8 px-5 md:px-0  space-x-8 min-h-screen'>
            <div className='flex-col col-span-4  items-center  justify-center mb-5'>
                <div className='font-semibold text-2xl pb-5 w-full'> {video.video.TitleEl}</div>
                {isEarned
                    ?
                    <div className='relative'>
                        <div className='w-full h-[360px]'>

                        </div>

                        <div className="absolute flex flex-col items-center justify-center bg-gradient-to-r from-app_blue  to-app_blue_dark w-[90%] left-[5%] shadow-md opacity-100  top-[5%] rounded-md p-5 ">
                            <div className='text-xl pb-5 font-semibold text-center text-white'>Ευχαριστούμε για το χρόνο σου</div>
                            {

                                !isAuthenticated
                                    ?
                                    <>
                                        <div className='text-xl font-semibold text-center text-white'>Για να κερδίζεις πόντους διαβάζοντας άρθρα, βλέποντας βίντεο και παίζοντας με κουίζ θα πρέπει να έχεις κάνει εγγραφή - εάν ο Δήμος σου συμμετέχει στο followgreen!</div>
                                        <div className='justify-center flex whitespace-pre-line px-8  cursor-pointer' onClick={() => {
                                            navigate('../../../account/register')
                                        }}>
                                            <div className='text-white text-lg font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Εγγραφη</div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {data ? null :
                                            <div className='flex justify-center text-md pb-5 font-semibold text-white'><div className='w-9 h-9 p-0 mr-2 text-center border-2 border-app_red rounded-full'>{video.video.Points}</div> πόντοι προστέθηκαν στον λογαριασμό σου!</div>
                                        }
                                        <div className='text-xl font-semibold text-center text-white'>Μπορείς να κερδίσεις περισσότερους πόντους διαβάζοντας άρθρα, βλέποντας βίντεο και παίζοντας με κουίζ!</div>
                                        <div className='justify-center flex whitespace-pre-line px-8 pt-8 cursor-pointer' onClick={() => {
                                            // window.history.back() 
                                            // console.log(searchParams.get('mission'))
                                            if(searchParams.get('mission'))
                                {
                                   
                                    if(searchParams.get('MissionRound') === '2')
                                    navigate('/../mission/single/currnet2');
                                    else
                                    navigate('/../mission/single/currnet');
                                }
                                else
                                          {
                                            navigate(-1)
                                            }
                                        }}>
                                           {searchParams.get('mission')?<div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'>Συνέχεια στο Mission</div>
                                    :
                                    <div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'> Περισσότερα</div>}
                                        </div>
                                    </>

                            }

                        </div>

                        {searchParams.get('mission') ?
                         <div className={` absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-app_brown to-app_red`}>
                            <GolfCourse className='text-white'></GolfCourse>
                        </div> : <div className="absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-11 h-11 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{video.video.Points}</div>
                        </div>
                        }
                    </div>
                    :
                    <div className='relative'> <div className='w-full h-[380px]'>
                        <ReactPlayer url={extractVideoID(video.video.Url)}
                            width="100%"
                            height="100%"
                            playsinline={true}
                            pip={false}
                            controls={false}
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 0 }
                                },
                            }}
                            onProgress={(s) => {
                                // console.log(s)
                            }}
                            onEnded={() => {
                                if( searchParams.get('mission'))
                                {
                                    handleMission()
                                }
                                handlePoints()
                            }}

                        />
                        {/* <iframe
                            className='mt-3 relative'
                            width="100%"
                            height="100%"
                            controls="0"
                            auto
                            src={extractVideoID(video.Url)}
                            title="video"
                            frameborder="0"
                            allow=""


                        ></iframe> */}
                    </div>

                        {/* <div className="absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-11 h-11 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{video.video.Points}</div>
                        </div> */}
                        {searchParams.get('mission') ?
                         <div className={` absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-app_brown to-app_red`}>
                            <GolfCourse className='text-white'></GolfCourse>
                        </div> : <div className="absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-11 h-11 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{video.video.Points}</div>
                        </div>
                        }

                    </div>}
                <div className='w-full text-white bg-gradient-to-r from-app_blue  to-app_blue_dark  font-sdemibold text-xl px-10 py-4 text-center'> {video.video.SubtitleEl}</div>

                <div className='flex whitespace-pre-line text-lg p-8' dangerouslySetInnerHTML={{ __html: '<div>' + (video.video.BodyEl ? video.video.BodyEl : '') }}>

                </div>

                {/* {(data || isEarned)
                    ?
                    <div className='justify-center flex whitespace-pre-line px-8 pb-10 cursor-pointer' >
                        <div className='text-white text-lg font-semibold p-2 px-3 bg-gray-300 rounded-3xl'>Έχεις παραλάβει τους πόντους σου! </div>

                    </div>
                    :
                    <div className='justify-center flex whitespace-pre-line px-8 pb-10 cursor-pointer' onClick={handlePoints}>
                        <div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'>Κέρδισες {video.Points} πόντους! Πάτησε εδώ!</div>
                    </div>} */}

            </div>

            <div className='flex-col  col-span-2 items-center  justify-center  divide-dashed '>
                <div className='font-semibold text-xl pb-5 w-full'>Δες περισσότερα</div>
                {video.videosList.map((_video, index) => {
                  //console.log(_video);
                    if (_video.VideoId !== video.VideoId)
                        return <div className='flex  mb-8   justify-start place-items-start '>

                            <div className='w-30 h-20 relative'><img
                                className='object-cover shadow-lg h-full rounded-xl'
                                src={getThumb(_video.Url)}
                                alt='' />

                            </div>

                            <div className='flex flex-col h-20 w-20 justify-between'>
                                <div className='w-48 text-[13px]  pl-2 font-semibold'>{_video.TitleEl}</div>

                                <Link
                                    to={'../' + _video.FriendlyUrl}
                                    state={{ post: _video, postList: video.videosList }}
                                    className='cursor-pointer pl-2 text-[12px] font-semibold'>{'read more'}

                                </Link>
                            </div>
                        </div>
                    else return null
                })}
            </div>

        </div>
    )
}

export default SingVideo