import { GolfCourse } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { addCitizenMission, addCitizenMissionAction, checkIfEarned, getBlogByUrl } from '../api';
import useAuth from '../hooks/useAuth';
import useMunicipality from '../hooks/useMunicipality';
import { addTransaction } from '../services/transactionService';
import { formatDate } from '../utils/utils';

const SingleBlogPost = () => {

    // const blogPost = useLocation().state.post;
    // const postList = useLocation().state.postList;

    const navigate = useNavigate();


    const [searchParams, setSearchParams] = useSearchParams();

    // console.log("Is Mission: ",searchParams.get('mission'));
    const transactionType = 6;

    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const [isEarned, setIsEarned] = useState(false);

    const { municipality } = useMunicipality();

    const location = useLocation()

    const blogUrl = location.pathname.split('/')[4];

    //offer and merchant must be fetched
    const { isLoading: isBlogLoading, isError: isBlogError, data: blogPost, isFetching: isBlogFetching } =
        useQuery(['singleblog'], () => getBlogByUrl({ url: blogUrl, municipalityId: municipality.MunicipalityId }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            suspense: true
        })

    const { isLoading, isError, error, data, isFetching, } =
        useQuery(['check_if_earned', isEarned, blogPost],
            () =>
                !blogPost ? null : checkIfEarned({ citizenId: user !== null ? user.citizenId : null, transactionType: transactionType, earnId: blogPost.blog.BlogId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                // /keepPreviousData: true
            })

    const handlePoints = async () => {
        try {
            await addTransaction({ citizenId: user.citizenId, points: blogPost.blog.Points, transactionType: transactionType, earnId: blogPost.blog.BlogId })
            // addPoints()
            updateUserValues()
            setIsEarned(true);
            await new Promise(resolve => setTimeout(resolve, 100));
            window.scrollTo({
                top: 0,
                behavior: 'smooth',

            });

        } catch (error) {

        }

    }
    const handleMission = async () => {
        try {

            if((data || isEarned))
            {}
            else{
                await addTransaction({ citizenId: user.citizenId, points: blogPost.blog.Points, transactionType: transactionType, earnId: blogPost.blog.BlogId })
            }
           
            
            await addCitizenMissionAction(
                { citizenId: user.citizenId, 
                    citizenMissionId: searchParams.get('CitizenMissionId'),
                    goalId:searchParams.get('MissionGoalId'),
                    targetId:blogPost.blog.BlogId,

                 }).then((e) => {

                // setUserMission(true);
              })
            updateUserValues()
            setIsEarned(true);
            await new Promise(resolve => setTimeout(resolve, 100));
            window.scrollTo({
                top: 0,
                behavior: 'smooth',

            });

        } catch (error) {

        }

    }


    if (isLoading || isFetching || isBlogLoading || isBlogFetching)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError || isBlogError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA: {error.message} </p>
            </div>
        </div>


    //console.log(data)

    return (
        <div className='grid mx-auto grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-6 justify-center w-full md:w-[90%]  lg:w-[90%] max-w-[1200px] py-8 px-5 md:px-0  space-x-8 min-h-screen'>
            <div className='flex-col col-span-4  items-center  justify-center mb-5'>
                <div className='font-semibold text-2xl pb-5 w-full '> {blogPost.blog.TitleEl}</div>
                {isEarned
                    ?
                    <div className='relative'>
                        <img
                            className='shadow-xl w-full rounded-t-xl  opacity-10 bg-opacity-100 bg-green-600'
                            src={(blogPost.blog.BlogImage.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + blogPost.blog.BlogImage}
                            alt='' />

                        <div className="absolute flex flex-col items-center justify-center bg-gradient-to-l from-app_blue  to-app_blue_dark w-[90%] left-[5%] shadow-md opacity-100  top-[25%] rounded-md p-5 ">
                            <div className='text-2xl pb-5 font-semibold text-center text-white'>Ευχαριστούμε για το χρόνο σου</div>
                            <div className='text-2xl font-semibold text-center text-white'>Μπορείς να κερδίσεις περισσότερους πόντους διαβάζοντας άρθρα, βλέποντας βίντεο και παίζοντας με κουίζ!</div>
                            <div className='justify-center flex whitespace-pre-line px-8 pt-8 cursor-pointer' onClick={() => {
                                // window.history.back() 
                                if(searchParams.get('mission'))
                                {
                                    if(searchParams.get('MissionRound') === '2')
                                    navigate('/../mission/single/currnet2');
                                    else
                                    navigate('/../mission/single/currnet');
                                }
                                else
                                navigate(-1)
                            }}>
                                {searchParams.get('mission')?<div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'>Συνέχεια στο Mission</div>
                                    :
                                    <div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'> Περισσότερα</div>}
                            </div>
                        </div>

                        {searchParams.get('mission') ?
                         <div className={` absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-app_brown to-app_red`}>
                            <GolfCourse className='text-white'></GolfCourse>
                        </div> : <div className="absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-11 h-11 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{blogPost.blog.Points}</div>
                        </div>
                        }
                    </div>
                    :
                    <div className='relative'> <img
                        className='shadow-xl w-full rounded-t-xl'
                        src={(blogPost.blog.BlogImage.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + blogPost.blog.BlogImage}
                        alt='' />

                        {searchParams.get('mission') ?
                         <div className={` absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-app_brown to-app_red`}>
                            <GolfCourse className='text-white'></GolfCourse>
                        </div> : <div className="absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-11 h-11 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{blogPost.blog.Points}</div>
                        </div>
                        }

                    </div>}
                <div className='w-full text-white bg-gradient-to-r from-app_blue  to-app_blue_dark  font-sdemibold text-xl px-10 py-4 text-center'> {blogPost.blog.SubtitleEl}</div>

                {/* <div className='flex whitespace-pre-line text-lg p-8' dangerouslySetInnerHTML={{ __html: '<div>' + blogPost.blog.BodyEl + '</div>'}}> */}
                <div className='flex heir-p:pb-3 text-lg p-8' dangerouslySetInnerHTML={{ __html: '<div>' + blogPost.blog.BodyEl + '</div>'}}>

                </div>

                {(() => {
                    if (!isAuthenticated) {
                        return <div className='justify-center flex whitespace-pre-line px-8 pb-10 cursor-pointer' >
                            <div className=' p-5 bg-gradient-to-r from-[#DEEFFF] to-[#F4F7EB] rounded-3xl'>
                                <div className='text-gray-700 font-semibold text-center'>Για να κερδίζεις πόντους διαβάζοντας άρθρα, βλέποντας βίντεο και παίζοντας με κουίζ θα πρέπει να έχεις κάνει εγγραφή - εάν ο Δήμος σου συμμετέχει στο followgreen!</div>
                                <div className='justify-center flex whitespace-pre-line px-8  cursor-pointer' onClick={() => {
                                    navigate('../../../account/register')
                                }}>
                                    <div className='text-white text-lg font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Εγγραφη</div>
                                </div>
                            </div>

                        </div>
                    }
                    else {
                        return <> 
                          {  searchParams.get('mission') ?<div className='justify-center flex whitespace-pre-line px-8 pb-10 cursor-pointer' onClick={handleMission}>
                                    <div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'>Για να ξεκλειδώσεις τον στόχο Πάτησε εδώ!</div>
                                </div> :
                            <>
                             {(data || isEarned)
                                ?
                                <div className='justify-center flex whitespace-pre-line px-8 pb-10 cursor-pointer' >
                                    <div className='text-white text-lg font-semibold p-2 px-3 bg-gray-300 rounded-3xl'>Έχεις παραλάβει τους πόντους σου! </div>
    
                                </div>
                                :
                                <div className='justify-center flex whitespace-pre-line px-8 pb-10 cursor-pointer' onClick={handlePoints}>
                                    <div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'>Κέρδισες {blogPost.blog.Points} πόντους! Πάτησε εδώ!</div>
                                </div>}
                            </>
                          }
                        </>

                    }
                })()}





            </div>

            <div className='flex-col  col-span-2 items-center  justify-center  divide-dashed '>
                <div className='font-semibold text-xl pb-5 w-full'>Διάβασε περισσότερα</div>
                {blogPost.postList.map((readMoreArticle, index) => {
                    if (readMoreArticle.BlogId !== blogPost.blog.BlogId)
                        return <div className='flex  mb-8   justify-start place-items-start '>

                            <div className='w-30 h-20 relative'><img
                                className='object-cover shadow-lg h-full rounded-xl'
                                src={(readMoreArticle.BlogImage.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + readMoreArticle.BlogImage}
                                alt='' />

                            </div>

                            <div className='flex flex-col h-20 w-20 justify-between'>
                                <div className='w-48 text-[13px]  pl-2 font-semibold'>{readMoreArticle.TitleEl}</div>

                                <Link
                                    to={'../' + readMoreArticle.FriendlyUrl}
                                    state={{ post: readMoreArticle, postList: blogPost.postList }}
                                    className='cursor-pointer pl-2 text-[14px] font-semibold'>{'περισσότερα '}

                                </Link>
                            </div>
                        </div>
                    else return null
                })}
            </div>

        </div>
    )
}

export default SingleBlogPost