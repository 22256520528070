import { Map, MapsUgc } from '@mui/icons-material';
import React, { useState } from 'react'
import { FaHeart, FaMapMarked, FaMapMarkedAlt, FaQuestion, FaShare } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate } from '../utils/utils';
import seperator from '../assets/offer-edge.svg';
import useAuth from '../hooks/useAuth';
import { toggleFavoriteOffer } from '../services/favoritesService';
import { addCitizenOffer, confirmEmail, getAllCitizenFavoritesOnly, getMerchantByUrl, getOfferByUrl } from '../api';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import { useModal } from '../contexts/ModalContext';
import { motion, useInView } from 'framer-motion';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import NotFind from './NotFind';
import OnboardSteps from '../components/OnboardSteps';

const ConfirmEmail = () => {
    

    // const offer = useLocation().state.offer;

    // const merchant = useLocation().state.merchant

    //first we need the offer url
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    
    const offerUrl = location.pathname.split('/')[4];

  //console.log(searchParams.userId)
  //console.log(searchParams.code)


    
    //offer and merchant must be fetched
    const { isLoading, isError, data } =
    useQuery('confirmEmail', () => confirmEmail({userId:searchParams.get('userId')}), {
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,       
    })


    if(!searchParams.get('userId') || !searchParams.get('code'))
    {
        return <NotFind></NotFind>
    }

    if (isLoading || !data)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA </p>
            </div>
        </div>
    if(data.data.status === 1 || data.data.status === 2)
    {
       return <NotFind></NotFind>
     
    }
    return (
        <div className='flex flex-col min-h-screen w-full px-4 mx-auto lg:flex lg:px-8 xl:px-0 max-w-[1200px] mt-10 '>
            <div className='flex justify-between flex-col md:flex-row '>
                
               
            </div>
            <div className='flex h-full flex-col justify-center space-y-10 items-center'>

            <div className='flex  font-bold text-2xl'>
                        Καλωσήρθες στο Followgreen 👋🏻
                    </div>
                    <div className='flex flex-col text-lg font-semibold'>
                    <div className='mb-2 flex '>Συγχαρητήρια, μόλις ολοκλήρωσες με επιτυχία την εγγραφή σου στο Followgreen</div>

Επιβραβεύουμε την προσπάθειά σου προσφέροντας τους πρώτους 50 πόντους επιβράβευσης. Ξεκίνησε άμεσα να συγκεντρώνεις περισσότερους πόντους, ανακυκλώνοντας και εμπλουτίζοντας τις γνώσεις σου γύρω από την ανακύκλωση και το περιβάλλον.<br></br>

Επιπλέον, αν συμμετέχεις στα προγράμματα Διαλογής στην Πηγή κερδίζεις κάθε μήνα επιπλέον πόντους επιβράβευσης! Μάθε περισσότερα στην κατηγορία ‘’Ανακύκλωσε΄’.Μη ξεχνάς! Όσο Ανακυκλώνεις. Κερδίζεις!

Μη ξεχνάς! Όσο Ανακυκλώνεις. Κερδίζεις!
                    </div>

                


            </div>
            <OnboardSteps></OnboardSteps>
           
            
        </div>
    )
}

export default ConfirmEmail