import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from '../assets/followgreen-app-logo.svg';
import logogreen from '../assets/logo-greenschools.svg';
import greenMissionImg from '../assets/header/green-mission-logo.png';
import howItWorksImg from '../assets/header/how-it-works-logo.png';
import useAuth from "../hooks/useAuth";
import useMunicipality from "../hooks/useMunicipality";
import { getImage } from "../utils/utils";
import MunicipalitySelectWidget from "./MunicipalitySelectWidget";
import { useQuery } from "react-query";
import { getLiveMission, getLiveMissionNext } from "../api";
import { CircularProgress } from "@mui/material";
import { useModal } from "../contexts/ModalContext";
import { motion } from 'framer-motion';


function Navbar() {
    const [state, setState] = useState()
    const { municipality } = useMunicipality();
    const location = useLocation()
    const navigate = useNavigate()

    const { logout, user, isAuthenticated } = useAuth();
    const { setModal, unSetModal } = useModal()


    const { isLoading: isLiveMissionLoading, isError: isLiveMissionError, data: liveMission, isFetching } =
        useQuery('live_mission_one', () => getLiveMission({ municipalityId: municipality.MunicipalityId, citizenId: isAuthenticated ? user.citizenId : 0 }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })


    const { isLoading: isLiveMissionNextLoading, isError: isLiveMissionNextError, data: liveMissionNext, isFetching: isNextMissionFetching } =
        useQuery('live_mission_two', () => getLiveMissionNext({ municipalityId: municipality.MunicipalityId, citizenId: isAuthenticated ? user.citizenId : 0 }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })



    let [navigation, setNavigation] = useState(
        [
            { title: "ΑΡΧΙΚΗ", path: "", selected: true },
            { title: "ΚΕΡΔΙΣΕ", path: `earn`, selected: false },
            { title: "ΕΞΑΡΓΥΡΩΣΕ", path: `offer`, selected: false },
            { title: "ΑΝΑΚΥΚΛΩΣΕ", path: `action`, selected: false },
            { title: "ΣΧΟΛΕΙΑ", path: `contest`, selected: false },
            // { title: "ΜΑΘΕ ΠΟΥ...", path: `learn`, selected: false },
        ])

    useEffect(() => {

        let pathLength = location.pathname.split('/');
        if (pathLength.length > 3) {
            let navigationCopy = navigation;
            for (let index = 0; index < navigationCopy.length; index++) {
                const element = navigationCopy[index];
                element.selected = false;

            }
            setNavigation([...navigationCopy])
        }

        else {
            let navigationCopy = navigation;
            for (let index = 0; index < navigationCopy.length; index++) {
                const element = navigationCopy[index];
                //console.log("PATH LENGTH ",pathLength)
                if (pathLength.length === 2) {
                    navigationCopy[0].selected = true;
                }

                if (pathLength[2] === element.path) {
                    element.selected = true;
                }
                else
                    element.selected = false;

            }
            setNavigation([...navigationCopy])

        }
    }, [municipality, location]);

    if (!location.pathname.split("/")[1]) {
        return <div className="w-full bg-white border-b lg:border-0 lg:static">
            <div className="w-full h-1 gradient mt-5"></div>
        </div>
    }

    if (isLiveMissionLoading || isFetching || isLiveMissionNextLoading || isNextMissionFetching)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>


    if (municipality.LocalityEn === 'new_comming_soon')
        return( <div></div>)

    return (

        <nav className="w-full bg-white border-b lg:border-0 lg:static  ">
            <div className="items-center justify-between px-4 mx-auto md:px-5 lg:flex lg:px-5 xl:px-0 max-w-[1200px] ">
                <div className="flex items-center justify-between py-3 lg:py-5 lg:block m ">

                    <Link to={isAuthenticated ? municipality.LocalityEn : '../'} onClick={() => {
                        for (let i = 0; i < navigation.length; i++) {
                            navigation[i].selected = false;
                        }

                        navigation[0].selected = true;
                        setNavigation([...navigation])
                    }}>
                        <img
                            src={municipality.IsGreenSchool === 1 ? logogreen : logo}
                            width={130}
                            height={50}
                            className={`${municipality.IsGreenSchool === 1 ? "-mb-7 -mt-7" : ""}`}
                            alt="Logo"
                        />

                    </Link>

                    <div className="lg:hidden">
                        <button className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                            onClick={() => setState(!state)}
                        >
                            {
                                state ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                    </svg>
                                )
                            }
                        </button>
                    </div>


                </div>



                <div className={`flex justify-self-center pb-3 mt-8 lg:block lg:pb-0 lg:mt-0 ${state ? 'block' : 'hidden'}`}>
                    <MunicipalitySelectWidget></MunicipalitySelectWidget>
                    {/* <a href="/" className=" rounded-xl pt-1 pb-1 pl-2.5 pr-2.5 border-2 border-gray-100 font-semibold text-gray-500 hover:text-gray-400 ">

                        ΔΗΜΟΣ ΑΜΠΕΛΟΚΗΠΩΝ - ΜΕΝΕΜΕΝΗΣ


                    </a> */}
                </div>

                {
                    user ?
                        <div className={`flex relative group justify-self-center justify-center items-center p-1 rounded-full hover:bg-gray-100 cursor-pointer`}>
                            <div className="pr-2 font-semibold">{user.name}</div>
                            {/* <img className="rounded-full w-9 h-9" src={user.avatar.length > 1 ? user.avatar: getImage('/images/anon_user.png')} alt="" /> */}
                            <img className="rounded-full w-9 h-9 object-cover" src={user.avatar !== null ? getImage(user.avatar) : getImage('/images/anon_user.png')} alt="" />
                            <div className="flex items-center justify-center bg-opacity-100 rounded-full shadow-md opacity-100 w-10 h-10 bg-app_red ">
                                <div className="flex  items-center justify-center font-bold text-gray-600 bg-white   rounded-full  w-8 h-8  text-[13px]  ">
                                    {user.points}
                                </div>
                            </div>
                            {/* <div className="bg-app_red h-9 w-9 flex justify-center text-center items-center rounded-full text-white font-semibold">{user.points}</div> */}
                            {/* <div className=" pb-3 pl-5 pr-5 pt-2 font-semibold text-gray-500 hover:text-gray-400 cursor-pointer">ΜΑΘΕ ΠΟΥ...</div> */}

                            <div className="absolute hidden top-12 group-hover:flex hover:flex  w-[180px]  flex-col bg-white drop-shadow-2xl z-50 rounded-xl overflow-hidden divide-y-[1px] divide-gray-300 divide-opacity-20">

                                <Link className="px-2 py-2 text-center text-[15px] hover:bg-gray-50 z-50  " to={municipality.LocalityEn + '/member'}>Προφίλ</Link>
                                <button className="px-2 py-2 text-center text-[15px] hover:bg-gray-50 z-50" onClick={() => {
                                    logout();
                                    navigate('./' + municipality.LocalityEn,)
                                }}>Έξοδος</button>

                            </div>
                        </div>
                        :
                        <div className={`flex justify-self-center pb-3 mt-8 lg:block lg:pb-0 lg:mt-0 ${state ? 'block' : 'hidden'}`}>
                            <Link className="pr-1" to={municipality.LocalityEn + '/account/login'}><span className="font-semibold hover:text-green-600">ΕΙΣΟΔΟΣ</span></Link>
                            |<Link className="pl-1  " to={municipality.LocalityEn + '/account/register'}><span className="font-semibold hover:text-green-600">ΕΓΓΡΑΦΗ</span></Link>
                        </div>
                }


            </div>

            <div className={`max-w-[1200px] mx-auto pb-3 mt-8 lg:flex justify-between lg:pb-0 lg:mt-0 ${state ? 'block' : 'hidden'}`}>
                <ul className="items-center justify-center space-x-2 lg:spaxe-x-5 lg:flex mx-10 xl:mx-0 ">
                    {
                        navigation.map((item, idx) => {
                            if (item.title === 'ΣΧΟΛΕΙΑ' && (municipality.LocalityEn === '4all'))
                                return null
                            if (item.title === 'ΕΞΑΡΓΥΡΩΣΕ' && (municipality.IsGreenSchool === 1))
                                return null
                            if (item.title === 'ΑΝΑΚΥΚΛΩΣΕ' && (municipality.IsGreenSchool === 1))
                                return null
                            else
                                return (
                                    <li key={idx} className={`pb-3 pl-5 pr-5 pt-2 font-semibold text-gray-500 hover:text-gray-400 
                                    ${item.selected ? 'z-10 absolute shadow-head -mb-2 bg-white rounded-t-3xl menu-item' : ''}
                                    `}>

                                        {/* {item.selected && <div className=" menu-item-left"></div>    } */}

                                        <Link to={municipality.LocalityEn + '/' + item.path} onClick={() => {
                                            for (let i = 0; i < navigation.length; i++) {
                                                navigation[i].selected = false;
                                            }

                                            navigation[idx].selected = true;
                                            setNavigation([...navigation])
                                        }}>
                                            {item.title}

                                        </Link>


                                        {/* {item.selected && <div className="menu-item-right"></div>    } */}

                                    </li>

                                )
                        })
                    }
                    {(municipality.LocalityEn === '4all' || municipality.IsGreenSchool === 1) ? null :
                        <div className=" relative z-50">

                            <div className="peer pb-3 pl-5 pr-5 pt-2 font-semibold text-gray-500 hover:text-gray-400 cursor-pointer">ΜΑΘΕ ΠΟΥ...</div>

                            <div className="absolute hidden peer-hover:flex hover:flex  w-[180px]  flex-col bg-white drop-shadow-2xl z-50 rounded-xl overflow-hidden divide-y-[1px] divide-gray-300 divide-opacity-20">

                                <Link className="px-2 py-2 text-center text-[15px] hover:bg-gray-50 z-50  " to={municipality.LocalityEn + '/recyclebucket'}>Ανακυκλώνεις</Link>
                                <Link className="px-2 py-2 text-center text-[15px] hover:bg-gray-50 z-50" to={municipality.LocalityEn + '/offer/merchantmap'}>Εξαργυρώνεις</Link>

                            </div>
                        </div>}

                </ul>
                <div className="hidden space-x-3 lg:flex mr-5 xl:mr-0">

                    <Link to={municipality.LocalityEn + '/home/how'} className="overflow-hidden shadow-md hover:shadow-lg h-7 shadow-app_green rounded-3xl hover:shadow-app_green_light transition duration-300 ease-in-out">
                        <img
                            src={howItWorksImg}
                            width={103}
                            height={50}

                            alt=""
                        />
                    </Link>
                    {(municipality.LocalityEn === '4all' || municipality.IsGreenSchool === 1 || !liveMission.hasMission) ? null :
                        // <Link to={municipality.LocalityEn + '/mission/single/currnet'} className="overflow-hidden shadow-md h-7 shadow-app_green rounded-3xl hover:shadow-app_green_light transition duration-300 ease-in-out hover:shadow-lg">
                        //     <img
                        //         src={greenMissionImg}
                        //         width={103}
                        //         height={50}
                        //         alt=""
                        //     />
                        // </Link>

                        <div className="cursor-pointer overflow-hidden shadow-md h-7 shadow-app_green rounded-3xl hover:shadow-app_green_light transition duration-300 ease-in-out hover:shadow-lg"

                            onClick={() => {
                                //missions
                                setModal(
                                    <div className='flex flex-col items-center justify-center py-5'>
                                        {/* <motion.div
                                    initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                    exit={{ rotate: 1, scale: 1, opacity: 0 }}
      
                                    animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                    transition={{
                                        damping: 20,
                                        duration: 0.35
                                    }}
                                    className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>
      
                                    <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                                </motion.div> */}
                                        <div className='font-bold text-lg text-center pt-5'>Διάλεξε Mission:</div>
                                        <Link to={municipality.LocalityEn + '/mission/single/currnet'} className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl  hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                            onClick={() => {
                                                unSetModal()
                                                // navigate(municipality.LocalityEn + '/mission/single/currnet')
                                            }}
                                        >{liveMission.mission.TitleEl}</Link>

                                        {liveMissionNext.hasMission ? <Link to={municipality.LocalityEn + '/mission/single/currnet2'} className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl  hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                            onClick={() => {
                                                unSetModal()
                                                // navigate(municipality.LocalityEn + '/mission/single/currnet')
                                            }}
                                        >{liveMissionNext.mission.TitleEl}</Link> : null}
                                    </div>
                                )
                            }}
                        >
                            <img
                                src={greenMissionImg}
                                width={103}
                                height={50}
                                alt=""
                            />
                        </div>}





                </div>

            </div>


            {/* <div className="w-full h-2 bg-gradient-to-r from-app_red via-app_blue to-app_green ">s</div> */}
            <div className="w-full h-1 gradient"></div>

        </nav>
    )
}

export default Navbar;
