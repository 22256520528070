import { Button, CircularProgress, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addCitizenMission, addCitizenVote, completeCitizenMission, getLiveMission, getLiveVote } from '../api';
import useAuth from '../hooks/useAuth';
import useMunicipality from '../hooks/useMunicipality';
import { getThumb } from '../utils/utils';
import { motion } from 'framer-motion';
import { MdArticle, MdSportsScore, MdVideoCameraBack } from 'react-icons/md';
import { BsCup, BsQuestionCircle } from 'react-icons/bs';
import { Gamepad, GamesRounded, GolfCourse } from '@mui/icons-material';
import { FaChevronLeft, FaMapMarkedAlt, FaTrophy } from 'react-icons/fa';
import { useModal } from '../contexts/ModalContext';
import { RiCheckLine, RiCloseLine, RiEmotionUnhappyFill } from 'react-icons/ri';
import VoteOption from '../components/VoteOption';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Carousel from 'react-multi-carousel';
import ReactPlayer from 'react-player';

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


const SingleVote = () => {

  const { municipality } = useMunicipality();
  const location = useLocation()
  const navigate = useNavigate()

  const [userMission, setUserMission] = useState()

  const { logout, user, isAuthenticated, updateUserValues } = useAuth();

  const [currentQuestion, setCurrentQuestion] = useState(0)

  const [submitions, setSubmitions] = useState(0)

  const [votingDone, setVoitingDone] = useState(false)

  const [timer, setTimer] = useState(false)

  const [filter, setFilter] = useState(0)

  const votesData = [];
  useEffect(() => {
    setTimeout(() => {
      setTimer(true)
    }, 1000)
  }, [])

  const getLocation = () =>
  {
     let locs = location.pathname.split("/");

      //get last item
     return locs[locs.length-1];
  }

  



  const handleFilterChanges = (_filter) => {
    setFilter(_filter)
     
     // <option value="1">Δημοτικό</option>
     // <option value="2">Γυμνάσιο</option>
     // <option value="3" selected="">Λύκειο</option>
     // <option value="4">Νηπιαγωγείο</option></select>
 }


  const { setModal, unSetModal } = useModal()
  const cardVariants = {
    initial: {
      y: 300,
    },
    exit: {
      y: 0
    },
    animate: {
      y: 0,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1
      }
    }
  };


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  const sliderSettings = {
    className: "center",
    // centerMode: true,
    // infinite: false,
    // centerPadding: "10px",
    // speed: 500,
    rows: 2,
    slidesToShow: 1,
    slidesPerRow: 5,
    dots: true,
    autoplay: false,
    // dots: true,
    infinite: false,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 4,
          
        }
      },
      {
        breakpoint: 600,
        settings: {
          rows: 2,
          slidesToShow: 1,
          slidesPerRow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          rows: 2,
          slidesToShow: 1,
          slidesPerRow: 3,
        }
      }
    ]


  };

  const { isLoading: isLiveVotenLoading, isError: isLiveVoteError, data: liveVote, } =
    useQuery(['live_vote_queries', submitions, filter], () => getLiveVote({ municipalityId: municipality.MunicipalityId, citizenId: isAuthenticated ? user.citizenId : 0, filter:filter, voteId:getLocation() }), {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    })



  const submitVote = async ({ voteOptionId, isYes }) => {

    console.log(isYes);
    if (isYes) {
      //has user submited more than 3 votes?
      if (submitions < 3) {
        const data =
        {
          citizenId: user.citizenId,
          voteId: liveVote.vote.VoteId,
          voteOptionId: voteOptionId
        }
        await addCitizenVote(data);

        setSubmitions(submitions + 1);
      }
      else {
        setVoitingDone(true);
      }
    }
    // return;  
    if (currentQuestion < liveVote.voteOptions.length - 1) { setCurrentQuestion(currentQuestion + 1); }
    else {

    }

  }



  //Error checking and loading        
  if (isLiveVotenLoading)
    return <div
      className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

  if (isLiveVoteError)

    return <div
      className='flex p-8 justify-center h-screen items-center'>
      <div className=' text-center'>

        <p className='texgt-md font-medium'>ERROR LOADING DATA: { } </p>
      </div>
    </div>

  if (liveVote.length)
    return <div className='flex p-8 justify-center h-screen items-center'>
      <div className=' text-center'>
        <p className='texgt-md font-medium'>No Data To Load</p>
      </div>
    </div>

  if (liveVote.vote === null || liveVote.voteOptions == null)
    return <div className='flex p-8 justify-center h-screen items-center'>
      <div className=' text-center'>
        <p className='texgt-md font-medium'>Δεν υπάρχει διαθέσιμη ψηφοφορία </p>
      </div>
    </div>

  return (
    <div className='bg-white  '>
      <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">

        <div className='space-y-5'>
          <h2 className=" text-3xl font-bold text-gray-700 ">{liveVote.vote.TitleEl}</h2>
          <div className='flex'>
            <div className='text-lg font-normal text-gray-700' dangerouslySetInnerHTML={{ __html: '<div>' + liveVote.vote.BodyEl.replaceAll(`/images/`, process.env.REACT_APP_BUCKET_URL + `/images/`) }}></div>
            {/* <div className='relative flex'>
              <img
                className='object-fit w-64 h-20'
                src={process.env.REACT_APP_BUCKET_URL + "/Content/css/assets/brush-stroke.png"}
                alt=""
              />
              <div className='absolute top-2 left-20 drop-shadow-sm font-semibold text-white'>ημέρες που λήγει:</div>
              <div className='absolute top-8 left-28 text-lg drop-shadow-sm font-semibold text-white'>{Math.floor((Date.parse(liveVote.vote.EndDate) - Date.now()) / 86400000)}</div>
            </div> */}
          </div>
          <div className="grid grid-cols-2 gap-3 md:flex justify-center md:space-x-5 p-3 md:p-8">
                        <div
                            className={`${filter === 0 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white  cursor-pointer  px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(0) }}>'Ολα</div>
                        <div
                            className={`${filter === 4 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer  px-16 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(4) }}>Νηπιαγωγεία</div>
                        <div
                            className={`${filter === 1 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer   px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(1) }}>Δημοτικά</div>
                        <div
                            className={`${filter === 2 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer   px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(2) }}>Γυμνάσια</div>
                        <div
                            className={`${filter === 3 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer  px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(3) }}>Λύκεια</div>


                    </div>
        </div>





        <div className="flex justify-center w-full h-full rounded-t-3xl ">


         {
          liveVote.voteOptions < 1? <div className='flex justify-center h-96 items-center flex-col'>
          <motion.div
            initial={{ rotate: 500, scale: 0, opacity: 0 }}
            exit={{ rotate: 1, scale: 1, opacity: 0 }}

            animate={{ rotate: 0, scale: 1, opacity: 1 }}
            transition={{
              damping: 20,
              duration: 0.35
            }}
            className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

            <div><RiEmotionUnhappyFill className='w-16 h-16 text-app_green_light'></RiEmotionUnhappyFill></div>
          </motion.div>
          <div className='font-bold text-xl text-app_green mt-5'>Δεν υπάρχουν ψηφοφορίες σε αυτήν την κατηγορία.</div>
        </div>
          : <>
          
          {
            (votingDone) ? <div className='flex justify-center h-96 items-center flex-col'>
              <motion.div
                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                transition={{
                  damping: 20,
                  duration: 0.35
                }}
                className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
              </motion.div>
              <div className='font-bold text-xl text-app_green mt-5'>Ευχαριστούμε για την συμμετοχή σου.</div>
            </div> :

              <div className='flex justify-center w-full flex-col items-center'>
                {
                  timer ?
                    <VoteOption canVoteMore={(submitions < 3 && liveVote.votelogs.length < 3) ? true : false} question={liveVote.voteOptions[currentQuestion]} submitAnswer={submitVote} expireDate={liveVote.vote.EndDate}></VoteOption>
                    : <div className='w-full h-[360px]'></div>
                }


                <div className="w-full">
                  <Slider {...sliderSettings} className="mt-5 mb-10 mx-5 md:mx-0">

                    {liveVote.voteOptions
                    // .sort((a, b) => a.VoteOptionId ? -1 : 1)
                    .map((voteOption, index) => {


                      return <div className="relative rounded-md p-3  cursor-pointer" key={voteOption.VoteOptionId}

                      >
                        <div onClick={() => {
                          setCurrentQuestion(index)
                        }}>

                          {voteOption.VoteOptionImage.includes('youtube') ? <div className="flex justify-center !h-32 ">
                            <LazyLoadImage
                              src={getThumb(voteOption.VoteOptionImage)}
                              alt=''
                              // loading="lazy"
                              // onDrag={(e)=>{e.preventDefault()}}
                              draggable={false}
                              className="flex !object-cover w-full   rounded-2xl shadow-md"
                            />
                          </div>

                            : <div className="flex justify-center !h-32 ">
                              <LazyLoadImage
                                src={(voteOption.VoteOptionImage.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + voteOption.VoteOptionImage}
                                alt=''
                                // loading="lazy"
                                // onDrag={(e)=>{e.preventDefault()}}
                                draggable={false}
                                className="flex !object-cover w-full rounded-2xl shadow-md"
                              />
                            </div>
                          }

                        </div>
                      </div>
                    }
                      // }

                    )}




                  </Slider>
                </div>


                {/* <div className="w-full">

                  <Carousel
                    className="pb-10 pt-5"
                    // swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    swipeable={true}


                    // customLeftArrow={}
                    // customRightArrow={<CustomRightArrow />}


                    // renderButtonGroupOutside={true}
                    // arrows={false}
                    // centerMode={true}
                    // ssr={true} // means to render carousel on server-side.
                    // infinite={true}
                    focusOnSelect={true}
                    // autoPlay={false}
                    autoPlay={timer ? true : false}
                    additionalTransfrom={0}


                  // autoPlaySpeed={1000}
                  // keyBoardControl={true}
                  // customTransition="all .5"
                  // transitionDuration={500}

                  // containerClass="carousel-container"
                  // removeArrowOnDeviceType={["tablet", "mobile"]}
                  // deviceType={this.props.deviceType}
                  // dotListClass="custom-dot-list-style pt-10 !mt-10"
                  // itemClass="carousel-item-padding-40-px"
                  >
                    {liveVote.voteOptions.sort((a, b) => a.VoteOptionId ? -1 : 1).map((voteOption, index) => {


                      return <div className="relative rounded-md p-3  cursor-pointer"

                      >
                        <div onClick={() => {
                          setCurrentQuestion(index)
                        }}>

                          {voteOption.VoteOptionImage.includes('youtube') ? <LazyLoadImage
                            src={getThumb(voteOption.VoteOptionImage)}
                            alt=''
                            // loading="lazy"
                            // onDrag={(e)=>{e.preventDefault()}}
                            draggable={false}
                            className="flex object-cover h-44 w-full   rounded-2xl"
                          />

                            : <LazyLoadImage
                              src={(voteOption.VoteOptionImage.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + voteOption.VoteOptionImage}
                              alt=''
                              // loading="lazy"
                              // onDrag={(e)=>{e.preventDefault()}}
                              draggable={false}
                              className="flex object-cover h-44 w-full   rounded-2xl"
                            />
                          }

                        </div>
                      </div>
                    }
                      // }

                    )}
                  </Carousel>




                </div> */}






              </div>
          }
          </>
          

         }


        </div>





        <div className='flex flex-col w-full justify-center items-center'>
          <div 
          
          onClick={()=>{
            
            if (!isAuthenticated) {
              setModal(
                  <div className='flex flex-col items-center justify-center py-5'>
                      <motion.div
                          initial={{ rotate: 500, scale: 0, opacity: 0 }}
                          exit={{ rotate: 1, scale: 1, opacity: 0 }}

                          animate={{ rotate: 0, scale: 1, opacity: 1 }}
                          transition={{
                              damping: 20,
                              duration: 0.35
                          }}
                          className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                          <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                      </motion.div>
                      <div className='font-bold text-lg text-center pt-5'>Παρακαλούμε συνδεθείτε ώστε να δείτε την κατάταξη</div>
                      <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                          onClick={() => { unSetModal() }}
                      >OK</div>
                  </div>
              )
          }
          else
            navigate('./winners')
          }}
          
          className='font-bold cursor-pointer text-xl px-8 mt-5 flex justify-center items-center  bg-app_dark_green p-3 shadow-lg rounded-3xl text-white '>Κατάταξη <FaTrophy className="ml-3 text-2xl" /></div>
          
          
        </div>

      </div>



    </div>
  )
}

export default SingleVote