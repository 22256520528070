import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";


const ModalContext = React.createContext();

const cardVariants = {
  initial: {
    y: -300,
  },
  exit: {
    y: 300,
  },
  animate: {
    y: 0,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.6,
      duration: 1,
    },
  },
};

const Modal = ({ modal, unSetModal, width = 500}) => {
  
  useEffect(() => {
   
    const bind = (e) => {
      if (e.keyCode !== 27) {
        return;
      }

      if (
        document.activeElement &&
        ["INPUT", "SELECT"].includes(document.activeElement.tagName)
      ) {
        return;
      }

      unSetModal();
    };

    document.addEventListener("keyup", bind);
    return () => document.removeEventListener("keyup", bind);
  }, [modal, unSetModal]);
  
  return (
    <div
    className="modal z-[100]"
    >
      <button className="modal__close " onClick={unSetModal} />
      <motion.div
       variants={cardVariants}
       initial="initial"
       animate="animate"
       exit="exit"
       viewport={{ once: true, amount: 0.8 }}
       
      className={`modal__inner  !relative !rounded-3xl !mx-2 !md:mx-10 !px-3 !py-6  max-w-[${width}px] shadow-xl`}>
        {/* <button className="!absolute w-11 h-11 !-top-2 !-right-2 bg-white shadow-md rounded-full modal__close-btn font-semibold text-gray-700 text-xl" onClick={unSetModal}>
          X
        </button> */}
        
        {modal}
      </motion.div>
    </div>
  );
};

const ModalProvider = (props) => {
  const [modal, setModal] = useState();
  const [width, setWidth] = useState(400);
  const unSetModal = useCallback(() => {
    setModal();
    setWidth(400)
  }, [setModal]);

  return (
    <ModalContext.Provider value={{ unSetModal, setModal, setWidth }} {...props}>
      {props.children}
      {modal && <Modal modal={modal} unSetModal={unSetModal} width={width}/>}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a UserProvider");
  }

  return context;
};

export { ModalProvider, useModal};
