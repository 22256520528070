import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axios from "../axios.js";

import { authUser, getUpdatedUserValues, setToken } from "../api";
import Loading from "../components/Loading";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("u-auth-token", accessToken);
    setToken(accessToken);
  } else {
    localStorage.removeItem("u-auth-token");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  // addPoints: () => {},
  logout: () => {},
  updateUserValues: () => {},
  // register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
  //console.log("LOGINNN");
    const response = await authUser({
      email,
      password,
    }).catch((e) => {
      // console.log(e)
      throw e;
    });

    
    if(response.data.status === 'schooluser') return response.data;

    if(response.data.status === 'publicorganizationuser') return response.data;

    const { token } = response.data;
    setSession(token);

    const payload = jwtDecode(token);
  //console.log("PAYLOAD IS:", payload);
    const user = {
      id: payload.user.id,
      citizenId: payload.user.citizenId,
      signature: payload.user.signature,
      name: payload.user.name,
      lastname: payload.user.lastName,
      username: payload.user.name,
      email: payload.user.email,
      avatar: payload.user.avatar,
      points: payload.user.points,
      municipalityId: payload.user.municipalityId,
      localityEn: payload.user.localityEn,

      Deh: payload.user.Deh,
      BrownBin: payload.user.BrownBin,
      ValidDeh: payload.user.ValidDeh,
      PlasticBagNumber: payload.user.PlasticBagNumber,          
      BinNumber: payload.user.BinNumber,
      RecyclableMaterials: payload.user.RecyclableMaterials, 
      ChangeBinNumberCount: payload.user.ChangeBinNumberCount,
      ChangeDehNumberCount: payload.user.ChangeDehNumberCount,


      IsActive: payload.user.IsActive,
      AgreedGDPR: payload.user.AgreedGDPR,
      Agreed: payload.user.Agreed,
      AuthyId: payload.user.AuthyId,
      AuthyVerified: payload.user.AuthyVerified,
      OTPTime: payload.user.OTPTime,
      OTPCount: payload.user.OTPCount,
      AreaId: payload.user.AreaId,
      EmailSentDate: payload.user.EmailSentDate,
      IsUnSubscribed: payload.user.IsUnSubscribed,   
    };

  //console.log("USER IS:", user);

    dispatch({
      type: "LOGIN",
      payload: {
          isAuthenticated: true,
        user,
      },
    });

    return user;
  };


  // const addPoints = async () => {
  //   try {
  //       const accessToken = window.localStorage.getItem("u-auth-token");

  //       if (accessToken && isValidToken(accessToken)) {
  //         setSession(accessToken);

  //         const payload = jwtDecode(accessToken);

  //         const points = window.localStorage.getItem("u-points-token")

  //         const user = {
  //           id: payload.user.id,
  //           citizenId: payload.user.citizenId,
  //           signature: payload.user.signature,
  //           name: payload.user.name,
  //           lastname: payload.user.lastName,
  //           username: payload.user.name,
  //           email: payload.user.email,
  //           avatar: payload.user.avatar,
  //           points: points ? points :payload.user.points,
  //           municipalityId: payload.user.municipalityId,
  //           localityEn: payload.user.localityEn,
  //           //avatar: '/assets/images/Logo_peristeri.jpg',
  //           // avatar: '',
  //           //age: 25,
  //         };
  //         // const response = await axios.get('/api/auth/profile')
  //         // const { user } = response.data
  //       //console.log("DISPAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
  //         dispatch({
  //           type: "INIT",
  //           payload: {
  //             isAuthenticated: true,
  //             user,
  //           },
  //         });
          
  //       } else {
  //         dispatch({
  //           type: "INIT",
  //           payload: {
  //             isAuthenticated: false,
  //             user: null,
  //           },
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       dispatch({
  //         type: "INIT",
  //         payload: {
  //           isAuthenticated: false,
  //           user: null,
  //         },
  //       });
  //     }
  // }


  const updateUserValues = async () => {
 

        // console.log(state.user.citizenId);
        
        const accessToken = window.localStorage.getItem("u-auth-token");
 

        //get new citizen object
        const _user = await getUpdatedUserValues({
          currentUserState: {token:accessToken}
        }).catch((e) => {
          throw e;
        });

        const { token } = _user;
        setSession(token);
    
        const payload = jwtDecode(token);
      //console.log("PAYLOAD IS:", payload);
        const user = {
          id: payload.user.id,
          citizenId: payload.user.citizenId,
          signature: payload.user.signature,
          name: payload.user.name,
          lastname: payload.user.lastName,
          username: payload.user.name,
          email: payload.user.email,
          avatar: payload.user.avatar,
          points: payload.user.points,
          municipalityId: payload.user.municipalityId,
          localityEn: payload.user.localityEn,

          Deh: payload.user.Deh,
          BrownBin: payload.user.BrownBin,
          ValidDeh: payload.user.ValidDeh,
          PlasticBagNumber: payload.user.PlasticBagNumber,          
          BinNumber: payload.user.BinNumber,
          RecyclableMaterials: payload.user.RecyclableMaterials, 
          ChangeBinNumberCount: payload.user.ChangeBinNumberCount,
          ChangeDehNumberCount: payload.user.ChangeDehNumberCount,

          IsActive: payload.user.IsActive,
          AgreedGDPR: payload.user.AgreedGDPR,
          Agreed: payload.user.Agreed,
          AuthyId: payload.user.AuthyId,
          AuthyVerified: payload.user.AuthyVerified,
          OTPTime: payload.user.OTPTime,
          OTPCount: payload.user.OTPCount,
          AreaId: payload.user.AreaId,
          EmailSentDate: payload.user.EmailSentDate,
          IsUnSubscribed: payload.user.IsUnSubscribed,   
        };
    
      //console.log("USER IS:", user);
    
        dispatch({
          type: "LOGIN",
          payload: {
              isAuthenticated: true,
            user,
          },
        });
    
        return user;
      };


        
       
       
      
  
  
   
  

  const register = async (email, username, password) => {
    // const response = await axios.post('/api/auth/register', {
    //     email,
    //     username,
    //     password,
    // })
    // const { accessToken, user } = response.data
    // setSession(accessToken)
    // dispatch({
    //     type: 'REGISTER',
    //     payload: {
    //         user,
    //     },
    // })
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("u-auth-token");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const payload = jwtDecode(accessToken);

          // const points = window.localStorage.getItem("u-points-token")

          const user = {
            id: payload.user.id,
            citizenId: payload.user.citizenId,
            signature: payload.user.signature,
            name: payload.user.name,
            lastname: payload.user.lastName,
            username: payload.user.name,
            email: payload.user.email,
            avatar: payload.user.avatar,
            points: payload.user.points,
            municipalityId: payload.user.municipalityId,
            localityEn: payload.user.localityEn,

            Deh: payload.user.Deh,
            BrownBin: payload.user.BrownBin,
            ValidDeh: payload.user.ValidDeh,
            PlasticBagNumber: payload.user.PlasticBagNumber,          
            BinNumber: payload.user.BinNumber,
            RecyclableMaterials: payload.user.RecyclableMaterials, 
            ChangeBinNumberCount: payload.user.ChangeBinNumberCount,
            ChangeDehNumberCount: payload.user.ChangeDehNumberCount,

            
            IsActive: payload.user.IsActive,
            AgreedGDPR: payload.user.AgreedGDPR,
            Agreed: payload.user.Agreed,
            AuthyId: payload.user.AuthyId,
            AuthyVerified: payload.user.AuthyVerified,
            OTPTime: payload.user.OTPTime,
            OTPCount: payload.user.OTPCount,
            AreaId: payload.user.AreaId,
            EmailSentDate: payload.user.EmailSentDate,
            IsUnSubscribed: payload.user.IsUnSubscribed,   
            //avatar: '/assets/images/Logo_peristeri.jpg',
            // avatar: '',
            //age: 25,
          };
          // const response = await axios.get('/api/auth/profile')
          // const { user } = response.data
        //console.log("DISPAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
          
        } else {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        register,
        login,
        logout,
        // addPoints,
        updateUserValues
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
