import React, { useState } from 'react'
import { generateRandomId } from '../utils/utils'
import { motion } from 'framer-motion';
import { Check, CheckCircle, Close, FactCheck, Remove, RemoveDone } from '@mui/icons-material';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';

const QuizQuestion = ({ question, submitAnswer }) => {

    const [userAnswer, setUserAnswer] = useState(0)
    const [submitted, setSubmitted] = useState(false)

    const [correctAnswerText, setCorrectAnswerText] = useState('')

    const correctAnswer = question.AnswerCode;

    // console.log(question)
    return (
        <div className='relative flex  flex-col bg-gray-800 h-full bg-opacity-40  w-full p-5 sm:p-8 justify-between'>
            <div >
                {
                    !submitted ? <></> :
                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2   w-4/5 h-3/5 md:w-3/5 md:h-3/5'>
                            <motion.div
                                initial={{ rotate: 0, y: -500, opacity: 0 }}
                                animate={{ rotate: 0, y: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    //  stiffness: 30,
                                    damping: 10,
                                    duration: 0.8
                                }}
                                key={generateRandomId()}
                                className='flex z-10 flex-col w-full h-full justify-evenly items-center bg-white  shadow-xl  rounded-3xl'>
                                <div>
                                    {
                                        (userAnswer === parseInt(question.AnswerCode)) ?
                                            <div className='flex '>
                                                <div className='text-lg font-semibold'>
                                                    Σωστό
                                                </div>
                                                <div className='pl-2'>
                                                    <Check className='text-app_green'></Check>
                                                </div>
                                            </div>
                                            :
                                            <div className='flex '>
                                                <div className='text-lg font-semibold'>
                                                    Λάθος
                                                </div>
                                                <div className='pl-2'>
                                                    <Close className='text-app_red'></Close>
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className='px-5 flex'>
                                <div className={`border-app_red bg-app_green flex mt-[3px] mr-1 justify-center items-center h-6 w-6 object-cover rounded-full`}>
                                    { <Check className='text-white p-0.5'></Check>}
                                </div>
                                   <div>
                                   {correctAnswerText}
                                   </div>
                                    </div>
                                <div
                                    className='h-10 w-28 flex justify-center items-center hover:bg-red-400 bg-app_red opacity-80 rounded-xl shadow-lg cursor-pointer text-center text-white font-semibold text-lg'
                                    onClick={() => {
                                        if (userAnswer === parseInt(question.AnswerCode)) {
                                            //is correct
                                            submitAnswer(true)
                                            setCorrectAnswerText('')
                                            setUserAnswer(0)
                                            setSubmitted(false)
                                        }
                                        else {
                                            //is wrong
                                            submitAnswer(false)
                                            setCorrectAnswerText('')
                                            setUserAnswer(0)
                                            setSubmitted(false)
                                        }

                                    }}
                                >Επόμενη</div>
                            </motion.div>
                        </div>
                }
                <motion.div
                    initial={{ rotate: 0, x: 600, scale: 0, opacity: 0 }}
                    animate={{ rotate: 0, x: 0, scale: 1, opacity: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 126,
                        // damping: 100,
                        duration: 1
                    }}
                    key={question.QuizQuestionId}

                    className='font-bold text-sm sm:text-md md:text-xl  text-white'>{question.QuestionEl}</motion.div>
                <div className='flex flex-col mt-8'>

                    {question.answers.map((answer, index) => {
                        return <motion.div
                            initial={{ rotate: 0, x: 600, scale: 0, opacity: 0 }}
                            animate={{ rotate: 0, x: 0, scale: 1, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 126,
                                // damping: 100,
                                duration: 1
                            }}
                            key={answer.QuizQuestionAnswerId}

                            className='flex py-3 sm:py-3 md:py-4'>
                            <div
                                className='flex w-7 h-6  mt-[3px] mr-2'
                                onClick={() => {
                                    if (submitted) return;
                                    const selectedAnswer = parseInt(answer.AnswerCode);
                                    if (userAnswer === answer.AnswerCode)
                                        setUserAnswer(0);
                                    else
                                        setUserAnswer(selectedAnswer);
                                }}
                            >
                                <div className={`${(userAnswer !== parseInt(answer.AnswerCode)) ? 'border-gray-300' : 'border-app_red bg-app_red'} flex justify-center items-center h-6 w-6 object-cover border-[2px]  hover:border-app_red  rounded-full cursor-pointer`}>
                                    {(userAnswer === parseInt(answer.AnswerCode)) ? <Check className='text-gray-300 p-0.5'></Check> : null}
                                </div>
                            </div>
                            <div

                                className={` text-base sm:text-md md:text-lg text-white font-semibold overflow-hidden`}>
                                {answer.DescriptionEl}
                            </div>

                        </motion.div>
                    })}
                </div>

            </div>
            {submitted ? null :
                <button
                    disabled={(userAnswer === 0)}
                    
                    className='h-10 w-28 flex justify-center disabled:cursor-not-allowed items-center hover:bg-red-400 bg-app_red opacity-80 rounded-xl shadow-lg cursor-pointer text-center text-white font-semibold text-lg'
                    onClick={() => {
 
                       if(userAnswer !== 0)
                       {
                        setSubmitted(!submitted)
                        for (let index = 0; index < question.answers.length; index++) {
                            const _answer = question.answers[index];
                            if (_answer.AnswerCode === question.AnswerCode)
                                setCorrectAnswerText(_answer.DescriptionEl)
                        }
                       }


                    }}
                >Επόμενη</button>
            }
        </div>
    )
}

export default QuizQuestion