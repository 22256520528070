import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { ArrowBackIos, ArrowRight, ArrowRightAlt, ArrowRightAltTwoTone, Visibility, VisibilityOff } from '@mui/icons-material';
// import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, CircularProgress, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
// import { Paragraph } from 'app/components/Typography';
import useAuth from '../hooks/useAuth';
import { Formik } from 'formik';
import { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '../components/Loading';
import { LoadingButton } from '@mui/lab';
import { useQuery } from 'react-query';
import { getLiveMunicipalities, registerUser } from '../api';
import useMunicipality from '../hooks/useMunicipality';
import { motion } from 'framer-motion';
import { useModal } from '../contexts/ModalContext';
import { RiCheckLine } from 'react-icons/ri';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));



// inital login credentials
const initialValues = {
  email: '',
  password: '',
  name: '',
  lastName: '',
  remember: true,
  agreeToGtpr: 0,
  //for Vula Vari Vuliagmenh
  deh: null,
  binNumber: null,
  plasticBagNumber: null,
  brownBin: null,
  recyclableMaterials: null

};



// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον 6 χαρακτήρες')
    .required('Παρακαλώ εισάγετε τον κωδικό πρόσβασης'),
  email: Yup.string().email('Η διεύθυνση email δεν είναι έγκυρη').required('Παρακαλώ εισάγετε διεύθυνση email'),
  name: Yup.string().required('Υποχρεωτικό πεδίο'),
  lastName: Yup.string().required('Υποχρεωτικό πεδίο'),
  deh: Yup.string().nullable()
    .min(8, "Εισάγετε ΜΟΝΟ τα 8 ψηφία, που βρίσκονται μετά τον πρώτο αριθμό έως και την παύλα, όπως παρακάτω: 23456789")
    .max(8, "Εισάγετε ΜΟΝΟ τα 8 ψηφία, που βρίσκονται μετά τον πρώτο αριθμό έως και την παύλα, όπως παρακάτω: 23456789")
});

const Register = ({ isLandingPage = false }) => {
  const breadcrumbs = useBreadcrumbs();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const [showpass, setShowpass] = useState(false);

  const { setModal, unSetModal } = useModal()

  const [separationAtSource, setSeparationAtSource] = useState(false);
  const [agree, setAgree] = useState(0);

  const { municipality, init, isInitialised } = useMunicipality();

  const { isLoading, isError, error, data } = useQuery('live_municipalities', () => getLiveMunicipalities(), {
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  })

  const { login } = useAuth();

  const location = useLocation()

  const [selectedMunicipality, setSelectedMunicipality] = useState();

  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const registerData =
      {

        //agree to gtpr
        AgreedToGtprByUser: values.agreeToGtpr,
        Email: values.email,
        Password: values.password,
        MunicipalityId: selectedMunicipality,
        FirstName: values.name,
        LastName: values.lastName,
        isFromApp: 0,
        //for Vula Vari Vuliagmenh
        Deh: values.deh,
        BinNumber: values.binNumber,
        PlasticBagNumber: values.plasticBagNumber,
        BrownBin: values.brownBin,
        RecyclableMaterials: values.recyclableMaterials,
      }
      //console.log(registerData)
      const registerResponse = await registerUser(registerData);
      //console.log(registerResponse)
      if (registerResponse.data.status === 1) {
        setIsWrong(true)
        setLoading(false);
      }
      else {
        setIsWrong(false)
        setLoading(false);
        setModal(
          <div className='flex flex-col items-center justify-center py-5'>
            <motion.div
              initial={{ rotate: 500, scale: 0, opacity: 0 }}
              exit={{ rotate: 1, scale: 1, opacity: 0 }}

              animate={{ rotate: 0, scale: 1, opacity: 1 }}
              transition={{
                damping: 20,
                duration: 0.35
              }}
              className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

              <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
            </motion.div>
            <div className='font-bold text-lg text-center pt-5'>Για να ολοκληρωθεί η διαδικασία εγγραφής παρακαλούμε πατήστε επιβεβαίωση στο email που σας έχει σταλεί στη διεύθυνση {values.email}</div>
            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
              onClick={() => {
                // resetForm();
                unSetModal();
              }}
            >OK</div>
          </div>
        )
      }

      // navigate('/');
    } catch (e) {
      //console.log("ERROR CREDENTIALS")
      setIsWrong(true)
      setLoading(false);
    }
  };
  if (isLoading) <>LOADING</>
  if (isError) <>ERROR</>
  if (!data) return <CircularProgress></CircularProgress>
  return (



    <motion.div

      initial={
        { x: -300, }
      }
      animate={
        {
          x: 0,
          transition: {
            type: "spring",
            bounce: 0.6,
            duration: 1
          }
        }
      }
      exit={
        { x: -300, }
      }
      className={`${!isLandingPage ? 'my-[5%] px-5 lg:px-[28%]' : ''} relative bg-white flex justify-center`} >
      {/* <svg className='absolute' viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path fill="#8FD6D2" d="M48.2,-38.6C55,-29.8,48,-10.2,40.5,3.7C32.9,17.7,24.7,26,15.6,29C6.6,32,-3.3,29.7,-19.5,27.1C-35.6,24.4,-58,21.4,-61.9,12C-65.8,2.6,-51.1,-13.3,-37.7,-23.5C-24.3,-33.7,-12.1,-38.4,4.3,-41.8C20.7,-45.2,41.3,-47.3,48.2,-38.6Z" transform="translate(100 100)" />
            </svg> */}

      {/* <div className="absolute top-0 -left-4 w-36 h-36 bg-app_red rounded-full  opacity-10  blur-md"></div>
            <div className="absolute top-0 -right-4 w-72 h-72 bg-app_green_light rounded-full  opacity-10 blur-md"></div>
            <div className="absolute -bottom-8 left-20 w-72 h-72 bg-app_yellow rounded-full  opacity-10 blur-md"></div> */}

      {/* <div className="flex">{breadcrumbs.map(({ breadcrumb }) => {
            return <div className="p-1 flex ">{breadcrumb}/</div>
         })}</div> */}
      <div className="z-10 relative !shadow-2xl  rounded-3xl  bg-white overflow-hidden flex flex-col justify-center items-center">

        <div className='pt-10 font-semibold text-xl text-gray-400'>Εγγραφή</div>
        <div className="bg-gradient-to-r w-72 mt-1 h-2 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>



        {isWrong ? <div className='pt-5 text-red-400'>Σφάλμα Αυτή η διεύθυνση ηλεκτρονικού ταχυδρομείου χρησιμοποιείται ήδη</div> : null}

        <div >
          <div className='bg-white p-10 px-16 h-auto w-auto'>
            <Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            // enableReinitialize={false}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, validateField }) => (
                <form onSubmit={handleSubmit}>

                  <FormControl
                    // helperText={touched.password && errors.password}
                    // error={Boolean(errors.password && touched.password)}
                    className='!outline-0 !border-0 w-full'>
                    <Select

                      className="flex w-[100%]  h-10 mb-5"
                      defaultValue={0}
                      onChange={
                        (e) => {

                          const val = e.target.value;
                          // var result = data.rows.filter(obj => {
                          //   return obj.MunicipalityId === parseInt(val)
                          // })

                          setSelectedMunicipality(val)
                        }
                      }

                    >
                      <MenuItem key={0} value={0}>
                        {"Επίλεξε τον Δήμο σου"}
                      </MenuItem>
                      {
                        data.rows.map((element, index) => {
                          if (element.Registered === 1 && element.LocalityEn !=='new_comming_soon')
                            return (<MenuItem key={index} value={element.MunicipalityId}>
                              {element.NameEl}
                            </MenuItem>)
                        })

                      }



                    </Select>
                  </FormControl>

                  <TextField
                    className='group !group:border-red-300'
                    fullWidth
                    size="small"
                    type="name"
                    name="name"
                    label="Όνομα"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={handleChange}
                    helperText={touched.name && errors.name}
                    error={Boolean(errors.name && touched.name)}
                    sx={{
                      mb: 3,
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //     borderColor: "#F19853"
                      // }
                    }}
                  />
                  <TextField
                    className='group !group:border-red-300'
                    fullWidth
                    size="small"
                    type="lastName"
                    name="lastName"
                    label="Επώνυμο"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.lastName}
                    onChange={handleChange}
                    helperText={touched.lastName && errors.lastName}
                    error={Boolean(errors.lastName && touched.lastName)}
                    sx={{
                      mb: 3,
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //     borderColor: "#F19853"
                      // }
                    }}
                  />
                  <TextField
                    className='group !group:border-red-300'
                    fullWidth
                    size="small"
                    type="email"
                    name="email"
                    label="Διεύθυνση email"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                    helperText={touched.email && errors.email}
                    error={Boolean(errors.email && touched.email)}
                    sx={{
                      mb: 3,
                      // "& .MuiOutlinedInput-notchedOutline": {
                      //     borderColor: "#F19853"
                      // }
                    }}
                  />

                  <TextField
                    fullWidth
                    size="small"
                    pas
                    name="password"
                    label="Κωδικός πρόσβασης"
                    variant="outlined"
                    type={showpass ? "text" : "password"}
                    onBlur={handleBlur}
                    value={values.password}
                    onChange={handleChange}
                    helperText={touched.password && errors.password}
                    error={Boolean(errors.password && touched.password)}
                    sx={{ mb: 3 }}
                    InputProps={
                      {
                        endAdornment: (
                          <InputAdornment position="end"> {
                            showpass ? (
                              <Visibility className="cursor_pointer"
                                onClick={
                                  () => {
                                    setShowpass(false)
                                  }
                                }
                              />
                            ) : (
                              <VisibilityOff onClick={

                                () => {
                                  setShowpass(true)
                                }
                              }
                              />
                            )
                          }
                          </InputAdornment>
                        ),
                      }}
                  />






                  {
                    selectedMunicipality !== 380 ? null :
                      <>
                        <Tooltip
                          // followCursor 
                          arrow

                          TransitionProps={{ timeout: 600 }}
                          title={`Από την ένδειξη "Αριθμός Παροχής" που αναγράφεται στον 
λογαριασμό σας: π.χ. 1 23456789-02 5 Εισάγετε ΜΟΝΟ τα 8 ψηφία, που βρίσκονται μετά τον πρώτο αριθμό 
έως και την παύλα, όπως παρακάτω: 23456789`} >
                          <TextField

                            className='group !group:border-red-300'
                            fullWidth
                            size="small"
                            type="number"
                            name="deh"
                            label="Αρ. παροχής ηλ. Ρεύματος π.χ ΔΕΗ"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.deh}
                            onChange={handleChange}
                            helperText={touched.deh && errors.deh}
                            error={Boolean(errors.deh && touched.deh)}
                            sx={{
                              mb: 3,
                              // "& .MuiOutlinedInput-notchedOutline": {
                              //     borderColor: "#F19853"
                              // }
                            }}
                          />
                        </Tooltip>
                        <div className='flex space-x-3 mt-0'>
                          <Checkbox
                            className=' !text-orange-300 '
                            size="small"
                            name="remember"
                            onChange={() => {
                              setSeparationAtSource(!separationAtSource)
                              if (separationAtSource === false) {
                                values.brownBin = null;
                                values.recyclableMaterials = null;
                                values.binNumber = null;
                                values.plasticBagNumber = null;
                              }
                            }}
                            checked={separationAtSource}
                            sx={{ padding: 0, }}
                          />

                          <div>Συμμετέχω σε Πρόγραμμα Διαλογής στην Πηγή</div>

                        </div>


                        {
                          !separationAtSource ? null :
                            <div className=' mt-3 mb-3'>
                              <div className='flex flex-col'>
                                <div className='flex space-x-3'>
                                  <Checkbox
                                    className=' !text-orange-300 '
                                    size="small"
                                    name="remember"
                                    onChange={() => {

                                      if (values.brownBin === 1) {
                                        values.brownBin = null;
                                        values.binNumber = null;
                                      }
                                      else {
                                        values.brownBin = 1;
                                      }
                                      console.log(values.brownBin);
                                      validateField()

                                    }}
                                    checked={values.brownBin === 1 ? true : false}

                                    sx={{ padding: 0, }}
                                  />

                                  <div>Καφέ κάδος (ανακύκλωση υπολειμμάτων τροφών)</div>

                                </div>
                                {!values.brownBin ? null :
                                  <Tooltip
                                    // followCursor 
                                    arrow

                                    TransitionProps={{ timeout: 600 }}
                                    title={`Ο κωδικός της μεταλλικής ετικέτας π.χ. 0001 αναγράφεται στην 
                              πίσω πλευρά του καφέ κάδου που εξυπηρετεί το κτίριό σου. 
                              Προσοχή! Ο κωδικός ετικέτας δεν ταυτίζεται με τον αριθμό στο 
                              καπάκι του καφέ κάδου κτιρίου.`} >
                                    <TextField
                                      className='group !group:border-red-300 !mt-5 !mr-3'
                                      fullWidth
                                      size="small"
                                      type="number"
                                      name="binNumber"
                                      label="Κωδικός μεταλλικής ετικέτας"
                                      variant="outlined"
                                      onBlur={handleBlur}
                                      value={values.binNumber}
                                      onChange={handleChange}
                                      helperText={touched.binNumber && errors.binNumber}
                                      error={Boolean(errors.binNumber && touched.binNumber)}
                                      sx={{
                                        mb: 0,
                                        // "& .MuiOutlinedInput-notchedOutline": {
                                        //     borderColor: "#F19853"
                                        // }
                                      }}
                                    />
                                  </Tooltip>
                                }

                                <div className='flex space-x-3 mt-3'>
                                  <Checkbox
                                    className=' !text-orange-300 '
                                    size="small"
                                    name="remember"
                                    onChange={() => {

                                      if (values.recyclableMaterials === 1) {
                                        values.recyclableMaterials = null;
                                        values.plasticBagNumber = null;
                                      }
                                      else {
                                        values.recyclableMaterials = 1;
                                      }
                                      console.log(values.recyclableMaterials);
                                      validateField()

                                    }}
                                    checked={values.recyclableMaterials === 1 ? true : false}

                                    sx={{ padding: 0, }}
                                  />

                                  <div>Έξυπνες αυτοκόλλητες ετικέτες (ανακύκλωση Χαρτιού, Pet, PmD με χρήση σακουλών)</div>

                                </div>
                                {!values.recyclableMaterials ? null :
                                  <Tooltip
                                    // followCursor 
                                    arrow

                                    TransitionProps={{ timeout: 600 }}
                                    title={`Ο κωδικός της αυτοκόλλητης ετικέτας π.χ. C0003879312 
                             αναγράφεται στο πακέτο κάθε 5αδας. Μπορείς να καταχωρείς 
                             έστω και έναν κωδικό πακέτου ανά μήνα για να λάβεις την 
                             επιβράβευση. Προσοχή! Μην ξεχνάς να ανανεώνεις τον αριθμό 
                             από ένα πακέτο 5αδας κάθε μήνα.`} >
                                    <TextField
                                      className='group !group:border-red-300 !mt-5 !mr-3'
                                      fullWidth
                                      size="small"
                                      type="number"
                                      name="plasticBagNumber"
                                      label="Κωδικός ετικέτας 5αδας"
                                      variant="outlined"
                                      onBlur={handleBlur}
                                      value={values.plasticBagNumber}
                                      onChange={handleChange}
                                      // helperText={touched.plasticBagNumber && errors.plasticBagNumber}
                                      error={Boolean(errors.plasticBagNumber && touched.plasticBagNumber)}
                                      helperText={`*Η συμπλήρωση ‘’κωδικού ετικέτας’’ είναι απαραίτητη για την
                              επιβράβευσή σου στα προγράμματα Διαλογής στην Πηγή! Αν δεν 
                              θυμάσαι τώρα τον κωδικό σου μπορείς έπειτα να τον 
                              καταχωρήσεις στην Κατηγορία ‘’Ανακύκλωσε’’ ολοκληρώνοντας 
                              την εγγραφή σου.`}
                                      sx={{
                                        mb: 0,
                                        // "& .MuiOutlinedInput-notchedOutline": {
                                        //     borderColor: "#F19853"
                                        // }
                                      }}
                                    />
                                  </Tooltip>
                                }
                              </div>


                            </div>
                        }


                      </>
                  }

                  <div className='flex space-x-3 mt-0'>
                    <Checkbox
                      className=' !text-orange-300 '
                      size="small"
                      name="agree"
                      onChange={() => {

                        if (agree === 0) {
                          setAgree(1);
                          values.agreeToGtpr = 1;
                        }
                        else {
                          setAgree(0);
                          values.agreeToGtpr = 0;
                        }

                        
                      }}
                      checked={agree === 1 ? true : false}
                      sx={{ padding: 0, }}
                    />

                    {/* <div>Συμφωνώ να λαμβάνω ενημερωτικά email από το Followgreen</div> */}
                    <Link to={'4all/home/policy'}  target="_blank" rel="noreferrer" className="">Συμφωνώ να λαμβάνω ενημερωτικά email από το Followgreen</Link>

                  </div>

                  <LoadingButton
                    className='!bg-gradient-to-r disabled:text-white disabled:cursor-not-allowed from-app_yellow to-app_brown !shadow-orange-300 !shadow-xl !drop-shadow-none'
                    type="submit"
                    disabled={selectedMunicipality > 0 ? false : true}
                    loading={loading}
                    variant="contained"
                    sx={{ my: 2 }}
                  >
                    <div className='capitalize pr-2'>Εγγραφή</div>
                    <ArrowRightAlt></ArrowRightAlt>
                  </LoadingButton>




                </form>

              )}
            </Formik>

            {!isLandingPage ? null :
              <FormControl className='!outline-0 !border-0 w-full !mt-5'>
                {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                   Επιλογή Δήμου
               </InputLabel> */}
                <Select

                  className="flex w-[100%]  h-10 mb-5"
                  // variant='outlined'

                  defaultValue={999}
                  onChange={
                    (e) => {

                      //For dev only


                      const val = e.target.value;

                      var result = data.rows.filter(obj => {
                        return obj.MunicipalityId === parseInt(val)
                      })

                      const newMunicipality = {
                        NameEl: result[0].NameEl,
                        MunicipalityId: result[0].MunicipalityId,
                        CenterLatitude: result[0].CenterLatitude,
                        CenterLongitude: result[0].CenterLongitude,
                        LocalityEn: result[0].LocalityEn,
                        IsGreenSchool: result[0].IsGreenSchool,
                        MobileEnabled: result[0].MobileEnabled,

                      }
                      //console.log(newMunicipality)
                      let splittedPath = location.pathname.split('/')
                      let newPath = '';
                      for (let index = 0; index < splittedPath.length; index++) {
                        const element = splittedPath[index].replace('/', '');

                        if (index === 1)
                          newPath += (result[0].LocalityEn);
                        else if (index === (splittedPath.length < 1))
                          newPath += (element);
                        else
                          newPath += (element);

                        if (newPath[newPath.length - 1] !== '/')
                          newPath += '/'
                      }

                      if (newPath[newPath.length - 1] === '/')
                        newPath = newPath.substring(0, newPath.length - 1)

                      navigate(newPath, { replace: true })
                      init(newMunicipality);
                    }
                  }

                >
                  <MenuItem key={999} value={999}>
                    {"Ή περιηγήσου ως επισκέπτης"}
                  </MenuItem>
                  {

                    data.rows.map((element, index) => {
                      if (element.Registered === 1)
                        return (<MenuItem key={index} value={element.MunicipalityId}>
                          {element.NameEl}
                        </MenuItem>)
                    })

                  }


                </Select>
              </FormControl>
            }


          </div>
        </div>

      </div>
    </motion.div >



  )
}

export default Register