import { ConstructionSharp } from "@mui/icons-material"
import { addCitizenTQrransaction, addCitizenTransaction, getPoints } from "../api"


export const addTransaction = async ({citizenId, transactionType, points, earnId} ) =>{

    const transaction = {
        CitizenId: citizenId,
        TransactionDate: Date.now(),
        TransactionPoints: points,
        TransactionTypeId: transactionType,
        EarnId: earnId
    }

    try {
        await addCitizenTransaction(transaction);
        // console.log("THE CITIZEN ID IS: ",citizenId)
        const newBalannce = await  getPoints(citizenId)
        // localStorage.setItem("u-points-token", newBalannce.points);
        // console.log("The NEw BAlance IS::: ",newBalannce.points)
    } catch (error) {
        throw new Error(error.message);
    }
   
   
}


