import { Check, CheckCircleOutlined } from '@mui/icons-material';
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Icon, InputAdornment, NativeSelect, TextField } from '@mui/material';
import React, { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLiveMunicipalities, getOfflineMunicipalities, updateSmartRecycleDetails, updateUserDetails } from '../api';
import { useModal } from '../contexts/ModalContext';
import useAuth from '../hooks/useAuth';
import useMunicipality from '../hooks/useMunicipality';
import { motion } from 'framer-motion';
import { MdElectricalServices } from 'react-icons/md';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';


const AccountDetailsEdit = () => {
  // Καταχώρηση ή Αποθήκευση
  const { login, user, isAuthenticated, updateUserValues } = useAuth();

  const { municipality } = useMunicipality();

  const [newMunicipality, setNewMunicipality] = useState(null);

  const { setModal, unSetModal, setWidth } = useModal()

  const { setModal: setErrorModal, unSetModal: unsetErrorModal } = useModal()

  const navigate = useNavigate();

  const [data, setData] = useState({
    MunicipalityId: user.municipalityId,
    FirstName: user.name,
    LastName: user.lastname,
    Email: user.email,
    // IsUnSubscribed: user.IsUnSubscribed,
    AgreedGDPR: user.AgreedGDPR,
    AreaId: user.AreaId,
    Deh: user.Deh,
  });

  const { isLoading, isError, error, data: municipalities }
    = useQuery('live_municipalities', () => getLiveMunicipalities(), {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    })

  const { isLoading: offlineMunicipalitiesIsLoading, isError: offlineMunicipalitiesIsError, error: offlineMunicipalitiesError, data: offlineMunicipalities }
    = useQuery('offline_municipalities', () => getOfflineMunicipalities(), {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    })


  const handleSubmit = async (event) => {
    //Construct the Data
    //Request a mutation to update the news
    await updateUserDetails({ citizenId: user.citizenId, data: data });
    updateUserValues();

    if (newMunicipality !== null) {
      setTimeout(() => {
        navigate('../../../' + newMunicipality);
        window.location.reload();
      }, 1000);
    }
    else
      setTimeout(() => {
        navigate(-1);

      }, 1000);

  }

  const handleDeiChange = () =>
  {
    var deh = 0;
    // setWidth(330)
    if(user.ChangeDehNumberCount >=2)
    {
      setErrorModal(
        <div className='flex flex-col items-center justify-center py-5'>
            <motion.div
                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                transition={{
                    damping: 20,
                    duration: 0.35
                }}
                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
            </motion.div>
            <div className='font-bold text-lg text-center pt-5'>Έχετε ήδη αλλάξει (2) φορές τον Αρ. παροχής ηλ. Ρεύματος
            </div>
            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                onClick={() => { unsetErrorModal() }}
            >OK</div>
        </div>
    )
    }
    else{
      setModal(
        <div className='flex flex-col items-center justify-center py-5'>
            <motion.div
                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                exit={{ rotate: 1, scale: 1, opacity: 0 }}
                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                transition={{
                    damping: 20,
                    duration: 0.35
                }}
                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                <div><MdElectricalServices className='w-16 h-16 text-app_red'></MdElectricalServices></div>
            </motion.div>

            <div className='font-bold text-lg text-center pt-5'>Καταχώρησε τον νέο Αριθμό Παροχής Ηλεκτρικού Ρεύματος</div>

            <p className=' text-center pt-5'>Από την ένδειξη "Αριθμός Παροχής" που αναγράφεται στον λογαριασμό σας: π.χ. 1 23456789-02 5 </p>
            <p className='text-center text-md italic pt-1'>*Εισάγετε ΜΟΝΟ τα 8 ψηφία, που βρίσκονται μετά τον πρώτο αριθμό έως και την παύλα, όπως παρακάτω: 23456789
            </p>


            <TextField
                className='!mt-5 !border-2 !outline-none !border-dashed !shadow-[inset_0px_6px_12px_rgba(0,0,0,0.3)]'
                label=""
                onChange={(e) => {

                    deh = e.target.value;
                }}
                type="number"
                sx={{ padding: 0, borderRadius: '15px !important', }}

            />
            <div className='flex flex-col md:flex-row'>
                <button className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                    onClick={async () => {
                        const digits_only = string => [...string].every(c => '0123456789'.includes(c));
                        if (deh.length === 8 && digits_only(deh)) {
                            console.log("Dei is: " + deh);

                            var recycleData = {
                                Deh: deh,
                                ValidDeh: user.ValidDeh,
                                BrownBin: user.BrownBin,
                                BinNumber: user.BinNumber,
                                RecyclableMaterials: user.RecyclableMaterials,
                                PlasticBagNumber: user.PlasticBagNumber,
                            };
                            const response = await updateSmartRecycleDetails({ citizenId: user.citizenId, data: recycleData })
                            console.log(response);
                            updateUserValues();
                            

                            unSetModal();

                            setModal(
                                <div className='flex flex-col items-center justify-center py-5'>
                                    <motion.div
                                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                        transition={{
                                            damping: 20,
                                            duration: 0.35
                                        }}
                                        className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                                        <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                                    </motion.div>
                                    <div className='font-bold text-lg text-center pt-5'>Ο αριθμός παροχής καταχωρήθηκε επιτυχώς!</div>
                                    <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                        onClick={() => { unSetModal() }}
                                    >OK</div>
                                </div>
                            )

                            setData({...data,Deh:deh})


                        }
                        else {
                            setErrorModal(
                                <div className='flex flex-col items-center justify-center py-5'>
                                    <motion.div
                                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                        transition={{
                                            damping: 20,
                                            duration: 0.35
                                        }}
                                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                    </motion.div>
                                    <div className='font-bold text-lg text-center pt-5'>Ο Αριθμός Παροχής δεν είναι σωστός
                                    </div>
                                    <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                        onClick={() => { unsetErrorModal() }}
                                    >OK</div>
                                </div>
                            )
                        }
                    }}
                >Καταχώρηση</button>


                <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                    onClick={() => { unSetModal() }}
                >Ακύρωση</div>
            </div>
        </div>
    )
    }
  }


  //console.log(newMunicipality);

  if (isLoading || offlineMunicipalitiesIsLoading) return <CircularProgress></CircularProgress>
  if (isError || offlineMunicipalitiesIsError) return <CircularProgress></CircularProgress>
  if (!municipalities) return <CircularProgress></CircularProgress>

  return (
    <div className='max-w-2xl px-4 min-h-screen mx-auto py-5 lg:py-16 sm:px-6 lg:max-w-7xl lg:px-8'>

      <h2 className="text-3xl font-bold text-gray-700 ">Αλλαγή στοιχείων</h2>
      <h2 className="text-lg font-normal text-gray-700 pb-7">Άλλαξε εδώ τα προσωπικά σου στοιχεία</h2>


      <ValidatorForm onSubmit={handleSubmit} onError={() => null} className='flex flex-col'>


        <div className='mb-2'>Δήμοι Followgreen:</div>
        <FormControl className='max-w-[550px]'>
          <NativeSelect
            className="border p-1 rounded "
            defaultValue={data.MunicipalityId}
            onChange={
              (e) => {
                //console.log(e.target.value);
                const val = e.target.value;

                var result = municipalities.rows.filter(obj => {
                  return obj.MunicipalityId === parseInt(val)
                })

                setNewMunicipality(result[0].LocalityEn)

                setData({
                  ...data,
                  MunicipalityId: e.target.value
                })
              }
            }
          >
            {
              municipalities.rows.map((element, index) => {
                if (element.Registered === 1)
                  return (<option key={index} value={element.MunicipalityId}>
                    {element.NameEl}
                  </option>)
              })
            }
          </NativeSelect>
        </FormControl>

        <div className='mb-2 mt-7'>Δήμοι 4all:</div>
        <FormControl className='max-w-[550px]'>
          <NativeSelect
            className="border p-1 rounded "
            defaultValue={data.AreaId}
            disabled={data.MunicipalityId == 327 ? false : true}
            onChange={
              (e) => {
                //327 = 4all
                if (e.target.value !== 999999)
                  setData({
                    ...data,
                    AreaId: e.target.value
                  })
              }
            }
          >

            <option key={999999} value={999999}>Επιλέξτε Δήμο</option>
            {
              offlineMunicipalities.rows.map((element, index) => {

                return (<option key={index} value={element.MunicipalityId}>
                  {element.NameEl}
                </option>)
              })
            }
          </NativeSelect>
        </FormControl>


        <div className='mb-2 mt-7'>Όνομα:</div>
        <TextValidator
          className='w-full max-w-[550px]'
          //label="NameEl"
          onChange={(event) => {
            event.persist()
            setData(
              { ...data, FirstName: event.target.value, }
            )
          }}
          type="text"
          value={data.FirstName}
          validators={['required']}
          errorMessages={'υποχρεωτικό πεδίο'}
        />


        <div className='mb-2 mt-7'>Επώνυμο:</div>
        <TextValidator
          className='w-full max-w-[550px]'
          //label="NameEl"
          onChange={(event) => {
            event.persist()

            setData(
              { ...data, LastName: event.target.value, }
            )
          }}
          type="text"
          value={data.LastName}
          validators={['required']}
          errorMessages={'υποχρεωτικό πεδίο'}
        />

        <div className='mb-2 mt-7'>Email:</div>
        <TextValidator
          disabled={true}
          className='w-full max-w-[550px]'
          //label="NameEl"
          // onChange={(event) => {
          //   event.persist()

          //   setData(
          //     { ...data, Deh: event.target.value, }
          //   )
          // }}
          type="teemailxt"
          value={data.Email}
        // validators={['required']}
        // errorMessages={'υποχρεωτικό πεδίο'}
        />


        <div className='mb-2 mt-7'>Αρ. παροχής ηλ. Ρεύματος π.χ ΔΕΗ:</div>
        <TextField
          disabled={true}
          className='w-full max-w-[550px]'
          //label="NameEl"
          onChange={(event) => {
            event.persist()

            setData(
              { ...data, Deh: event.target.value, }
            )
          }}
          type="text"
          value={data.Deh}
          InputProps={{
            endAdornment: (
              <div onClick={handleDeiChange} className='text-app_yellow hover:cursor-pointer'>Αλλαγή</div>
            ),
          }}
        // validators={['required']}
        // errorMessages={'υποχρεωτικό πεδίο'}
        />




        <div className='flex justify-start mb-1 mt-10 '>
          <FormControlLabel control={
            <Checkbox
              checked={data.AgreedGDPR === 1 ? false : true}
              onChange={() => {
                const val = data.AgreedGDPR === 0 ? 1 : 0
                //console.log(val);
                setData({ ...data, AgreedGDPR: val })
              }}
              color="info"
            />
          } label="Δεν επιθυμώ να λαμβάνω ειδοποιήσεις μέσω email" />

        </div>


        <Button
          color="success"
          variant="contained"
          type="submit"
          className="!mt-7 mb-6 ql-align-center p-3 max-w-[550px]"
        >
          <Check></Check>
          <span className="pl-2 capitalize">{' Υποβολή αλλαγών!'}</span>
        </Button>

      </ValidatorForm>
    </div>
  )
}

export default AccountDetailsEdit

