import earnImg12 from '../assets/earn/12.jpg';
import earnImg8 from '../assets/earn/8.jpg';
import earnImg from '../assets/earn/NO1_2.png';


import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BsQuestionCircle, BsRecordCircleFill } from 'react-icons/bs';
import { MdArticle, MdVideoCameraBack } from "react-icons/md";
import { useInfiniteQuery, useQuery } from 'react-query';
import { checkIfEarnedAll, getAllBlogs, getAllQuizes, getAllVideos, getLiveMission, getLiveMissionNext } from '../api';
import useMunicipality from '../hooks/useMunicipality';
import { generateRandomId, getThumb } from '../utils/utils';
import { Link } from 'react-router-dom';
import { CheckBox } from '@mui/icons-material';
import useAuth from '../hooks/useAuth';
import { FaCheck } from 'react-icons/fa';
import Carousel from 'react-multi-carousel';



const products = [
    {
        id: 1,
        name: 'Πλαστικά μίας χρήσης και επιπτώσεις',
        href: '#',
        imageSrc: earnImg8,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: '#befreefromplastic',
        points: 10,
    },
    {
        id: 2,
        name: 'Πλαστικά μίας χρήσης και επιπτώσεις στο περιβάλλον',
        href: '#',
        imageSrc: earnImg,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: '#befreefromplastic',
        points: 10,
    },
    {
        id: 3,
        name: 'Πλαστικά μίας χρήσης και επιπτώσεις στο περιβάλλον',
        href: '#',
        imageSrc: earnImg12,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: '#befreefromplastic',
        points: 10,
    },
    {
        id: 4,
        name: 'Πλαστικά μίας χρήσης και επιπτώσεις στο περιβάλλον',
        href: '#',
        imageSrc: earnImg,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: '#befreefromplastic',
        points: 10,
    },
    {
        id: 5,
        name: 'Πλαστικά μίας χρήσης και επιπτώσεις στο περιβάλλον',
        href: '#',
        imageSrc: earnImg,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: '#befreefromplastic',
        points: 10,
    },
    {
        id: 6,
        name: 'Πλαστικά μίας χρήσης και επιπτώσεις στο περιβάλλον',
        href: '#',
        imageSrc: earnImg,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: '#befreefromplastic',
        points: 10,
    },
    {
        id: 7,
        name: 'Πλαστικά μίας χρήσης και επιπτώσεις στο περιβάλλον',
        href: '#',
        imageSrc: earnImg,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: '#befreefromplastic',
        points: 10,
    },
    {
        id: 8,
        name: 'Πλαστικά μίας χρήσης και επιπτώσεις στο περιβάλλον',
        href: '#',
        imageSrc: earnImg,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: '#befreefromplastic',
        points: 10,
    },

    // More products...
]


const cardVariants = {
    initial: {
        y: 300,
    },
    exit: {
        y: 0
    },
    animate: {
        y: 0,
        rotate: 0,
        transition: {
            type: "spring",
            bounce: 0.6,
            duration: 1
        }
    }
};


//Keep the count of loaded items to check agains the database
let loadedQuizes = 0
let loadedBLogs = 0
let loadedVideos = 0

export default function Earn({ isHomePage = false }) {

    //Pagination configuration
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(isHomePage ? 4 : 12);

    const [filter, setFilter] = useState(0)

    const { login, user, isAuthenticated } = useAuth();

    const handleFilterChanges = (filterId) => {
        setFilter(filterId)
    }

    const checkEarnStatus = (id) => {
        let isEarned = false;

        for (let index = 0; index < checkIfEarned.length; index++) {
            const element = checkIfEarned[index];
            if (element.EarnId === id)
                isEarned = true;

        }

        return isEarned;
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };



    let allData = [];

    //To handle response on municiupality changes
    const { municipality } = useMunicipality();


    const { isLoading: isLiveMissionLoading, isError: isLiveMissionError, data: liveMission, isFetching: isMissionFetching } =
        useQuery('live_mission_navbar', () => getLiveMission({ municipalityId: municipality.MunicipalityId, citizenId: isAuthenticated ? user.citizenId : 0 }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })


    const { isLoading: isLiveMissionNextLoading, isError: isLiveMissionNextError, data: liveMissionNext, isFetching: isNextMissionFetching } =
        useQuery('live_mission_two', () => getLiveMissionNext({ municipalityId: municipality.MunicipalityId, citizenId: isAuthenticated ? user.citizenId : 0 }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })

    //Get blog posts
    const { isLoading, isError, error, data: blogs, isFetching, fetchNextPage: fetchNextPageBlogs,
        hasNextPage: hasNextPageBlogs,
        isFetchingNextPage: isFetchingNextPageBlogs } =
        useInfiniteQuery(['blogs', municipality],
            ({ pageParam = 0 }) =>
                getAllBlogs({ limit: pageSize, page: pageParam, optionalKeyword: '', municipalityId: municipality.MunicipalityId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                getNextPageParam: (lastPage, allPages) => {
                    const count = lastPage.count;
                    // console.log("count is:", count)
                    let _i = 0;
                    for (let index = 0; index < allPages.length; index++) {
                        const element = allPages[index].rows.length
                        _i += element

                    }
                    // console.log("Length is:", _i+10)                    
                    const nextPage = allPages.length
                    loadedBLogs = _i
                    if (_i + 10 <= count && allPages.length && (filter === 2 || filter === 0))
                        return nextPage
                    // else {
                    //     return null
                    // }
                }
                // keepPreviousData: true
            })

    //Get Videos        
    const { isLoading: isLoadingVideo, isError: isErrorVideo, error: errorVideo, data: videos, isFetching: isFetchingVideo,
        fetchNextPage: fetchNextPageVideos,
        hasNextPage: hasNextPageVideos,

        isFetchingNextPage: isFetchingNextPageVideos
    } =
        useInfiniteQuery(['videos', municipality],
            ({ pageParam = 0 }) =>
                getAllVideos({ limit: pageSize, page: pageParam, optionalKeyword: '', municipalityId: municipality.MunicipalityId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                getNextPageParam: (lastPage, allPages) => {
                    const count = lastPage.count;
                    // console.log("count is:", count)
                    let _i = 0;
                    for (let index = 0; index < allPages.length; index++) {
                        const element = allPages[index].rows.length
                        _i += element

                    }
                    // console.log("Length is:", _i+10)                    
                    const nextPage = allPages.length
                    loadedVideos = _i
                    if (_i + 10 <= count && allPages.length && (filter === 3 || filter === 0))
                        return nextPage
                    // else {
                    //     return null
                    // }
                }
                // keepPreviousData: true
            })

    //Get Quizes        
    const { isLoading: isLoadingQuiz, isError: isErrorQuiz, error: errorQuiz, data: quizes, isFetching: isFetchingQuiz,
        fetchNextPage: fetchNextPageQuiz,
        hasNextPage: hasNextPageQuiz,

        isFetchingNextPage: isFetchingNextPageQuiz
    } =
        useInfiniteQuery(['quizes', municipality],
            ({ pageParam = 0 }) =>
                getAllQuizes({ limit: pageSize, page: pageParam, optionalKeyword: '', municipalityId: municipality.MunicipalityId  }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                getNextPageParam: (lastPage, allPages) => {
                    const count = lastPage.count;
                    // console.log("count is:", count)
                    let _i = 0;
                    for (let index = 0; index < allPages.length; index++) {
                        const element = allPages[index].rows.length
                        _i += element

                    }
                    // console.log("Length is:", _i+10)    
                    // console.log('LoadedQuizes is :',loadedQuizes)                
                    const nextPage = allPages.length
                    // console.log('filter is: ',filter)
                    loadedQuizes = _i
                    if ((_i + 10 <= count || allPages.length) && (filter === 1 || filter === 0))

                        return nextPage
                    // else {
                    //     return false
                    // }
                }
                // keepPreviousData: true
            })
    // console.log("USER ID IS:::",user.citizenId)

    const { isLoading: isLoadingcheckIfEarned, isError: isErrorcheckIfEarned, data: checkIfEarned, isFetching: isFetchingcheckIfEarned, } =
        useQuery(['check_if_earned_items'],
            () =>
                checkIfEarnedAll(isAuthenticated ? user.citizenId : null),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                // /keepPreviousData: true
            })

    const observer = useRef()


    let _videos = [];
    let _blogs = [];
    let _quizes = [];

    const lastElementRef = useCallback((node) => {




        // if (_quizes.length >= quizes.pages[0].count - 1 && filter === 1) return
        // if (_blogs.length >= blogs.pages[0].count - 1 && filter === 2) return
        // if (_videos.length >= videos.pages[0].count - 1 && filter === 3) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {



            if (entries[0].isIntersecting) {

                const { scrollHeight, scrollTop, clientHeight } =
                    node;

                // console.log("LD", loadedQuizes)
                if (isLoading || isLoadingQuiz || isLoadingVideo) return

                // console.log("WRONGGGGGGGGGGGGGGGGGGg")

                if (scrollHeight - scrollTop <= clientHeight + 800)
                    fetchMore()



            }
        })
        if (node) observer.current.observe(node)
        // console.log(node)
    }, [isLoading, isLoadingQuiz, isLoadingVideo, hasNextPageQuiz, hasNextPageBlogs, hasNextPageVideos])

    // useEffect(() => {
    //     let fetching = false;
    //     const handleScroll = async (e) => {
    //       const {scrollHeight, scrollTop, clientHeight} = e.target.scrollingElement;
    //       if(!fetching && scrollHeight - scrollTop <= clientHeight * 1.2) {
    //         fetching = true
    //         if(hasNextPageBlogs)
    //         await  fetchNextPageBlogs()
    //         // if(hasNextPageQuiz)
    //         // await fetchNextPageQuiz()
    //         // if(hasNextPageVideos)
    //         // await fetchNextPageVideos()

    //         fetching = false
    //       }
    //     }
    //     document.addEventListener('scroll', handleScroll)
    //     return () => {
    //       document.removeEventListener('scroll', handleScroll)
    //     }
    //   }, [fetchNextPageBlogs, hasNextPageBlogs])
    // const handleObserver = useCallback((entries) => {
    //     const [target] = entries
    //     if (target.isIntersecting && hasNextPageBlogs) {
    //         fetchNextPageBlogs()
    //     }
    // }, [fetchNextPageBlogs, hasNextPageBlogs])
    const fetchMore = () => {

        //             if (filter === 1) { allData = [..._quizes]; }
        // if (filter === 2) { allData = [..._blogs]; }
        // if (filter === 3) { allData = [..._videos]; }


        if (hasNextPageBlogs && (filter === 2 || filter === 0) && (loadedBLogs < blogs.pages[0].count)) {
            fetchNextPageBlogs()
            let previousScrollYPosition = window.scrollY

            // setTimeout(function () {
            //     window.scrollTo({
            //         top: previousScrollYPosition,
            //         behavior: "smooth"
            //     });
            // }, 50);
        }
        if (hasNextPageQuiz && (filter === 1 || filter === 0) && (loadedQuizes < quizes.pages[0].count)) {
            fetchNextPageQuiz()
            // let previousScrollYPosition = window.scrollY

            // setTimeout(function () {
            //     window.scrollTo({
            //         top: previousScrollYPosition,
            //         behavior: "smooth"
            //     });
            // }, 50);
        }
        if (hasNextPageVideos && (filter === 3 || filter === 0) && (loadedVideos < videos.pages[0].count)) {
            fetchNextPageVideos()
            let previousScrollYPosition = window.scrollY

            // setTimeout(function () {
            //     window.scrollTo({
            //         top: previousScrollYPosition,
            //         behavior: "smooth"
            //     });
            // }, 50);
        }



    }


    //Error checking and loading        
    if (isLoading || isLoadingVideo || isLoadingQuiz || isLoadingcheckIfEarned || isLiveMissionLoading || isMissionFetching || isLiveMissionNextLoading || isNextMissionFetching)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError || isErrorVideo || isErrorQuiz || isErrorcheckIfEarned)

        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA: { } </p>
            </div>
        </div>

    if (blogs.length < 1 || videos.length < 1 || quizes.length < 1)
        return <div className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>
                <p className='texgt-md font-medium'>No Data To Load</p>
            </div>
        </div>



    //Filter, Combine Videos, Blogs, Quizes in the same array 


    for (let i = 0; i < blogs.pages.length; i++) {
        const _page = blogs.pages[i];
        for (let j = 0; j < _page.rows.length; j++) {
            const element = _page.rows[j];
            _blogs.push(element);
        }
    }
    for (let i = 0; i < videos.pages.length; i++) {
        const _page = videos.pages[i];
        for (let j = 0; j < _page.rows.length; j++) {
            const element = _page.rows[j];
            _videos.push(element);
        }
    }
    for (let i = 0; i < quizes.pages.length; i++) {
        const _page = quizes.pages[i];
        for (let j = 0; j < _page.rows.length; j++) {
            const element = _page.rows[j];
            _quizes.push(element);
        }
    }

    if (filter === 0) { allData = [..._videos, ..._quizes, ..._blogs]; }
    if (filter === 1) { allData = [..._quizes]; }
    if (filter === 2) { allData = [..._blogs]; }
    if (filter === 3) { allData = [..._videos]; }

    //shuffle Videos, Blogs, Quizes based on some value(Views, InsertDate etc...)
    allData.sort(function (a, b) {
        // return  Math.random() - 0.5
        return new Date(b.InsertDate) - new Date(a.InsertDate);
        // return b.Views -  a.Views;
    });

    if (isHomePage) {
        if (liveMission.mission)
            allData.length = 2
        else allData.length = 4
    }





    return (
        <div className="bg-white  overflow-hidden ">
            <div className="relative max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">

                {isHomePage ? <></> :
                    <div>
                        <div className=''>
                            {municipality.IsGreenSchool === 1 ? <h2 className=" text-3xl font-bold text-gray-700 ">Διάβασε, παίξε, δες και κέρδισε πόντους!</h2> :
                                <h2 className=" text-3xl font-bold text-gray-700 ">Ανακύκλωσε, διάβασε, παίξε, δες και κέρδισε πόντους!</h2>}


                            {municipality.IsGreenSchool === 1 ? <h2 className="text-lg font-normal text-gray-700">Διάβασε άρθρα, δοκίμασε τις γνώσεις σου σε κουίζ, παρακολούθησε βίντεο και συγκέντρωσε όλο και περισσότερους πόντους επιβράβευσης.</h2> :
                                <h2 className="text-lg font-normal text-gray-700">Διάβασε άρθρα, δοκίμασε τις γνώσεις σου σε κουίζ, παρακολούθησε βίντεο, καταχώρησε την ανακύκλωση που κάνεις και συγκέντρωσε όλο και περισσότερους πόντους επιβράβευσης.</h2>}

                        </div>




                        {(liveMission && liveMission.mission) ? <Carousel
                            className="pb-10 pt-5"
                            swipeable={liveMissionNext.hasMission ? true : false}
                            draggable={liveMissionNext.hasMission ? true : false}
                            showDots={liveMissionNext.hasMission ? true : false}
                            responsive={responsive}


                            // renderButtonGroupOutside={true}
                            // arrows={false}
                            // centerMode={true}
                            // ssr={false} // means to render carousel on server-side.
                            infinite={true}
                            // focusOnSelect={false}
                            autoPlay={liveMissionNext.hasMission ? true : false}
                            autoPlaySpeed={5000}
                            // keyBoardControl={true}
                            // customTransition="all .5"
                            // transitionDuration={500}
                            arrows={liveMissionNext.hasMission ? true : false}
                            // containerClass="carousel-container"
                            // removeArrowOnDeviceType={["tablet", "mobile"]}
                            // deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style pt-10 !mt-10"
                        // itemClass="carousel-item-padding-40-px"
                        >


                            <div className='flex justify-center w-full'>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20
                                    }}
                                    className="relative w-full  bg-gradient-to-l from-green-400 via-blue-500 to-green-500  justify-center items-center flex flex-col duration-200 ease-out shadow-lg  rounded-3xl group  hover:-translate-y-2">

                                    <Link to={'./../mission/single/currnet'}

                                        className="w-full  rounded-md min-h-64 aspect-w-1 aspect-h-1 lg:h-auto lg:aspect-none">
                                        <div className='relative h-[20.0rem]'>
                                            <img
                                                loading="lazy"
                                                src={liveMission.mission.Image ? ((liveMission.mission.Image.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + liveMission.mission.Image) : ''}
                                                alt=''
                                                className="object-cover  h-full object-center w-full p-1.5 lg:w-full rounded-3xl"
                                            />

                                            <div className="absolute bottom-5 flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3  bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                                <div className="absolute flex items-center justify-center text-lg font-bold text-gray-800 bg-white border-0 border-white rounded-full shadow-inner shadow-gray-200 w-12 h-12 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{liveMission.mission.Reward}</div>
                                            </div>



                                            <div className='absolute   top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col justify-center items-center w-full'>
                                                <div className="pt-5 pb-2 px-3 w-11/12 space-y-3">
                                                    <div
                                                        style={{ 'text-shadow': '2px 2px 4px #353535' }}
                                                        className="flex justify-center text-3xl font-semibold leading-6 text-center text-white drop-shadow-md  shadow-black">

                                                        {liveMission.mission.TitleEl}
                                                    </div>
                                                    <div
                                                        style={{ 'text-shadow': '2px 2px 4px #353535' }}
                                                        className="flex justify-center text-xl font-semibold leading-6 text-center text-white drop-shadow-lg shadow-black">

                                                        {liveMission.mission.SubtitleEl}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                            </div>

                            {liveMissionNext.hasMission ?
                                <div className='flex justify-center w-full'>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{
                                            type: "spring",
                                            stiffness: 260,
                                            damping: 20
                                        }}
                                        className="relative w-full  bg-gradient-to-l from-green-400 via-blue-500 to-green-500  justify-center items-center flex flex-col duration-200 ease-out shadow-lg  rounded-3xl group  hover:-translate-y-2">

                                        <Link to={'./../mission/single/currnet2'}

                                            className="w-full  rounded-md min-h-64 aspect-w-1 aspect-h-1 lg:h-auto lg:aspect-none">
                                            <div className='relative h-[20.0rem]'>
                                                <img
                                                    loading="lazy"
                                                    src={liveMissionNext.mission.Image ? ((liveMissionNext.mission.Image.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + liveMissionNext.mission.Image) : ''}
                                                    alt=''
                                                    className="object-cover  h-full object-center w-full p-1.5 lg:w-full rounded-3xl"
                                                />

                                                <div className="absolute bottom-5 flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3  bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                                    <div className="absolute flex items-center justify-center text-lg font-bold text-gray-800 bg-white border-0 border-white rounded-full shadow-inner shadow-gray-200 w-12 h-12 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{liveMission.mission.Reward}</div>
                                                </div>



                                                <div className='absolute   top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col justify-center items-center w-full'>
                                                    <div className="pt-5 pb-2 px-3 w-11/12 space-y-3">
                                                        <div
                                                            style={{ 'text-shadow': '2px 2px 4px #353535' }}
                                                            className="flex justify-center text-3xl font-semibold leading-6 text-center text-white drop-shadow-md  shadow-black">

                                                            {liveMissionNext.mission.TitleEl}
                                                        </div>
                                                        <div
                                                            style={{ 'text-shadow': '2px 2px 4px #353535' }}
                                                            className="flex justify-center text-xl font-semibold leading-6 text-center text-white drop-shadow-lg shadow-black">

                                                            {liveMissionNext.mission.SubtitleEl}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </motion.div>
                                </div>
                                : null}


                        </Carousel> : null}








                        {/* Filters Menu */}
                        {/* <div className="absolute mt-7 w-full h-10 gradient opacity-5 rounded-full"></div> */}
                        <div className="grid grid-cols-3 gap-3 md:flex justify-center md:space-x-5 p-3 md:p-8">
                            <div
                                className={`${filter === 0 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white  cursor-pointer  px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                                onClick={() => { handleFilterChanges(0) }}>ΟΛΑ</div>
                            <div
                                className={`${filter === 1 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer   px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                                onClick={() => { handleFilterChanges(1) }}>ΚΟΥΙΖ</div>
                            <div
                                className={`${filter === 2 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer   px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                                onClick={() => { handleFilterChanges(2) }}>ΑΡΘΡΑ</div>
                            <div
                                className={`${filter === 3 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer  px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                                onClick={() => { handleFilterChanges(3) }}>ΒΙΝΤΕΟ</div>
                        </div>
                    </div>
                }


                {/* The actula Content lIST */}
                <div className="grid grid-cols-1 mt-6 gap-y-10 md:gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">






                    {(liveMission && isHomePage && liveMission.mission) ?
                        <Carousel
                            className="col-span-2 shadow-2xl cursor-pointer rounded-3xl bg-gradient-to-l from-green-400 via-blue-500 to-green-500"
                            swipeable={liveMissionNext.hasMission ? true : false}
                            draggable={liveMissionNext.hasMission ? true : false}
                            showDots={liveMissionNext.hasMission ? true : false}
                            responsive={responsive}


                            // renderButtonGroupOutside={true}
                            // arrows={false}
                            // centerMode={true}
                            // ssr={false} // means to render carousel on server-side.
                            infinite={true}
                            // focusOnSelect={false}
                            autoPlay={liveMissionNext.hasMission ? true : false}
                            autoPlaySpeed={5000}
                            // keyBoardControl={true}
                            // customTransition="all .5"
                            // transitionDuration={500}
                            arrows={liveMissionNext.hasMission ? true : false}
                            // containerClass="carousel-container"
                            // removeArrowOnDeviceType={["tablet", "mobile"]}
                            // deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style pb-2 !mt-10"
                        >

                            <div className=' relative p-1 bg-gradient-to-l from-green-400 via-blue-500 to-green-500  overflow-hidden duration-200 ease-out  group  hover:-translate-y-2'>
                                <Link to={'./mission/single/currnet'}>
                                    <div className='bg-white w-full h-full rounded-3xl relative'>
                                        <img
                                            loading="lazy"
                                            src={liveMission.mission.HomeImage ? ((liveMission.mission.HomeImage.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + liveMission.mission.HomeImage) : ''}
                                            alt=''
                                            className="object-cover object-center w-full p-0 lg:w-full rounded-3xl"
                                        />
                                        <div className="absolute flex items-center justify-center w-12 h-12 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 top-32 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-9 h-9 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{liveMission.mission.Reward}</div>
                                        </div>
                                        <div className="h-24 px-5">
                                            <div className="flex justify-center text-[17px] font-semibold text-center text-black">

                                                {liveMission.mission.TitleEl}
                                            </div>
                                            <div className='text-center'>{liveMission.mission.SubtitleEl}</div>
                                        </div>

                                    </div>
                                </Link>

                            </div>


                    {   liveMissionNext.hasMission?       <div className=' h-full relative p-1 bg-gradient-to-l from-green-400 via-blue-500 to-green-500  overflow-hidden duration-200 ease-out shadow-2xl cursor-pointer rounded-3xl group  hover:-translate-y-2'>
                                <Link to={'./mission/single/currnet2'}>
                                    <div className='bg-white w-full rounded-3xl relative'>
                                        <img
                                            loading="lazy"
                                            src={liveMissionNext.mission.HomeImage ? ((liveMissionNext.mission.HomeImage.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + liveMissionNext.mission.HomeImage) : ''}
                                            alt=''
                                            className="object-cover object-center w-full p-0 lg:w-full rounded-3xl"
                                        />
                                        <div className="absolute flex items-center justify-center w-12 h-12 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 top-32 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-9 h-9 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{liveMission.mission.Reward}</div>
                                        </div>
                                        <div className="h-24 px-5">
                                            <div className="flex  justify-center text-[17px] font-semibold text-center text-black">

                                                {liveMissionNext.mission.TitleEl}
                                            </div>
                                            <div className='text-center'>{liveMissionNext.mission.SubtitleEl}</div>
                                        </div>

                                    </div>
                                </Link>

                            </div> : null}
                        </Carousel>
                        : null}

                    {allData.map((post, index) => {
                        // if (allData.length === index + 1) return
                         
                        // else
                        return (

                            ((post.MunicipalityId === municipality.MunicipalityId || post.MunicipalityId === null) && post.MissionOnly !== 1) ?

                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20
                                    }}
                                    key={index} className="relative p-1 bg-gradient-to-l from-green-400 via-blue-500 to-green-500  overflow-hidden duration-200 ease-out shadow-2xl cursor-pointer rounded-3xl group  hover:-translate-y-2">
                                    <div className='bg-white w-full h-full rounded-3xl'>

                                        <Link to={(() => {
                                            if (post.BlogId !== undefined) {
                                                return isHomePage ? './blog/single/' + post.FriendlyUrl : '../blog/single/' + post.FriendlyUrl
                                            } if (post.QuizId !== undefined) {
                                                return isHomePage ? './quiz/single/' + post.FriendlyUrl : '../quiz/single/' + post.FriendlyUrl
                                            } if (post.VideoId !== undefined) {
                                                return isHomePage ? './video/single/' + post.FriendlyUrl : '../video/single/' + post.FriendlyUrl
                                            }
                                        })()}
                                            // isHomePage ? '../blog/single/' + post.FriendlyUrl : '../blog/single/' + post.FriendlyUrl}
                                            state={
                                                {
                                                    post: post,
                                                    postList: (() => {
                                                        if (post.QuizId)
                                                            return _quizes.slice(0, 8)
                                                        if (post.BlogId)
                                                            return _blogs.slice(0, 8)
                                                        if (post.VideoId)
                                                            return _videos.slice(0, 8)


                                                    })()
                                                }
                                            }
                                            className="w-full rounded-md min-h-70 aspect-w-1 aspect-h-1 lg:h-70 lg:aspect-none">
                                            <div>
                                                {(() => {
                                                    if (post.Image) {
                                                        return (

                                                            <img
                                                                loading="lazy"
                                                                src={post.Image ? ((post.Image.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + post.Image) : ''}
                                                                alt=''
                                                                className="object-cover object-center w-full p-0 lg:w-full rounded-3xl"
                                                            />

                                                        )
                                                    } else if (post.BlogImage) {
                                                        return (
                                                            <img
                                                                loading="lazy"
                                                                src={post.BlogImage ? ((post.BlogImage.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + post.BlogImage) : ''}
                                                                alt=''
                                                                className="object-cover object-center w-full p-0 lg:w-full rounded-3xl"
                                                            />
                                                        )
                                                    } else {
                                                        return (
                                                            <img
                                                                loading="lazy"
                                                                src={getThumb(post.Url)}
                                                                alt=''
                                                                className="object-cover object-center w-full p-0 lg:w-full rounded-3xl"
                                                            />
                                                        )
                                                    }
                                                })()}


                                                <div className="absolute flex items-center justify-center w-12 h-12 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 top-32 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                                    <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-9 h-9 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{post.Points}</div>
                                                </div>

                                                <div className="absolute flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 left-3 top-3 bg-slate-500 shadow-md">

                                                    {(() => {
                                                        if (post.BlogId !== undefined) {
                                                            return (

                                                                <MdArticle color='white' size={22}></MdArticle>

                                                            )
                                                        } if (post.QuizId !== undefined) {
                                                            return (
                                                                <BsQuestionCircle color='white' size={22}></BsQuestionCircle>
                                                            )
                                                        } if (post.VideoId !== undefined) {
                                                            return (
                                                                <MdVideoCameraBack color='white' size={22}></MdVideoCameraBack>
                                                            )
                                                        }
                                                    })()}

                                                </div>

                                                {(() => {

                                                    if (isAuthenticated) {
                                                        if (post.BlogId !== undefined) {
                                                            if (checkEarnStatus(post.BlogId))
                                                                return (
                                                                    <div className="absolute bg-green-500 flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 right-3 top-3">
                                                                        <FaCheck color='white' size={22}></FaCheck>
                                                                    </div>
                                                                )

                                                        } if (post.QuizId !== undefined) {
                                                            if (checkEarnStatus(post.QuizId))
                                                                return (
                                                                    <div className="absolute bg-green-500 flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 right-3 top-3">
                                                                        <FaCheck color='white' size={22}></FaCheck>
                                                                    </div>
                                                                )

                                                        } if (post.VideoId !== undefined) {
                                                            if (checkEarnStatus(post.VideoId))
                                                                return (
                                                                    <div className="absolute bg-green-500 flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 right-3 top-3">
                                                                        <FaCheck color='white' size={22}></FaCheck>
                                                                    </div>
                                                                )

                                                        }
                                                    }
                                                })()}


                                            </div>



                                            <div className="p-3.5 pb-1">
                                                <div className="flex justify-center text-[17px] font-semibold text-center">

                                                    {post.TitleEl}
                                                </div>

                                                {/* {post.TitleEl} */}
                                            </div>
                                            <div className="flex justify-center p-3 pb-5 text-center">
                                                <p className="text-sm font-medium text-gray-900">{post.SubtitleEl}</p>
                                            </div>
                                        </Link>

                                    </div>




                                </motion.div>

                                : null
                        )
                    })}





                </div>
                <div
                    key={100000000}
                    className="relative   overflow-hidden flex justify-center items-center"
                    ref={lastElementRef}
                >
                    {/* <CircularProgress></CircularProgress> */}
                </div>
                {isHomePage ? null :
                    <div

                        className=' w-full flex justify-center mt-20'>
                        <div type="button"
                            onClick={() => {


                                // window.scrollTo(0, 0);
                                // setTimeout(function () {
                                //     window.scrollTo({
                                //         top: 0,
                                //         behavior: "smooth"
                                //     });
                                // }, 5);

                            }}
                            className="shadow-lg flex shadow-gray-300 bg-white drop-shadow-md   text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                            <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
                                <p className='pl-1.5 pb-0.5'>{allData.length > 1 ? 'Δεν βρέθηκαν άλλες καταχωρίσεις.' : 'Δεν βρέθηκαν καταχωρίσεις.'}</p></div>
                        </div>

                    </div>
                }
            </div>
        </div>
    )
}