import {
    Link,
    useLocation
} from "react-router-dom";

import earnImg19 from '../assets/earn/19.jpg';
import earnImg20 from '../assets/earn/20.jpg';
import earnImg3 from '../assets/earn/3.jpg';


import { motion } from "framer-motion";
import { BsRecordCircleFill } from "react-icons/bs";
import { useQuery } from "react-query";
import useMunicipality from "../hooks/useMunicipality";
import { useState } from "react";
import { addSchoolTransaction, getAllContestSchools, getAllSchoolContests, getAllSchoolProjects, getAllSchools, getLastSchoolContest } from "../api";
import { CircularProgress, Slider, TextField } from "@mui/material";
import { getImage } from "../utils/utils";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SchoolPieChart from "../components/SchoolsPieChart";
import useAuth from "../hooks/useAuth";
import { RiCloseLine } from "react-icons/ri";
import { useModal } from "../contexts/ModalContext";
import { TextValidator } from "react-material-ui-form-validator";
import { Check } from "@mui/icons-material";




export default function SchoolUserView() {


    const { municipality } = useMunicipality();



    const school = useLocation().state.school;
    const project = useLocation().state.project;
    
  const [data, setData] = useState(project);


    const [pointsData, setPointsData] = useState([
        { name: 'Αλουμίνιο', data: data.BalanceAluminium },
        { name: 'Μπαταρίες', data: data.BalanceBatteries },
        { name: 'Καπάκια', data: data.BalanceCaps },
        { name: 'Γυαλί', data: data.BalanceGlass },
        { name: 'Λαμπτήρες', data: data.BalanceLamps },
        { name: 'Missions', data: data.BalanceMissions },
        { name: 'Organic/Ρούχα', data: data.BalanceOrganic },
        { name: 'Τηγανέλαιο', data: data.BalancePanOil },
        { name: 'Χαρτί', data: data.BalancePaper },
        { name: 'Πλαστικό', data: data.BalancePlastic },
        { name: 'AHHE', data: data.BalanceWEEE },
        { name: 'Total Points', data: data.BalancePoints },
      ]);













    const cardVariants = {
        initial: {
            y: 300,
        },
        exit: {
            y: 0
        },
        animate: {
            y: 0,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0.6,
                duration: 1
            }
        }
    };


    const getDonationPoint = () => {
        let totalPoints = data.BalancePoints;
        for (let index = 0; index < pointsData.length - 1; index++) {
          const element = pointsData[index];
          totalPoints -= element.data;
    
        }
    
        return totalPoints;
    
      }


    //error handlers
    // if (isLoading || isFetching)
    //     return <div className='flex justify-center items-center min-h-screen'><CircularProgress></CircularProgress></div>

    // if (isError) {

    //     return <div className='flex p-8 justify-center min-h-screen items-center'>
    //         <div className=' text-center'>

    //             <p className='texgt-md font-medium'>ERROR LOADING DATA: {error.message} </p>
    //         </div>
    //     </div>
    // }

    // if (data.length < 1)
    //     return <div className='flex p-8 justify-center min-h-screen items-center'>
    //         <div className=' text-center'>

    //             <p className='texgt-md font-medium'>No Data To Load</p>
    //         </div>
    //     </div>








    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <motion.div className="card-container"
                    variants={cardVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    viewport={{ once: true, amount: 0.8 }}>

                    <h2 className="pb-8 text-3xl font-bold text-gray-700">Το σχολείο μου</h2>
                    {/* <h2 className="pb-3 text-3xl font-semibold text-gray-700">{data.contest.TitleEl}</h2> */}
                    {/* <h2 className="text-lg font-semibold text-gray-700">Ανακάλυψε τα σχολεία που συμμετέχουν στη δράση, δώρισε πόντους και ενίσχυσε την προσπάθεια των μαθητών.</h2> */}
                    


                   

                </motion.div>
                {/* <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8"> */}
                <div className="grid grid-cols-1 mt-5 gap-y-5 gap-x-6 sm:grid-cols-1 lg:grid-cols-1 xl:gap-x-8">

                <motion.div
                            variants={cardVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            viewport={{ once: true, amount: 0.8 }} key={school.SchoolId}
                            className="overflow-hidden border-8 border-white shadow-gray-300 m-5 shadow-xl  rounded-3xl">
                            <div className="flex flex-col lg:flex-row">

                                <div className="w-full lg:w-2/5">
                                    <img
                                        src={(school.Image ===null || school.Image.includes('ΦΩΤΟΓΡΑΦΙΑ ΣΧΟΛΕΙΟΥ') ||(school.Image.includes('ΦΩΤΟ.png')) ) ? getImage('/images/school/default.jpg') : getImage(school.Image)}
                                        alt=''
                                        loading="lazy"
                                        className="flex object-cover h-72 w-full  "
                                    // SetNews - anna
                                    />
                                </div>


                                <div className=" flex flex-col lg:flex-row  w-full lg:w-[70%] min-h-14 h-full content-between justify-between pl-3  opacity-90  ">
                                    <div className="p-3 w-full  lg:w-1/2 space-y-5 flex flex-col items-start justify-between">
                                        <div className=" font-semibold">
                                            {school.Name}
                                        </div>
                                        <div className="flex ">
                                            <div className="font-semibold">
                                                {school.Purpose}
                                            </div>
                                        </div>
                                        
                                        <div className="flex flex-col items-center">
                                            <div className="flex items-center">
                                                <div className="text-md">κιλά/μαθητή:</div>
                                                <div className=" border-app_green_light font-semibold  pl-1 text-lg">{(project.BalancePoints / school.StudentCount).toFixed(2)}</div>
                                            </div>
                                            {/* <div className="relative pt-1">
                                                <div style={{ width: `${150}px` }} className="overflow-hidden  h-3 mb-4 text-xs flex rounded bg-orange-200">
                                                    <div style={{ width: `${(((getProjectPointsBySchool(school.SchoolId)) / school.StudentCount) / 7.5)}px` }} className="shadow-none  flex flex-col text-center whitespace-nowrap text-white justify-center bg-orange-500"></div>
                                                </div>
                                            </div> */}

                                        </div>

                                    </div>
                                    <div className="relative flex justify-center items-center w-full  lg:w-[60%]  h-72">

                                        {/* <div>{lastContests.ProjectPoints / school.StudentCount}</div> */}
                                        {/* <div className="absolute flex flex-col justify-center items-center  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                                            <div className="text-md font-semibold"> {((getProjectPointsBySchool(school.SchoolId))).toFixed(2)}</div>
                                            <div className="text-[13px]">κιλά/πόντοι</div>
                                        </div>
                                        <SchoolPieChart data={getProjectMaterialsBySchool(school.SchoolId)}></SchoolPieChart> */}
                                    </div>


                                </div>


                            </div>


                            <div className='grid grid-cols-4 mt-10'>
        {pointsData.map((value, index) => {
          if (index < pointsData.length - 1)
            return (<div className='bg-white mt-3 mb-3 m-3 shadow-md rounded-2xl overflow-hidden'>
              <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                <div className='p-2 text-center  font-semibold  text-gray-500 '>{value.name}</div>
              </div>
              <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                <div>{value.data}</div>
              </div>
            </div>)
        })}
        </div>

        <div className='bg-white mt-3 mb-3 m-3 shadow-md rounded-2xl overflow-hidden'>
          <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
            <div className='p-2 text-center  font-semibold  text-gray-500'>{'Δωρεά Πόντων'}</div>
          </div>
          <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
            <div>{getDonationPoint()}</div>
          </div>
        </div>
        {/* TOTAL POINTS */}
        <div className='bg-white mt-3 mb-3 m-3 shadow-md rounded-2xl overflow-hidden col-span-2'>
          <div className='bg-green-100 overflow-hidden'>
            <div className='p-2 text-center  font-semibold  text-gray-500'>{pointsData[pointsData.length - 1].name}</div>
          </div>
          <div className='p-3 text-center text-lg font-semibold text-gray-500 '>
            <div>{pointsData[pointsData.length - 1].data}</div>
          </div>
        </div>
        {/* POINTS PER STUDENT */}
        <div className='bg-white mt-3 mb-3 m-3 shadow-md rounded-2xl overflow-hidden col-span-2'>
          <div className='bg-purple-100 overflow-hidden'>
            <div className='p-2 text-center  font-semibold  text-gray-500'>{'Πόντοι ανά μαθητή'}</div>
          </div>
          <div className='p-3 text-center text-lg font-semibold text-gray-500'>
            <div>{(data.BalancePoints / school.StudentCount).toFixed(0)}</div>
          </div>
        </div>

                        </motion.div>


                </div>



            </div >


        </div >
    )
}