import { Map, MapsUgc, Phone } from '@mui/icons-material';
import React, { useState } from 'react'
import { FaHeart, FaMapMarked, FaMapMarkedAlt, FaQuestion, FaShare } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/utils';
import seperator from '../assets/offer-edge.svg';
import useAuth from '../hooks/useAuth';
import { toggleFavoriteOffer } from '../services/favoritesService';
import { addCitizenOffer, getAllCitizenFavoritesOnly, getMerchantByUrl, getOfferByUrl, sendOtp, updateSmartRecycleDetails, verifyOtp } from '../api';
import { useQuery } from 'react-query';
import { CircularProgress, TextField } from '@mui/material';
import { useModal } from '../contexts/ModalContext';
import { motion, useInView } from 'framer-motion';
import { RiCellphoneFill, RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMunicipality from '../hooks/useMunicipality';
import { MdElectricalServices } from 'react-icons/md';


const initialValuesPhone = {
    phone: '',

};

const initialValuesCode = {
    code: '',

};

var phone = null;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// form field validation schema
const validationSchemaPhone = Yup.object().shape({

    phone: Yup.string().min(10, 'Ελέγξτε αν ο αριθμός τηλεφώνου είναι στη σωστή μορφή').matches(phoneRegExp, 'Ελέγξτε αν ο αριθμός τηλεφώνου είναι στη σωστή μορφή'),
});

const validationSchemaCode = Yup.object().shape({

    code: Yup.string().min(5, 'Ελέγξτε αν ο αριθμός είναι στη σωστή μορφή'),
});



const SingleOffer = () => {
   
    const [isVerified, setIsVerified] = useState(false)

    // const offer = useLocation().state.offer;

    // const merchant = useLocation().state.merchant
    const { municipality } = useMunicipality();

    //first we need the offer url
    const location = useLocation()

    const offerUrl = location.pathname.split('/')[4];

    //offer and merchant must be fetched
    const { isLoading, isError, data: offer, } =
        useQuery(['singleoffer',isVerified], () => getOfferByUrl({ url: offerUrl }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })

    // const [otpSent, setOtpSent] = useState(false)
    // const [phone, setPhone] = useState(null)
    const handleFormSubmitPhone = async (values) => {
      //console.log(values)
      //console.log(values)
      if (values.phone.length > 9) {
        await sendOtp({ phone: values.phone }).then((e) => {
           phone = values.phone
           if(e.data.status === 1){
               unSetModal();
               setModal(
                   <div className='flex flex-col items-center justify-center py-5'>
                       <motion.div
                           initial={{ rotate: 500, scale: 0, opacity: 0 }}
                           exit={{ rotate: 1, scale: 1, opacity: 0 }}

                           animate={{ rotate: 0, scale: 1, opacity: 1 }}
                           transition={{
                               damping: 20,
                               duration: 0.35
                           }}
                           className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                           <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                       </motion.div>
                       <div className='font-bold text-lg text-center pt-5'>Έχετε ήδη χρησιμοποιήσει αυτόν τον αριθμό!
                       </div>
                       <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                           onClick={() => { unSetModal() }}
                       >OK</div>
                   </div>
               )
           }
           else if(e.data.status === 3)
           {
               alert('Σας έχει αποσταλεί ο κωδικός επιβεβαίωσης.');
           }
           else
           {
               unSetModal();
               setModal(
                   <div className='flex flex-col items-center justify-center py-5'>
                       <motion.div
                           initial={{ rotate: 500, scale: 0, opacity: 0 }}
                           exit={{ rotate: 1, scale: 1, opacity: 0 }}

                           animate={{ rotate: 0, scale: 1, opacity: 1 }}
                           transition={{
                               damping: 20,
                               duration: 0.35
                           }}
                           className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                           <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                       </motion.div>
                       <div className='font-bold text-lg text-center pt-5'>Ωχ Κάτι πήγε στραβά. Ενημερώθηκε ο διαχειριστής συστήματος.
                       </div>
                       <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                           onClick={() => { unSetModal() }}
                       >OK</div>
                   </div>
               )
           }
       })
       // 
       
       // 
       
   }
         
        // console.log("OTPSENT IS:",otpSent)
    }

    const handleFormSubmitCode = async (values) => {
      //console.log(phone)
        if(!phone)
        return
     
        
        const otpVerificationResonse = await verifyOtp({phone:phone, code:values.code, citizenId: user.citizenId })
        if (otpVerificationResonse.data.status === 2)
        {
            updateUserValues()
            setIsVerified(true);
            unSetModal()
        }
        // setOtpSent(true)
    }


    const [favoritesClicked, setFavoritesClicked] = useState(false)

    const navigate = useNavigate();

    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const { setModal, unSetModal } = useModal()


    const { isLoading: isCitizenFavoritesLoading, isError: isCitizenFavoritesError, data: citizenFavorites, isFetched, isFetching } =
        useQuery(['citizenFavoritesOnly', favoritesClicked], () => getAllCitizenFavoritesOnly({ citizenId: user !== null ? user.citizenId : null }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            onSuccess: (ftchedData) => {
              //console.log("FETCHED DATA: ", ftchedData)
            }
        })

    const checkIfFavorite = (offerId) => {
        //Prevent errors if user is not loged in
        if (!isAuthenticated) return 0;



        var citizenFavorite = citizenFavorites.filter(obj => {

            return obj.OfferId === offerId
        })

        if (citizenFavorite === null) return 0;


        try {
            if (citizenFavorite[0].IsFavorite === 1)
                return 1

        } catch (error) {
            return 0
        }

    }


    const handleρedemption = async (offer) => {

        if (municipality.MunicipalityId === 380 && user.Deh === null) {
            var deh = 0;
            // setWidth(330)
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}
                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><MdElectricalServices className='w-16 h-16 text-app_red'></MdElectricalServices></div>
                    </motion.div>

                    <div className='font-bold text-lg text-center pt-5'>Καταχώρησε τον Αριθμό Παροχής Ηλεκτρικού Ρεύματος για να συνεχίσεις</div>

                    <p className=' text-center pt-5'>Από την ένδειξη "Αριθμός Παροχής" που αναγράφεται στον λογαριασμό σας: π.χ. 1 23456789-02 5 </p>
                    <p className='text-center text-md italic pt-1'>*Εισάγετε ΜΟΝΟ τα 8 ψηφία, που βρίσκονται μετά τον πρώτο αριθμό έως και την παύλα, όπως παρακάτω: 23456789
                    </p>


                    <TextField
                        className='!mt-5 !border-2 !outline-none !border-dashed !shadow-[inset_0px_6px_12px_rgba(0,0,0,0.3)]'
                        label=""
                        onChange={(e) => {

                            deh = e.target.value;
                        }}
                        type="number"
                        sx={{ padding: 0, borderRadius: '15px !important', }}

                    />
                    <div className='flex flex-col md:flex-row'>
                        <button className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={async () => {
                                const digits_only = string => [...string].every(c => '0123456789'.includes(c));
                                if (deh.length === 8 && digits_only(deh)) {
                                    console.log("Dei is: " + deh);

                                    var recycleData = {
                                        Deh: deh,
                                        ValidDeh: user.ValidDeh,
                                        BrownBin: user.BrownBin,
                                        BinNumber: user.BinNumber,
                                        RecyclableMaterials: user.RecyclableMaterials,
                                        PlasticBagNumber: user.PlasticBagNumber,
                                    };
                                    await updateSmartRecycleDetails({ citizenId: user.citizenId, data: recycleData })

                                    updateUserValues();

                                    unSetModal();

                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Ο αριθμός παροχής καταχωρήθηκε επιτυχώς!</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    )


                                }
                                else {
                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Ο Αριθμός Παροχής δεν είναι σωστός
                                            </div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    )
                                }
                            }}
                        >Καταχώρηση</button>


                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { unSetModal() }}
                        >Ακύρωση</div>
                    </div>
                </div>
            )
        }
        
        else if (municipality.MunicipalityId !== 380 && user.AuthyVerified === 0) {
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><RiCellphoneFill className='w-12 h-12 text-app_yellow'></RiCellphoneFill></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Επιβεβαίωσε τον αριθμό του κινητού σου</div>
                    <div className='font-bold text-lg text-center pt-5'>Για να ολοκληρωθεί η διαδικασία επιβεβαίωσης του προφίλ σου, καταχώρησε τον αριθμό του κινητού τηλεφώνου σου και πάτησε το κουμπί αποστολή για να λάβεις τον κωδικό ενεργοποίησης.</div>
                    <div className="flex flex-col md:flex-row justify-evenly w-full">
                        <Formik
                            onSubmit={handleFormSubmitPhone}
                            initialValues={initialValuesPhone}
                            validationSchema={validationSchemaPhone} className='flex flex-col pt-5 items-center space-y-3'>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='flex justify-center items-center'>
                                        <div>+30</div>
                                        <TextField
                                            className='group  w-40 !mt-1.5'
                                            size="small"
                                            type="tel"
                                            name="phone"
                                            label=""
                                            variant="standard"
                                            onBlur={handleBlur}
                                            value={values.phone}
                                            onChange={handleChange}
                                            helperText={touched.phone && errors.phone}
                                            error={Boolean(errors.phone && touched.phone)}
                                        />
                                    </div>
                                    <button type="submit" className='mt-3 flex w-40 justify-center cursor-pointer  text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                    //    onClick={}
                                    >Αποστολή</button>
                                </form>
                            )}
                        </Formik>
                        <Formik 
                            onSubmit={handleFormSubmitCode}
                            initialValues={initialValuesCode}
                            validationSchema={validationSchemaCode} className='flex flex-col pt-5 items-center space-y-3'>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='flex justify-center items-center'>
                                        
                                        <TextField
                                            className='group  w-40 !mt-0.5'
                                            placeholder='000000'
                                            size="small"
                                            type="text"
                                            name="code"
                                            label=""
                                            variant="standard"
                                            onBlur={handleBlur}
                                            value={values.code}
                                            onChange={handleChange}
                                            helperText={touched.code && errors.code}
                                            error={Boolean(errors.code && touched.code)}

                                        />
                                    </div>
                                    <button  type="submit" className=' mt-3 disabled:cursor-not-allowed disabled:bg-gray-300 flex w-40 justify-center cursor-pointer  text-center text-white font-semibold hover:text-white enabled:bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                       
                                    >Ενεργοποίηση</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            );
        }
        else if (user.points < offer.offer.OfferPoints) {
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Δυστυχώς έχετε μόλις {user.points} πόντους!</div>
                    <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                        onClick={() => { unSetModal() }}
                    >OK</div>
                </div>
            );
        }
        else {


            // console.log(citizenOffer)
            // downloadPDF(citizenOffer)
            // updateUserValues();
            //ON SUCCESS WE HAVE TO MAKE 
            // let transaction;

            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><FaQuestion className='w-12 h-12 text-app_yellow'></FaQuestion></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Επιβεβαίωση απόκτησης προσφοράς</div>
                    <div className='font-bold text-lg text-center pt-5'>Με την ολοκλήρωση της ενέργειας θα αφαιρεθούν ({offer.offer.OfferPoints}) πόντοι απο τον λογαριασμό σου.</div>
                    <div className="flex flex-col md:flex-row">
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={async () => {

                                unSetModal()
                                let citizenOffer = await addCitizenOffer({ citizenId: user.citizenId, offer: offer.offer, });
                                if (citizenOffer.transaction_result === 2) {
                                    updateUserValues();
                                    setModal(

                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_green rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCheckLine className='w-16 h-16 text-app_green'></RiCheckLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Επιτυχής απόκτηση προσφοράς. Το κουπόνι έχει εκδοθεί και έχει αποσταλεί στο email σου.</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    );

                                }
                                else {
                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Δεν υπάρχει διαθέσιμη προσφορά.</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    );
                                }

                            }}
                        >ΝΑΙ</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { unSetModal() }}
                        >ΟΧΙ</div>
                    </div>
                </div>
            );








        }


    }


    if (isLoading || isCitizenFavoritesLoading || !isFetched || isFetching || offer.offer === undefined)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError || isCitizenFavoritesError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA </p>
            </div>
        </div>

    return (
        <div className='flex flex-col min-h-screen w-full px-4 mx-auto lg:flex lg:px-8 xl:px-0 max-w-[1200px] mt-10 '>
            <div className='flex justify-between flex-col md:flex-row '>
                <div className='flex flex-col'>
                    <Link to={'./../../merchant/' + offer.merchant.Token} className='font-semibold text-2xl pb-2 w-full text-gray-600'> {offer.merchant.NameEl + " >"}</Link>
                    <div className='pb-5 w-full flex text-gray-600 '>
                        <FaMapMarkedAlt className='mr-1 mt-0.5 text-[20px]'></FaMapMarkedAlt>
                        <div className='font-semibold text-gray-600'>{offer.merchant.Address}</div>
                    </div>
                </div>
                <div className='relative flex'>
                    <img
                        className='object-fit w-64 h-20'
                        src={process.env.REACT_APP_BUCKET_URL + "/Content/css/assets/brush-stroke.png"}
                        alt=""
                    />
                    <div className='absolute top-2 left-20 drop-shadow-sm font-semibold text-white'>ημέρες που λήγει:</div>
                    <div className='absolute top-8 left-28 text-lg drop-shadow-sm font-semibold text-white'>{Math.floor((Date.parse(offer.offer.EndDate) - Date.now()) / 86400000)}</div>
                </div>
            </div>
            <div className='flex h-full flex-col md:flex-row justify-between w-full  md:space-x-8 shadow-xl overflow-hidden rounded-3xl '>

                <div className='flex'>


                    <div className='relative flex'>
                        <img
                            className='relative flex w-full object-cover rounded-tr-[30px] '
                            src={(offer.offer.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + offer.offer.Image}
                            alt='' />
                        <img className='absolute -right-[1px] !h-[102%]  top-8 ' src={seperator} alt="" />

                        <div className="absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 top-2 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                            <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-11 h-11 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{offer.offer.OfferPoints}</div>
                        </div>
                    </div>
                    {/* <div className='w-full text-white bg-gradient-to-r from-app_blue  to-app_blue_dark  font-sdemibold text-xl px-10 py-4 text-center'> {offer.offer.SubtitleEl}</div> */}


                </div>

                <div className='flex flex-col mx-auto p-5 w-full items-center   md:w-[45%] justify-center'>
                    <div className='font-semibold text-2xl'> {offer.offer.TitleEl}</div>
                    
                    <div className='flex heir-p:pb-3 text-lg p-8' dangerouslySetInnerHTML={{ __html: '<div>' + offer.offer.DescriptionEl + '</div>'}}>

                    {/* <div className='font-semibold pb-5 p-5 text-center'> {offer.offer.DescriptionEl} */}
                    </div>
                    
                    {!isAuthenticated
                        ?
                        <div className='justify-center flex whitespace-pre-line px-8 pb-10 cursor-pointer ' >
                            <div className='shadow-md p-5 bg-gradient-to-r from-[#DEEFFF] to-[#F4F7EB] rounded-3xl'>
                                <div className='text-gray-700 font-semibold text-center'>Απόκτησε αυτή την προσφορά, κάνοντας είσοδο. Εάν δεν είσαι ήδη μέλος κάνε τώρα εγγραφή και απόλαυσε την επιβράβευση.</div>
                                <div className='flex justify-center space-x-3'>
                                    <div className='justify-center flex whitespace-pre-line   cursor-pointer' onClick={() => {
                                        navigate('../../../account/login')
                                    }}>
                                        <div className='text-white  font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Είσοδος</div>
                                    </div>
                                    <div className='justify-center flex whitespace-pre-line   cursor-pointer' onClick={() => {
                                        navigate('../../../account/register')
                                    }}>
                                        <div className='text-white font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Εγγραφη</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        :
                        <div className='justify-center flex flex-col whitespace-pre-line pb-10  ' >
                            <div className='shadow-md p-3 px-5 bg-gradient-to-r from-[#DEEFFF] to-[#F4F7EB] rounded-3xl'>
                                <div className='text-gray-700 text-sm font-semibold text-center'>Μπορείς να αποκτήσεις άμεσα αυτή την προσφορά!</div>

                            </div>

                            <div className='flex justify-between items-baseline w-11/12'>
                                <div
                                    className="flex items-center justify-center bg-gray-200 rounded-full opacity-50 h-14 w-14"
                                    onClick={async () => {
                                        if (isAuthenticated) {

                                            await toggleFavoriteOffer({ citizenId: user.citizenId, offerId: offer.offer.OfferId, isFavorited: 1 })
                                                .then(() => {
                                                  //console.log('Added favorite');
                                                    setFavoritesClicked(!favoritesClicked)
                                                });

                                        }
                                    }}
                                >
                                    {
                                        checkIfFavorite(offer.offer.OfferId) === 1
                                            ? <FaHeart color='red' size={18}></FaHeart>
                                            :
                                            <FaHeart color='gray' size={18}></FaHeart>
                                    }
                                </div>
                                <button className={`m-5 ${isAuthenticated ? "bg-app_green_light opacity-100" : "bg-gray-500 opacity-30"}  rounded-full `}
                                    onClick={async () => {
                                        if (!isAuthenticated) {
                                            setModal(
                                                <div className='flex flex-col items-center justify-center py-5'>
                                                    <motion.div
                                                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                        transition={{
                                                            damping: 20,
                                                            duration: 0.35
                                                        }}
                                                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                                    </motion.div>
                                                    <div className='font-bold text-lg text-center pt-5'>Απόκτησε αυτή την προσφορά, κάνοντας είσοδο. Εάν δεν είσαι ήδη μέλος κάνε τώρα εγγραφή και απόλαυσε την επιβράβευση.</div>
                                                    <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                        onClick={() => { unSetModal() }}
                                                    >OK</div>
                                                </div>
                                            )
                                        }
                                        else {
                                            await handleρedemption(offer)
                                        }

                                    }}
                                >
                                    <p className="px-5 py-4 font-semibold text-white ">{'Αποκτηση'.toUpperCase()}</p>
                                </button>
                                <div className="flex items-center justify-center bg-gray-200 rounded-full opacity-50 h-14 w-14">
                                    <FaShare color='gray' size={18}></FaShare>
                                </div>
                            </div>

                        </div>
                    }

                </div>


            </div>
            <div className='flex flex-wrap justify-center w-full mt-10'>
                <div className='bg-gray-100 space-y-3 p-5 rounded-3xl'>
                    <div className="font-semibold">Όροι και Προϋποθέσεις</div>
                    <div className='flex heir-p:pb-3 p-8' dangerouslySetInnerHTML={{ __html: '<div>' + offer.offer.TermsEl + '</div>'}}>

{/* <div className='font-semibold pb-5 p-5 text-center'> {offer.offer.DescriptionEl} */}
</div>
                    {/* <div className='text-sm'> Η προσφορά θα πρέπει να εξαργυρωθεί εντός 21 ημερών από την απόκτησή της, με την επίδειξή του κουπονιού προσφοράς σε έντυπη ή ηλεκτρονική μορφή. Το κουπόνι μπορεί να εξαργυρωθεί μία φορά και μόνο εντός της επιχείρησης. Η προσφορά δεν μπορεί να εξαργυρωθεί σε συνδυασμό με άλλου είδους προσφορά, έκπτωση, προώθηση, δωροεπιταγή, κουπόνι και δεν μπορεί να εξαργυρωθεί με καταβολή μετρητών. Επιστροφή, αλλαγή ή αντικατάσταση της προσφοράς δεν είναι δυνατή. Οι φωτογραφίες είναι ενδεικτικές.</div> */}

                </div>
            </div>


        </div>
    )
}

export default SingleOffer