import { CircularProgress } from '@mui/material';
import React from 'react'
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { getNewsByUrl } from '../api';
import useMunicipality from '../hooks/useMunicipality';
import { formatDate } from '../utils/utils';
import parse from 'html-react-parser';

const SingleArticle = () => {

    // const article = useLocation().state.article;
    // const otherNews = useLocation().state.otherNews;

    const { municipality } = useMunicipality();

    const location = useLocation()

    const articleUrl = location.pathname.split('/')[4];

    //offer and merchant must be fetched
    const { isLoading, isError, data: news, } =
        useQuery(['singlearticle',], () => getNewsByUrl({ url: articleUrl,municipalityId: municipality.MunicipalityId   }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })

    //TOFO: Fix page starting at footer height
    // fix footer height

    if (isLoading)
    return <div
        className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

if (isError)
    return <div
        className='flex p-8 justify-center h-screen items-center'>
        <div className=' text-center'>

            <p className='texgt-md font-medium'>ERROR LOADING DATA </p>
        </div>
    </div>

    return (
        <div className='grid mx-auto grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-6 justify-center w-full md:w-[95%]  lg:w-[95%] max-w-[1200px] py-8 px-5 md:px-0  space-x-0 md:space-x-7 min-h-screen'>
            <div className='flex-col col-span-4   items-center  justify-center mb-5'>
                <div className='font-semibold text-lg md:text-xl lg:text-2xl pb-5 w-full'> {news.article.TitleEl}</div>

                <div className='relative'> <img
                    className='shadow-xl w-full rounded-t-xl'
                    src={(news.article.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + news.article.Image}
                    alt='' />
                </div>

                <div className='flex flex-col items-center w-full text-white bg-gradient-to-r from-app_blue  to-app_blue_dark   px-10 py-4 text-center'>
                    <div className='font-sdemibold text-xl'>{news.article.SubtitleEl}</div>
                    <div className='flex text-[15px] pt-1 italic'>Ημερομηνία δημοσίευσης: {formatDate(news.article.StartDate)}</div>
                </div>

                <div className='flex pt-3 heir-p:pb-3' dangerouslySetInnerHTML={{ __html: '<div>' + news.article.BodyEl + '</div>' }}></div>
                
            </div>

            <div className='flex-col  col-span-2  items-center  justify-center  divide-dashed'>
                <div className='font-semibold text-xl pb-5 w-full'>Διάβασε περισσότερα</div>
                {news.otherNews.map((readMoreArticle, index) => {
                    if (readMoreArticle.NewsId !== news.article.NewsId)
                        return <div className='flex  mb-8   justify-start place-items-start '>

                            <div className='flex w-32 h-20 relative'><img
                                className='object-cover shadow-lg h-full w-32 rounded-xl'
                                src={(readMoreArticle.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + readMoreArticle.Image}
                                alt='' />

                                <div className="absolute flex items-center justify-center  opacity-90 left-0 top-0 text-[12px] bg-app_green p-0.5 text-white">
                                    {/* <MdVideoCameraBack color='white' size={18}></MdVideoCameraBack> */}
                                    {formatDate(readMoreArticle.StartDate)}
                                </div>
                            </div>

                            <div className='flex flex-col _h-20 justify-between overflow-hidden'>
                                <div className='text-[12px] pl-2 font-semibold line-clamp-4 leading-4'>{readMoreArticle.TitleEl}</div>

                                <Link
                                    to={'../' + readMoreArticle.FriendlyUrl}
                                    state={{ article: readMoreArticle, otherNews: news.otherNews }}
                                    className='cursor-pointer pl-2 text-[12px] font-semibold'>{'Περισσότερα'}

                                </Link>
                            </div>
                        </div>
                    else return null
                })}
            </div>

        </div>
    )
}

export default SingleArticle