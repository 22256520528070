import React from 'react'


const termshtml = `<section class="become-a-volunteer">
<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="sec-title">
                <h1>
Πληροφορίες για Τοπικές Επιχειρήσεις                        
                </h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
<img class="img-responsive" style="float: right; height:300px; border:#d0d0d0 1px solid; margin-left:10px;" src="/images/image.jpg" alt="" border="#d0d0d0 1px solid;"><p>Είσαι Τοπική Επιχείρηση; Μάθε γιατί πρέπει να συμμετέχεις στο followgreen! Συμμετέχοντας στο followgreen επιβραβεύεις τους συμπολίτες σου και προβάλλεις την επιχείρησή σου! Είναι τόσο απλό!</p>
<p style="text-align: justify;">Μπορείς να συμμετέχεις ως τοπική επιχείρηση, <strong>παρέχοντας προνομιακά τα προϊόντα ή τις υπηρεσίες σου</strong> στα μέλη του προγράμματος. Εκπτώσεις και ειδικές προσφορές είναι μόνο μερικά από τα πλεονεκτήματα που μπορείς να προσφέρεις στα μέλη του followgreen του Δήμου σου.</p>
<p style="text-align: justify;">Για να εγγραφείς και να δηλώσεις τις προσφορές σου, συμπλήρωσε το Έντυπο συμμετοχής που θα βρεις <a href="/docs/form.pdf" target="_blank">ΕΔΩ.</a></p>
<p>&nbsp;</p>
<p style="text-align: justify;">Οι προσφορές που θα δηλώσεις, θα δημοσιευτούν στη σελίδα <strong>Εξαργύρωση πόντων</strong>, προβάλλοντας με αυτόν τον τρόπο την επιχείρησή σου.</p>
<p style="text-align: justify;">Δες το <a href="/docs/Followgreen_flyer.pdf" target="_blank">ενημερωτικό φυλλάδιο</a> του followgreen και εάν έχεις ερωτήσεις ή αν θες να μάθεις περισσότερα, στείλε μας e-mail στο <a href="mailto:offers@followgreen.gr">offers@followgreen.gr</a>.</p>
<p>&nbsp;</p>                
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
<h3 style="padding-bottom:10px">5 βασικά πράγματα που πρέπει να γνωρίζεις ως συνεργαζόμενη τοπική επιχείρηση</h3>
<ol style="margin-left: 9px;">
<li>Τα μέλη του προγράμματος θα ενημερώνονται για τις προσφορές που είναι διαθέσιμες στη σελίδα <strong>Εξαργύρωση πόντων</strong>.</li>
<li>Οι συγκεντρωμένοι πόντοι μπορούν να <strong>εξαργυρώνονται</strong>, έναντι <strong>κουπονιού προσφοράς</strong> για χρήση στις συνεργαζόμενες επιχειρήσεις.</li>
<li>Το κουπόνι προσφοράς θα πρέπει να <strong>εξαργυρώνεται εντός 21 ημερών</strong> από την ημερομηνία έκδοσής του.</li>
<li>Το κουπόνι προσφοράς θα <strong>εξαργυρώνεται μία μόνο φορά</strong> με την επίδειξή του σε έντυπη ή ηλεκτρονική μορφή. Η ταυτοποίησή του γίνεται μέσω του <strong>μοναδικού κωδικού</strong> που αναγράφεται σ’ αυτό.</li>
<li>Οι συνεργαζόμενες επιχειρήσεις έχουν δικαίωμα να ανακαλέσουν ή να μεταβάλουν τις προσφορές τους με ειδοποίηση μέσω email.</li>
</ol>
<p>&nbsp;</p>
<p>Περισσότερες πληροφορίες, περιλαμβάνονται στους <a href="/home/terms" target="_blank">Όρους χρήσης του προγράμματος</a>.</p>
<br><br>                              
        </div>
    </div>
</div>
</section>`;

const LocalBussinesses = () => {
  return (
    <div className='max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8'>

        
<div  className='max-w-none' dangerouslySetInnerHTML={{ __html: '<div>' + termshtml.replaceAll(`/images/`,process.env.REACT_APP_BUCKET_URL + `/images/`) }}></div>

    </div>
  )
}

export default LocalBussinesses