import {
    Link,
    useLocation
} from "react-router-dom";

import earnImg19 from '../assets/earn/19.jpg';
import earnImg20 from '../assets/earn/20.jpg';
import earnImg3 from '../assets/earn/3.jpg';
import ReactGA from "react-ga";


import { motion } from "framer-motion";
import { BsRecordCircleFill } from "react-icons/bs";
import { useQuery } from "react-query";
import useMunicipality from "../hooks/useMunicipality";
import { useState } from "react";
import { addSchoolTransaction, getAllContestSchools, getAllSchoolContests, getAllSchoolProjects, getAllSchools, getLastSchoolContest, sendOtp, verifyOtp } from "../api";
import { CircularProgress, Slider, TextField } from "@mui/material";
import { getImage } from "../utils/utils";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SchoolPieChart from "../components/SchoolsPieChart";
import useAuth from "../hooks/useAuth";
import { RiCellphoneFill, RiCloseLine } from "react-icons/ri";
import { useModal } from "../contexts/ModalContext";
import { TextValidator } from "react-material-ui-form-validator";
import { Check } from "@mui/icons-material";
import AppColors from "../constants/AppColors";
import { Formik } from "formik";
import * as Yup from 'yup';

const chartData = [

    {
        "id": "BalancePlastic",
        "label": "stylus",
        "value": null,
        "color": ""
    },
    {
        "id": "xarti",
        "label": "xarti",
        "value": 112,
        "color": ""
    },
    {
        "id": "c",
        "label": "c",
        "value": 69,
        "color": ""
    },
    {
        "id": "php",
        "label": "php",
        "value": 406,
        "color": "hsl(316, 70%, 50%)"
    }
]


const initialValuesPhone = {
    phone: '',

};

const initialValuesCode = {
    code: '',

};


var phone = null;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// form field validation schema
const validationSchemaPhone = Yup.object().shape({

    phone: Yup.string().min(10, 'Ελέγξτε αν ο αριθμός τηλεφώνου είναι στη σωστή μορφή').matches(phoneRegExp, 'Ελέγξτε αν ο αριθμός τηλεφώνου είναι στη σωστή μορφή'),
});

const validationSchemaCode = Yup.object().shape({

    code: Yup.string().min(5, 'Ελέγξτε αν ο αριθμός είναι στη σωστή μορφή'),
});


export default function SchoolsContest() {
    const { pathname } = useLocation();

    const { municipality } = useMunicipality();

    let { setModal, unSetModal } = useModal()
    const { setModal: setInputModal, unSetModal: unsetInputModal } = useModal()

    const [maxSchools, setMaxSchools] = useState(0);
    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);

    const [pointsAdded, setPointsAdded] = useState(0)

    const [filter, setFilter] = useState(0)

    const [isVerified, setIsVerified] = useState(false)



    const { isLoading, isError, error, data, isFetching, } =
        useQuery(['schools_contest', municipality, pointsAdded, filter],
            () =>
                getAllContestSchools({ municipalityId: municipality.MunicipalityId, filter: filter }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                // keepPreviousData: true
            })



    const handleFormSubmitPhone = async (values) => {
        //console.log(values)
        if (values.phone.length > 9) {
             await sendOtp({ phone: values.phone }).then((e) => {
                phone = values.phone
                if(e.data.status === 1){
                    unSetModal();
                    setModal(
                        <div className='flex flex-col items-center justify-center py-5'>
                            <motion.div
                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                transition={{
                                    damping: 20,
                                    duration: 0.35
                                }}
                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                            </motion.div>
                            <div className='font-bold text-lg text-center pt-5'>Έχετε ήδη χρησιμοποιήσει αυτόν τον αριθμό!
                            </div>
                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                onClick={() => { unSetModal() }}
                            >OK</div>
                        </div>
                    )
                }
                else if(e.data.status === 3)
                {
                    alert('Σας έχει αποσταλεί ο κωδικός επιβεβαίωσης.');
                }
                else
                {
                    unSetModal();
                    setModal(
                        <div className='flex flex-col items-center justify-center py-5'>
                            <motion.div
                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                transition={{
                                    damping: 20,
                                    duration: 0.35
                                }}
                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                            </motion.div>
                            <div className='font-bold text-lg text-center pt-5'>Ωχ Κάτι πήγε στραβά. Ενημερώθηκε ο διαχειριστής συστήματος.
                            </div>
                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                onClick={() => { unSetModal() }}
                            >OK</div>
                        </div>
                    )
                }
            })
            // 
            
            // 
            
        }

        // console.log("OTPSENT IS:",otpSent)
    }

    const handleFormSubmitCode = async (values) => {
        //console.log(phone)
        if (!phone)
            return


        const otpVerificationResonse = await verifyOtp({ phone: phone, code: values.code, citizenId: user.citizenId })
        if (otpVerificationResonse.data.status === 2) {
            updateUserValues()
            setIsVerified(true);
            unSetModal()
        }
        // setOtpSent(true)
    }


    const handleFilterChanges = (_filter) => {
        setFilter(_filter)
        // if (filter === 0) {
        //     schools = data.schools.filter(obj => {
        //         return obj.SchoolLevelTypeId <= 5;
        //     })
        // }
        // if (filter === 1) {
        //     schools = data.schools.filter(obj => {
        //         return obj.SchoolLevelTypeId ===1;
        //     })
        // }
        // if (filter === 2) {
        //     schools = data.schools.filter(obj => {
        //         return obj.SchoolLevelTypeId ===2;
        //     })
        // }
        // if (filter === 3) {
        //     schools = data.schools.filter(obj => {
        //         return obj.SchoolLevelTypeId ===3;
        //     })
        // }
        // if (filter === 4) {
        //     schools = data.schools.filter(obj => {
        //         return obj.SchoolLevelTypeId ===4;
        //     })
        // }

        // <option value="1">Δημοτικό</option>
        // <option value="2">Γυμνάσιο</option>
        // <option value="3" selected="">Λύκειο</option>
        // <option value="4">Νηπιαγωγείο</option></select>
    }




    const getProjectPointsBySchool = (schoollId) => {
        let points = 0
        for (let index = 0; index < data.projects.length; index++) {
            const element = data.projects[index];
            if (element.SchoolId === schoollId)
                points = element.BalancePoints;

        }

        return points;
    }

    const getProjectStatusBySchool = (schoollId) => {
        let status = false
        for (let index = 0; index < data.projects.length; index++) {
            const element = data.projects[index];
            if (element.SchoolId === schoollId)
                status = element.IsActive === 1 ? true : false;

        }

        return status;
    }


    const getProjectIdBySchoolId = (schoollId) => {
        let id = false
        for (let index = 0; index < data.projects.length; index++) {
            const element = data.projects[index];
            if (element.SchoolId === schoollId)
                id = element.SchoolProjectId;

        }

        return id;
    }


    const getProjectPointsIdBySchoolId = (schoolId) => {
        let points = 0;
        for (let index = 0; index < data.projects.length; index++) {
            const element = data.projects[index];
            if (element.SchoolId === schoolId)
                points = element.BalancePoints;

        }

        return points;
    }

    //first we need to get the last transaction of the current user

    // const [transaction, setTransaction] = useState({
    // SchoolProjectId: data.SchoolProjectId,
    // SchoolTransactionTypeId: 2,
    // TransactionDate: Date.now(),
    // MaterialId: 0,
    // ItemCount: 0,
    // TransactionPoints: 10,
    // CitizenId: user.citizenId,
    // });

    const handleDontation = async (points, schoolProjectId) => {
        if (user.points < points) {
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Δυστυχώς έχετε μόλις {user.points} πόντους για να δωρίσετε!</div>
                    <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                        onClick={() => { unSetModal() }}
                    >OK</div>
                </div>
            );
        }
        else {

            let transaction = await addSchoolTransaction(
                { citizenId: user.citizenId, points: points, additionalPoints: data.contest.AdditionalPoints, schoolProjectId: schoolProjectId });


            if (transaction.exeded > 0 && transaction.transaction_result === 2) {
                setModal(
                    <div className='flex flex-col items-center justify-center py-5'>
                        <motion.div
                            initial={{ rotate: 500, scale: 0, opacity: 0 }}
                            exit={{ rotate: 1, scale: 1, opacity: 0 }}

                            animate={{ rotate: 0, scale: 1, opacity: 1 }}
                            transition={{
                                damping: 20,
                                duration: 0.35
                            }}
                            className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                            <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                        </motion.div>
                        <div className='font-bold text-lg text-center pt-5'>Για αυτό το μήνα μπορείτε να δωρίσετε μέχρι ({points - transaction.exeded}) πόντους σε αυτό το σχολείο</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { unSetModal() }}
                        >OK</div>
                    </div>
                );
            }
            if (transaction.transaction_result === 1) {
                setModal(
                    <div className='flex flex-col items-center justify-center py-5'>
                        <motion.div
                            initial={{ rotate: 500, scale: 0, opacity: 0 }}
                            exit={{ rotate: 1, scale: 1, opacity: 0 }}

                            animate={{ rotate: 0, scale: 1, opacity: 1 }}
                            transition={{
                                damping: 20,
                                duration: 0.35
                            }}
                            className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                            <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                        </motion.div>
                        <div className='font-bold text-lg text-center pt-5'>Έχετε ξεπεράσει το όριο δωρεάς πόντων ({transaction.exeded}) για αυτό το μήνα.</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { unSetModal() }}
                        >OK</div>
                    </div>
                );
            }

            if (transaction.transaction_result === 3) {
                updateUserValues()
                setModal(
                    <div className='flex flex-col items-center justify-center py-5'>
                        <motion.div
                            initial={{ rotate: 500, scale: 0, opacity: 0 }}
                            exit={{ rotate: 1, scale: 1, opacity: 0 }}

                            animate={{ rotate: 0, scale: 1, opacity: 1 }}
                            transition={{
                                damping: 20,
                                duration: 0.35
                            }}
                            className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                            <div><Check className='w-20 h-20 text-app_green_light'></Check></div>
                        </motion.div>
                        <div className='font-bold text-lg text-center pt-5'>Ευχαριστούμε πολύ! Το υπόλοιπο πόντων στο λογαριασμό σας είναι ({transaction.exeded})</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => {
                                setPointsAdded(pointsAdded + 1)
                                unSetModal();
                            }}
                        >OK</div>
                    </div>
                );
            }



        }


    }

    const getProjectMaterialsBySchool = (schoollId) => {


        const _data = [];
        for (let index = 0; index < data.projects.length; index++) {
            const element = data.projects[index];
            if (element.SchoolId === schoollId) {
                if (element.BalancePlastic > 0) {
                    _data.push({
                        "id": "Πλαστικό",
                        "label": "Πλαστικό",
                        "value": element.BalancePlastic,
                        "color": "#ff8717ff"
                    });
                }
                if (element.BalanceBatteries > 0) {

                    _data.push({
                        "id": "Μπαταρίες",
                        "label": "Μπαταρίες",
                        "value": element.BalanceBatteries,
                        "color": "#9e9e9eff"
                    });
                }
                if (element.BalanceAluminium > 0) {

                    _data.push({
                        "id": "Αλουμίνιο",
                        "label": "Αλουμίνιο",
                        "value": element.BalanceAluminium,
                        "color": "#f53913ff"
                    });
                }
                if (element.BalancePaper > 0) {

                    _data.push({
                        "id": "Χαρτί",
                        "label": "Χαρτί",
                        "value": element.BalancePaper,
                        "color": "#ffd500ff"
                    });
                }
                if (element.BalanceWEEE > 0) {

                    _data.push({
                        "id": "AHHE",
                        "label": "AHHE",
                        "value": element.BalanceWEEE,
                        "color": "#dbdbdbff"
                    });
                }
                if (element.BalancePanOil > 0) {

                    _data.push({
                        "id": "Τηγανέλαιο",
                        "label": "Τηγανέλαιο",
                        "value": element.BalancePanOil,
                        "color": "hsl(316, 70%, 50%)"
                    });
                }

                if (element.BalanceCaps > 0) {

                    _data.push({
                        "id": "Καπάκια",
                        "label": "Καπάκια",
                        "value": element.BalanceCaps,
                        "color": "#ff6505ff"
                    });
                }
                if (element.BalanceOrganic > 0) {

                    _data.push({
                        "id": "Organic",
                        "label": "Organic",
                        "value": element.BalanceOrganic,
                        "color": "hsl(226, 70%, 50%)"
                    });
                }
                if (element.BalanceLamps > 0) {

                    _data.push({
                        "id": "Λαμπτήρες",
                        "label": "Λαμπτήρες",
                        "value": element.BalanceLamps,
                        "color": "hsl(206, 50%, 50%)"
                    });
                }
                if (element.BalanceGlass > 0) {

                    _data.push({
                        "id": "Ρούχα",
                        "label": "Ρούχα",
                        "value": element.BalanceGlass,
                        "color": "#fc8bd2ff"
                    });
                }


                //tiganelaio - ladi xroma



                var balance = element.BalancePoints;
                //console.log("BALANCE IS:::", balance);
                for (let i = 0; i < _data.length; i++) {


                    balance -= _data[i]['value'];
                    //console.log(balance);
                }

                if (balance > 0) {

                    _data.push({
                        "id": "Δωρεά",
                        "label": "Δωρεά",
                        "value": balance,
                        "color": "hsl(60, 70%, 80%)"
                    });
                }




                // BalanceGlass: 0



            }

        }


        //console.log(_data);
        return _data;
    }

    const cardVariants = {
        initial: {
            y: 300,
        },
        exit: {
            y: 0
        },
        animate: {
            y: 0,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0.6,
                duration: 1
            }
        }
    };



    //error handlers
    if (isLoading || isFetching)
        return <div className='flex justify-center items-center min-h-screen'><CircularProgress></CircularProgress></div>

    if (isError) {

        return <div className='flex p-8 justify-center min-h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA: {error.message} </p>
            </div>
        </div>
    }

    if (data.length < 1)
        return <div className='flex p-8 justify-center min-h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>No Data To Load</p>
            </div>
        </div>








    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <motion.div className="card-container"
                    variants={cardVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    viewport={{ once: true, amount: 0.8 }}>

                    <h2 className="pb-8 text-3xl font-bold text-gray-700">Ανακύκλωση στα σχολεία</h2>
                    <h2 className="pb-3 text-3xl font-semibold text-gray-700">{data.contest.TitleEl}</h2>
                    <h2 className="text-lg font-semibold text-gray-700">Ανακάλυψε τα σχολεία που συμμετέχουν στη δράση, δώρισε πόντους και ενίσχυσε την προσπάθεια των μαθητών.</h2>
                    <Link
                        to={'description'}
                        // state={{ offer: offer, citizenFavorites: citizenFavorites, merchant: getMerchant(offer.MerchantId) }}
                        className="flex mt-3 w-40 mb-6 shadow-lg shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                        <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
                            <p className='pl-1.5 pb-0.5'>{'περισσοτερα'.toUpperCase()}</p></div>
                    </Link>


                    <div className="grid grid-cols-2 gap-3 md:flex justify-center md:space-x-5 p-3 md:p-8">
                        <div
                            className={`${filter === 0 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white  cursor-pointer  px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(0) }}>'Ολα</div>
                        <div
                            className={`${filter === 4 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer  px-16 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(4) }}>Νηπιαγωγεία</div>
                        <div
                            className={`${filter === 1 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer   px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(1) }}>Δημοτικά</div>
                        <div
                            className={`${filter === 2 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer   px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(2) }}>Γυμνάσια</div>
                        <div
                            className={`${filter === 3 ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-50 text-gray-400 '}  hover:bg-white cursor-pointer  px-12 py-1 drop-shadow-lg w-16 flex justify-center font-semibold text-gray-500 rounded-full`}
                            onClick={() => { handleFilterChanges(3) }}>Λύκεια</div>


                    </div>

                </motion.div>
                {/* <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8"> */}
                <div className="grid grid-cols-1 mt-5 gap-y-5 gap-x-6 sm:grid-cols-1 lg:grid-cols-1 xl:gap-x-8">

                    {data.schools
                        .sort((a, b) => {

                            let aPoints = getProjectPointsIdBySchoolId(a.SchoolId);
                            let bPoints = getProjectPointsIdBySchoolId(b.SchoolId);

                            if (aPoints < bPoints) return 1
                            else return 0;



                        })
                        .map((school, index) => {

                            return <motion.div
                                variants={cardVariants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                viewport={{ once: true, amount: 0.8 }} key={school.SchoolId}
                                className="overflow-hidden border-8 border-white shadow-gray-300 m-5 shadow-xl  rounded-3xl">
                                <div className="flex flex-col lg:flex-row">

                                    <div className="w-full lg:w-2/5">
                                        <img
                                            src={(school.Image === null || school.Image.includes('ΦΩΤΟΓΡΑΦΙΑ ΣΧΟΛΕΙΟΥ') || (school.Image.includes('ΦΩΤΟ.png'))) ? getImage('/images/school/default.jpg') : getImage(school.Image)}
                                            alt=''
                                            loading="lazy"
                                            className="flex object-cover h-72 w-full  "
                                        // SetNews - anna
                                        />
                                    </div>


                                    <div className=" flex flex-col lg:flex-row  w-full lg:w-[70%] min-h-14 h-full content-between justify-between pl-3  opacity-90  ">
                                        <div className="p-3 w-full  lg:w-1/2 space-y-5 flex flex-col items-start justify-between">
                                            <div className=" font-semibold">
                                                {school.Name}
                                            </div>
                                            <div className="flex ">
                                                <div className="font-semibold">
                                                    {school.Purpose}
                                                </div>
                                            </div>
                                            <div className="font-semibold p-1">
                                                Αριθμός μαθητών: {school.StudentCount}
                                            </div>
                                            <button disabled={data.contest.Status === 3 ? false : true} className="disabled:bg-gray-200 disabled:drop-shadow-none disabled:shadow-none disabled:cursor-not-allowed flex bg-app_green px-2 py-1 text-white rounded-full cursor-pointer shadow-lg hover:shadow-2xl hover:drop-shadow-lg"
                                                onClick={async () => {
                                                    //ReactGA.event({category:"Διαγωνισμός: "+ municipality.NameEl, action : "Κλικ στην Δωρέα", label : user.name});
                                                    if (!isAuthenticated) {
                                                        setModal(
                                                            <div className='flex flex-col items-center justify-center py-5'>
                                                                <motion.div
                                                                    initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                                    exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                                    animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                                    transition={{
                                                                        damping: 20,
                                                                        duration: 0.35
                                                                    }}
                                                                    className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                                    <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                                                </motion.div>
                                                                <div className='font-bold text-lg text-center pt-5'>Κάνε εγγραφή ή συνδέσου για να δωρίσεις πόντους.</div>
                                                                <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                    onClick={() => { unSetModal() }}
                                                                >OK</div>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        var points = 0;

                                                        if (municipality.MunicipalityId === 384 && user.AuthyVerified === 0) {
                                                            setModal(
                                                                <div className='flex flex-col items-center justify-center py-5'>
                                                                    <motion.div
                                                                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                                        transition={{
                                                                            damping: 20,
                                                                            duration: 0.35
                                                                        }}
                                                                        className='border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center'>

                                                                        <div><RiCellphoneFill className='w-12 h-12 text-app_yellow'></RiCellphoneFill></div>
                                                                    </motion.div>
                                                                    <div className='font-bold text-lg text-center pt-5'>Επιβεβαίωσε τον αριθμό του κινητού σου</div>
                                                                    <div className='font-bold text-lg text-center pt-5'>Για να ολοκληρωθεί η διαδικασία επιβεβαίωσης του προφίλ σου, καταχώρησε τον αριθμό του κινητού τηλεφώνου σου και πάτησε το κουμπί αποστολή για να λάβεις τον κωδικό ενεργοποίησης.</div>
                                                                    <div className="flex flex-col md:flex-row justify-evenly w-full">
                                                                        <Formik
                                                                            onSubmit={handleFormSubmitPhone}
                                                                            initialValues={initialValuesPhone}
                                                                            validationSchema={validationSchemaPhone} className='flex flex-col pt-5 items-center space-y-3'>
                                                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                                                                <form onSubmit={handleSubmit}>
                                                                                    <div className='flex justify-center items-center'>
                                                                                        <div>+30</div>
                                                                                        <TextField
                                                                                            className='group  w-40 !mt-1.5'
                                                                                            size="small"
                                                                                            type="tel"
                                                                                            name="phone"
                                                                                            label=""
                                                                                            variant="standard"
                                                                                            onBlur={handleBlur}
                                                                                            value={values.phone}
                                                                                            onChange={handleChange}
                                                                                            helperText={touched.phone && errors.phone}
                                                                                            error={Boolean(errors.phone && touched.phone)}
                                                                                        />
                                                                                    </div>
                                                                                    <button type="submit" className='mt-3 flex w-40 justify-center cursor-pointer  text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                                    //    onClick={}
                                                                                    >Αποστολή</button>
                                                                                </form>

                                                                            )}
                                                                        </Formik>
                                                                        {/* <div>{"Staluhke"}</div> */}
                                                                        <Formik
                                                                            onSubmit={handleFormSubmitCode}
                                                                            initialValues={initialValuesCode}
                                                                            validationSchema={validationSchemaCode} className='flex flex-col pt-5 items-center space-y-3'>
                                                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                                                                <form onSubmit={handleSubmit}>
                                                                                    <div className='flex justify-center items-center'>

                                                                                        <TextField
                                                                                            className='group  w-40 !mt-0.5'
                                                                                            placeholder='000000'
                                                                                            size="small"
                                                                                            type="text"
                                                                                            name="code"
                                                                                            label=""
                                                                                            variant="standard"
                                                                                            onBlur={handleBlur}
                                                                                            value={values.code}
                                                                                            onChange={handleChange}
                                                                                            helperText={touched.code && errors.code}
                                                                                            error={Boolean(errors.code && touched.code)}

                                                                                        />
                                                                                    </div>
                                                                                    <button type="submit" className=' mt-3 disabled:cursor-not-allowed disabled:bg-gray-300 flex w-40 justify-center cursor-pointer  text-center text-white font-semibold hover:text-white enabled:bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'

                                                                                    >Ενεργοποίηση</button>
                                                                                </form>
                                                                            )}
                                                                        </Formik>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }

                                                        else {
                                                            setInputModal(
                                                                <div className='flex flex-col items-center justify-center py-5'>

                                                                    <div className='font-bold text-lg text-center pt-5'>Δήλωσε τους πόντους που θέλεις να δωρίσεις στο σχολείο</div>
                                                                    <div className='mb-2 mt-7'>Πόντοι:</div>
                                                                    <TextField
                                                                        className=''
                                                                        //label="NameEl"
                                                                        onChange={(event) => {
                                                                            event.persist()
                                                                            points = event.target.value;
                                                                        }}
                                                                        type="number"
                                                                        defaultValue={0}
                                                                    // value={points}

                                                                    />
                                                                    <div className="flex flex-col md:flex-row">
                                                                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                            onClick={async () => {

                                                                                await handleDontation(points, getProjectIdBySchoolId(school.SchoolId))
                                                                            }}
                                                                        >
                                                                            Καταχώρηση
                                                                        </div>
                                                                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_brown to-app_red  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                            onClick={() => { unsetInputModal() }}
                                                                        >
                                                                            Έξοδος
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        // await handleDontation(555120, getProjectIdBySchoolId(school.SchoolId))
                                                    }
                                                }}
                                            >
                                                <div className="font-semibold p-1">
                                                    ΔΩΡΕΑ ΠΟΝΤΩΝ
                                                </div>
                                            </button>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    <div className="text-md">Πόντοι/μαθητή:</div>
                                                    <div className=" border-app_green_light font-semibold  pl-1 text-lg">{((getProjectPointsBySchool(school.SchoolId)) / school.StudentCount).toFixed(2)}</div>
                                                </div>
                                                <div className="relative pt-1">
                                                    <div style={{ width: `${150}px` }} className="overflow-hidden  h-3 mb-4 text-xs flex rounded bg-orange-200">
                                                        <div style={{ width: `${(((getProjectPointsBySchool(school.SchoolId)) / school.StudentCount) / 7.5)}px` }} className="shadow-none  flex flex-col text-center whitespace-nowrap text-white justify-center bg-orange-500"></div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="relative flex justify-center items-center w-full  lg:w-[60%]  h-72">

                                            {/* <div>{lastContests.ProjectPoints / school.StudentCount}</div> */}
                                            <div className="absolute flex flex-col justify-center items-center  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                                                <div className="text-md font-semibold"> {((getProjectPointsBySchool(school.SchoolId))).toFixed(2)}</div>
                                                <div className="text-[13px]">πόντοι</div>
                                            </div>
                                            <SchoolPieChart data={getProjectMaterialsBySchool(school.SchoolId)}></SchoolPieChart>
                                        </div>


                                    </div>


                                </div>

                            </motion.div>
                        }
                            // }


                        )}


                </div>



            </div >


        </div >
    )
}