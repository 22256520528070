import * as React from 'react';

import NativeSelect from '@mui/material/NativeSelect';

import { useQuery } from 'react-query';

import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import { RiCommunityLine } from 'react-icons/ri';
import useMunicipality from '../hooks/useMunicipality';
import { getLiveMunicipalities, getMunicipalityByLocality } from '../api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


const MunicipalitySelectWidget = () => {


    const { isLoading, isError, error, data } = useQuery('live_municipalities', () => getLiveMunicipalities(), {
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    })

    const navigate = useNavigate()
    const location = useLocation()

    const { municipality, init, isInitialised } = useMunicipality();

    function setMunicipalityByUrl() {
        if ((municipality.LocalityEn !== location.pathname.split('/')[1])) {
          //console.log("CHANGED MUNICIPALITY......0000", location.pathname.split('/')[1])
          //console.log("CHANGED MUNICIPALITY......1111", municipality.LocalityEn)
            getMunicipalityByLocality(location.pathname.split('/')[1]).then((m) => {
                if (m.Registered === 1) {
                    const newMunicipality = {
                        NameEl: m.NameEl,
                        MunicipalityId: m.MunicipalityId,
                        CenterLatitude: m.CenterLatitude,
                        CenterLongitude: m.CenterLongitude,
                        LocalityEn: m.LocalityEn,
                        RecycleBucketText:m.RecycleBucketText,
                        IsGreenSchool:m.IsGreenSchool,
                        MobileEnabled: m.MobileEnabled,
                    }
                    init(newMunicipality);
                }
            })
        }
    }

    useEffect(() => {
        setMunicipalityByUrl()
    }, [location]);

    if (isLoading)return <CircularProgress></CircularProgress>
    if (isError) return <CircularProgress></CircularProgress>
    if (data.length < 1) return <CircularProgress></CircularProgress>

    return (

        <div className='flex justify-center align-middle items-center'>
          {  process.env.NODE_ENV === "production" ? <div className='border-2 border-gray-500 p-1 px-3 rounded-3xl'>{municipality.NameEl}</div>
          :
            <FormControl className='!outline-0 !border-0'>
             
                <Select

                    className="w-full  m-2 !rounded-2xl h-10 "
                    //  variant='standard'
                    //  disabled={true}
                    defaultValue={municipality.MunicipalityId}
                    onChange={
                        (e) => {

                            //For dev only


                            const val = e.target.value;

                            var result = data.rows.filter(obj => {
                                return obj.MunicipalityId === parseInt(val)
                            })

                            const newMunicipality = {
                                NameEl: result[0].NameEl,
                                MunicipalityId: result[0].MunicipalityId,
                                CenterLatitude: result[0].CenterLatitude,
                                CenterLongitude: result[0].CenterLongitude,
                                LocalityEn: result[0].LocalityEn,
                                RecycleBucketText:result[0].RecycleBucketText,
                                 IsGreenSchool :result[0].IsGreenSchool,
                                 MobileEnabled: result[0].MobileEnabled,

                            }
                            // console.log(newMunicipality)
                            let splittedPath = location.pathname.split('/')
                            let newPath = '';
                            for (let index = 0; index < splittedPath.length; index++) {
                                const element = splittedPath[index].replace('/', '');

                                if (index === 1)
                                    newPath += (result[0].LocalityEn);
                                else if (index === (splittedPath.length < 1))
                                    newPath += (element);
                                else
                                    newPath += (element);

                                if (newPath[newPath.length - 1] !== '/')
                                    newPath += '/'
                            }

                            if (newPath[newPath.length -1] === '/')
                            newPath =  newPath.substring(0, newPath.length - 1)
                            
                            navigate(newPath,{ replace: true })
                            init(newMunicipality);
                        }
                    }

                >
                    {
                        data.rows.map((element, index) => {
                            if (element.Registered === 1)
                                return (<MenuItem key={index} value={element.MunicipalityId}>
                                    {element.NameEl}
                                </MenuItem>)
                        })

                    }


                </Select>
            </FormControl>}
        </div>
    )
}

export default MunicipalitySelectWidget