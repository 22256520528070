import { Edit } from "@mui/icons-material";
import { Card, Grid, styled, useTheme, Divider, CircularProgress } from "@mui/material";

import axios from "axios";
import { Fragment, useState } from "react";
import { getUploadUrl } from "../../api";

const AvatarUploadField = ({ imageHandler, currentImage, uploadFolder, userid }) => {
    const [file, setFile] = useState(currentImage);
    const [isUploading, setIsUploading] = useState(false)
  //console.log("FILE: " + file)
    const handleUpload = async (file) => {
        setIsUploading(true)
        try {

            const dt = await getUploadUrl({
                "fileName": userid+'_'+file.name,
                "fileType": file.type,
                "folder": 'images/profileimages',
            })

            await axios({
                method: "put",
                url: dt,
                data: file,
                headers: {
                    "Content-Type": file.type,
                    "x-amz-acl": "public-read",
                },
            }).then((res) => {
              //console.log("RESPONSE: ", res);
                const fileUrl =
                process.env.REACT_APP_BUCKET_URL+ "/images/profileimages/" +
                userid+'_'+file.name;
                setFile(fileUrl)
                imageHandler(fileUrl)
              //console.log(fileUrl);
            });
        } catch (err) {
          //console.log(err);
            setIsUploading(false)
        }
        setIsUploading(false)
    }

    if (isUploading) {
        return (
            <div className="relative">
                <div class="absolute z-50 top-2 left-2 flex items-center justify-center bg-opacity-100 rounded-full shadow-md opacity-100 w-10 h-10 bg-gray-400 cursor-pointer ">
                    
                <CircularProgress className="absolute flex items-center justify-center w-10 h-10 text-xl font-bold rounded-full shadow-md cursor-pointer right-0 top-0 bg-amber-400 text-neutral-100 hover:bg-red-400"></CircularProgress>

                </div>
            </div>)
    }

    return (
        <div className="relative">

            <div className="absolute z-50 top-2 left-2 flex items-center justify-center bg-opacity-100 rounded-full shadow-md opacity-100 w-10 h-10 bg-gray-400 cursor-pointer">
                <label
                    for="imageInput"
                    class="flex "
                >

                    <Edit className="flex  z-50"></Edit>

                    <input
                        className="hidden"
                        id="imageInput"
                        type="file"
                        accept=".jpeg, .jpg, .png"
                        onChange={async (e) => {
                            handleUpload(e.target.files[0]);
                        }}
                    ></input>
                </label>
            </div>
            
        </div>



    );
};

export default AvatarUploadField;
