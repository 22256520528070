import React from 'react'

const GreenBanner = ({isGreenSchool = 0}) => {
    return (
        <div className='flex justify-center w-full  bg-gradient-to-b from-app_green_light to-app_dark_green'>
            <div className='grid px-40 justify-center items-center place-content-center place-items-center space-x-1 grid-cols-1  lg:grid-cols-4 gap-3'>
                <div className='text-white text-center font-bold text-3xl '>Ανακύκλωσε <br>
                  </br>
                   { isGreenSchool ===1 ? 'Μάθε. Δώρισε!': 'Μάθε. Κέρδισε!'}</div>
                <div className='flex flex-col p-2 w-56 justify-center items-center h-44'>
                        <img className="w-24 h-24" alt="app store badge" src={process.env.REACT_APP_BUCKET_URL + "/Content/css/assets/3.png"} />    
                    <div className='flex justify-center text-center font-semibold text-white text-sm'>
                        { isGreenSchool ===1 ? 'Κερδίζεις πόντους όταν ενημερώνεσαι': 'Κερδίζεις πόντους  όταν ανακυκλώνεις'} </div>
                </div>
                {isGreenSchool === 1 ? null : <div className='flex flex-col p-2 w-56 justify-center items-center h-44'>
                        <img className="w-24 h-24" alt="app store badge" src={process.env.REACT_APP_BUCKET_URL + "/Content/css/assets/1.png"} /> 
                    <div className='flex justify-center text-center font-semibold text-white text-sm'>Εξαργυρώνεις πόντους
                        στις τοπικές επιχειρήσεις </div>
                </div>}
                <div className='flex flex-col p-2 w-56 justify-center items-center h-44'>
                        <img className="w-24 h-24" alt="app store badge" src={process.env.REACT_APP_BUCKET_URL + "/Content/css/assets/2.png"} /> 
                    <div className='flex justify-center text-center font-semibold text-white text-sm'>Δωρίζεις πόντους
                        σε σχολικές δράσεις </div>

                </div>
            </div>
        </div>
    )
}

export default GreenBanner