import {
  Link,
  useLocation
} from "react-router-dom";

import earnImg19 from '../assets/earn/19.jpg';
import earnImg20 from '../assets/earn/20.jpg';
import earnImg3 from '../assets/earn/3.jpg';


import { motion } from "framer-motion";
import { BsRecordCircleFill } from "react-icons/bs";
import { useQuery } from "react-query";
import useMunicipality from "../hooks/useMunicipality";
import { useState } from "react";
import { addAndPrintCoupons, addSchoolTransaction, getAllContestSchools, getAllMerchants, getAllSchoolContests, getAllSchoolProjects, getAllSchools, getLastSchoolContest, geTokensbyPublicOrganization } from "../api";
import { CircularProgress, Slider, TextField } from "@mui/material";
import { getImage } from "../utils/utils";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SchoolPieChart from "../components/SchoolsPieChart";
import useAuth from "../hooks/useAuth";
import { RiCloseLine } from "react-icons/ri";
import { useModal } from "../contexts/ModalContext";
import { TextValidator } from "react-material-ui-form-validator";
import { Check } from "@mui/icons-material";




export default function PublicOrganizationView() {

 const [updateTokens,setUpdateTokens] = useState(0);
  const { municipality } = useMunicipality();



  const publicorganization = useLocation().state.publicorganization;
  // const tokens = useLocation().state.tokens;

  const { isLoading, isError, error, data: tokens, isFetching,  } =
  useQuery(['potokens',updateTokens],
      () =>
      geTokensbyPublicOrganization({ publicOrganizationId: publicorganization.PublicOrganizationId}),
      {
          refetchOnWindowFocus: false,
          refetchIntervalInBackground: false,

      })
  // const project = useLocation().state.project;

  // const [data, setData] = useState(project);


  function getRedeemedCoupons() {
    let redeemed = [];
    for (let index = 0; index < tokens.length; index++) {

      const element = tokens[index];
      if (element.RedemptionDate != null) {
        redeemed.push(element)
      }
    }
    return redeemed;
  }












  const cardVariants = {
    initial: {
      y: 300,
    },
    exit: {
      y: 0
    },
    animate: {
      y: 0,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1
      }
    }
  };





  //error handlers
  if (isLoading || isFetching)
      return <div className='flex justify-center items-center min-h-screen'><CircularProgress></CircularProgress></div>

  if (isError) {

      return <div className='flex p-8 justify-center min-h-screen items-center'>
          <div className=' text-center'>

              <p className='texgt-md font-medium'>ERROR LOADING DATA: {error.message} </p>
          </div>
      </div>
  }

  // if (tokens.length < 1)
  //     return <div className='flex p-8 justify-center min-h-screen items-center'>
  //         <div className=' text-center'>

  //             <p className='texgt-md font-medium'>No Data To Load</p>
  //         </div>
  //     </div>








  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
        <motion.div className="card-container"
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          viewport={{ once: true, amount: 0.8 }}>

          <h2 className="pb-8 text-3xl font-bold text-gray-700">{publicorganization.NameEl}</h2>





        </motion.div>
        {/* <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8"> */}
        <div className="grid grid-cols-1 mt-5 gap-y-5 gap-x-6 sm:grid-cols-1 lg:grid-cols-1 xl:gap-x-8">

          <motion.div
            variants={cardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            viewport={{ once: true, amount: 0.8 }} key={publicorganization.SchoolId}
            className="overflow-hidden border-8 border-white shadow-gray-300 m-5 shadow-xl  rounded-3xl">
            <div className="flex flex-col lg:flex-row">




              <div className=" flex flex-col lg:flex-row  w-full  min-h-14 h-full  pl-3  ">
                <div className="p-3 w-full   space-y-5 flex flex-col items-start justify-between">
                  <div className=" font-semibold">
                    {publicorganization.NameEl}
                  </div>
                  <div className="flex ">
                    <div className="font-semibold text-lg">
                      {publicorganization.AddressEl}
                    </div>
                  </div>
                  <div className="flex space-x-5">
                    <div className="font-semibold">
                      Συνολικά κουπόνια: {tokens.length}
                    </div>
                    <div className="font-semibold">
                      Εξαργυρωμένα κουπόνια: {getRedeemedCoupons().length}
                    </div>
                    <div className="font-semibold">
                      Διαθέσημα κουπόνια: {tokens.length - getRedeemedCoupons().length}
                    </div>
                  </div>

                  <div className="w-32 text-center  bg-app_green cursor-pointer h-12 text-white"
                  onClick={async () => {
                    console.log("Clicked");
                    let pdf = await addAndPrintCoupons({
                      municipalityName: municipality.NameEl,
                      publicOrganizationName: publicorganization.NameEl,
                      publicOrganizationAddress:
                      publicorganization.AddressEl,
                      publicOrganizationEmail: publicorganization.Email,
                      cuponsToCreate: 6,
                      publicOrganizationId: publicorganization.PublicOrganizationId
                    });

                    //  let pdf = await getOfferPdf(
                    //   { citizenOfferId: _offer.CitizenOfferId, offerId: offer.OfferId, citizenId: user.citizenId })
                    const file = new Blob(
                      [pdf],
                      { type: 'application/pdf' }
                    );
                    //console.log(pdf)

                    const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                    window.open(fileURL, "_blank");
                    setUpdateTokens(updateTokens+1);

                  }}
                >
                  Εκτύπωση: 6 κουπόνια
                </div>




                <div className="w-32 text-center  bg-app_green cursor-pointer h-12 text-white"
                  onClick={async () => {
                    console.log("Clicked");
                    let pdf = await addAndPrintCoupons({
                      municipalityName: municipality.NameEl,
                      publicOrganizationName: publicorganization.NameEl,
                      publicOrganizationAddress:
                      publicorganization.AddressEl,
                      publicOrganizationEmail: publicorganization.Email,
                      cuponsToCreate: 12,
                      publicOrganizationId: publicorganization.PublicOrganizationId
                    });

                    //  let pdf = await getOfferPdf(
                    //   { citizenOfferId: _offer.CitizenOfferId, offerId: offer.OfferId, citizenId: user.citizenId })
                    const file = new Blob(
                      [pdf],
                      { type: 'application/pdf' }
                    );
                    //console.log(pdf)

                    const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                    window.open(fileURL, "_blank");
                    setUpdateTokens(updateTokens+1);

                  }}
                >
                  Εκτύπωση: 12 κουπόνια
                </div>



                <div className="w-32 text-center  bg-app_green cursor-pointer h-12 text-white"
                  onClick={async () => {
                    console.log("Clicked");
                    let pdf = await addAndPrintCoupons({
                      municipalityName: municipality.NameEl,
                      publicOrganizationName: publicorganization.NameEl,
                      publicOrganizationAddress:
                      publicorganization.AddressEl,
                      publicOrganizationEmail: publicorganization.Email,
                      cuponsToCreate: 24,
                      publicOrganizationId: publicorganization.PublicOrganizationId
                    });

                    //  let pdf = await getOfferPdf(
                    //   { citizenOfferId: _offer.CitizenOfferId, offerId: offer.OfferId, citizenId: user.citizenId })
                    const file = new Blob(
                      [pdf],
                      { type: 'application/pdf' }
                    );
                    //console.log(pdf)

                    const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                    window.open(fileURL, "_blank");
                    setUpdateTokens(updateTokens+1);

                  }}
                >
                  Εκτύπωση: 24 κουπόνια
                </div>














                </div>
               


              </div>


            </div>


            <div className='grid grid-cols-4 mt-10'>
            
              {/* {pointsData.map((value, index) => {
          if (index < pointsData.length - 1)
            return (<div className='bg-white mt-3 mb-3 m-3 shadow-md rounded-2xl overflow-hidden'>
              <div className=' bg-white border-b-[2px] border-0 border-pink-200 border-dashed overflow-hidden'>
                <div className='p-2 text-center  font-semibold  text-gray-500 '>{value.name}</div>
              </div>
              <div className='p-3 text-center text-lg font-semibold text-white bg-blue-300'>
                <div>{value.data}</div>
              </div>
            </div>)
        })} */}
            </div>



          </motion.div>


        </div>



      </div >


    </div >
  )
}