import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { ArrowBackIos, ArrowRight, ArrowRightAlt, ArrowRightAltTwoTone, TextFieldsTwoTone, Visibility, VisibilityOff } from '@mui/icons-material';
// import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, Grid, InputAdornment, TextareaAutosize, TextField } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
// import { Paragraph } from 'app/components/Typography';
import useAuth from '../hooks/useAuth';
import { Formik, replace } from 'formik';
import { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '../components/Loading';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import { useMutation, useQueryClient } from 'react-query';
import { addCitizenTransaction, addUserPost } from '../api';
import { transactionType } from '../utils/TransactionType';
import { addTransaction } from '../services/transactionService';


const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));



// inital login credentials
const initialValues = {
    title: '',
    description: '',
    // remember: true,
};



// form field validation schema
const validationSchema = Yup.object().shape({
    title: Yup.string()
        // .min(6, 'Password must be 6 character length')
        .required('Το πεδίο Τίτλος είναι υποχρεωτικό'),
    description: Yup.string().required('Το πεδίο Περιγραφή είναι υποχρεωτικό'),
});

const UserPost = () => {
    const breadcrumbs = useBreadcrumbs();
    const theme = useTheme();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isWrong, setIsWrong] = useState(false);
    const [showpass, setShowpass] = useState(false);

    const { login, user, updateUserValues} = useAuth();



    const queryClient = useQueryClient();
    const queryTitle = 'schoolcontests';

    const transactionType = 11;

    const handleFormSubmit = async (values) => {
        // setData({ ...data, TitleEl: values.title, Body: values.description});
        let data = {
            CitizenId: user.citizenId,
            TitleEl: values.title,
            Body: values.description,
            ImageAttachment: null,
            VideoAttachment: null,
            FileAttachment: null,
            SuggestedPostStatusId: 1,
            IsActive: 1,
        }

        let transaction = {

            CitizenId: user.citizenId,
            TransactionDate: Date.now(),
            TransactionPoints: 50,
            TransactionTypeId: transactionType,

        }

        

        setLoading(true);
        try {

          //console.log(data)
          //console.log(user.citizenId)

            await addUserPost(data).then((d => {
            //console.log("USER POST ADDED: ",d)
            }))          

            await addTransaction( {citizenId:user.citizenId,points: 50,transactionType:transactionType})
              updateUserValues()
            setIsWrong(false)
            setLoading(false);
            navigate(user.localityEn);
        } catch (e) {
          //console.log("ERROR CREDENTIALS")
            setIsWrong(true)
            setLoading(false);
        }
    };



    return (



        < motion.div

            initial={
                { x: 300, }
            }
            animate={
                {
                    x: 0,
                    transition: {
                        type: "spring",
                        bounce: 0.6,
                        duration: 1
                    }
                }
            }
            exit={
                { x: 300, }
            }
            // viewport={{ once: true, amount: 0.8 }}
            className={`relative bg-white flex justify-center h-screen w-full p-10`} >

            <div className="z-10 !shadow-2xl w-2/3 rounded-3xl  bg-white overflow-hidden flex flex-col items-center">
                <div className='flex flex-col justify-center items-center '>
                    <div className='pt-10 font-semibold text-xl text-gray-400'>Συμπλήρωσε τη φόρμα για να υποβάλεις το άρθρο σου</div>
                    <div className="bg-gradient-to-r w-72 mt-1 h-2 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>
                    <div className='pt-10 font-semibold text-gray-400'>Συμπλήρωσε τον τίτλο του άρθρου, γράψε μια περιγραφή και επισύναψε τα αρχεία που θέλεις.</div>
                </div>

                {isWrong ? <div className='pt-5 text-red-400'>Λάθος email ή κωδικός πρόσβασης</div> : null}

                <div className='w-full p-5' >
                    <div className='flex'>
                        <Formik
                            enableReinitialize
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        // enableReinitialize={false}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form className='w-full' onSubmit={handleSubmit}>

                                    <TextField
                                        className='group !group:border-red-300 w-full'


                                        type="text"
                                        name="title"
                                        label="Τίτλος"
                                        // variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.title}
                                        onChange={handleChange}
                                        helperText={touched.title && errors.title}
                                        error={Boolean(errors.title && touched.title)}
                                        sx={{
                                            mb: 3,
                                            // width: '100% !important',
                                            // "& .MuiInputBase-root .MuiOutlinedInput-root": {
                                            //     width: '100% !important',
                                            // }
                                        }}
                                    />





                                    <TextField
                                        className='group !group:border-red-300'
                                        fullWidth
                                        // size="description"
                                        // type="description"
                                        name="description"
                                        label="Περιγραφή"
                                        placeholder='Περιγραφή'
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.description}
                                        multiline
                                        rows={10}

                                        onChange={handleChange}
                                        helperText={touched.description && errors.description}
                                        error={Boolean(errors.description && touched.description)}
                                        sx={{
                                            mb: 3,
                                            // "& .MuiOutlinedInput-notchedOutline": {
                                            //     borderColor: "#F19853"
                                            // }
                                        }}
                                    />





                                    <LoadingButton
                                        className='!bg-gradient-to-r from-app_yellow to-app_brown !shadow-orange-300 !shadow-xl !drop-shadow-none'
                                        type="submit"

                                        loading={loading}
                                        variant="contained"
                                        sx={{ my: 2 }}
                                    >
                                        <div className='capitalize pr-2'> Αποστολή πρότασης</div>
                                        <ArrowRightAlt></ArrowRightAlt>
                                    </LoadingButton>


                                </form>
                            )}
                        </Formik>


                    </div>
                </div>

            </div>
        </ motion.div>



    )
}

export default UserPost