import axios from "axios";

const localUrl = process.env.REACT_APP_LOCAL_URL;
//const localUrl = 'https://urchin-app-n63ko.ondigitalocean.app';
const productionUrl = process.env.REACT_APP_PRODUCTION_URL;
// const productionUrl = "https://urchin-app-n63ko.ondigitalocean.app";

//******************************/
//Api Config
//******************************/
export const setToken = (token) => {
  API.interceptors.request.use(async (req) => {
    //   if (localStorage.getItem('profile')) {
    //     req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    //   }
    //const token = setToken

    const config = {
      "Content-type": "application/json",
      "u-auth-token": token,
    };

    req.headers = config;

    return req;
  });
};

const API = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? productionUrl : localUrl,
});
export const getUploadUrl = async (data) => {
  const response = await API.post(`/api/getuploadurl`, data);
  return response.data;
};

//******************************/
//Auth Api Requests//
//******************************/

//Auth Api Requests
// export const registerUser = () => API.post(`/api/auth`)
export const authUser = (data) => API.post(`/api/auth/login`, data);
export const registerUser = (data) => API.post(`/api/auth/register`, data);
export const confirmEmail = (data) => API.post(`/api/auth/confirmemail`, data);
export const resetPasswordEmailRequest = (data) => API.post(`/api/auth/resetpasswordemailrequest`, data);
export const resetPasswordEmailConfirm = (data) => API.post(`/api/auth/resetpasswordconfirm`, data);
export const sendContactEmail = (data) => API.post(`/api/sendcontactemail`, data);
export const sendOtp = (data) => API.post(`/api/auth/sendotp`, data);
export const verifyOtp = (data) => API.post(`/api/auth/verifyotp`, data);
export const changePassword = (data) => API.post(`/api/auth/changepassword`, data);
// export const updatePassword = (data) => API.post(`/api/auth/updatepass`, data)
// export const updateUserData = (data) => API.post(`/api/users/update`, data)
// export const getUploadUrl = async (data) => {
//   const response = await API.post(`/api/getuploadurl`, data);
//   return response.data;
// };

////////////////////////////////
//Municipality Api Requests
////////////////////////////////
export const getLiveMunicipalities = async () => {
  const response = await API.get(`/api/municipality/live`);

  return response.data;
};

export const getOfflineMunicipalities = async () => {
  const response = await API.get(`/api/municipality/offline`);

  return response.data;
};

export const getMunicipalityByLocality = async (locality) => {
  const response = await API.get(
    `/api/municipality/locality?locality=${locality}`
  );
  return response.data;
};

export const getAllMunicipalities = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
}) => {
  const response = await API.get(
    `/api/municipality?page=${page.toString()}&limit=${limit.toString()}&optionalKeyword=${optionalKeyword}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//News Api Requests
////////////////////////////////
export const getAllNews = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/news?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  return response.data;
};

export const getNewsByUrl = async ({
  municipalityId = 0,
  url = null,
}) => {
  const response = await API.get(
    `/api/news/getbyurl?url=${url}&municipalityId=${municipalityId}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Blog Api Requests
////////////////////////////////
export const getAllBlogs = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/blog/earningslist?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
//console.log(response.error);
  return response.data;
};

export const getBlogByUrl = async ({
  municipalityId = 0,
  url = null,
}) => {
  const response = await API.get(
    `/api/blog/getbyurl?url=${url}&municipalityId=${municipalityId}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Quiz Api Requests
////////////////////////////////
export const getAllQuizes = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/quiz/earningslist?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
//console.log("QIOZZZZ CALLL");
  return response.data;
};

export const getCompleteQuiz = async ({ quizId = 0 }) => {
  const response = await API.get(`/api/quiz/getcompletequiz?quizId=${quizId}`);

  return response.data;
};


export const getQuizByUrl = async ({
  municipalityId = 0,
  url = null,
}) => {
  const response = await API.get(
    `/api/quiz/getbyurl?url=${url}&municipalityId=${municipalityId}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Videos Api Requests
////////////////////////////////
export const getAllVideos = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/videos/earningslist?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
//console.log(response.error);
  return response.data;
};


export const getVideoByUrl = async ({
  municipalityId = 0,
  url = null,
}) => {
  const response = await API.get(
    `/api/videos/getbyurl?url=${url}&municipalityId=${municipalityId}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Offers Api Requests
////////////////////////////////
export const getAllOffers = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = null,
}) => {
  const response = await API.get(
    `/api/offers/earningslist?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
//console.log(response.error);
  return response.data;
};


export const getAllFeaturedOffers = async ({

  municipalityId = null,
}) => {
  const response = await API.get(
    `/api/offers/featured?municipalityId=${municipalityId}`
  );
//console.log(response.error);
  return response.data;
};

export const getOfferByUrl = async ({

  url = null,
}) => {
  const response = await API.get(
    `/api/offers/getbyurl?url=${url}`
  );
//console.log(response.error);
  return response.data;
};






export const getOfferPdf = async ({
  citizenOfferId = null,
  offerId = null,
  citizenId = null,
}) => {
  const response = await API.get(

    `/api/offers/getpdf?citizenOfferId=${citizenOfferId}&offerId=${offerId}&citizenId=${citizenId}`,{
      responseType: "blob",
    }
  );
//console.log(response.error);
  return response.data;
};


////////////////////////////////
//OfferTypes Api Requests
////////////////////////////////
export const getAllOfferTypes = async () => {
  const response = await API.get(`/api/offertypes`);
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Merchants Api Requests
////////////////////////////////
export const getAllMerchants = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/merchants?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
//console.log(response.error);
  return response.data;
};

export const getMerchantByUrl = async ({

  url = null,
}) => {
  const response = await API.get(
    `/api/merchants/getbyurl?url=${url}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Lookup Api Requests
////////////////////////////////
export const getLookupsByType = async ({ lookupType }) => {
  const response = await API.get(
    `/api/lookup?lookupType=${lookupType.toString()}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Recycle Buckets Api Requests
////////////////////////////////
export const getAllRecycleBuckets = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/recyclebuckets?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
//console.log(response.data);
  return response.data;
};

////////////////////////////////
//User Posts Api Requests
export const addUserPost = async (data) => {
  const response = await API.post(`/api/userposts?`, data);
  return response.data;
};

////////////////////////////////
//CitizenTransactions Api Requests
////////////////////////////////
export const getCitizenTransactions = async ({ citizenId }) => {
  const response = await API.get(
    `/api/citizentransactions?citizenId=${citizenId}`
  );
//console.log(response.error);
  return response.data;
};

export const checkIfEarned = async ({ citizenId, earnId, transactionType }) => {
  const response = await API.get(
    `/api/citizentransactions/checkifearned?citizenId=${citizenId}&earnId=${earnId}&transactionType=${transactionType}`
  );
//console.log(response.error);
  return response.data;
};



export const checkIfEarnedAll = async (citizenId ) => {
  // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee:",citizenId);
  const response = await API.get(
    `/api/citizentransactions/checkifearnedall?citizenId=${citizenId}`
  );
//console.log(response.error);
  return response.data;
};

export const addCitizenTransaction = async (data) => {
  const response = await API.post(`/api/citizentransactions`, data);
  return response.data;
};
export const addCitizenTQrransaction = async (data) => {
  const response = await API.post(`/api/citizentransactions/qradd`, data);
  return response.data;
};




////////////////////////////////
//Citizen Recycle/Donate/Savings/referals Api Requests
////////////////////////////////
export const addCitizenRecycle = async (data) => {
  const response = await API.post(`/api/citizenrecycle`, data);
  return response.data;
};
export const addCitizenSaving = async (data) => {
  const response = await API.post(`/api/citizensavings`, data);
  return response.data;
};

export const addCitizenReferal = async (data) => {
  const response = await API.post(`/api/citizenreferal`, data);
  return response.data;
};

export const addCitizenReceipt = async (data) => {
  const response = await API.post(`/api/citizenreceipt`, data);
  return response.data;
};

////////////////////////////////
//Citizen Offers Api Requests
////////////////////////////////
export const getAllCitizenOffers = async ({ citizenId }) => {
  const response = await API.get(`/api/citizenoffers?citizenId=${citizenId}`);
  // console.log(response.data);
  return response.data;
};

export const getCitizenOfferByCoupon = async ({ coupon }) => {
  const response = await API.get(`/api/citizenoffers/getofferbycoupon?coupon=${coupon}`);
  // console.log(response.data);
  return response.data;
};

export const redeemCoupon = async ({ coupon }) => {
  const response = await API.post(`/api/citizenoffers/redeemcoupon?coupon=${coupon}`, {});
  // console.log(response.data);
  return response.data;
};


export const addCitizenOffer = async (data) => {
  const response = await API.post(`/api/citizenoffers`, data);
  return response.data;
};

////////////////////////////////
//Citizen Offers Api Requests
////////////////////////////////
export const getAllCitizenFavorites = async ({ citizenId }) => {
  const response = await API.get(
    `/api/citizenfavorites?citizenId=${citizenId}`
  );
  // console.log(response.data);
  return response.data;
};

export const getAllCitizenFavoritesOnly = async ({ citizenId }) => {
  const response = await API.get(
    `/api/citizenfavorites/favoritesonly/?citizenId=${citizenId}`
  );
  // console.log(response.data);
  return response.data;
};

export const addCitizenFavorite = async (data) => {
  const response = await API.post(`/api/citizenfavorites`, data);
  return response.data;
};

////////////////////////////////
//Citizen  Api Requests
////////////////////////////////
export const updateUserAvatar = async ({ citizenId, data }) => {
  const response = await API.post(
    `/api/citizens/updateavatar?citizenId=${citizenId}`,
    data
  );

  return response.data;
};

export const updateUserDetails = async ({ citizenId, data }) => {
  const response = await API.post(
    `/api/citizens/updatedetails?citizenId=${citizenId}`,
    data
  );

  return response.data;
};

export const subscribeToNewsletter = async ({ citizenId, }) => {
  const response = await API.post(
    `/api/citizens/subscribe?citizenId=${citizenId}`,
    
  );

  return response.data;
};

export const getSmartRecyclePoints = async (id) => {
  const response = await API.get(
    `/api/citizens/getsmartrecyclepoints?citizenId=${id}`
  );
  console.log(response.data);
  return response.data;
};


export const updateSmartRecycleDetails = async ({ citizenId, data }) => {
  const response = await API.post(
    `/api/citizens/updatesmartrecycle?citizenId=${citizenId}`,
    data
  );

  return response.data;
};

export const getUpdatedUserValues = async ({ currentUserState }) => {
  const response = await API.post(`/api/citizens/getuser`, currentUserState);
  // console.log(response.data);
  return response.data;
};

////////////////////////////////
//Citizen Account Api Requests
////////////////////////////////
export const getPoints = async (id) => {
//console.log("IIIDDD", id);
  const response = await API.get(
    `/api/citizensaccount/getpoints?citizenId=${id}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Schools Api Requests
////////////////////////////////

export const getAllSchools = async ({ municipalityId = 0 }) => {
  const response = await API.get(
    `/api/schools/getallwithcontest?municipalityId=${municipalityId}`
  );
  // console.log(response.data.toString());
  return response.data;
};

export const getAllContestSchools = async ({ municipalityId = 0, filter=0 }) => {
  const response = await API.get(
    `/api/schools/getonlywithcontest?municipalityId=${municipalityId}&filter=${filter}`
  );
  // console.log(response.data.toString());
  return response.data;
};

////////////////////////////////
//Sport Cloubs Api Requests
////////////////////////////////

export const getAllSportClubs = async ({ municipalityId = 0 }) => {
  const response = await API.get(
    `/api/sportclubs/getallwithcontest?municipalityId=${municipalityId}`
  );
  // console.log(response.data.toString());
  return response.data;
};


export const getSportClubsFollow = async ({ citizenId = 0 }) => {
  const response = await API.get(
    `/api/sportclubs/getfollow?citizenId=${citizenId}`
  );
  // console.log(response.data.toString());
  return response.data;
};

export const addSportClubsFollow= async ({ citizenId,sportClubId,municipalityId, }) => {
  const response = await API.post(
    `/api/sportclubs/addorupdatefollow?citizenId=${citizenId}&sportClubId=${sportClubId}&municipalityId=${municipalityId}`,
    {}
  );
  return response.data;
};

export const removeSportClubFollow = async ({citizenId}) => {
  const response = await API.delete(`/api/sportclubs/removefollow?citizenId=${citizenId}`);
  return response.data;
};

////////////////////////////////
//School Contests and Projects Api Requests
////////////////////////////////
export const getAllSchoolContests = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/schoolcontests?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
//console.log(response.error);
  return response.data;
};

export const getLastSchoolContest = async ({ municipalityId = 0 }) => {
  const response = await API.get(
    `/api/schoolcontests/last?municipalityId=${municipalityId}`
  );
//console.log(response.error);
  return response.data;
};

export const getAllSchoolProjects = async ({ schoolContestId = 0 }) => {
  const response = await API.get(
    `/api/schoolprojects?schoolContestId=${schoolContestId}`
  );
//console.log(response.error);
  return response.data;
};

////////////////////////////////
//Schools Transaction Api Requests
////////////////////////////////
export const addSchoolTransaction = async ({ points,citizenId,schoolProjectId,additionalPoints,data }) => {
  const response = await API.post(
    `/api/schooltransactions/donate?citizenId=${citizenId}&points=${points}&schoolProjectId=${schoolProjectId}&additionalPoints=${additionalPoints}`,
    data
  );
  return response.data;
};


////////////////////////////////
//Missions Api Requests
////////////////////////////////
export const getAllMissions = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/missions?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
//console.log(response.data);
  return response.data;
};

export const getLiveMission = async ({
 
  citizenId = 0,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/missions/getlive?municipalityId=${municipalityId}&citizenId=${citizenId}`
  );
//console.log(response.data);
  return response.data;
};


export const getLiveMissionNext = async ({
 
  citizenId = 0,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/missions/getliveNext?municipalityId=${municipalityId}&citizenId=${citizenId}`
  );
//console.log(response.data);
  return response.data;
};


export const addCitizenMission = async ({ citizenId,missionId}) => {
//console.log("MISSION ID IS:",missionId);
  const response = await API.post(
    `/api/missions/addcitizenmission?citizenId=${citizenId}&missionId=${missionId}`,{}
  );
  return response.data;
};

export const completeCitizenMission = async ({ citizenId,missionId}) => {
  //console.log("MISSION ID IS:",missionId);
    const response = await API.post(
      `/api/missions/completecitizenmission?citizenId=${citizenId}&missionId=${missionId}`,{}
    );
    return response.data;
  };

export const addCitizenMissionAction = async ({ citizenId,citizenMissionId,goalId,targetId}) => {
  //console.log("MISSION ID IS:",missionId);
    const response = await API.post(
      `/api/missions/addcitizenmissionaction?citizenId=${citizenId}&citizenMissionId=${citizenMissionId}&goalId=${goalId}&targetId=${targetId}`,{}
    );
    return response.data;
  };






  //public organization api
  export const addAndPrintCoupons = async (data) => {
    const response = await API.post(`/api/publicOrganizationToken/addandprint`, data,{
      responseType: "blob",
    });
    return response.data;
  };


  export const geTokensbyPublicOrganization = async ({
 
    publicOrganizationId = 0,
   
  }) => {
    const response = await API.get(
      `/api/publicOrganizationToken/gettokensbypublicorganization?publicOrganizationId=${publicOrganizationId}}`
    );
  //console.log(response.data);
    return response.data;
  };




  //Votes api request
  export const getLiveVote = async ({ 
    citizenId = 0,
    municipalityId = 0,
    filter=0,
    voteId = 0,
  }) => {
    const response = await API.get(
      `/api/votes/getlive?municipalityId=${municipalityId}&citizenId=${citizenId}&filter=${filter}&voteId=${voteId}`
    );
  //console.log(response.data);
    return response.data;
  };

  export const getAllLiveVotes = async ({ 
    
    municipalityId = 0,
   
  }) => {
    const response = await API.get(
      `/api/votes/getliveall?municipalityId=${municipalityId}`
    );
  //console.log(response.data);
    return response.data;
  };


  export const addCitizenVote = async (data) => {
    //console.log("MISSION ID IS:",missionId);
      const response = await API.post(
        `/api/votes/addcitizenvote`,data
      );
      return response.data;
    };
  
  