import { CircularProgress, LinearProgress } from '@mui/material';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addCitizenMission, completeCitizenMission, getLiveMission, getLiveMissionNext } from '../api';
import useAuth from '../hooks/useAuth';
import useMunicipality from '../hooks/useMunicipality';
import { getThumb } from '../utils/utils';
import { motion } from 'framer-motion';
import { MdArticle, MdVideoCameraBack } from 'react-icons/md';
import { BsQuestionCircle } from 'react-icons/bs';
import { Gamepad, GamesRounded, GolfCourse } from '@mui/icons-material';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { useModal } from '../contexts/ModalContext';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';

const SingleMissionNext = () => {

  const { municipality } = useMunicipality();
  const location = useLocation()
  const navigate = useNavigate()

  const [userMission, setUserMission] = useState()

  const { logout, user, isAuthenticated,updateUserValues } = useAuth();

  const { setModal, unSetModal } = useModal()


  const { isLoading: isLiveMissionLoading, isError: isLiveMissionError, data: liveMission, } =
    useQuery(['live_mission_next_query', userMission], () => getLiveMissionNext({ municipalityId: municipality.MunicipalityId, citizenId: isAuthenticated ? user.citizenId : 0 }), {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    })

  //Error checking and loading        
  if (isLiveMissionLoading)
    return <div
      className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

  if (isLiveMissionError)

    return <div
      className='flex p-8 justify-center h-screen items-center'>
      <div className=' text-center'>

        <p className='texgt-md font-medium'>ERROR LOADING DATA: { } </p>
      </div>
    </div>

  if (liveMission.length)
    return <div className='flex p-8 justify-center h-screen items-center'>
      <div className=' text-center'>
        <p className='texgt-md font-medium'>No Data To Load</p>
      </div>
    </div>

  return (
    <div className='bg-white  overflow-auto'>
      <div className="relative max-w-2xl py-8 mb-10 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">

        <div className='space-y-5'>
          <h2 className=" text-3xl font-bold text-gray-700 ">{liveMission.mission.TitleEl}</h2>
          <div className='flex'>
            <div className='text-lg font-normal text-gray-700' dangerouslySetInnerHTML={{ __html: '<div>' + liveMission.mission.BodyEl.replaceAll(`/images/`, process.env.REACT_APP_BUCKET_URL + `/images/`) }}></div>
            <div className='relative flex'>
              <img
                className='object-fit w-64 h-20'
                src={process.env.REACT_APP_BUCKET_URL + "/Content/css/assets/brush-stroke.png"}
                alt=""
              />
              <div className='absolute top-2 left-20 drop-shadow-sm font-semibold text-white'>ημέρες που λήγει:</div>
              <div className='absolute top-8 left-28 text-lg drop-shadow-sm font-semibold text-white'>{Math.floor((Date.parse(liveMission.municipalitymission.EndDate) - Date.now()) / 86400000)}</div>
            </div>
          </div>
        </div>


        {isAuthenticated ?
          <div className='mt-14 mb-14'>
            {liveMission.citizenMission ?
              <div className="relative pt-1">
                {
                  (liveMission.citizenMissionActions.length === liveMission.goals.length || (liveMission.citizenMissionActions.length === (liveMission.goals.length -1) && liveMission.offer))

                  ?
                  <div className='hover:bg-app_green hover:shadow-md flex justify-center items-center space-x-3 bg-app_dark_green p-3 shadow-lg cursor-pointer  rounded-3xl'
                onClick={async () => {
                  const missionCopleteResponce = await completeCitizenMission({ citizenId: user.citizenId, missionId: liveMission.mission.MissionId });
                  if(missionCopleteResponce.result === 3)
                  {
                    updateUserValues()
                    setModal(
                      <div className='flex flex-col items-center justify-center py-5'>
                          <motion.div
                              initial={{ rotate: 500, scale: 0, opacity: 0 }}
                              exit={{ rotate: 1, scale: 1, opacity: 0 }}

                              animate={{ rotate: 0, scale: 1, opacity: 1 }}
                              transition={{
                                  damping: 20,
                                  duration: 0.35
                              }}
                              className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                              <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                          </motion.div>
                          <div className='font-bold text-lg text-center pt-5'>Ευχαριστούμε για την συμμετοχή στο Mission. Μόλις κέρδισες 120 πόντους.</div>
                          <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                              onClick={() => { unSetModal() }}
                          >OK</div>
                      </div>
                  )
                  }
                  else{
                    setModal(
                      <div className='flex flex-col items-center justify-center py-5'>
                          <motion.div
                              initial={{ rotate: 500, scale: 0, opacity: 0 }}
                              exit={{ rotate: 1, scale: 1, opacity: 0 }}

                              animate={{ rotate: 0, scale: 1, opacity: 1 }}
                              transition={{
                                  damping: 20,
                                  duration: 0.35
                              }}
                              className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                              <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                          </motion.div>
                          <div className='font-bold text-lg text-center pt-5'> Έχεις ήδη λάβει τους πόντους σου!</div>
                          <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                              onClick={() => { unSetModal() }}
                          >OK</div>
                      </div>
                  )
                  }
                }}
              ><div className=' text-white font-semibold text-md text-center'> ΠΑΡΕ ΤΟΥΣ ΠΟΝΤΟΥΣ ΣΟΥ! </div>
              {/* <GolfCourse className='text-white'></GolfCourse> */}
            </div>
                  :
                  <LinearProgress variant="determinate" className='!h-5 rounded-full opacity-50' value={(liveMission.citizenMissionActions.length * 25)} />
                }

              </div>
              :
              <div className='hover:bg-app_green hover:shadow-md flex justify-center items-center space-x-3 bg-app_dark_green p-3 shadow-lg cursor-pointer w-52 rounded-3xl'
                onClick={async () => {
                  await addCitizenMission({ citizenId: user.citizenId, missionId: liveMission.mission.MissionId }).then((e) => {

                    setUserMission(true);
                  })
                }}
              >
                <div className=' text-white font-semibold text-md text-center'> ΞΕΚΙΝΑ ΤΩΡΑ </div>
                <GolfCourse className='text-white'></GolfCourse>
              </div>}

          </div>
          : <div className=' mt-14 mb-14 justify-center flex whitespace-pre-line  cursor-pointer ' >
            <div className='shadow-md p-5 bg-gradient-to-r from-[#DEEFFF] to-[#F4F7EB] rounded-3xl'>
              <div className='text-gray-700 font-semibold text-center'>Προσοχή: Εάν είστε ήδη εγγεγραμένο μέλος παρακαλούμε συνδεθείτε ώστε να κερδίσετε τους πόντους</div>
              <div className='flex justify-center space-x-3'>
                <div className='justify-center flex whitespace-pre-line   cursor-pointer' onClick={() => {
                  navigate('../../../account/login')
                }}>
                  <div className='text-white  font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Είσοδος</div>
                </div>

              </div>
            </div>

          </div>
        }



        <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {[].concat(liveMission.goals).sort((a, b) => a.Image ? 1 : -1).map((goal, index) => {
            // if (allData.length === index + 1) return

            // else
            return (



              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20
                }}
                key={index} className={`${liveMission.citizenMission ? 'grayscale-0' : 'grayscale'} relative p-1 bg-gradient-to-l from-green-400 via-blue-500 to-green-500  overflow-hidden duration-200 ease-out shadow-2xl cursor-pointer rounded-3xl group  hover:-translate-y-2`}>
                <div className='bg-white w-full h-full rounded-3xl'>

                  <Link to={(() => {

                    if (goal.blog !== undefined) {
                      return !liveMission.citizenMission ? '/' : '/../blog/single/' + goal.blog.FriendlyUrl + '?mission=true&CitizenMissionId=' + liveMission.citizenMission.CitizenMissionId + '&MissionGoalId=' + goal.MissionGoalId + '&MissionRound=2'
                    } else if (goal.quiz !== undefined) {
                      return !liveMission.citizenMission ? '/' : '/../quiz/single/' + goal.quiz.FriendlyUrl + '?mission=true&CitizenMissionId=' + liveMission.citizenMission.CitizenMissionId + '&MissionGoalId=' + goal.MissionGoalId + '&MissionRound=2'
                    } else if (goal.video !== undefined) {
                      return !liveMission.citizenMission ? '/' : '/../video/single/' + goal.video.FriendlyUrl + '?mission=true&CitizenMissionId=' + liveMission.citizenMission.CitizenMissionId + '&MissionGoalId=' + goal.MissionGoalId + '&MissionRound=2'
                    }
                    else {
                      if (liveMission.offer !== null) {
                        return !liveMission.citizenMission ? '/' : '/../offer/detail/' + liveMission.offer.Token + '?mission=true&CitizenMissionId=' + liveMission.citizenMission.CitizenMissionId + '&MissionGoalId=' + goal.MissionGoalId + '&MissionRound=2'
                      }
                    }
                  })()}
                    // isHomePage ? '../blog/single/' + post.FriendlyUrl : '../blog/single/' + post.FriendlyUrl}
                    state={
                      {
                        // post: goal,
                        // postList: (() => {
                        //     if (goal.QuizId)
                        //         return _quizes.slice(0, 8)
                        //     if (goal.BlogId)
                        //         return _blogs.slice(0, 8)
                        //     if (goal.VideoId)
                        //         return _videos.slice(0, 8)


                        // })()
                      }
                    }
                    className="w-full rounded-md min-h-70 aspect-w-1 aspect-h-1 lg:h-70 lg:aspect-none">
                    <div>
                      {(() => {
                        if (goal.quiz) {
                          return (

                            <img
                              src={goal.quiz.Image ? ((goal.quiz.Image.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + goal.quiz.Image) : ''}
                              alt=''
                              className={`${liveMission.citizenMission ? 'grayscale-0' : 'grayscale'}  object-cover object-center w-full p-0 lg:w-full rounded-3xl`}
                            />

                          )
                        } else if (goal.blog) {
                          return (
                            <img
                              src={goal.blog.BlogImage ? ((goal.blog.BlogImage.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + goal.blog.BlogImage) : ''}
                              alt=''
                              className={`${liveMission.citizenMission ? 'grayscale-0' : 'grayscale'}  object-cover object-center w-full p-0 lg:w-full rounded-3xl`}
                            />
                          )
                        } else if (goal.video) {
                          return (
                            <img
                              src={getThumb(goal.video.Url)}
                              alt=''
                              className={`${liveMission.citizenMission ? 'grayscale-0' : 'grayscale'}  object-cover object-center w-full p-0 lg:w-full rounded-3xl`}
                            />
                          )
                        } else {
                          return (
                            <img
                              src={goal.Image ? ((goal.Image.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + goal.Image) : ''}
                              alt=''
                              className={`${liveMission.citizenMission ? 'grayscale-0' : 'grayscale'}  object-cover object-center w-full p-0 lg:w-full rounded-3xl`}
                            />
                          )
                        }
                      })()}


                      <div className={`${liveMission.citizenMission ? 'grayscale-0' : 'grayscale'} absolute flex items-center justify-center w-12 h-12 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 top-32 bg-gradient-to-b from-app_brown to-app_red`}>
                        <GolfCourse className='text-white'></GolfCourse>
                      </div>



                      {(() => {
                        if (goal.blog !== undefined) {
                          return (
                            <div className="absolute flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 left-3 top-3 bg-slate-500 shadow-md">
                              <MdArticle color='white' size={22}></MdArticle>
                            </div>
                          )
                        } if (goal.quiz !== undefined) {
                          return (
                            <div className="absolute flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 left-3 top-3 bg-slate-500 shadow-md">
                              <BsQuestionCircle color='white' size={22}></BsQuestionCircle>
                            </div>
                          )
                        } if (goal.video !== undefined) {
                          return (
                            <div className="absolute flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 left-3 top-3 bg-slate-500 shadow-md">
                              <MdVideoCameraBack color='white' size={22}></MdVideoCameraBack>
                            </div>
                          )
                        }
                      })()}



                      {(() => {

                        // if(isAuthenticated)

                        // {if (goal.BlogId !== undefined) {
                        //     if (checkEarnStatus(goal.BlogId))
                        //         return (
                        //             <div className="absolute bg-green-500 flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 right-3 top-3">
                        //                 <FaCheck color='white' size={22}></FaCheck>
                        //             </div>
                        //         )

                        // } if (goal.QuizId !== undefined) {
                        //     if (checkEarnStatus(goal.QuizId))
                        //         return (
                        //             <div className="absolute bg-green-500 flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 right-3 top-3">
                        //                 <FaCheck color='white' size={22}></FaCheck>
                        //             </div>
                        //         )

                        // } if (goal.VideoId !== undefined) {
                        //     if (checkEarnStatus(goal.VideoId))
                        //         return (
                        //             <div className="absolute bg-green-500 flex items-center justify-center rounded-full bg-opacity-70 w-10 h-10 right-3 top-3">
                        //                 <FaCheck color='white' size={22}></FaCheck>
                        //             </div>
                        //         )

                        // }}
                      })()}


                    </div>



                    <div className="p-3.5">
                      <a href={goal.href} className="flex justify-center text-[17px] font-semibold text-center">

                        {goal.TitleEl}
                      </a>
                      <div className="flex justify-center p-3 pb-5 text-center">
                  <p className="text-sm font-medium text-gray-900">{goal.SubtitleEl}</p>
                </div>
                    </div>
                  </Link>

                </div>

               


              </motion.div>


            )
          })}






        </div>

      </div>

    </div>
  )
}

export default SingleMissionNext