import { CircularProgress } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FaTrophy } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useQuery } from 'react-query';
import { getLiveVote } from '../api';
import useAuth from '../hooks/useAuth';
import useMunicipality from '../hooks/useMunicipality';
import { getThumb } from '../utils/utils';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';


const SingleVoteWinners = () => {

  const { municipality } = useMunicipality();
  // const location = useLocation()
  // const navigate = useNavigate()



  const { logout, user, isAuthenticated, updateUserValues } = useAuth();

  


  const location = useLocation();



  const getLocation = () =>
  {
     let locs = location.pathname.split("/");

      //get last item
     return locs[locs.length-2];
  }




  const { isLoading: isLiveVotenLoading, isError: isLiveVoteError, data: liveVote, } =
    useQuery(['live_vote_queries', ], () => getLiveVote({ municipalityId: municipality.MunicipalityId, citizenId: isAuthenticated ? user.citizenId : 0, filter: 0, voteId:getLocation() }), {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    })



  const log_live_vote_queries = () => {

    const items = []

    for (let index = 0; index < liveVote.voteOptions.length; index++) {
      const element = liveVote.voteOptions[index];
      if(element.Points>0)
      {
        let obj = {
          name: element.BodyEl,
          image: element.VoteOptionImage.split('/')[element.VoteOptionImage.split('/').length - 1],
          points: element.Points
        }

        items.push(obj)

        
      }
    }
    console.log(items)
  } 

 


  //Error checking and loading        
  if (isLiveVotenLoading)
    return <div
      className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

  if (isLiveVoteError)

    return <div
      className='flex p-8 justify-center h-screen items-center'>
      <div className=' text-center'>

        <p className='texgt-md font-medium'>ERROR LOADING DATA: { } </p>
      </div>
    </div>

  if (liveVote.length)
    return <div className='flex p-8 justify-center h-screen items-center'>
      <div className=' text-center'>
        <p className='texgt-md font-medium'>No Data To Load</p>
      </div>
    </div>

  if (liveVote.vote === null || liveVote.voteOptions == null)
    return <div className='flex p-8 justify-center h-screen items-center'>
      <div className=' text-center'>
        <p className='texgt-md font-medium'>Δεν υπάρχει διαθέσιμη ψηφοφορία </p>
      </div>
    </div>


 
log_live_vote_queries()


  return (
    <div className='bg-white  '>
      <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">

      




      





        <div className='flex flex-col w-full justify-center items-center'>
          <div className='font-bold text-xl px-8 mt-5 mb-5 flex justify-center items-center  bg-app_dark_green p-3 shadow-lg rounded-3xl text-white '>Κατάταξη <FaTrophy className="ml-3 text-2xl" /></div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
            {liveVote.voteOptions.sort((a, b) => a.Points < b.Points ? 1 : -1).map((vote, index) => {

              if (vote.Points < 1 || index > 50) return null

              else

                return <div className='flex flex-col relative mt-5 justify-end'>
                  <div className=' pb-2   leading-5 z-20 '>
                    {vote.BodyEl}
                  </div>

                 

                  <div className='absolute px-3 py-1 mt-2 -bottom-2 left-1/2 transform -translate-x-1/2  text-lg font-bold text-white bg-app_green text-center flex justify-center items-center rounded-3xl'>
                    Πόντοι: {vote.Points}
                  </div>

                  <div className='absolute px-3 bottom-7 left-1/2 transform -translate-x-1/2  text-xl font-bold text-white bg-app_red w-12 h-12 text-center flex justify-center items-center rounded-full'>
                    {index + 1}
                  </div>

                  {vote.VoteOptionImage.includes('youtube') ? <LazyLoadImage
                    src={getThumb(vote.VoteOptionImage)}
                    alt=''
                    // loading="lazy"
                    // onDrag={(e)=>{e.preventDefault()}}
                    draggable={false}
                    className="flex object-cover h-44 w-full   rounded-2xl"
                  />

                    : <LazyLoadImage
                      src={(vote.VoteOptionImage.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + vote.VoteOptionImage}
                      alt=''
                      // loading="lazy"
                      // onDrag={(e)=>{e.preventDefault()}}
                      draggable={false}
                      className="flex object-cover h-44 w-full   rounded-2xl"
                    />
                  }


                </div>

            }



            )}

          </div>
        </div>

      </div>



    </div>
  )
}

export default SingleVoteWinners


