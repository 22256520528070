import { ArrowRightAlt, Check, CheckCircleOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  NativeSelect,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changePassword,
  getLiveMunicipalities,
  getOfflineMunicipalities,
  updateUserDetails,
} from "../api";
import useAuth from "../hooks/useAuth";
import useMunicipality from "../hooks/useMunicipality";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { LoadingButton } from "@mui/lab";
import { useModal } from "../contexts/ModalContext";
import { motion } from 'framer-motion';
import { RiCheckLine, RiCloseLine } from "react-icons/ri";


// inital login credentials
const initialValues = {
 
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
 
};



// form field validation schema
const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(6, 'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον 6 χαρακτήρες')
    .required('Παρακαλώ εισάγετε τον κωδικό πρόσβασης'),
  newPassword: Yup.string()
    .min(6, 'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον 6 χαρακτήρες')
    .required('Παρακαλώ εισάγετε τον κωδικό πρόσβασης'),
  newPasswordConfirm: Yup.string().required('Παρακαλώ εισάγετε τον κωδικό πρόσβασης')
  .oneOf([Yup.ref('newPassword')], 'Ο κωδικός πρόσβασης που εισαγάγατε δεν ταιριάζει')
  // .test('passwords-match', 'Ο κωδικός πρόσβασης που εισαγάγατε δεν ταιριάζει', function(value){
  //   return this.parent.newPassword === value
  // })

});


const AccountChangePassword = () => {
  // Καταχώρηση ή Αποθήκευση
  const { login, user, isAuthenticated, updateUserValues } = useAuth();

  const navigate = useNavigate();
  const { setModal, unSetModal } = useModal()
  // const [oldpass, setOldPass] = useState("");
  // const [newpass, setNewPass] = useState("");
  // const [newpassConfirm, setNewPassConfirm] = useState("");

  const handleSubmit = async (values) => {
   
    const data = 
    {
      email: user.email, 
      oldPassword :  values.oldPassword, 
      newPassword :  values.newPassword
    }

    const passwordChangeResponce = await changePassword(data)


    if (passwordChangeResponce.data.status === 1) {
      setModal(
        <div className='flex flex-col items-center justify-center py-5'>
          <motion.div
                                                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                        transition={{
                                                            damping: 20,
                                                            duration: 0.35
                                                        }}
                                                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                                    </motion.div>
          <div className='font-bold text-lg text-center pt-5'>Λάθος  κωδικός πρόσβασης.</div>
          <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
            onClick={() => {
              // resetForm();
              unSetModal();
            }}
          >OK</div>
        </div>
      )
    }
    else {
     
      setModal(
        <div className='flex flex-col items-center justify-center py-5'>
          <motion.div
            initial={{ rotate: 500, scale: 0, opacity: 0 }}
            exit={{ rotate: 1, scale: 1, opacity: 0 }}

            animate={{ rotate: 0, scale: 1, opacity: 1 }}
            transition={{
              damping: 20,
              duration: 0.35
            }}
            className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

            <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
          </motion.div>
          <div className='font-bold text-lg text-center pt-5'>Ο κωδικός σας άλλαξε με επιτυχία.</div>
          <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
            onClick={() => {
              // resetForm();
              unSetModal();
            }}
          >OK</div>
        </div>
      )
    }

    console.log(data);

    // setTimeout(() => {
    //   navigate(-1);
    // }, 1000);
  };

  return (
    <div className="max-w-2xl px-4 min-h-screen mx-auto py-5 lg:py-16 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 className="text-3xl font-bold text-gray-700 ">Αλλαγή κωδικού</h2>
      <h2 className="text-lg font-normal text-gray-700 pb-7">
        Συμπλήρωσε τα παρακάτω πεδία για να ορίσεις το νέο κωδικό πρόσβασης
      </h2>
       
       <div className="w-auto max-w-lg">

       <Formik
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
              
            // enableReinitialize={false}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e)}} className="space-y-5">

                  

                  <TextField
                    fullWidth
                    size="small"
                    className="!w-full"
                    name="oldPassword"
                    label="Παλαιός Κωδικός πρόσβασης:"
                    variant="outlined"
                    type={"password"}
                    onBlur={handleBlur}
                    value={values.oldPassword}
                    onChange={handleChange}
                    helperText={touched.oldPassword && errors.oldPassword}
                    error={Boolean(errors.oldPassword && touched.oldPassword)}
                    sx={{ mb: 1.5 }}
                    
                  />
                  <TextField
                    fullWidth
                    size="small"
                    className="!w-full"
                    name="newPassword"
                    label="Νέος Κωδικός πρόσβασης:"
                    variant="outlined"
                    type={"password"}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    onChange={handleChange}
                    helperText={touched.newPassword && errors.newPassword}
                    error={Boolean(errors.newPassword && touched.newPassword)}
                    sx={{ mb: 1.5 }}
                    
                  />
                  <TextField
                    fullWidth
                    size="small"
                    className="!w-full"
                    name="newPasswordConfirm"
                    label="Επιβεβαίωση νέου κωδικού πρόσβασης:"
                    variant="outlined"
                    type={"password"}
                    onBlur={handleBlur}
                    value={values.newPasswordConfirm}
                    
                    onChange={handleChange}
                    helperText={touched.newPasswordConfirm && errors.newPasswordConfirm}
                    error={Boolean(errors.newPasswordConfirm && touched.newPasswordConfirm)}
                    sx={{ mb: 1.5 }}
                    
                  />



                  <LoadingButton
                    className='!bg-gradient-to-r disabled:text-white disabled:cursor-not-allowed from-app_yellow to-app_brown !shadow-orange-300 !shadow-xl !drop-shadow-none'
                    type="submit"
                   
                    // loading={loading}
                    variant="contained"
                    sx={{ my: 2 }}
                  >
                    <div className='capitalize pr-2'>Υποβολή</div>
                    <ArrowRightAlt></ArrowRightAlt>
                  </LoadingButton>




                </form>

              )}
            </Formik>
       </div>
     
    </div>
  );
};

export default AccountChangePassword;
