import { Map, MapsUgc } from '@mui/icons-material';
import React, { useState } from 'react'
import { FaHeart, FaMapMarked, FaMapMarkedAlt, FaQuestion, FaShare } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/utils';
import seperator from '../assets/offer-edge.svg';
import useAuth from '../hooks/useAuth';
import { toggleFavoriteOffer } from '../services/favoritesService';
import { addCitizenOffer, getAllCitizenFavoritesOnly, getCitizenOfferByCoupon, getMerchantByUrl, getOfferByUrl, redeemCoupon } from '../api';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import { useModal } from '../contexts/ModalContext';
import { motion, useInView } from 'framer-motion';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import MarkerDragMap from '../components/shared/MarkerDragMap';

const Coupon = () => {


    // const offer = useLocation().state.offer;

    // const merchant = useLocation().state.merchant

    //first we need the offer url
    const location = useLocation()

    const coupon = location.pathname.split('/')[3];

    const [isRedeemed, setIsRedeemed] = useState(false);

    //offer and merchant must be fetched
    const { isLoading, isError, data: offer, } =
        useQuery(['singlecoupon',isRedeemed], () => getCitizenOfferByCoupon({ coupon: coupon }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,

        })








    if (isLoading)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA </p>
            </div>
        </div>

if (offer === null)
return <div
    className='flex p-8 justify-center h-screen items-center'>
    <div className=' text-center'>

        <p className='texgt-md font-medium'>Ο κωδικός κουπονιού δεν βρέθηκε </p>
    </div>
</div>

    return (
        <div className='flex flex-col min-h-screen w-full px-4 mx-auto lg:flex lg:px-8 xl:px-0 max-w-[1200px] mt-10 '>

            <div className='flex flex-col md:flex-row items-center  justify-between w-full border-2 border-dashed  shadow-xl overflow-hidden rounded-3xl '>

                <img
                    className='relative flex w-full object-cover rounded-[30px] p-5 '
                    src={(offer.actualOffer.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + offer.actualOffer.Image}
                    alt='' />

                <div className='flex flex-col justify-between  h-[100] items-start p-5 w-full text-gray-600'>

                    <div className='font-semibold text-2xl'>{offer.actualOffer.TitleEl}</div>

                    <div className='pt-10'>
                        <div className='font-semibold text-md'>Κωδικός κουπονιού: {offer.citizenOffer.CouponCode}</div>
                        <div className='font-semibold text-md'>Ονοματεπώνυμο κατόχου: {offer.citizen.FirstName + " " + offer.citizen.LastName}</div>
                      {Math.floor((Date.parse(offer.actualOffer.EndDate) - Date.now()) / 86400000) < 1 ? null : 
                        <div className='font-semibold text-md'>Λήγει σε: {Math.floor((Date.parse(offer.actualOffer.EndDate) - Date.now()) / 86400000)} μέρες</div>}
                    </div>

                    {Math.floor((Date.parse(offer.actualOffer.EndDate) - Date.now()) / 86400000) < 1 ? <div className='mt-14 mb-14'>

                        <div className='flex justify-center items-center space-x-3 bg-gray-400 p-3   w-52 rounded-3xl'>
                            <div className=' text-white font-semibold text-md text-center'> Το κουπόνι έχει λήξει </div>
                        </div>
                    </div>
                        :
                        <div className='mt-14 mb-14'>
                            {offer.citizenOffer.CitizenOfferStatusId === 5 ?
                                <div className='flex justify-center items-center space-x-3 bg-gray-400 p-3   w-52 rounded-3xl'

                                >
                                    <div className=' text-white font-semibold text-md text-center'> Εξαργυρώθηκε </div>

                                </div>
                                :
                                <div className='hover:bg-app_green hover:shadow-md flex justify-center items-center space-x-3 bg-app_dark_green p-3 shadow-lg cursor-pointer w-52 rounded-3xl'
                                    onClick={async () => {
                                       try {
                                        const redeemRequest =  await redeemCoupon({ coupon: coupon });
                                        //console.log(redeemRequest);
                                          setIsRedeemed(true)
                                       } catch (error) {
                                          
                                       }
                                    }}
                                >
                                    <div className=' text-white font-semibold text-md text-center'> Εξαργύρωση </div>

                                </div>
                            }
                        </div>}
                </div>


            </div>
        </div>




    )
}

export default Coupon