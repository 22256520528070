import { Circle, CircleNotificationsRounded, CircleOutlined, Edit } from '@mui/icons-material';
import React, { useState } from 'react'
import useAuth from '../hooks/useAuth';
import QRCode from "react-qr-code";
import useMunicipality from '../hooks/useMunicipality';
import { isCancelledError, useMutation, useQuery } from 'react-query';
import { addCitizenOffer, getAllCitizenFavorites, getAllCitizenOffers, getAllMerchants, getAllOfferTypes, getLookupsByType, getOfferPdf, getUpdatedUserValues, sendOtp, updateSmartRecycleDetails, updateUserAvatar, verifyOtp } from '../api';
import { CircularProgress, TextField } from '@mui/material';
import { FaHeart, FaQuestion, FaShare } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { generateRandomId, getImage } from '../utils/utils';
import { toggleFavoriteOffer } from '../services/favoritesService';
import AvatarUploadField from '../components/shared/AvatarUploadField';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useModal } from '../contexts/ModalContext';
import { RiCellphoneFill, RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MdElectricalServices } from 'react-icons/md';


const initialValuesPhone = {
    phone: '',

};

const initialValuesCode = {
    code: '',

};

var phone = null;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// form field validation schema
const validationSchemaPhone = Yup.object().shape({

    phone: Yup.string().min(10, 'Ελέγξτε αν ο αριθμός τηλεφώνου είναι στη σωστή μορφή').matches(phoneRegExp, 'Ελέγξτε αν ο αριθμός τηλεφώνου είναι στη σωστή μορφή'),
});

const validationSchemaCode = Yup.object().shape({

    code: Yup.string().min(5, 'Ελέγξτε αν ο αριθμός είναι στη σωστή μορφή'),
});

const AccountSettings = () => {
    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const [isVerified, setIsVerified] = useState(false)



    const { setModal, unSetModal } = useModal()

    const { municipality } = useMunicipality();

    const navigate = useNavigate();


    const [favoritesClicked, setFavoritesClicked] = useState(false)

    const [isFavorites, setIsFavorites] = useState(false);

    const [avatar, setAvatar] = useState(null);

    const handleFormSubmitPhone = async (values) => {
       //console.log(values)
       if (values.phone.length > 9) {
        await sendOtp({ phone: values.phone }).then((e) => {
           phone = values.phone
           if(e.data.status === 1){
               unSetModal();
               setModal(
                   <div className='flex flex-col items-center justify-center py-5'>
                       <motion.div
                           initial={{ rotate: 500, scale: 0, opacity: 0 }}
                           exit={{ rotate: 1, scale: 1, opacity: 0 }}

                           animate={{ rotate: 0, scale: 1, opacity: 1 }}
                           transition={{
                               damping: 20,
                               duration: 0.35
                           }}
                           className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                           <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                       </motion.div>
                       <div className='font-bold text-lg text-center pt-5'>Έχετε ήδη χρησιμοποιήσει αυτόν τον αριθμό!
                       </div>
                       <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                           onClick={() => { unSetModal() }}
                       >OK</div>
                   </div>
               )
           }
           else if(e.data.status === 3)
           {
               alert('Σας έχει αποσταλεί ο κωδικός επιβεβαίωσης.');
           }
           else
           {
               unSetModal();
               setModal(
                   <div className='flex flex-col items-center justify-center py-5'>
                       <motion.div
                           initial={{ rotate: 500, scale: 0, opacity: 0 }}
                           exit={{ rotate: 1, scale: 1, opacity: 0 }}

                           animate={{ rotate: 0, scale: 1, opacity: 1 }}
                           transition={{
                               damping: 20,
                               duration: 0.35
                           }}
                           className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                           <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                       </motion.div>
                       <div className='font-bold text-lg text-center pt-5'>Ωχ Κάτι πήγε στραβά. Ενημερώθηκε ο διαχειριστής συστήματος.
                       </div>
                       <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                           onClick={() => { unSetModal() }}
                       >OK</div>
                   </div>
               )
           }
       })
       // 
       
       // 
       
   }
        // console.log("OTPSENT IS:",otpSent)
    }

    const handleFormSubmitCode = async (values) => {
        //console.log(phone)
        if (!phone)
            return


        const otpVerificationResonse = await verifyOtp({ phone: phone, code: values.code, citizenId: user.citizenId })
        if (otpVerificationResonse.data.status === 2) {
            updateUserValues()
            setIsVerified(true);
            unSetModal()
        }
        // setOtpSent(true)
    }


    //console.log(user)

    const update = useMutation((data) => updateUserAvatar({ citizenId: user.citizenId, data: data }), {
        onSuccess: () => {

            //   setTimeout(() => window.history.back(), 1000);
        },
        onError: (e) => {
            //console.log('Error: ' + e)
        }
    })

    //Get All Offer Types          
    const { isLoading: isCitizenOffersLoading, isError: isCitizenOffersError, data: citizenOffers, } =
        useQuery('citizenOffers', () => getAllCitizenOffers({ citizenId: user.citizenId }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            onSuccess: (ftchedData) => {
                //console.log("FETCHED DATA: ", ftchedData)
            }
        })

    const { isLoading: isCitizenFavoritesLoading, isError: isCitizenFavoritesError, data: citizenFavorites, } =
        useQuery(['citizenFavoritesOnly', favoritesClicked], () => getAllCitizenFavorites({ citizenId: user !== null ? user.citizenId : null }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            onSuccess: (ftchedData) => {
                //console.log("FETCHED DATA: ", ftchedData)
            }
        })

    const { isLoading: lookupIsLoading, isError: lookupIsError, error: lookupError, data: lookups } =
        useQuery('merchant_lookup', () => getLookupsByType({ lookupType: 'merchant_category' }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })

    //Get All Offer Types          
    const { isLoading: isOfferTypeLoading, isError: isOfferTypeError, data: offerTypes, } =
        useQuery('offertypes', () => getAllOfferTypes(), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })


    const { isLoading: isLoadingMerchants, isError: isErrorMerchantd, error: errorMerchants, data: merchants, } =
        useQuery(['merchants', municipality,],
            () =>
                getAllMerchants({ limit: 0, page: 0, optionalKeyword: '', }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,

            })

    const getMerchantTitle = (merchantId) => {
        var corispondingMerchantTitle = merchants.rows.filter(obj => {
            return obj.MerchantId === merchantId
        })

        try {
            if (corispondingMerchantTitle) return corispondingMerchantTitle[0].NameEl
            else return ".."
        } catch (error) {
            return ".."
        }

    }

    const handleρedemption = async (offer) => {
        if (municipality.MunicipalityId === 380 && user.Deh === null) {
            var deh = 0;
            // setWidth(330)
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}
                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><MdElectricalServices className='w-16 h-16 text-app_red'></MdElectricalServices></div>
                    </motion.div>

                    <div className='font-bold text-lg text-center pt-5'>Καταχώρησε τον Αριθμό Παροχής Ηλεκτρικού Ρεύματος για να συνεχίσεις</div>

                    <p className=' text-center pt-5'>Από την ένδειξη "Αριθμός Παροχής" που αναγράφεται στον λογαριασμό σας: π.χ. 1 23456789-02 5 </p>
                    <p className='text-center text-md italic pt-1'>*Εισάγετε ΜΟΝΟ τα 8 ψηφία, που βρίσκονται μετά τον πρώτο αριθμό έως και την παύλα, όπως παρακάτω: 23456789
                    </p>


                    <TextField
                        className='!mt-5 !border-2 !outline-none !border-dashed !shadow-[inset_0px_6px_12px_rgba(0,0,0,0.3)]'
                        label=""
                        onChange={(e) => {

                            deh = e.target.value;
                        }}
                        type="number"
                        sx={{ padding: 0, borderRadius: '15px !important', }}

                    />
                    <div className='flex flex-col md:flex-row'>
                        <button className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={async () => {
                                const digits_only = string => [...string].every(c => '0123456789'.includes(c));
                                if (deh.length === 8 && digits_only(deh)) {
                                    console.log("Dei is: " + deh);

                                    var recycleData = {
                                        Deh: deh,
                                        ValidDeh: user.ValidDeh,
                                        BrownBin: user.BrownBin,
                                        BinNumber: user.BinNumber,
                                        RecyclableMaterials: user.RecyclableMaterials,
                                        PlasticBagNumber: user.PlasticBagNumber,
                                    };
                                    await updateSmartRecycleDetails({ citizenId: user.citizenId, data: recycleData })

                                    updateUserValues();

                                    unSetModal();

                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Ο αριθμός παροχής καταχωρήθηκε επιτυχώς!</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    )


                                }
                                else {
                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Ο Αριθμός Παροχής δεν είναι σωστός
                                            </div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    )
                                }
                            }}
                        >Καταχώρηση</button>


                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { unSetModal() }}
                        >Ακύρωση</div>
                    </div>
                </div>
            )
        }
        else if (municipality.MunicipalityId !== 380 && user.AuthyVerified === 0) {
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><RiCellphoneFill className='w-12 h-12 text-app_yellow'></RiCellphoneFill></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Επιβεβαίωσε τον αριθμό του κινητού σου</div>
                    <div className='font-bold text-lg text-center pt-5'>Για να ολοκληρωθεί η διαδικασία επιβεβαίωσης του προφίλ σου, καταχώρησε τον αριθμό του κινητού τηλεφώνου σου και πάτησε το κουμπί αποστολή για να λάβεις τον κωδικό ενεργοποίησης.</div>
                    <div className="flex flex-col md:flex-row justify-evenly w-full">
                        <Formik
                            onSubmit={handleFormSubmitPhone}
                            initialValues={initialValuesPhone}
                            validationSchema={validationSchemaPhone} className='flex flex-col pt-5 items-center space-y-3'>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='flex justify-center items-center'>
                                        <div>+30</div>
                                        <TextField
                                            className='group  w-40 !mt-1.5'
                                            size="small"
                                            type="tel"
                                            name="phone"
                                            label=""
                                            variant="standard"
                                            onBlur={handleBlur}
                                            value={values.phone}
                                            onChange={handleChange}
                                            helperText={touched.phone && errors.phone}
                                            error={Boolean(errors.phone && touched.phone)}
                                        />
                                    </div>
                                    <button type="submit" className='mt-3 flex w-40 justify-center cursor-pointer  text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                    //    onClick={}
                                    >Αποστολή</button>
                                </form>
                            )}
                        </Formik>
                        <Formik
                            onSubmit={handleFormSubmitCode}
                            initialValues={initialValuesCode}
                            validationSchema={validationSchemaCode} className='flex flex-col pt-5 items-center space-y-3'>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='flex justify-center items-center'>

                                        <TextField
                                            className='group  w-40 !mt-0.5'
                                            placeholder='000000'
                                            size="small"
                                            type="text"
                                            name="code"
                                            label=""
                                            variant="standard"
                                            onBlur={handleBlur}
                                            value={values.code}
                                            onChange={handleChange}
                                            helperText={touched.code && errors.code}
                                            error={Boolean(errors.code && touched.code)}

                                        />
                                    </div>
                                    <button type="submit" className=' mt-3 disabled:cursor-not-allowed disabled:bg-gray-300 flex w-40 justify-center cursor-pointer  text-center text-white font-semibold hover:text-white enabled:bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'

                                    >Ενεργοποίηση</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            );
        }
        else if (user.points < offer.OfferPoints) {
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Δυστυχώς έχετε μόλις {user.points} πόντους!</div>
                    <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                        onClick={() => { unSetModal() }}
                    >OK</div>
                </div>
            );
        }
        else {


            // console.log(citizenOffer)
            // downloadPDF(citizenOffer)
            // updateUserValues();
            //ON SUCCESS WE HAVE TO MAKE 
            // let transaction;

            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                    <motion.div
                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                        transition={{
                            damping: 20,
                            duration: 0.35
                        }}
                        className='border-4  border-app_yellow rounded-full w-20 h-20 flex justify-center items-center'>

                        <div><FaQuestion className='w-12 h-12 text-app_yellow'></FaQuestion></div>
                    </motion.div>
                    <div className='font-bold text-lg text-center pt-5'>Επιβεβαίωση απόκτησης προσφοράς</div>
                    <div className='font-bold text-lg text-center pt-5'>Με την ολοκλήρωση της ενέργειας θα αφαιρεθούν ({offer.OfferPoints}) πόντοι απο τον λογαριασμό σου.</div>
                    <div className="flex flex-col md:flex-row">
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={async () => {

                                unSetModal()
                                let citizenOffer = await addCitizenOffer({ citizenId: user.citizenId, offer: offer, });
                                if (citizenOffer.transaction_result === 2) {
                                    updateUserValues();
                                    setModal(

                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_green rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCheckLine className='w-16 h-16 text-app_green'></RiCheckLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Επιτυχής απόκτηση προσφοράς. Το κουπόνι έχει εκδοθεί και έχει αποσταλεί στο email σου.</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    );

                                }
                                else {
                                    setModal(
                                        <div className='flex flex-col items-center justify-center py-5'>
                                            <motion.div
                                                initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                transition={{
                                                    damping: 20,
                                                    duration: 0.35
                                                }}
                                                className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                            </motion.div>
                                            <div className='font-bold text-lg text-center pt-5'>Δεν υπάρχει διαθέσιμη προσφορά.</div>
                                            <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                onClick={() => { unSetModal() }}
                                            >OK</div>
                                        </div>
                                    );
                                }

                            }}
                        >ΝΑΙ</div>
                        <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_red to-app_brown  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                            onClick={() => { unSetModal() }}
                        >ΟΧΙ</div>
                    </div>
                </div>
            );








        }


    }


    const imageHandler = async (url) => {
        await updateUserAvatar({ citizenId: user.citizenId, data: { url: url } });
        setAvatar(url);
        updateUserValues();
        toast.success('Η εικόνα άλλαξε επιτυχώς!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        //update the user image data
        //console.log("THE NEW URL IS:", url);
    }


    const getMerchant = (merchantId) => {
        var corispondingMerchantTitle = merchants.rows.filter(obj => {
            return obj.MerchantId === merchantId
        })

        if (corispondingMerchantTitle) return corispondingMerchantTitle[0]
        else return null
    }


    const getCitizenOfferStatusId = (offerId) => {
        var citizenOffer = citizenOffers.citizenOffers.filter(obj => {
            return obj.OfferId === offerId
        })

        try {
            //console.log("ID IS:::", citizenOffer[0].CitizenOfferStatusId)
            return citizenOffer[0].CitizenOfferStatusId;
        } catch (error) {
            return 1
        }

    }

    const getOfferTitle = (offerTypeId) => {
        var corispondingOfferTitle = offerTypes.filter(obj => {
            return obj.OfferTypeId === offerTypeId
        })

        if (corispondingOfferTitle) return corispondingOfferTitle[0].NameEl
        else return ".."
    }

    const checkIfFavorite = (offerId) => {
        //Prevent errors if user is not loged in
        if (!isAuthenticated) return 0;



        var citizenFavorite = citizenFavorites.citizenFavorites.filter(obj => {
            return obj.OfferId === offerId
        })

        if (citizenFavorite === null) return 0;


        try {
            if (citizenFavorite[0].IsFavorite === 1)
                return 1

        } catch (error) {
            return 0
        }

    }

    const getOfferByOfferId = (offers, citizenOffer) => {
        for (let index = 0; index < offers.length; index++) {
            const element = offers[index];
            if (element.OfferId === citizenOffer.OfferId)
                return element

        }
    }

    const getMerchantCategory = (merchantId) => {

        var corispondingMerchant = merchants.rows.filter(obj => {
            return obj.MerchantId === merchantId
        })

        var corispondingMerchantCategory = lookups.filter(obj => {
            return obj.code === corispondingMerchant[0].MerchantCategoryId
        })
        // console.log(corispondingMerchantCategory[0].name)
        if (corispondingMerchantCategory) return corispondingMerchantCategory[0].name ?? ''
        else return ".."
    }

    const checkIfNewOffer = (startDate) => {
        const today = new Date();
        const offerStartDate = new Date(startDate);
        const diffTime = Math.abs(offerStartDate - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // console.log(diffDays + " days");
        if (diffDays > 30) return false;
        else return true
    }

    const onImageDownload = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {


            canvas.width = img.width + 50;
            canvas.height = img.height + 50;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, canvas.width / 2 - img.width / 2, canvas.height / 2 - img.height / 2);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };


    if (isCitizenOffersLoading || isLoadingMerchants || isCitizenFavoritesLoading || lookupIsLoading || isOfferTypeLoading)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isCitizenOffersError || isErrorMerchantd || isCitizenFavoritesError || lookupIsError || isOfferTypeError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA</p>
            </div>
        </div>

    if (merchants.length < 1)
        return <div className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>
                <p className='texgt-md font-medium'>No Data To Load</p>
            </div>
        </div>





    return (
        <div className="bg-white min-h-screen">
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="max-w-2xl px-4  mx-auto py-5 lg:py-16 sm:px-6 lg:max-w-7xl lg:px-8">

                <div className='flex justify-center mb-8'>
                    <h2 className="flex text-center text-2xl font-bold text-gray-700 ">Ο λογαριασμός μου</h2>
                    {/* <div className="h-0.5 w-[25%] bg-red-200"></div> */}
                </div>



                <div className='grid grid-cols-1 lg:grid-cols-3 justify-center '>
                    <div className='space-y-3 pr-5 col-span-1 '>
                        {(municipality.IsGreenSchool === 1 || municipality.LocalityEn === '4all') ? <></> :
                            <>

                                <div className='font-semibold'>Δες εδώ </div>

                                <div
                                    onClick={() => {
                                        navigate('../action')
                                    }}
                                    className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                                    <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                                    <div className='font-semibold'>Διαλογή στην Πηγή</div>
                                </div>
                                <div
                                    onClick={() => {
                                        navigate('../recyclebucket')
                                    }}
                                    className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                                    <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                                    <div className='font-semibold'>Πού ανακυκλώνω</div>
                                </div>
                                <div
                                    onClick={() => {
                                        navigate('../offer/merchantmap')

                                    }}
                                    className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                                    <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                                    <div className='font-semibold'>Πού εξαργυρώνω</div>
                                </div>
                            </>
                        }
                        {/* <div
                            onClick={() => {

                            }}
                            className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                            <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                            <div className='font-semibold'>Ιστορικό ανακυκλώσεων</div>
                        </div>
                        <div
                            onClick={async () => {
                            }}
                            className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                            <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                            <div className='font-semibold'>Ιστορικό συναλλαγών</div>
                        </div> */}
                        {/* <div
                            onClick={() => {

                            }}
                            className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                            <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                            <div className='font-semibold'>Σύσταση σε φίλο</div>
                        </div> */}




                        <div className='font-semibold pt-10'>Προσωπικά στοιχεία </div>
                        <div
                            onClick={() => {
                                navigate('changedetails')

                            }}
                            className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                            <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                            <div className='font-semibold'>Αλλαγή στοιχείων</div>
                        </div>
                        <div
                            onClick={() => {
                                navigate('changepassword')
                            }}
                            className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                            <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                            <div className='font-semibold'>Αλλαγή κωδικού</div>
                        </div>
                        <div
                            onClick={() => {

                            }}
                            className='relative cursor-pointer items-center h-14 md:w-[90%] shadow-lg border-8 border-white flex bg-[#FAFAFA] rounded-2xl p-3'>
                            <div className='absolute -top-2 -left-2 text-green-500' ><CircleOutlined></CircleOutlined></div>
                            <div className='font-semibold'>Απενεργοποίηση λογαριασμού</div>
                        </div>





                    </div>
                    <div className=' col-span-2 grid  gird-cols-1 md:grid-cols-1 divide-y-8 divide-white    bg-[#FAFAFA] rounded-3xl  shadow-lg'>
                        <div className='flex justify-evenly pb-10 p-8'>
                            <div className='relative'>
                                {/* <div className="absolute top-2 left-2 flex items-center justify-center bg-opacity-100 rounded-full shadow-md opacity-100 w-10 h-10 bg-gray-400 cursor-pointer">
                                    <div className="flex  items-center justify-center font-bold text-gray-600 bg-white   rounded-full  w-8 h-8  text-[13px]  ">
                                        <Edit></Edit>
                                    </div>
                                </div> */}

                                <AvatarUploadField imageHandler={imageHandler} userid={user.citizenId.toString()} currentImage={user.avatar} uploadFolder='images/profileimages'></AvatarUploadField>

                                <div className=' flex h-44 w-44 border-8 rounded-full overflow-hidden border-white shadow-lg '>
                                    <img className='flex object-cover  h-full w-full' src={avatar === null ? getImage(user.avatar) : avatar} alt="" />
                                </div>

                                <div className="flex w-44 mt-5 items-center justify-center bg-opacity-100 rounded-3xl shadow-lg  opacity-100 p-2 bg-white ">
                                    <div className="flex w-full h-full  items-center justify-center font-bold text-gray-600 bg-[#FAFAFA]   rounded-3xl   text-[13px]  ">
                                        <div className='font-semibold text-lg '>Πόντοι: {user.points}</div>
                                    </div>
                                </div>
                            </div>

                            <div className='relative flex flex-col'>

                                <div className='font-semibold text-lg flex space-x-1'>
                                    <div>{user.name}</div>
                                    <div>{user.lastname}</div>
                                    {/* <div>{user.name}</div> */}
                                </div>
                                <div className='font-bold text-lg pt-5'>Στοιχεία μέλους:</div>
                                <div className=' text-lg'>{user.email}</div>
                                {/* {
                                    municipality.IsGreenSchool === 1  ? <div className=' text-lg'>ID: {user.citizenId}</div>
                                    : null
                                }
                                 */}
                                 <div className=' text-lg'>ID: {user.citizenId}</div>
                                <div className='text-lg'>{municipality.NameEl}</div>
                                <div className='flex cursor-pointer mt-5' onClick={onImageDownload}>
                                    <img className="rounded-full shadow-lg  w-20 h-20 border-8  border-white" alt="" src={process.env.REACT_APP_BUCKET_URL + "/images/qr_icon.png"} />
                                </div>

                                <QRCode
                                    className='!z-3 !bg-white hidden'
                                    id='QRCode'
                                    level='L'
                                    size={512}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={user.email}
                                    viewBox={`0 0 512 512`}
                                    onClick={onImageDownload}

                                />

                            </div>
                        </div>

                        <div className='pt-5 flex flex-col p-8 '>
                            {/* <div className='mt-5 mx-auto font-bold text-2xl'>Οι προσφορές μου</div> */}
                            <div className="flex justify-center  p-8">
                                <div
                                    className={`${!isFavorites ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-100 text-gray-400 '}  hover:bg-white  cursor-pointer  px-12 py-1 drop-shadow-lg flex justify-center font-semibold text-gray-500 rounded-l-full`}
                                    onClick={() => { setIsFavorites(false) }}>Οι προσφορές μου</div>
                                <div
                                    className={`${isFavorites ? 'drop-shadow-lg bg-white' : 'drop-shadow-none bg-gray-100 text-gray-400 '}  hover:bg-white  cursor-pointer  px-12 py-1 drop-shadow-lg flex justify-center font-semibold text-gray-500 rounded-r-full`}
                                    onClick={() => { setIsFavorites(true) }}>Τα αγαπημένα μου</div>


                            </div>
                            {
                                !isFavorites ? (
                                    citizenOffers.actualOffers.length < 1
                                        ?
                                        <div className='flex items-center  justify-center  '><div className='mt-10 bg-gradient-to-r from-[#DFEFFE] to[#FAFAFA] p-3 rounded-3xl text-[#6B9AAE] shadow-md'>
                                            Δεν βρέθηκαν προσφορές στον λογαριασμό σου
                                        </div>
                                        </div>
                                        :
                                        <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                                            {

                                                citizenOffers.citizenOffers.map((_offer, index) => {
                                                    const offer = getOfferByOfferId(citizenOffers.actualOffers, _offer)
                                                    if (!offer) return <></>
                                                    return (

                                                        <motion.div
                                                            initial={{ rotate: 25, scale: 0, opacity: 0 }}
                                                            animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                            transition={{
                                                                type: "spring",
                                                                stiffness: 260,
                                                                damping: 20
                                                            }} 
                                                            key={index}
                                                            className="relative justify-between flex flex-col duration-200 ease-out shadow-2xl cursor-pointer rounded-3xl group shadow-gray-500 hover:-translate-y-2">
                                                            <Link
                                                                to={'./detail/' + offer.Token}
                                                                // state={{ offer: offer, offersList:filteredOffers.slice(0, 8)} }

                                                                className="w-full rounded-md  aspect-w-1  lg:aspect-none">
                                                                <div className='relative h-[13.3rem]'>
                                                                    <img
                                                                        src={offer.Image ? ((offer.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + offer.Image) : ''}
                                                                        alt={offer.imageAlt}
                                                                        className="object-cover object-center w-full p-1.5 lg:w-full rounded-3xl"
                                                                    />


                                                                    <div className="absolute bottom-0 flex items-center justify-center bg-opacity-100 rounded-full shadow-md opacity-100 w-14 h-14 right-3 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                                                        <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner w-11 h-11 shadow-gray-200 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">
                                                                            {offer.OfferPoints}
                                                                        </div>
                                                                    </div>





                                                                </div>



                                                                <div className="pt-5 pb-2 px-3 w-11/12">
                                                                    <a href={'/'} className="flex justify-center text-xl font-semibold leading-6 text-center text-gray-600 ">

                                                                        {offer.TitleEl}
                                                                    </a>
                                                                </div>
                                                            </Link>



                                                            <div className="flex flex-col items-center text-center justify-evenly w-full">
                                                                <div className="flex justify-center py-1.5 mx-4 my-3 text-center bg-gray-100 rounded-xl w-11/12">

                                                                    <p className="text-sm font-semibold text-gray-900 ">{getMerchantTitle(offer.MerchantId)}</p>
                                                                </div>


                                                                <div className='flex justify-center items-baseline w-11/12'>

                                                                    {getCitizenOfferStatusId(offer.OfferId) === 2 ?
                                                                        <button className='m-5 bg-app_green_light opacity-100 rounded-full '
                                                                            onClick={async () => {


                                                                                let pdf = await getOfferPdf(
                                                                                    { citizenOfferId: _offer.CitizenOfferId, offerId: offer.OfferId, citizenId: user.citizenId })
                                                                                const file = new Blob(
                                                                                    [pdf],
                                                                                    { type: 'application/pdf' }
                                                                                );
                                                                                //console.log(pdf)

                                                                                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                                                                                window.open(fileURL, "_blank");
                                                                            }}
                                                                        >
                                                                            <p className="px-5 py-4 font-semibold text-white ">{'Εκτυπωση'.toUpperCase()}</p>
                                                                        </button>
                                                                        :
                                                                        <button className='m-5 bg-gray-500 rounded-full opacity-30'>
                                                                            <p className="px-5 py-4 font-semibold text-white ">{'Εξαργυρωμένο'.toUpperCase()}</p>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </motion.div>
                                                    )
                                                }

                                                )}
                                        </div>
                                ) : (
                                    citizenFavorites.actualFavorites.length < 1
                                        ? <div className='flex items-center  justify-center  '><div className='mt-10 bg-gradient-to-r from-[#DFEFFE] to[#FAFAFA] p-3 rounded-3xl text-[#6B9AAE] shadow-md'>
                                            Δεν έχεις επιλέξει αγαπημένες προσφορές
                                        </div>
                                        </div>
                                        :
                                        <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
                                            {

                                                citizenFavorites.actualFavorites.map((offer, index) => (
                                                    <motion.div
                                                        initial={{ rotate: 25, scale: 0, opacity: 0 }}
                                                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                        transition={{
                                                            type: "spring",
                                                            stiffness: 260,
                                                            damping: 20
                                                        }} key={index}
                                                        className="relative justify-between flex flex-col duration-200 ease-out shadow-2xl cursor-pointer rounded-3xl group shadow-gray-500 hover:-translate-y-2">
                                                        <Link
                                                            to={'../offer/detail/' + offer.Token}
                                                            state={{ offer: offer, merchant: getMerchant(offer.MerchantId) }}

                                                            className="w-full rounded-md min-h-80 aspect-w-1 aspect-h-1 lg:h-auto lg:aspect-none">
                                                            <div className='relative h-[17.3rem]'>
                                                                <img
                                                                    src={offer.Image ? ((offer.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + offer.Image) : ''}
                                                                    alt={offer.imageAlt}
                                                                    className="object-cover object-center w-full p-1.5 lg:w-full rounded-3xl"
                                                                />


                                                                <div className="absolute bottom-0 flex items-center justify-center bg-opacity-100 rounded-full shadow-md opacity-100 w-14 h-14 right-3 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                                                    <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner w-11 h-11 shadow-gray-200 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">
                                                                        {offer.OfferPoints}
                                                                    </div>
                                                                </div>


                                                                <div className="absolute bottom-0 z-10 flex items-center justify-center h-10 px-3 -skew-x-12 -left-4 opacity-90 rounded-2xl bg-app_green">
                                                                    <a href={offer.href} className="flex justify-center text-base font-semibold text-center text-white ">

                                                                        {getOfferTitle(offer.OfferTypeId)}
                                                                    </a>
                                                                </div>
                                                                {
                                                                    checkIfNewOffer(offer.StartDate) ? <div className="absolute flex items-center justify-center h-10 -skew-x-12 -left-4 bottom-8 opacity-90 rounded-2xl bg-app_red">
                                                                        <a href={offer.href} className="flex justify-center px-5 text-base font-semibold text-center text-white">
                                                                            Νέο
                                                                        </a>
                                                                    </div> : <></>
                                                                }



                                                            </div>



                                                            <div className="pt-5 pb-2 px-3 w-11/12">
                                                                <a href={'/'} className="flex justify-center text-xl font-semibold leading-6 text-center text-gray-600 ">

                                                                    {offer.TitleEl}
                                                                </a>
                                                            </div>
                                                        </Link>



                                                        <div className="flex flex-col items-center text-center justify-evenly w-full">
                                                            <div className="flex justify-center py-1.5 mx-4 my-3 text-center bg-gray-100 rounded-xl w-11/12">

                                                                <p className="text-sm font-semibold text-gray-900 ">{getMerchantTitle(offer.MerchantId)}</p>
                                                            </div>

                                                            {/* <div className="flex justify-center text-center w-11/12">
            
                                                    <p className="text-sm font-semibold text-gray-500 ">{getMerchantCategory(offer.MerchantId)}</p>
                                                </div> */}

                                                            <div className='flex justify-between items-baseline w-11/12'>
                                                                <div
                                                                    className="flex items-center justify-center bg-gray-200 rounded-full opacity-50 h-14 w-14"
                                                                    onClick={async () => {
                                                                        if (isAuthenticated) {

                                                                            await toggleFavoriteOffer({ citizenId: user.citizenId, offerId: offer.OfferId, isFavorited: 1 })
                                                                                .then(() => {
                                                                                    //console.log('Added favorite');
                                                                                    setFavoritesClicked(!favoritesClicked)
                                                                                });

                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        checkIfFavorite(offer.OfferId) === 1
                                                                            ? <FaHeart color='red' size={18}></FaHeart>
                                                                            :
                                                                            <FaHeart color='gray' size={18}></FaHeart>
                                                                    }
                                                                </div>
                                                                <button className={`m-5 ${isAuthenticated ? "bg-app_green_light opacity-100" : "bg-gray-500 opacity-30"}  rounded-full `}
                                                                    onClick={async () => {
                                                                        if (!isAuthenticated) {
                                                                            setModal(
                                                                                <div className='flex flex-col items-center justify-center py-5'>
                                                                                    <motion.div
                                                                                        initial={{ rotate: 500, scale: 0, opacity: 0 }}
                                                                                        exit={{ rotate: 1, scale: 1, opacity: 0 }}

                                                                                        animate={{ rotate: 0, scale: 1, opacity: 1 }}
                                                                                        transition={{
                                                                                            damping: 20,
                                                                                            duration: 0.35
                                                                                        }}
                                                                                        className='border-4 border-app_red rounded-full w-20 h-20 flex justify-center items-center'>

                                                                                        <div><RiCloseLine className='w-16 h-16 text-app_red'></RiCloseLine></div>
                                                                                    </motion.div>
                                                                                    <div className='font-bold text-lg text-center pt-5'>Απόκτησε αυτή την προσφορά, κάνοντας είσοδο. Εάν δεν είσαι ήδη μέλος κάνε τώρα εγγραφή και απόλαυσε την επιβράβευση.</div>
                                                                                    <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                                                                                        onClick={() => { unSetModal() }}
                                                                                    >OK</div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        else {
                                                                            await handleρedemption(offer)
                                                                        }

                                                                    }}
                                                                >
                                                                    <p className="px-5 py-4 font-semibold text-white ">{'Αποκτηση'.toUpperCase()}</p>
                                                                </button>
                                                                <div className="flex items-center justify-center bg-gray-200 rounded-full opacity-50 h-14 w-14">
                                                                    <FaShare color='gray' size={18}></FaShare>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </motion.div>
                                                ))}
                                        </div>
                                )
                            }



                        </div>


                    </div>


                </div>



            </div>

        </div >
    )
}

export default AccountSettings