import React from 'react'
import useMunicipality from '../hooks/useMunicipality';

const AppDownloadSection = () => {
    const { municipality } = useMunicipality();
    if(municipality.MobileEnabled)
    return (
        <div className=' flex flex-col md:flex-row justify-center max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8'>
            <div className='flex flex-col items-center justify-center'>
                <div className=" border-gray-200 text-prim font-semibold text-2xl pb-1">Κατέβασε το Followgreen App δωρεάν</div>
                <div className="bg-gradient-to-r w-[100%] max-w-[300px] h-1 rounded-full from-app_green_light  to-app_green "></div>
                <div className=" border-gray-200 pt-3">Κέρδισε και εξαργύρωσε πόντους όπου κι'αν βρίσκεσαι</div>
                <div className="flex space-x-3 pt-3 justify-center">
                    <a className='w-32' href="https://apps.apple.com/gr/app/follow-green/id1478606193" target="_blank" rel="noreferrer">
                        <img className=" w-full mr-2" alt="app store badge" src={process.env.REACT_APP_BUCKET_URL + "/images/app-banner/app-store-badge.svg"} /></a>
                    <a className='w-36' href="https://play.google.com/store/apps/details?id=gr.followgreen&amp;hl=en" target="_blank" rel="noreferrer">
                        <img className=" w-full mr-2" alt="google play store badge" src={process.env.REACT_APP_BUCKET_URL + "/images/app-banner/google-play-badge.png"} /></a>
                </div>
            </div>
            <div className='w-100% flex justify-center'>
                <img
                className='!max-w-[350px]'
                src={process.env.REACT_APP_BUCKET_URL + '/Content/css/assets/followgreen-app.png'} alt="" />
            </div>
        </div>
    )
   else return null 
}

export default AppDownloadSection