
import { MdVideoCameraBack } from "react-icons/md";
import { useQuery } from 'react-query';
import { getAllNews } from '../api';
import { useState } from 'react';
import useMunicipality from '../hooks/useMunicipality';
import { CircularProgress } from '@mui/material';
import NotFind from './NotFind';
import { formatDate } from '../utils/utils';
import { Link } from "react-router-dom";



const News = ({isHomePage = false}) => {

    //Pagination configuration
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(isHomePage ? 6 : 0);

    //To handle response on municiupality changes
    const { municipality } = useMunicipality();

    const { isLoading, isError, error, data, isFetching, } =
        useQuery(['news', page, pageSize, municipality],
            () =>
                getAllNews({ limit: pageSize, page: page, optionalKeyword: '', municipalityId: municipality.MunicipalityId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                keepPreviousData: true
            })


    if (isLoading || isFetching)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA: {error.message} </p>
            </div>
        </div>

    if (data.length < 1)
        return <div className='flex p-8 justify-center h-full items-center'>
            <div className=' text-center'>
                <p className='texgt-md font-medium'>No Data To Load</p>
            </div>
        </div>



    return (
        <div className="bg-white">
            <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                {isHomePage ? null :<h2 className="text-3xl font-bold text-gray-700 ">Τα νέα του Δήμου μας</h2>}
                {/* <h2 className="text-lg font-normal text-gray-700">Διάβασε άρθρα, δοκίμασε τις γνώσεις σου σε κουίζ, παρακολούθησε βίντεο, καταχώρησε την ανακύκλωση που κάνεις και συγκέντρωσε όλο και περισσότερους πόντους επιβράβευσης.</h2> */}

                <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                    {data.rows.map((article) => (
                        <Link
                         key={article.NewsId} 
                         to={isHomePage ? './news/single/'+ article.FriendlyUrl : './single/'+ article.FriendlyUrl}
                         state={{ article: article, newsList:data.rows.slice(0, 8)} }
                         className="relative overflow-hidden duration-200 ease-out shadow-2xl cursor-pointer rounded-3xl group shadow-gray-500 hover:-translate-y-2"
                        
                        >
                            <div className="w-full rounded-md min-h-80 aspect-w-1 aspect-h-1  lg:aspect-none">
                                <div>
                                    <img
                                        src={(article.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + article.Image}
                                        alt={article.imageAlt}
                                        className="object-cover h-56 object-center w-full p-1.5 lg:w-full rounded-3xl"
                                    />
                                    <div className="absolute flex items-center justify-center rounded-2xl opacity-90 left-2 top-2 bg-red-400 p-2 text-white">
                                        {/* <MdVideoCameraBack color='white' size={18}></MdVideoCameraBack> */}
                                        {formatDate(article.StartDate)}
                                    </div>
                                </div>

                                <div className="p-2">
                                    <a href={article.href} className="flex justify-center text-md font-semibold text-center">

                                        {article.TitleEl}
                                    </a>
                                </div>
                            </div>
                            <div className="flex justify-center mb-5 text-center">
                                <p className="text-sm font-medium text-gray-900 p-3">{article.SubtitleEl}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default News