import React from "react";
import ReactDOM from "react-dom/client";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { ModalProvider } from "./contexts/ModalContext";
import { MunicipalityProvider } from "./contexts/MunicipalityContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import logo from "./assets/followgreen-app-logo.svg";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MunicipalityProvider>
        <BrowserRouter>
          <ModalProvider>
            <AuthProvider>
              <App />
              {/* <div className="flex flex-col justify-center h-screen items-center">
                <div>
                  <img
                    src={logo}
                    width={190}
                    height={50}
                    className={""}
                    alt="Logo"
                  />
                </div>
                <div className="font-semibold py-3 text-xl">Το followgreen αναβαθμίζεται</div>
                <div>Θα είμαστε σύντομα κοντά σας!</div>
              </div> */}
            </AuthProvider>
          </ModalProvider>

          <ScrollToTop />
          <ReactQueryDevtools></ReactQueryDevtools>
        </BrowserRouter>
      </MunicipalityProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
