import { Map, MapsUgc } from '@mui/icons-material';
import React, { useState } from 'react'
import { FaHeart, FaMapMarked, FaMapMarkedAlt, FaQuestion, FaShare } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/utils';
import seperator from '../assets/offer-edge.svg';
import useAuth from '../hooks/useAuth';
import { toggleFavoriteOffer } from '../services/favoritesService';
import { addCitizenOffer, getAllCitizenFavoritesOnly, getMerchantByUrl, getOfferByUrl } from '../api';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import { useModal } from '../contexts/ModalContext';
import { motion, useInView } from 'framer-motion';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import MarkerDragMap from '../components/shared/MarkerDragMap';

const SingleMerchant = () => {


    // const offer = useLocation().state.offer;

    // const merchant = useLocation().state.merchant

    //first we need the offer url
    const location = useLocation()

    const offerUrl = location.pathname.split('/')[4];

    //offer and merchant must be fetched
    const { isLoading, isError, data: merchant, } =
        useQuery(['singleoffer'], () => getMerchantByUrl({ url: offerUrl }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,

        })




    const [favoritesClicked, setFavoritesClicked] = useState(false)

    const navigate = useNavigate();

    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const { setModal, unSetModal } = useModal()


    const { isLoading: isCitizenFavoritesLoading, isError: isCitizenFavoritesError, data: citizenFavorites, isFetched, isFetching } =
        useQuery(['citizenFavoritesOnly', favoritesClicked], () => getAllCitizenFavoritesOnly({ citizenId: user !== null ? user.citizenId : null }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            onSuccess: (ftchedData) => {
              //console.log("FETCHED DATA: ", ftchedData)
            }
        })

    const checkIfFavorite = (offerId) => {
        //Prevent errors if user is not loged in
        if (!isAuthenticated) return 0;



        var citizenFavorite = citizenFavorites.filter(obj => {

            return obj.OfferId === offerId
        })

        if (citizenFavorite === null) return 0;


        try {
            if (citizenFavorite[0].IsFavorite === 1)
                return 1

        } catch (error) {
            return 0
        }

    }





    if (isLoading || isCitizenFavoritesLoading || !isFetched || isFetching)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isError || isCitizenFavoritesError)
        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA </p>
            </div>
        </div>

    return (
        <div className='flex flex-col min-h-screen w-full px-4 mx-auto lg:flex lg:px-8 xl:px-0 max-w-[1200px] mt-10 '>
            <div className='flex justify-between flex-col md:flex-row '>
                <div className='flex flex-col'>
                    <div className='font-semibold text-2xl pb-2 w-full text-gray-600'> {merchant.merchant.NameEl}</div>
                    <div className='pb-5 w-full flex text-gray-600 '>
                        <FaMapMarkedAlt className='mr-1 mt-0.5 text-[20px]'></FaMapMarkedAlt>
                        <div className='font-semibold text-gray-600'>{merchant.merchant.Address}</div>
                    </div>
                </div>

            </div>
            <div className='flex flex-col md:flex-row justify-between w-full   shadow-xl overflow-hidden rounded-3xl '>

                <div className='flex h-hull w-full md:w-[50%]'>


                         <MarkerDragMap center={{lat:merchant.merchant.AddressLatitude,lng:merchant.merchant.AddressLongitude}}></MarkerDragMap>
                    
                </div>

                <div className='flex flex-col mx-auto p-5 w-full items-center   md:w-[45%] justify-center'>
                    <div className='font-semibold text-2xl'> {merchant.merchant.TitleEl}</div>
                    <div className='font-semibold pb-5 p-5 text-center'> {merchant.merchant.DescriptionEl}</div>

                   {merchant.merchant.MerchantImage ? <img
                        className='relative flex w-full object-cover rounded-[30px] '
                        src={(merchant.merchant.MerchantImage.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + merchant.merchant.MerchantImage}
                        alt='' /> : null}
                    

                    <div className='justify-center flex flex-col whitespace-pre-line pb-10  ' >



                    </div>


                </div>


            </div>



        </div>
    )
}

export default SingleMerchant