const chart_brown = "#F5CE75";
const chart_green = "#82CB5D";
const chart_sum = "#bdb2ff";

const colors = {
  app_brown: "#f07e64",
  app_red: "#e35972",
  app_green: "#6fa84a",
  app_green_light: "#93BC78",
  app_yellow: "#f2c237",
  app_blue: "#49a3d4",
  app_blue_dark: "#136492",
  app_dark_green: "#098C5E",

  pie_yellow: "#ffd500ff",
  pie_orange: "#ff8717ff",
  pie_red: "#f53913ff",
  pie_pink: "#fc8bd2ff",
  pie_gray: "#dbdbdbff",
  pie_gray_dark: "#9e9e9eff",
  pie_orange_dark: "#ff6505ff",
  pie_green: "#a9e20cff",
};

module.exports = () => {
  return colors;
};
