import { ArrowUpward } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { NavigationType, useLocation, useNavigationType } from "react-router-dom";

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};


export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const { pathname } = useLocation();

  const isPop = useBackButton();

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(function(){
      scrollToTop()
  }, 5);
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [pathname,isPop]);



  return (
    <div className="fixed bg-pink bottom-3 right-3  cursor-pointer">
      {isVisible && (
        <div
          onClick={scrollToTop}
          className="fixed p-2 rounded-lg bg-app_yellow bottom-3 right-3 lg:bottom-5 lg:right-5 cursor-pointer"
        >
          <ArrowUpward></ArrowUpward>
        </div>
      )}
    </div>
  );
}