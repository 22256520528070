import React from 'react'

const PrintCoupon = () => {

    
  return (
    <div>
        <div class="row">
        <div class="panel panel-default coupon"  id="printable-area">
            <div class="panel-heading" id="head">
                <div class="panel-title text-center" id="title">
                    @*<i class="fa fa-navicon fa-2x"></i>*@
                    <span class="hidden-xs">ΚΟΥΠΟΝΙ ΠΡΟΣΦΟΡΑΣ</span>
                    <span class="visible-xs">ΚΟΥΠΟΝΙ ΠΡΟΣΦΟΡΑΣ</span>
                </div>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="pull-left">
                            <img src="@Model.Offer.Image" class="coupon-img img-rounded" alt="@Model.Offer.Title"/>
                        </div>
                        <div class="offer-description">
                            <span id="merchant">@Model.Merchant.Name</span>
                            <span id="offer">@Model.Offer.Title</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="well well-sm">
                            <div id="business-info">
                                <ul>
                                    <li><span><i class="fa fa-map-marker"></i> @Model.Merchant.Address</span></li>
                                    <li><span><i class="fa fa-envelope"></i> @Model.Merchant.Email</span></li>
                                    <li><span><i class="fa fa-phone"></i> @Model.Merchant.Phone</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table width="100%">
                            <tr>
                                <td align="left" valign="top">
                                    @Model.Offer.Description
                                </td>
                                <td align="right">
                                    {/* @Html.GenerateQrCode(System.Configuration.ConfigurationManager.AppSettings["BaseUrl"] + "/offer/" + Model.OfferId, 150, 150) */}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="disclosure">
                            {/* @Model.Offer.Terms */}
                        </p>
                    </div>
                </div>
            </div>
            <div class="panel-footer">
                <div class="coupon-code">
                    Κωδικός κουπονιού: @Model.Offer.Token
                    <span class="print">
                        <img src={process.env.REACT_APP_BUCKET_URL +"/images/coupon/followgreen-logo-colored.png"} alt="coupon logo"/>
                    </span>
                </div>
                {/* @{
                    var endDate = (Model.Offer.EndDate == null) ? "" : (Model.Offer.EndDate ?? DateTime.Now).ToShortDateString();
                    if (endDate != "")
                    {
                        <div class="exp">Λήξη: @endDate</div>
                    }
                } */}
            </div>
        </div>
        <div class="coupon-toolbox">
            <span class="text-center">
                <a href="/member/printcoupon/@Model.CitizenOfferId" id="btn-print"><i class="fa fa-lg fa-print"></i> Εκτύπωση κουπονιού</a>
            </span>
        </div>
    </div>
    </div>
  )
}

export default PrintCoupon