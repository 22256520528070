import React from 'react'


const termshtml = `<section class="become-a-volunteer">
<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="sec-title text-center">
                <h1> ΠΟΛΙΤΙΚΗ ΤΗΡΗΣΗΣ ΑΠΟΡΡΗΤΟΥ</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3 widget sidebar popular-categories hidden-sm hidden-xs hidden-md">
            
            <ul class="list space-y-2 flex flex-col items-center pt-5 pb-5 justify-center">
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Εισαγωγή" class="font-semibold sidelink">Εισαγωγή</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Ποια-στοιχεία-σας-επεξεργαζόμαστε" class="font-semibold sidelink">Ποια στοιχεία σας επεξεργαζόμαστε</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Σκοποί-επεξεργασίας" class="font-semibold sidelink">Σκοποί επεξεργασίας</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Ποιοι-λαμβάνουν-και-επεξεργάζονται-τα-στοιχεία-σας" class="font-semibold sidelink">Ποιοι λαμβάνουν και επεξεργάζονται τα στοιχεία σας</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Για-πόσο-χρόνο-διατηρούμε-τα-στοιχεία-σας" class="font-semibold sidelink">Για πόσο χρόνο διατηρούμε τα στοιχεία σας</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Ανήλικοι-Χρήστες" class="font-semibold sidelink">Ανήλικοι Χρήστες</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Διαδικτυακοί-Τόποι" class="font-semibold sidelink">Διαδικτυακοί Τόποι</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Ασφάλεια-Προστασία-Αποποίηση-Ευθύνης" class="font-semibold sidelink">Ασφάλεια και τρόποι προστασίας των δεδομένων – Αποποίηση ευθύνης &nbsp;</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Τα-δικαιώματά-σας" class="font-semibold sidelink">Τα δικαιώματά σας</a>
                </li>
                <li>
                    <span class="icon flaticon-blank36"></span>
                    <a href="#Αλλαγές-στην-Πολιτική-Απορρήτου" class="font-semibold sidelink">Αλλαγές στην Πολιτική Απορρήτου</a>
                </li>
            </ul>
            
        </div>
            <div class="col-lg-9 page-title">
                <h3><span id="Εισαγωγή"></span>Εισαγωγή</h3>
                <p style="text-align: justify;">Το&nbsp;πρόγραμμα&nbsp;FOLLOWGREEN είναι ένα πρόγραμμα ενημέρωσης - ευαισθητοποίησης για το περιβάλλον με έμφαση στην ανακύκλωση και την εξοικονόμηση ενέργειας, το οποίο έχει υλοποιηθεί, για λογαριασμό Δήμων της επικράτειας, μέσω της ιστοσελίδας&nbsp;<a href="http://www.followgreen.gr">www.followgreen.gr</a>&nbsp;(εφεξής “Ιστοσελίδα”). Η εν λόγω Ιστοσελίδα ανήκει στην εταιρεία με την επωνυμία “ΕΝΕΡΓΕΙΑ – ΠΕΡΙΒΑΛΛΟΝ – ΤΟΠΙΚΗ ΑΝΑΠΤΥΞΗ ΑΕ.” (εφεξής "Πάροχος" ή "Εμείς"), η οποία και διαχειρίζεται τη λειτουργία της.</p>
                <p style="text-align: justify;">Η προστασία των προσωπικών σας δεδομένων είναι προτεραιότητα για εμάς. Αυτή η Πολιτική Προστασίας Προσωπικών Δεδομένων εξηγεί τον τρόπο και το λόγο για τον οποίο χρησιμοποιούμε οποιεσδήποτε πληροφορίες σχετικά με εσάς (τα "Προσωπικά Δεδομένα"), σύμφωνα με την Ευρωπαϊκή νομοθεσία για την προστασία των δεδομένων.</p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Ποια-στοιχεία-σας-επεξεργαζόμαστε"></span>Ποια στοιχεία σας επεξεργαζόμαστε</h3>
                <p style="text-align: justify;">Ο Πάροχος συλλέγει και διατηρεί στοιχεία που μας παρέχετε όταν συμπληρώνετε την αίτηση για να ανοίξετε λογαριασμό στην ιστοσελίδα μας. Ειδικότερα, συλλέγουμε και επεξεργαζόμαστε τα ακόλουθα στοιχεία σας:</p>
                <ul style="text-align: justify;margin-left: 9px;">
                    <li>Προσωπικά στοιχεία, όπως ονοματεπώνυμο και ημερομηνία γέννησης </li>
                    <li>Στοιχεία επικοινωνίας, όπως διεύθυνση κατοικίας και email</li>
                    <li>Στοιχεία ταυτοποίησης, όπως αριθμός παροχής ηλεκτρικού ρεύματος</li>
                </ul>
                <p style="text-align: justify;">Επίσης, συγκεντρωτικά στοιχεία συλλέγονται από την πλοήγησή σας στην ιστοσελίδα και εμφανίζουν τις προτιμήσεις σας, τη συχνότητα των επισκέψεών σας, τη διάρκεια αυτών κ.ά. Τα στοιχεία αυτά συλλέγονται αυτόματα μέσω των cookies, όταν επισκέπτεστε την ιστοσελίδα και συνδυάζονται με παρεμφερή στοιχεία που έχουμε συλλέξει για άλλους επισκέπτες.</p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Σκοποί-επεξεργασίας"></span>Σκοποί για τους οποίους επεξεργαζόμαστε τα στοιχεία σας</h3>
                <p style="text-align: justify;">Επεξεργαζόμαστε τα στοιχεία σας για τους ακόλουθους σκοπούς:</p>
                <p style="text-align: justify;">Α) Εξαγωγή στατιστικών στοιχείων και αξιολόγηση του βαθμού &nbsp;ενημέρωσης – ευαισθητοποίησης των πολιτών αλλά και του βαθμού συμμετοχής τους στην ανακύκλωση και σε λοιπά περιβαλλοντικά θέματα με στόχο την αύξηση της περιβαλλοντικής επίδοσης στον Δήμο.</p>
                <p style="text-align: justify;">Β) Εφόσον μας έχετε δώσει τη συναίνεσή σας, ο Πάροχος δύναται να αποστέλλει ενημερωτικά email στη διεύθυνση ηλεκτρονικής αλληλογραφίας που έχετε δηλώσει κατά την εγγραφή σας, σχετικά με περιβαλλοντικά θέματα και θέματα ανακύκλωσης ή/και προσφορές τοπικών επιχειρήσεων για την εξαργύρωση των πόντων που συλλέγετε από τη συμμετοχή σας στο πρόγραμμα Followgreen.</p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Ποιοι-λαμβάνουν-και-επεξεργάζονται-τα-στοιχεία-σας"></span>Ποιοι λαμβάνουν και επεξεργάζονται τα στοιχεία σας</h3>
                <p style="text-align: justify;">
                    Πρόσβαση στα στοιχεία σας μπορούν να έχουν:<br>
                    α) κατάλληλα εξουσιοδοτημένοι υπάλληλοι του Παρόχου<br>
                    β) εξωτερικοί συνεργάτες του Παρόχου που παρέχουν υπηρεσίες πληροφορικής για την καταγραφή και αποθήκευση των στοιχείων σας ή προϊόντα λογισμικού (software)<br>
                    γ) εξουσιοδοτημένοι υπάλληλοι των δημοτικών αρχών που κάνουν χρήση του προγράμματος FOLLOWGREEN<br>
                    δ) τρίτα φυσικά ή νομικά πρόσωπα στα οποία ο Πάροχος έχει παραχωρήσει τη δυνατότητα χρήσης της πλατφόρμας FOLLOWGREEN, στο πλαίσιο σύμβασης των φυσικών ή νομικών αυτών προσώπων με Δήμους της επικράτειας
                </p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Για-πόσο-χρόνο-διατηρούμε-τα-στοιχεία-σας"></span>Για πόσο χρόνο διατηρούμε τα στοιχεία σας</h3>
                <p style="text-align: justify;">Θα διατηρήσουμε τα δεδομένα σας για ένα (1) έτος από την ημερομηνία που θα απενεργοποιήσετε το λογαριασμό σας στην Ιστοσελίδα. Στη συνέχεια, τα στοιχεία σας θα διαγράφονται, εκτός εάν δώσετε εκ νέου τη συγκατάθεσή σας για την επεξεργασία τους. Σε κάθε περίπτωση έχετε το δικαίωμα να ζητήσετε τη διακοπή της επικοινωνίας για προώθηση ενημερωτικών μηνυμάτων ή/και προσφορών οποιαδήποτε στιγμή.</p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Ανήλικοι-Χρήστες"></span>Ανήλικοι Χρήστες</h3>
                <p style="text-align: justify;">Δεν επιτρέπεται σε χρήστες που είναι ανήλικοι να μας παρέχουν προσωπικά δεδομένα και να εγγραφούν στην Ιστοσελίδα.</p>
                <p style="text-align: justify;">Η αποδοχή των όρων και των προϋποθέσεων και η παροχή προσωπικών δεδομένων εκ μέρους του χρήστη θα τεκμαίρει ότι αυτός είναι ενήλικος, ήτοι άνω των 18 ετών.</p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Διαδικτυακοί-Τόποι"></span>Σύνδεση με Διαδικτυακούς Τόπους Τρίτων</h3>
                <p style="text-align: justify;">Για τη διευκόλυνσή σας ενδέχεται να παρέχουμε συνδέσμους (links) με διαδικτυακούς τόπους τρίτων. Οφείλετε να εξετάσετε προσεχτικά τις πολιτικές απορρήτου/ ασφαλείας των τρίτων, στους οποίους παρέχει πρόσβαση η παρούσα Ιστοσελίδα. Λοιποί διαδικτυακοί τόποι που είναι προσβάσιμοι μέσω της παρούσας τοποθεσίας διαθέτουν τις δικές τους πολιτικές και μεθόδους συλλογής, χρήσης και γνωστοποίησης δεδομένων. Δεν φέρουμε ευθύνη για τις πολιτικές και πρακτικές των τρίτων. Οι πρακτικές περί πληροφοριών των διαδικτυακών αυτών τόπων, οι οποίοι συνδέονται μέσω της παρούσας Ιστοσελίδας, δεν καλύπτονται από την παρούσα Πολιτική Απορρήτου.</p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Ασφάλεια-Προστασία-Αποποίηση-Ευθύνης"></span>Ασφάλεια και τρόποι προστασίας των δεδομένων – Αποποίηση ευθύνης</h3>
                <p style="text-align: justify;">Εφαρμόζουμε αυστηρά όλα τα νομικά, οργανωτικά και τεχνικά μέτρα ασφάλειας για να προστατεύουμε τα προσωπικά σου στοιχεία από πρόσβαση από μη εξουσιοδοτημένα πρόσωπα και από την παράνομη επεξεργασία, τυχαία απώλεια, γνωστοποίηση, μεταβολή, καταστροφή ή ζημιά. Ωστόσο, παρά την αποτελεσματικότητα των μέτρων προστασίας που λαμβάνουμε, κανένα σύστημα ασφαλείας δεν είναι αδιαπέραστο. Η φύση του διαδικτύου είναι τέτοια που είναι αδύνατο να διασφαλιστεί ότι η πρόσβασή σας στην Ιστοσελίδα θα είναι αδιάκοπη ή ελεύθερη από σφάλματα ή ότι ο παρών διαδικτυακός τόπος, οι εξυπηρετητές του ή τα ηλεκτρονικά μηνύματα που αποστέλλονται από εμάς είναι ελεύθερα από ιούς και άλλα επιβλαβή στοιχεία. Για τον λόγο αυτό, αν οποιαδήποτε στιγμή αντιληφθείτε ότι η πλοήγησή σας στην ιστοσελίδα δεν είναι πλέον ασφαλής, παρακαλούμε να μας ενημερώσετε το συντομότερο δυνατό.</p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Τα-δικαιώματά-σας"></span>Τα δικαιώματά σας</h3>
                <p style="text-align: justify;">Ο Πάροχος διασφαλίζει τα δικαιώματά σας σχετικά με την επεξεργασία των προσωπικών σας δεδομένων και φροντίζει να σας διευκολύνει για να τα ασκήσετε.</p>
                <p style="text-align: justify;">Έχετε δικαίωμα:<br>Α) Να ζητήσετε πρόσβαση στα προσωπικά σας δεδομένα, και συγκεκριμένα μπορείτε να λάβετε πληροφορίες σχετικά με το ποια δεδομένα σας επεξεργαζόμαστε, τους σκοπούς για τους οποίους τα επεξεργαζόμαστε, τις κατηγορίες παραληπτών στους οποίους τα δεδομένα σας αποστέλλονται και την προγραμματισμένη περίοδο αποθήκευσης.</p>
                <p style="text-align: justify;">Β) Να ζητήσετε διόρθωση των προσωπικών σας δεδομένων εάν είναι ανακριβή ή ελλιπή.</p>
                <p style="text-align: justify;">Γ) Να ζητήσετε διαγραφή των προσωπικών σας δεδομένων, εκτός εάν η επεξεργασία των δεδομένων αυτών είναι απαραίτητη για την άσκηση των δικαιωμάτων του Παρόχου ή τρίτων, για την εκπλήρωση νομικής υποχρέωσης, για λόγους δημόσιου συμφέροντος ή για την προάσπιση των νόμιμων δικαιωμάτων μας ενώπιον δικαστικών ή άλλων Αρχών.</p>
                <p style="text-align: justify;">Δ) Να ζητήσετε περιορισμό της επεξεργασίας των προσωπικών σας δεδομένων μόνο για συγκεκριμένους σκοπούς.</p>
                <p style="text-align: justify;">Ε) Να ανακαλέσετε οποιαδήποτε στιγμή τη συγκατάθεσή σας για την επεξεργασία των προσωπικών σας δεδομένων για προώθηση ενημερωτικών μηνυμάτων ή/και προσφορών σε ειδική σελίδα στον Λογαριασμό σας. Στην περίπτωση αυτή, θα διακοπεί η επεξεργασία τους από εμάς για τους σκοπούς αυτούς, χωρίς αυτό να επηρεάζει τη νομιμότητα κάθε επεξεργασίας μέχρι την ανάκληση της συγκατάθεσής σας.</p>
                <p style="text-align: justify;">Τέλος, εάν θεωρείτε ότι παραβιάζονται τα δικαιώματά σας έχετε το δικαίωμα να προσφύγετε στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (Α.Π.Δ.Π.Χ.), τα στοιχεία επικοινωνίας της οποίας παρέχονται κατωτέρω.</p>
                <p style="text-align: justify;">Στοιχεία Επικοινωνίας “ΕΝΕΡΓΕΙΑ – ΠΕΡΙΒΑΛΛΟΝ – ΤΟΠΙΚΗ ΑΝΑΠΤΥΞΗ ΑΕ”: <br>Υπεύθυνος Προστασίας Δεδομένων (DPO): dpo@followgreen.gr</p>
                <p style="text-align: justify;">Στοιχεία Επικοινωνίας Αρχής Προστασίας Δεδομένων Προσωπικού Χαρακτήρα:<br>Λεωφ. Κηφισίας 1-3<br>Τ.Κ. 11523 Αθήνα<br>Τηλ. 2106475600<br>Fax: 2106475628<br>Ηλεκτρονικό Ταχυδρομείο: contact@dpa.gr</p>
                <p><strong>&nbsp;</strong></p>
                <h3><span id="Αλλαγές-στην-Πολιτική-Απορρήτου"></span>Αλλαγές στην Πολιτική Απορρήτου</h3>
                <p style="text-align: justify;">Σε περίπτωση που προβούμε σε τροποποίηση της παρούσας Πολιτικής Απορρήτου, οι αλλαγές θα δημοσιευθούν εγκαίρως στην παρούσα Ιστοσελίδα. Ο Πάροχος τηρώντας τις εκάστοτε προβλέψεις της νομοθεσίας, διατηρεί το δικαίωμα τροποποίησης της εν λόγω Πολιτικής ανά πάσα στιγμή. Ως εκ τούτου, σας συμβουλεύουμε να την ελέγχετε τακτικά για τυχόν αλλαγές. Τυχόν αλλαγές θα τεθούν σε ισχύ από τη στιγμή της δημοσίευσης της αναθεωρημένης Πολιτικής Απορρήτου στον παρόντα διαδικτυακό τόπο. Η χρήση της ιστοσελίδας μετά τις εν λόγω αλλαγές συνιστά την από μέρους σας αποδοχή της Πολιτικής Απορρήτου, όπως θα ισχύει κατά τον χρόνο εκείνο με τις εκάστοτε αναθεωρήσεις – τροποποιήσεις.</p>
                <p><strong>&nbsp;</strong></p>
                <p><br><br></p>
            </div>
                </div>
</div>
</section>`;

const Policy = () => {
  return (
    <div className='max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8'>

        
<div  className='max-w-none' dangerouslySetInnerHTML={{ __html: '<div>' + termshtml.replaceAll(`/images/`,process.env.REACT_APP_BUCKET_URL + `/images/`) }}></div>

    </div>
  )
}

export default Policy