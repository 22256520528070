import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/followgreen-app-logo.svg';
import useMunicipality from '../hooks/useMunicipality';

const ComingSoon = () => {

    const { municipality } = useMunicipality();

    return (
        <div className="h-screen  flex flex-col items-center mt-12 space-y-5 mx-3 text-center">
            <div className="max-w-lg w-[200px] md:w-[300px]">
                <img
                    src={logo}
                    className="flex w-full"
                    alt="Logo"
                />
            </div>
            <img
                            
                            src={'https://followgreen.fra1.digitaloceanspaces.com/Content/css/assets/muncipality-images/new_comming_soon.jpg'}
                            width={655}
                            // height={50}
                            alt=""
                            className='rounded-3xl'
                        />
            <h1 className="text-3xl font-bold text-app_green_light">{municipality.NameEl}</h1>
            <h1 className="text-2xl font-bold text-app_green">Η σελίδα θα είναι κοντά σας σε λίγες μέρες.</h1>
           
            {/* <p className="mb-8">But dont worry, you can find plenty of other things on our homepage.</p> */}

            <Link to={`../`} className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-orange-400  hover:bg-orange-500">Επιστροφή στην αρχική σελίδα</Link>
        
        
        </div>
    )
}

export default ComingSoon