import { QuestionAnswer } from '@mui/icons-material';
import { CircularProgress, duration } from '@mui/material';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { addCitizenMissionAction, checkIfEarned, getCompleteQuiz, getQuizByUrl } from '../api';
import { formatDate, generateRandomId } from '../utils/utils';
import { motion } from 'framer-motion';
import QuizQuestion from '../components/QuizQuestion';
import useAuth from '../hooks/useAuth';
import { addTransaction } from '../services/transactionService';
import useMunicipality from '../hooks/useMunicipality';

const SingleQuiz = () => {

    // const quiz = useLocation().state.post;
    // const quizList = useLocation().state.postList;

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [currentQuestion, setCurrentQuestion] = useState(0)

    const [correctAnswersCount, setCorrectAnswersCount] = useState(0)

    //NotStarted = 0, Started = 1, Completed = 2 
    const [quizState, setQuizState] = useState(0)


    const transactionType = 4;

    const { login, user, isAuthenticated, updateUserValues } = useAuth();

    const [isEarned, setIsEarned] = useState(false);



    const { municipality } = useMunicipality();

    const location = useLocation()

    const quizUrl = location.pathname.split('/')[4];

    //offer and merchant must be fetched
    const { isLoading: isQuizLoading, isError: isQuizError, data: quiz, } =
        useQuery(['singlequiz',], () => getQuizByUrl({ url: quizUrl, municipalityId: municipality.MunicipalityId }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })


    const submitAnswer = (isCorrect) => {
        if (isCorrect) {
            setCorrectAnswersCount(correctAnswersCount + 1)
        }

        if (currentQuestion < quiz.questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1)
        }
        else {
            if (correctAnswersCount >= quiz.questions.length - 2) {
                // alert('Μπράβο, τελείωσες το κουίζ!')
                handlePoints()
            }
            setQuizState(2)
        }
    }
    // console.log("CORRECT ANSWERS: ", correctAnswersCount)

    // const { isLoading, isError, error, data: quiz.questions, isFetching, } =
    //     useQuery(['complete_quiz',],
    //         () =>
    //             getCompleteQuiz({ quizId: quiz.quiz.QuizId }),
    //         {
    //             refetchOnWindowFocus: false,
    //             refetchIntervalInBackground: false,
    //             // /keepPreviousData: true
    //         })

    const { isLoading: isLoadingEarned, isError: isErrorEarned, error: errorEarned, data: isEarnedData, isFetching: isFetchingEarned, } =
        useQuery(['check_if_earned', quiz],
            () =>
                !quiz ? null : checkIfEarned({ citizenId: user !== null ? user.citizenId : null, transactionType: transactionType, earnId: quiz.quiz.QuizId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                // /keepPreviousData: true
            })

    const handlePoints = async () => {
        try {

            if (isAuthenticated) {
                if (!isEarnedData) {
                    alert('Κέρδισες πόντους για το mission!')
                    await addTransaction({ citizenId: user.citizenId, points: quiz.quiz.Points, transactionType: transactionType, earnId: quiz.quiz.QuizId })
                    updateUserValues();
                    if (searchParams.get('mission')) {
                        handleMission()
                    }
                }
                else {
                    if (searchParams.get('mission')) {
                        handleMission()
                    }
                }
                setIsEarned(true);


            }


        } catch (error) {

        }

    }

    const handleMission = async () => {

        try {
            await addCitizenMissionAction(
                {
                    citizenId: user.citizenId,
                    citizenMissionId: searchParams.get('CitizenMissionId'),
                    goalId: searchParams.get('MissionGoalId'),
                    targetId: quiz.quiz.QuizId,

                }).then((e) => {

                    // setUserMission(true);
                })
            updateUserValues()
            setIsEarned(true);
            await new Promise(resolve => setTimeout(resolve, 100));
            window.scrollTo({
                top: 0,
                behavior: 'smooth',

            });

        } catch (error) {

        }

    }


    if (isQuizLoading || isLoadingEarned || isFetchingEarned)
        return <div
            className='flex justify-center items-center h-screen'><CircularProgress></CircularProgress></div>

    if (isQuizError || isErrorEarned) {

        return <div
            className='flex p-8 justify-center h-screen items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ERROR LOADING DATA </p>
            </div>
        </div>
    }


    return (
        <div className='grid mx-auto grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-6 justify-center w-full md:w-[90%]  lg:w-[90%] max-w-[1200px] py-8 px-5 md:px-0  space-x-8 min-h-screen'>
            <div className='flex-col col-span-4  items-center  justify-center mb-5 '>
                <div className='font-semibold text-2xl pb-5 w-full'> {quiz.quiz.TitleEl}</div>

                <div className='flex relative  min-h-[450px] rounded-t-3xl '>

                    {(() => {
                        if (quizState === 0) {
                            return <div className='absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
                                <div
                                    onClick={() => {
                                        setQuizState(1);
                                    }}
                                    className=" flex  items-center cursor-pointer justify-center px-5 text-white h-12 bg-opacity-100 rounded-3xl shadow-md opacity-100 font-bold right-[42%] bottom-[40%] bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                                    Ξεκίνα το κουίζ
                                </div>
                            </div>
                        }
                        if (quizState === 1) {
                            return <div className='absolute w-full h-full backdrop-blur-md rounded-t-3xl overflow-hidden'>
                                <QuizQuestion question={quiz.questions[currentQuestion]} submitAnswer={submitAnswer}></QuizQuestion>
                            </div>
                        }
                        if (quizState === 2) {
                            return <div className='absolute w-full h-full  p-4 md:p-32 backdrop-blur-md rounded-t-3xl overflow-hidden'>
                                {!isAuthenticated
                                    ?
                                    <>
                                        <div className='text-xl font-semibold text-center text-white'>Για να κερδίζεις πόντους διαβάζοντας άρθρα, βλέποντας βίντεο και παίζοντας με κουίζ θα πρέπει να έχεις κάνει εγγραφή - εάν ο Δήμος σου συμμετέχει στο followgreen!</div>
                                        <div className='justify-center flex whitespace-pre-line px-8  cursor-pointer' onClick={() => {
                                            navigate('../../../account/register')
                                        }}>
                                            <div className='text-white text-lg font-semibold p-2 mt-5 px-3 bg-[#E94E1B] rounded-3xl'>Εγγραφη</div>
                                        </div>
                                    </>
                                    :
                                    <div className='relative'> <div className='w-full h-[380px]'>
                                        {(isEarnedData || !isEarned) ? null :

                                            <div className='flex flex-col md:flex-row items-center text-center justify-center text-md pb-5 font-semibold text-white'><div className='w-9 h-9 p-0 mr-2 font-semibold flex justify-center items-center border-4 border-app_red rounded-full'>{quiz.quiz.Points}</div> πόντοι προστέθηκαν στον λογαριασμό σου!</div>
                                        }
                                        <div className='text-md md:text-xl font-semibold text-center text-white'>Μπορείς να κερδίσεις περισσότερους πόντους διαβάζοντας άρθρα, βλέποντας βίντεο και παίζοντας με κουίζ!</div>
                                        <div className='justify-center flex whitespace-pre-line px-8 pt-8 cursor-pointer' onClick={() => {
                                            // window.history.back() 
                                            if (searchParams.get('mission')) {
                                                if (searchParams.get('MissionRound') === '2')
                                                    navigate('/../mission/single/currnet2');
                                                else
                                                    navigate('/../mission/single/currnet');
                                            }
                                            else {
                                                navigate(-1)
                                            }

                                        }}>
                                            {searchParams.get('mission') ? <div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'>Συνέχεια στο Mission</div>
                                                :
                                                <div className='text-white text-lg font-semibold p-2 px-3 bg-[#E94E1B] rounded-3xl'> Περισσότερα</div>}
                                        </div>

                                    </div>

                                    </div>}

                            </div>
                        }
                    })()}



                    <img
                        className='shadow-xl object-cover w-full !rounded-t-3xl overflow-hidden'
                        src={(quiz.quiz.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + quiz.quiz.Image}
                        alt='' />




                    <div className="absolute flex items-center justify-center w-16 h-16 bg-opacity-100 rounded-full shadow-md opacity-100 right-3 -bottom-8 bg-gradient-to-b from-green-400 via-blue-500 to-green-500 ">
                        <div className="absolute flex items-center justify-center font-bold text-gray-800 bg-white border-2 border-white rounded-full shadow-inner shadow-gray-200 w-11 h-11 text-md border-bg-gradient-to-r from-cyan-500 to-blue-500 hover:animate-ping">{quiz.quiz.Points}</div>
                    </div>


                </div>
                <div className='w-full text-white bg-gradient-to-r from-app_blue  to-app_blue_dark  font-sdemibold text-sm sm:text-md lg:text-xl px-10 py-4 text-center'> {quiz.quiz.SubtitleEl}</div>

                {/* <div className='flex pt-3' dangerouslySetInnerHTML={{ __html: '<div>' + quiz.BodyEl }}></div> */}
            </div>



            <div className='flex-col  col-span-2 items-center  justify-center  divide-dashed '>
                <div className='font-semibold text-xl pb-5 w-full'>Απάντησε περισσότερα</div>
                {quiz.quizList.map((readMoreArticle, index) => {
                    if (readMoreArticle.QuizId !== quiz.quiz.QuizId)
                        return <div className='flex  mb-8   justify-start place-items-start '>

                            <div className='w-30 h-20 relative'><img
                                className='object-cover shadow-lg h-full rounded-xl'
                                src={(readMoreArticle.Image.includes('digitalocean') ? "" : process.env.REACT_APP_BUCKET_URL) + readMoreArticle.Image}
                                alt='' />

                            </div>

                            <div className='flex flex-col h-20 w-20 justify-between'>
                                <div className='w-48 text-[13px]  pl-2 font-semibold'>{readMoreArticle.TitleEl}</div>

                                <Link
                                    to={'../' + readMoreArticle.FriendlyUrl}
                                    state={{ post: readMoreArticle, postList: quiz.quizList }}
                                    className='cursor-pointer pl-2 text-[14px] font-semibold'>{'περισσότερα '}

                                </Link>
                            </div>
                        </div>
                    else return null
                })}
            </div>

        </div>
    )
}

export default SingleQuiz