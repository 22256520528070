import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { ArrowBackIos, ArrowRight, ArrowRightAlt, ArrowRightAltTwoTone, Visibility, VisibilityOff } from '@mui/icons-material';
// import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, Grid, InputAdornment, TextField } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
// import { Paragraph } from 'app/components/Typography';
import useAuth from '../hooks/useAuth';
import { Formik, replace } from 'formik';
import { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '../components/Loading';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import useMunicipality from '../hooks/useMunicipality';
import { useQuery } from 'react-query';
import { getMunicipalityByLocality, resetPasswordEmailRequest } from '../api';
import { useModal } from '../contexts/ModalContext';
import { RiCheckLine } from 'react-icons/ri';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));



// inital login credentials
const initialValues = {
    email: '',
   
};



// form field validation schema
const validationSchema = Yup.object().shape({
   
    email: Yup.string().email('Η διεύθυνση email δεν είναι έγκυρη').required('Παρακαλώ εισάγετε διεύθυνση email'),
});

const ResetPasswordRequest = ({ isLandingPage = false, event: handleBackToLogin }) => {
   
    
    const [loading, setLoading] = useState(false);
    const [isWrong, setIsWrong] = useState(false);
    
    const [errorMessage, setErrorMessage] = useState('Λάθος email ή κωδικός πρόσβασης');  

    const { setModal, unSetModal } = useModal()   

    const handleFormSubmit = async (values) => {
        setLoading(true);
        try {

            
            await resetPasswordEmailRequest({email: values.email})
            setModal(
                <div className='flex flex-col items-center justify-center py-5'>
                  <motion.div
                    initial={{ rotate: 500, scale: 0, opacity: 0 }}
                    exit={{ rotate: 1, scale: 1, opacity: 0 }}
      
                    animate={{ rotate: 0, scale: 1, opacity: 1 }}
                    transition={{
                      damping: 20,
                      duration: 0.35
                    }}
                    className='border-4 border-app_green_light rounded-full w-20 h-20 flex justify-center items-center'>
      
                    <div><RiCheckLine className='w-16 h-16 text-app_green_light'></RiCheckLine></div>
                  </motion.div>
                  <div className='font-bold text-lg text-center pt-5'>Μόλις λάβατε ένα email στο {values.email} για να επαναφέρετε τον κωδικό πρόσβασης.</div>
                  <div className='flex justify-center cursor-pointer py-3 md:mx-2 mt-6 text-center text-white font-semibold hover:text-white bg-gradient-to-r p-[6px] from-app_green to-app_green_light  transition ease-in-out  rounded-xl md:w-[150px] hover:shadow-lg    hover:-translate-y-1 hover:scale-110 duration-150'
                    onClick={() => {
                      // resetForm();
                      unSetModal();
                    }}
                  >OK</div>
                </div>
              )

            setIsWrong(false)
            setLoading(false);

        } catch (e) {
            
           
                setErrorMessage('Ώχ! Κάτι πήγε στραβά!')
            
            setIsWrong(true)
            setLoading(false);
        }
    };



    return (



        < motion.div

            initial={
                { x: 300, }
            }
            animate={
                {
                    x: 0,
                    transition: {
                        type: "spring",
                        bounce: 0.6,
                        duration: 1
                    }
                }
            }
            exit={
                { x: 300, }
            }
            
            className={`${!isLandingPage ? 'my-[10%]' : ''} relative bg-white flex justify-center `} >
           
            <div className="z-10 relative !shadow-2xl h-full rounded-3xl  bg-white overflow-hidden flex flex-col justify-center items-center">
                <div className='flex flex-col justify-center items-center'>
                    <div className='pt-10 font-semibold text-xl text-gray-400'>Επαναφορά κωδικού πρόσβασης </div>
                    <div className="bg-gradient-to-r w-72 mt-1 h-2 rounded-full from-app_yellow  to-app_brown shadow-lg   shadow-red-500"></div>
                </div>
                    <div className='p-5 w-[80%] text-gray-400'>Εισάγετε το email σας και θα σας αποστείλουμε μήνυμα για την επαναφορά του κωδικού πρόσβασης </div>

                {isWrong ? <div className='pt-5 px-5 w-[80%] text-red-400'>{errorMessage}</div> : null}

                <div >
                    <div className='bg-white p-5 px-16  h-auto w-auto'>
                        <Formik
                            enableReinitialize
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        // enableReinitialize={false}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>

                                    <TextField
                                        className='group !group:border-red-300'
                                        fullWidth
                                        size="small"
                                        type="email"
                                        name="email"
                                        label="Διεύθυνση email"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        value={values.email}
                                        onChange={handleChange}
                                        helperText={touched.email && errors.email}
                                        error={Boolean(errors.email && touched.email)}
                                        sx={{
                                            mb: 3,
                                            // "& .MuiOutlinedInput-notchedOutline": {
                                            //     borderColor: "#F19853"
                                            // }
                                        }}
                                    />

                                    

                                   
                                   

                                    <LoadingButton
                                        className='!bg-gradient-to-r from-app_yellow to-app_brown !shadow-orange-300 !shadow-xl !drop-shadow-none'
                                        type="submit"

                                        loading={loading}
                                        variant="contained"
                                        sx={{ my: 2 }}
                                    >
                                        <div className='capitalize pr-2'> Υποβολή</div>
                                        <ArrowRightAlt></ArrowRightAlt>
                                    </LoadingButton>

                                    <div className='mt-5 flex justify-center cursor-pointer'>

                                        <div
                                            onClick={()=>{
                                                handleBackToLogin()
                                            }}

                                        >
                                            Πίσω στην είσοδο
                                        </div>
                                    </div>

                                </form>
                            )}
                        </Formik>


                    </div>
                </div>

            </div>
        </ motion.div>



    )
}

export default ResetPasswordRequest